import PropTypes from "prop-types";
import { Fragment, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from "../../components/shared/MenuPopover";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authActionCreators, authenticationActionCreators } from "../../redux/auth/auth.store";


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const AccountPopover = ({menuConfig = [], ...props}) => {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={props.authReducer.user.profileImageUrl} alt={props.authReducer.user.displayName}/>
      </IconButton>

      <button/>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
            height: 42,
          },
        }}
      >
        <Box sx={{my: 2.5, px: 2.5}}>
          <Typography variant="subtitle2" sx={{color: "text.primary"}} noWrap>
            {props.authReducer.user.displayName}
          </Typography>
          <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
            {props.authReducer.user.email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: "dashed"}}/>

        {menuConfig.length > 0 && (
          <>
            <Stack sx={{p: 1}}>
              {menuConfig.map((option) => {
                if (option.hasOwnProperty("isAdmin") && !option.isAdmin(props.authReducer.user.isAdmin)) {
                  return <Fragment key={option.label}/>;
                } else
                  return (
                    <MenuItem component={Link} key={option.label} to={option.linkTo} onClick={handleClose}>
                      {option.label}
                    </MenuItem>
                  );
              })}
            </Stack>
            <Divider sx={{borderStyle: "dashed"}}/>
          </>
        )}

        <MenuItem sx={{m: 1}} onClick={props.logout}>
          로그아웃
        </MenuItem>
      </MenuPopover>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  },
);
export default enhance(AccountPopover);

AccountPopover.propTypes = {
  menuConfig: PropTypes.array,
};
