import { FAILED, IDLE, PENDING, SUCCEEDED } from "../../constants/status";


export const authActions = {

  SIGN_IN_WITH_EMAIL_OR_PHONE: "SIGN_IN_WITH_EMAIL_OR_PHONE",
  LOAD_MY_INFO: "LOAD_MY_INFO",
  AUTH_FULFILLED: "AUTH_FULFILLED",
  AUTH_REJECTED: "AUTH_REJECTED",
  SIGN_OUT: "SIGN_OUT",
  // TODO 위에 것은 없애야함


  APP_INITIALIZE: "APP_INITIALIZE",

  SIGN_IN: "SIGN_IN",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",

  FETCH_USER_ME_STAT: "FETCH_USER_ME_STAT",
  FETCH_USER_ME_STAT_SUCCESS: "FETCH_USER_ME_STAT_SUCCESS",

  LOGOUT: "LOGOUT",

  FETCH_MY_INFO: "FETCH_MY_INFO",
  FETCH_MY_INFO_SUCCESS: "FETCH_MY_INFO_SUCCESS",

  FETCH_NOTIFICATION: "FETCH_NOTIFICATION",
  FETCH_NOTIFICATION_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
}


// action creators
export const signIn = ({email, password, schoolId, remember}) => ({type: authActions.SIGN_IN, payload: {email, password, schoolId, remember}});
export const signInWithEmailOrPhone = ({user, token}) => ({type: authActions.SIGN_IN_WITH_EMAIL_OR_PHONE, payload: {user, token}});
export const authFulfilled = (result) => ({type: authActions.AUTH_FULFILLED, payload: result});
export const authRejected = (error) => ({type: authActions.AUTH_REJECTED, payload: error, error: true});
export const signOut = () => ({type: authActions.SIGN_OUT});

// 다른 코드에 지장 없음
export const authenticationActionCreators = {
  signIn: ({email, phoneNumber, password, schoolId, remember, loginType}) => ({type: authActions.SIGN_IN, payload: {email, phoneNumber, password, schoolId, remember, loginType}}),
  signInWithEmailOrPhone: ({user, token}) => ({type: authActions.SIGN_IN_WITH_EMAIL_OR_PHONE, payload: {user, token}}),
  signOut: () => ({type: authActions.SIGN_OUT}),
};

export const authActionCreators = {
  appInitialize: (cb) => ({type: authActions.APP_INITIALIZE, cb}),
  fetchMyInfo: (cb) => ({type: authActions.FETCH_MY_INFO, cb}),
  fetchMyInfoSuccess: ({user, cb}) => ({type: authActions.FETCH_MY_INFO_SUCCESS, user, cb}),
  logout: (cb) => ({type: authActions.LOGOUT, cb}),
  fetchUserMeStat: (cb) => ({type: authActions.FETCH_USER_ME_STAT, cb}),
  fetchUserMeStatSuccess: ({stat, cb}) => ({type: authActions.FETCH_USER_ME_STAT_SUCCESS, stat, cb}),

  fetchNotification: (cb) => ({type: authActions.FETCH_NOTIFICATION, cb}),
  fetchNotificationSuccess: ({notifications, cb}) => ({type: authActions.FETCH_NOTIFICATION_SUCCESS, cb, notifications}),

};

// reducer
const initialState = {
  status: IDLE,  // TODO 없앨 것
  errorMessage: "", // TODO 없앨 것

  isLoggedIn: false,
  user: {},
  stat: {
    bookReportCount: 0,
    courseRegisterCount: 0,
    classRegisterCount: 0,
    userBookCount: 0,
  },
  notifications: [],
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SIGN_IN:
      return {
        ...state,
        status: PENDING,
      };
    case authActions.SIGN_IN_WITH_EMAIL_OR_PHONE:
      return {
        ...state,
        status: PENDING,
      };
    case authActions.LOAD_MY_INFO:
      return {
        ...state,
        status: PENDING,
      };
    case authActions.AUTH_FULFILLED:
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: action.payload.isLoggedIn,
        status: SUCCEEDED,
      };
    case authActions.AUTH_REJECTED:
      return {
        ...state,
        isLoggedIn: false,
        status: FAILED,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case authActions.SIGN_OUT:
      return {
        ...initialState,
        status: SUCCEEDED,
      };

    case authActions.FETCH_MY_INFO_SUCCESS:
      return {
        ...state,
        user: {...state.user, ...action.user},
        isLoggedIn: true,
      };

    case authActions.FETCH_USER_ME_STAT_SUCCESS:
      return {
        ...state,
        stat: {...state.stat, ...action.stat},
      };
    case authActions.FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.notifications || [],
      };

    case authActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};
