import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  Button,
  Container,
  Pagination,
  PaginationItem,
  Table,
  TextField,
  Stack,
  TableHead,
  Snackbar,
  TableBody,
  Box,
  TableRow,
  InputAdornment,
  MenuItem,
  Paper,
  Alert,
  TableContainer,
  InputBase,
  IconButton,
  Card, Typography, TableCell,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import numeral from "numeral";
import {useLocation, useNavigate} from "react-router-dom";
import * as XLSX from "xlsx-js-style";
import dayjs from "dayjs";
import _ from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import Network from "../../lib/Network";

const AdminSubjectCreatePage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [snackbarState, setSnackbarState] = useState({open: false, message: '', key: '', color: ''});
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState('')

  const _load = async () => {

  }


  const _upload = async (e) => {
    e.preventDefault?.();
    setUploading(true);
    try {
      const res = await Network.subjectPOST({title: title.trim()})
      setTitle('');
      _handleSnackbarOpen({key: res.subejct?.id, message: `[${res.subject?.title}]등록되었습니다.`, color: 'success'})
    } catch (e) {
      window.alert(e.cause.errorMessage);
      _handleSnackbarOpen({key: 'error', message: '등록에 실패하였습니다.', color: 'error'})
    } finally {
      setUploading(false);
    }
  }

  useEffect(() => {
    _load();

  }, [])

  const _handleSnackbarOpen = ({key, message, color}) => {
    setSnackbarState({key, message, open: true, color});
  }
  const _handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(prev => ( {...prev, open: false} ));
  }

  return (
    <div>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={snackbarState.open}
        onClose={_handleSnackbarClose}
        key={snackbarState.key}
      >
        <Alert onClose={_handleSnackbarClose} severity={snackbarState.color} sx={{width: '100%'}}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
      <Container maxWidth={"lg"}>
        <Typography
          gutterBottom
          variant={"h5"}
        >
          과목 등록
        </Typography>
      </Container>
      <form>
        <Container maxWidth={"lg"}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>과목명</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <InputBase
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="과목명"
                    sx={{width: "100%"}}
                    inputProps={{}}
                  />
                </TableCell>
                <TableCell>
                  <LoadingButton
                    type={'submit'}
                    loading={uploading}
                    variant={"contained"} onClick={_upload}>
                    추가
                  </LoadingButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Container>
      </form>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminSubjectCreatePage);
