import PropTypes from "prop-types";
import React from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar,IconButton } from "@mui/material";
// hooks
import useResponsive from "../../assi-design-system-react/mui/hooks/useResponsive";
// utils
// config
import { HEADER, NAVBAR } from "../../config/mui.config";
// components
//
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { MenuRounded } from '@mui/icons-material';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isCollapse" ,
})(({ isCollapse, theme }) => ({
  // ...cssStyles(theme).bgBlur(),
  background: theme.palette.background.paper,
  boxShadow: "none",
  borderBottomWidth: 1,
  borderColor: theme.palette.grey[500_24],
  // borderBottomStyle: "dashed",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  /*transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),*/
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
  },
}));

// ----------------------------------------------------------------------

const Header = ({ onToggleSidebar, onOpenSidebar, isCollapse = false, accountPopoverConfig, ...props }) => {
  const isDesktop = useResponsive("up", "lg");

  return (
    <RootStyle isCollapse={isCollapse}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {!isDesktop && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={onToggleSidebar}
            edge='start'
            sx={{ color: 'text.primary' }}
          >
            <MenuRounded />
          </IconButton>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <NotificationsPopover />
          <AccountPopover menuConfig={accountPopoverConfig} />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
};

export default Header;

Header.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  accountPopoverConfig: PropTypes.array,
};
