import { createLogic } from "redux-logic";
import NetworkStudent from '../../lib/NetworkStudent';
import { notificationStudentActionCreators, notificationStudentActions } from './notification-student.store';

const LIMIT = 50;

const fetchNotificationStudentLogic = createLogic({
  type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    try {
      const res = await NetworkStudent
        .notificationsGET({offset: 0, limit: LIMIT});
      dispatch(notificationStudentActionCreators.fetchNotificationStudentSuccess(
        {
          notifications: [...res.notifications],
          endReached: res.notifications?.length < LIMIT,
        }));
    } catch (e) {

    } finally {
      action.cb?.();
      done();
    }

  },
})

const fetchNotificationStudentMoreLogic = createLogic({
  type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION_MORE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {notifications, endReached} = getState().notificationStudentReducer;

    try {
      if (!endReached) {
        const res = await NetworkStudent
          .notificationsGET({offset: notifications.length, limit: LIMIT});
        dispatch(notificationStudentActionCreators.fetchNotificationStudentMoreSuccess(
          {
            notifications: [...res.notifications],
            endReached: res.notifications?.length < LIMIT,
          }));
      } else {
        dispatch(notificationStudentActionCreators.fetchNotificationStudentMoreSuccess(
          {
            notifications: [],
            endReached: true,
          }))
      }

    } catch (e) {

    } finally {
      action.cb?.();
      done();
    }

  },
})


export default [
  fetchNotificationStudentLogic,
  fetchNotificationStudentMoreLogic,
]
