import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {questionTypeText} from '../../constants/dataTypes';
import {styled} from '@mui/material/styles'
import {
  Button, Container, Pagination, PaginationItem, Paper, Table,
  ImageList,
  ImageListItem,
  Dialog, DialogTitle, DialogContent, DialogContentText, InputBase, DialogActions,
  Stack, Box, Chip, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, TextField, List, ListItem, ListItemAvatar, Avatar, IconButton, LinearProgress
} from "@mui/material";
import _ from 'lodash';
import numeral from 'numeral';
import * as Icon from '@mui/icons-material'
import {fileUploadToFirebase} from '../../utils/fileUploadToFirebase';
import LoadingButton from '@mui/lab/LoadingButton';
import {moveItemInArrayFromIndexToIndex} from '../../assi-design-system/utils/array';
import dayjs from 'dayjs';


const Input = styled('input')({
  display: 'none',
});


const StudentBookReportWriteAnswerComponent = (props) => {

  const [opening, setOpening] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({total: 0, current: 0, bytesTransferred: 0, totalBytes: 0});
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState('');


  const {answer} = props;
  if (!answer) return null;

  const _handleSelectImages = async (e) => {
    // console.log(e.target.files);
    setUploadingImage(true);
    setUploading(true);
    props.onUploadSetUploading(true);
    const imageUrls = [...answer.imageUrls];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const uploadedFile = await fileUploadToFirebase({
        bucketName: `school/${props.auth?.user?.schoolId}/user/${props.auth.user.id}/images/${dayjs().format('YYYYMM')}`,
        file: file,
        onProgress: (bytesTransferred, totalBytes) => setUploadStatus(prev => ({
          total: e.target.files.length,
          current: i + 1,
          bytesTransferred: bytesTransferred,
          totalBytes: totalBytes
        }))
      })
      // console.log({uploadedFile})
      imageUrls.push(uploadedFile);
    }
    answer.imageUrls = imageUrls;
    props.onChange(answer);
    setUploadingImage(false);
    setUploading(false);
    props.onUploadSetUploading(false);
  }
  const _handleSelectVideo = async (e) => {
    // console.log(e.target.files);
    if (e.target.files?.length < 1) return;
    setUploadingVideo(true);
    setUploading(true);
    props.onUploadSetUploading(true);
    const file = e.target.files[0];
    const uploadedFile = await fileUploadToFirebase({
      file: file,
      bucketName: `school/${props.auth?.user?.schoolId}/user/${props.auth.user.id}/videos/${dayjs().format('YYYYMM')}`,
      onProgress: (bytesTransferred, totalBytes) => setUploadStatus(prev => ({
        total: 1,
        current: 1,
        bytesTransferred: bytesTransferred,
        totalBytes: totalBytes
      }))
    })
    answer.videoUrls = [uploadedFile];
    props.onChange(answer);
    setUploadingVideo(false);
    setUploading(false);
    props.onUploadSetUploading(false);
  }
  const _handleSelectFiles = async (e) => {
    setUploadingFile(true);
    setUploading(true);
    props.onUploadSetUploading(true);
    const fileUrls = [...answer.fileUrls];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const uploadedFile = await fileUploadToFirebase({
        file: file,
        bucketName: `school/${props.auth?.user?.schoolId}/user/${props.auth.user.id}/files/${dayjs().format('YYYYMM')}`,
        onProgress: (bytesTransferred, totalBytes) => setUploadStatus(prev => ({
          total: e.target.files.length,
          current: i + 1,
          bytesTransferred: bytesTransferred,
          totalBytes: totalBytes
        }))
      })
      fileUrls.push(uploadedFile);
    }
    answer.fileUrls = fileUrls;
    props.onChange(answer);
    setUploadingFile(false);
    setUploading(false);
    props.onUploadSetUploading(false);
    e.target.value = '';
  }
  const _renderQuestionType = () => {
    return (
      <Chip variant="contained"
        // color="success"
            sx={{mb: 2}}
            label={`답변 유형: ${questionTypeText[answer.question?.questionType]}`}
      />)
  }
  const _renderQuestionTitle = () => {
    return (
      <Box sx={{display: 'flex', mb: 2}}>
        <Box sx={{
          display: 'flex',
          width: 30, height: 30, bgcolor: 'primary.main',
          borderRadius: 2,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
            }}>
            {answer?.question?.order + 1}
          </Typography>
        </Box>
        <Typography
          sx={{pl: 1, fontWeight: 'bold', mt: 0.2, fontSize: '1.2rem'}}
        >
          {answer?.question?.title}
        </Typography>
      </Box>
    );
  };
  const _renderQuestionDescription = () => {
    if (!answer.question?.description || answer.question?.description?.trim() === "") return null;
    return (
      <Typography
        // variant={'h6'}
        sx={{mb: 2, color: 'gray'}}
        className={'whitespace-pre-line'}>
        {answer.question?.description}
      </Typography>
    );
  };


  const _renderBody = () => {

    switch (answer.question?.questionType) {
      case "TEXT":
        return (
          <Box sx={{}}>
            <TextField
              fullWidth
              label="답변"
              multiline
              rows={10}
              helperText={`${numeral(answer.content?.length).format('0,0') || '0'}자 (공백 제외 ${numeral(answer.content?.replace?.(/ /gi, '')?.replace?.(/\n/gi, '').length).format('0,0') || '0'}자) 최대 10,000자까지 작성이 가능합니다.`}
              // defaultValue="Default Value"
              value={answer.content || ''}
              onChange={(e) => {
                // setInquiry(prev => ({...prev, answer: e.target.value}))
                if (e.target.value?.length > 10000) return window.alert('10,000자 이내로 작성해 주세요.')
                props.onChange({...answer, content: e.target.value?.replace?.(/\n\n\n/gi, '\n\n')})
              }}
            />

          </Box>
        )
      case "VIDEO":
        if (answer.videoUrls?.length > 0) {
          return (
            <Box>
              <div className={'snap-center min-w-full aspect-h-3 aspect-w-4'}>
                <video controls className="w-full outline-none">
                  <source src={answer.videoUrls[0]?.url} type="video/mp4"/>
                  <source src={answer.videoUrls[0]?.url} type="video/mov"/>
                  영상 재생을 지원하지 않는 브라우저 입니다. 최신 버전의 브라우저를 사용해 주세요. (크롬을 권장합니다.)
                </video>
              </div>
              <Button
                onClick={() => {
                  if (!window.confirm('삭제하시겠습니까?')) return;
                  answer.videoUrls = [];
                  props.onChange(answer);
                }}
                color={'error'}
              >
                영상 삭제
              </Button>
            </Box>
          )
        } else {
          return (
            <Box>
              <label>
                <Input
                  onChange={_handleSelectVideo}
                  multiple
                  accept="video/mp4"
                  type="file"/>
                <LoadingButton
                  loading={uploadingVideo}
                  disabled={uploadingVideo}
                  component={'span'}
                  variant={'outlined'}>
                  영상 추가
                </LoadingButton>
              </label>
            </Box>
          )
        }
      case "IMAGE":
        return (
          <Box sx={{}}>
            <Box>
              {
                answer.imageUrls.map((item, index) => {
                  // _.times(10, o => ({url: 'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/school%2F1%2Fuser%2F2%2Fcourse%2F129ee937-8490-4200-9730-0db314b68bc1.jpg?alt=media'})).map((item, index) => {
                  return (
                    <Box
                      sx={{display: 'flex', mt: 1, mb: 1, alignItems: 'center',}}
                      key={index}>
                      <img
                        className={'w-24 h-24 object-contain border border-black'}
                        src={item.url}
                      />
                      <Box sx={{pl: 2}}>
                        <IconButton
                          onClick={() => {
                            if (!window.confirm('삭제하시겠습니까?')) return;
                            answer.imageUrls.splice(index, 1);
                            props.onChange(answer);
                          }}
                          variant={'contained'}
                          color={'error'}>
                          <Icon.DeleteOutline/>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            answer.imageUrls = moveItemInArrayFromIndexToIndex(answer.imageUrls, index, index - 1)
                            props.onChange(answer);
                          }}
                          disabled={index === 0}
                          variant={'outlined'}
                          color={'primary'}>
                          <Icon.ArrowUpward/>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            answer.imageUrls = moveItemInArrayFromIndexToIndex(answer.imageUrls, index, index + 1)
                            props.onChange(answer);
                          }}
                          disabled={index === answer?.imageUrls?.length - 1}
                          variant={'outlined'} color={'primary'}>
                          <Icon.ArrowDownward/>
                        </IconButton>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            <label>
              <Input
                onChange={_handleSelectImages}
                multiple
                accept="image/*"
                type="file"/>
              <LoadingButton
                loading={uploadingImage}
                disabled={uploadingImage}
                variant={'outlined'}
                component={'span'}>
                이미지 추가
              </LoadingButton>
            </label>

          </Box>
        )
      case "FILE":
        return (
          <Box sx={{}}>
            <Box>
              {answer.fileUrls.map((fileUrl, index) => {
                return (
                  <Box
                    key={index}
                    sx={{mt: 1, mb: 1, display: 'flex', justifyContent: 'space-between'}}
                  >
                    <p>
                      {fileUrl.fileName}
                    </p>
                    <Box sx={{pl: 2}}>
                      <IconButton
                        onClick={() => {
                          if (!window.confirm('삭제하시겠습니까?')) return;
                          answer.fileUrls.splice(index, 1);
                          props.onChange(answer);
                        }}
                        variant={'contained'}
                        color={'error'}>
                        <Icon.DeleteOutline/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          answer.fileUrls = moveItemInArrayFromIndexToIndex(answer.fileUrls, index, index - 1)
                          props.onChange(answer);
                        }}
                        disabled={index === 0}
                        variant={'outlined'}
                        color={'primary'}>
                        <Icon.ArrowUpward/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          answer.fileUrls = moveItemInArrayFromIndexToIndex(answer.fileUrls, index, index + 1)
                          props.onChange(answer);
                        }}
                        disabled={index === answer?.fileUrls?.length - 1}
                        variant={'outlined'} color={'primary'}>
                        <Icon.ArrowDownward/>
                      </IconButton>
                    </Box>
                  </Box>
                )
              })}
            </Box>
            <label>
              <Input
                onChange={_handleSelectFiles}
                multiple
                type="file"/>
              <LoadingButton
                loading={uploadingFile}
                disabled={uploadingFile}
                variant={'outlined'}
                component={'span'}
              >
                파일 추가
              </LoadingButton>
            </label>
          </Box>
        )
      default:
        return (
          <div>
            {answer.question?.questionType}
          </div>
        )
    }
  }
  const _renderProgress = () => {
    if (!uploading && !uploadingVideo && !uploadingImage && !uploadingFile) return null;
    const progress = (uploadStatus.current - 1 + (uploadStatus.bytesTransferred / uploadStatus.totalBytes)) / uploadStatus.total * 100
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant="determinate"
                          value={progress}
          />
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{mb: 6}}>
      {_renderQuestionTitle()}
      {_renderQuestionDescription()}
      {_renderQuestionType()}
      {_renderBody()}
      {_renderProgress()}
    </Box>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(StudentBookReportWriteAnswerComponent);
