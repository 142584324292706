import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  ListItemAvatar,
  Container, List, ListItem, ListItemText, Typography, Box, Grid, Avatar, Button, ListSubheader,
  Dialog, TextField, DialogActions, DialogContentText, DialogTitle, DialogContent,
} from "@mui/material";
import NetworkStudent from '../../lib/NetworkStudent';
import { fileUploadToFirebase } from '../../utils/fileUploadToFirebase';
import { styled } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import { authActionCreators } from '../../redux/auth/auth.store';
import dayjs from 'dayjs';

const Input = styled('input')({
  display: 'none',
});

const StudentSettingProfileImageChangeComponent = (props) => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(props.authReducer?.user?.profileImageUrl || "");


  const _handleConfirm = async () => {
    setUploading(true);
    try {
      const res = await NetworkStudent
        .userMePUT({
          profileImageUrl,
        });
      setDialogOpen(false);
      props.fetchMyInfo(() => {

      });
    } catch (e) {
      alert(e.cause?.errorMessage);
    } finally {
      setUploading(false);
    }
  };
  const _handleSelectImage = async (e) => {
    setUploadingImage(true);
    setUploading(true);
    const imageUrls = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const uploadedFile = await fileUploadToFirebase({
        bucketName: `school/${props.authReducer?.user?.schoolId}/user/${props.authReducer.user.id}/profile/${dayjs().format('YYYYMM')}`,
        file: file,
      })
      imageUrls.push(uploadedFile);
    }
    setProfileImageUrl(imageUrls[0]?.url);
    setUploadingImage(false);
    setUploading(false);
  }

  const _handleOpenDialog = () => {
    setDialogOpen(true);
  }
  const _handleCloseDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <List
        sx={{bgcolor: 'background.paper'}}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar
              src={props.authReducer?.user?.profileImageUrl}
            />
          </ListItemAvatar>
          <ListItemText
            primary={'프로필이미지'}
          />
          <Button onClick={_handleOpenDialog}>
            변경하기
          </Button>
        </ListItem>
      </List>
      <Dialog
        fullWidth
        open={dialogOpen}>
        <DialogTitle>
          프로필 이미지 변경
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            프로필 이미지를 변경합니다.
          </DialogContentText>
        </DialogContent>

        <DialogContent sx={{pb: 3}}>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <img
              className={'object-cover w-32 h-32 rounded-full'}
              onError={({currentTarget}) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://images.unsplash.com/photo-1648064568712-7891fa38bf65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";
              }}
              src={profileImageUrl}
            />
            <label htmlFor="icon-button-file">

              <Input
                onChange={_handleSelectImage}
                accept="image/*" id="icon-button-file"
                type="file"/>
              <LoadingButton
                loading={uploadingImage}
                variant={'outlined'} component={'span'}>
                이미지 선택
              </LoadingButton>
            </label>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant={'contained'}
            loading={uploading}
            disabled={uploading || uploadingImage}
            onClick={_handleConfirm}>
            확인
          </LoadingButton>
          <Button onClick={_handleCloseDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  },
);

export default enhance(StudentSettingProfileImageChangeComponent);
