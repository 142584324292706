import {Link} from "react-router-dom";
import {PATH_TEACHER} from "../../routes/paths";
import dayjs from "dayjs";

const BookReport = ({bookReport}) => {
  if (!bookReport?.id) return;

  return (
    <Link to={PATH_TEACHER.bookReport.detail.root(bookReport.id)}>
      <div className={'p-4 hover:bg-hover space-y-2'}>
        <div className={'flex items-center'}>
          <img src={bookReport.user?.profileImageUrl} className={'w-[30px] h-[30px] rounded-full object-resize'}/>
          <p className={'pl-2'}>
            {bookReport?.user?.name}
          </p>
        </div>
        <div>
          <p className={'line-clamp-1 font-bold'}>
            {bookReport.title}
          </p>
          <p className={'text-sm text-gray-500'}>
            {dayjs(bookReport.submittedAt).format('YY.MM.DD(ddd)HH:mm')} | {dayjs(bookReport.submittedAt).fromNow()}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default BookReport
