import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';

const AdminClassCreatePageTabBulk = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  },[])

  return (
    <div>
      AdminClassCreatePageTabBulk
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(AdminClassCreatePageTabBulk);
