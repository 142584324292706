import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputBase,
  IconButton,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import NetworkStudent from "../../lib/NetworkStudent";

const StudentBookReportWriteSearchBookDialogComponent = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [keyword, setKeyword] = useState("");
  const [books, setBooks] = useState([]);
  const [searching, setSearching] = useState(false);
  const LIMIT = 50;

  const _handleSearch = async (e) => {
    e.preventDefault();
    if (searching) return;
    setSearching(true);
    const res = await NetworkStudent.bookSearchGET({
      offset: 0,
      limit: LIMIT,
      keyword,
    });
    if (!res.err) {
      setBooks(res.books);
      setIsLoaded(true);
    }
    setSearching(false);
  };

  const _handleSelectBook = (book) => {
    props.selectBook?.(book);
    props.onClose?.();
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);

  return (
    <Dialog fullWidth open={props.open || false}>
      <DialogTitle>
        책 추가/변경
        <DialogContentText>독후감을 작성할 책을 선택해 주세요.</DialogContentText>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <Box className={"bg-gray-100 rounded"} component="form" sx={{p: "2px 4px", display: "flex", alignItems: "center"}} onSubmit={_handleSearch}>
          <InputBase
            value={keyword}
            name={"keyword"}
            sx={{ml: 1, flex: 1}}
            placeholder="검색..."
            inputProps={{}}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          {!!keyword && (
            <IconButton
              onClick={() => {
                setKeyword("");
              }}
              sx={{p: "2px"}}
              aria-label="search"
            >
              <Icon.CloseRounded/>
            </IconButton>
          )}
          <Button type="submit" variant={"outlined"} sx={{p: "2px"}}>
            검색
          </Button>
        </Box>
      </DialogContent>
      <DialogContent sx={{height: "50vh"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>제목</TableCell>
              <TableCell>도서</TableCell>
              <TableCell>선택</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books.map((book, index) => {
              return (
                <TableRow key={book.id}>
                  <TableCell>
                    <img className={"w-12 object-contain"} src={book.coverImageUrl}/>
                  </TableCell>
                  <TableCell>
                    <div className={'line-clamp-1'}>
                      {book.title}
                    </div>
                    <div className={'line-clamp-1'}>
                      {book.authors} ({book.pubDate})
                    </div>
                  </TableCell>
                  <TableCell>
                    <Button size={'small'} onClick={() => _handleSelectBook(book)}>선택</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <LoadingButton
          onClick={props.onClose}
          // loading={uploading}
          // disabled={uploading || newTeacherUserCandidatesFromExcel?.length < 1}
          // onClick={_addManyUser}
        >
          닫기
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const enhance = connect(
  (state) => ( {
    ...state,
  } ),
  {},
);

export default enhance(StudentBookReportWriteSearchBookDialogComponent);
