import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import zIndex from './zIndex';

import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';


ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ThemeProvider({children}) {
  let isLightMode = false;
  isLightMode = true;

  const themeOptions = useMemo(
    () => ({
      palette: isLightMode ? palette.light : palette.dark,
      typography,
      breakpoints,
      direction: 'ltr',
      shadows: isLightMode ? shadows.light : shadows.dark,
      customShadows: isLightMode ? customShadows.light : customShadows.dark,
      zIndex,
      shape: {
        borderRadius: 4,
      },
      // spacing: 4,
    }),
    [isLightMode],
  )

  const theme = createTheme(themeOptions);
  // theme.components = componentsOverride(theme);


  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline enableColorScheme/>
      {children}
    </MUIThemeProvider>
  );
}
