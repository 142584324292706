import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Container, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import dayjs from 'dayjs';
import Network from '../../lib/Network';
import LoadingComponent from '../../components/TO_BE_deleted/LoadingComponent';
import {PATH_ADMIN} from '../../routes/paths';

const AdminBookReportPage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({})
  const [bookReports, setBookReports] = useState([]);
  const page = parseInt(searchParams.get('page') || '1', 10);
  const keyword = searchParams.get("keyword") || "";

  const LIMIT = 20;

  const _load = async () => {
    try {
      const res = await Network
        .bookReportsGET({
          keyword,
          offset: (page - 1) * LIMIT,
          limit: LIMIT,
        })
      setBookReports(res.bookReports)
      setMeta(res.meta || {})
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  }
  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      setSearchParams({keyword});
    }
  };

  useEffect(() => {
    _load();
    window.scrollTo(0, 0)
  }, [keyword, page])

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'xl'}>
      <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword}/>
      <Box sx={{position: "relative"}} my={2}>
        <Pagination
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
        />
      </Box>

      <Scrollbar>
        <TableContainer sx={{minWidth: "max-content"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  No.
                </TableCell>
                <TableCell>
                  독후감 제목
                </TableCell>
                <TableCell>
                  책
                </TableCell>
                <TableCell>
                  작성자(학생)
                </TableCell>
                <TableCell>
                  선생님
                </TableCell>
                <TableCell>
                  제출일시
                </TableCell>
                <TableCell>
                  채점상태
                </TableCell>
                <TableCell>
                  댓글
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports?.map((bookReport, index) => {
                return (
                  <TableRow key={bookReport.id} className={"group hover:bg-gray-50"}>
                    <TableCell>
                      {index + 1 + (page - 1) * LIMIT}
                    </TableCell>
                    <TableCell>
                      <Link to={PATH_ADMIN.bookReport.detail(bookReport.id)} className={'hover:text-primary-500'}>
                        {bookReport.title}
                      </Link>
                      <p>
                        {bookReport.task?.title}
                      </p>
                    </TableCell>
                    <TableCell>
                      {bookReport.book?.title}
                      <img className={"w-[30px] h-[50px] object-contain"} src={bookReport.book?.coverImageUrl}/>
                    </TableCell>
                    <TableCell>
                      {bookReport?.user?.name} ({bookReport?.user?.displayName})
                    </TableCell>
                    <TableCell>
                      {bookReport?.teacherUser?.name} ({bookReport?.teacherUser?.displayName})
                    </TableCell>
                    <TableCell>
                      {dayjs(bookReport.submittedAt).format('YYYY.MM.DD(ddd) HH:mm')}
                    </TableCell>
                    <TableCell>
                      {bookReport?.score || '채점전'}
                    </TableCell>
                    <TableCell>
                      {bookReport?.stat?.bookReportCommentCount}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{position: "relative"}} my={2}>
        <Pagination
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
        />
      </Box>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(AdminBookReportPage);
