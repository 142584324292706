import { notificationTypes } from "../../constants";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { PATH_TEACHER } from "../../routes/paths";

const Notification = ({notification, onClickNotification}) => {

  if (!notification) return <></>;

  let navigateUrlByType = '', $statement$, $image$;

  const {course, task, user, bookReportComment} = notification.customField || {}

  switch (notification.type) {
    case notificationTypes.TEACHER_BOOK_REPORT_SUBMITTED.type:
      navigateUrlByType = PATH_TEACHER.bookReport.detail.root(notification.customField.bookReportId)
      $statement$ = (
        <span>
          {!!course?.title && (<span><b>[{notification.customField.course?.title}]</b>수업의 </span>)}
          {!!task?.title && (<span>과제 <b>{notification.customField.task?.title}</b>에{' '}</span>)}
          {!!user && (<span><b>{notification.customField?.user?.name}</b>학생의 </span>)}
          새로운 독후감이 제출되었습니다.
        </span>
      )
      $image$ = (<img src={user?.profileImageUrl} className={'w-[40px] h-[40px] rounded-full object-cover'}/>)
      break;
    case notificationTypes.TEACHER_NEW_COURSE_REGISTER.type:
      navigateUrlByType = PATH_TEACHER.course.detail.root(notification.customField.courseId) + `?tab=STUDENT`
      $statement$ = (
        <>
          [{notification.customField.course?.title}] 수업에 새로운 학생 [{user?.displayName}] 이 등록되었습니다.
        </>
      )
      $image$ = (<img src={user?.profileImageUrl} className={'w-[40px] h-[40px] rounded-full object-cover'}/>)
      break;
    case notificationTypes.TEACHER_BOOK_REPORT_COMMENT.type:
      navigateUrlByType = PATH_TEACHER.bookReport.detail.root(notification.customField.bookReportId)
      $statement$ = (
        <>
          {!!bookReportComment && (<span>{bookReportComment.content}</span>)}
        </>
      )
      $image$ = (<img src={user?.profileImageUrl} className={'w-[40px] h-[40px] rounded-full object-cover'}/>)
      break;
  }

  return (
    <Link
      key={notification.id}
      to={navigateUrlByType}
      onClick={() => {
        onClickNotification?.({notificationId: notification.id});
      }}
    >
      <div className={"group px-6 py-4 rounded-lg hover:bg-hover space-y-2"}>
        <div className="flex items-center justify-between">
          <p className="text-sm text-gray-500 font-bold">
            {notificationTypes[notification.type].name}
          </p>
          <p className="min-w-max text-sm font-medium bind-text-gray-500">{dayjs(notification.createdAt).format("YYYY.M.D ddd HH:mm")}</p>
        </div>
        <div className="flex items-center space-x-2">
          {$image$}
          <p className={'font-medium flex-1'}>
            {$statement$}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default Notification
