import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Network from "../../lib/Network";
import Loader from "../../assi-design-system-react/components/Loader";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Breadcrumbs, Container, Tab} from "@mui/material";
import CourseDetailTaskTab from "../../sections/teacher/CourseDetailTaskTab";
import CourseDetailStudentTab from "../../sections/teacher/CourseDetailStudentTab";
import CourseDetailInfoTab from "../../sections/teacher/CourseDetailInfoTab";
import {PATH_TEACHER} from "../../routes/paths";

const TeacherCourseDetail = (props) => {

  const {courseId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab') || 'TASK'
  const [searchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [course, setCourse] = useState({})

  const _load = async () => {
    try {
      const res = await Network.courseGET({courseId})
      setCourse(res.course);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  const _handleChangeTab = (newTab) => {
    searchParams.set('tab', newTab);
    navigate(location.pathname + `?${searchParams.toString()}`)
  }


  if (!isLoaded) return <Loader/>
  if (course.teacherUserId !== props.authReducer.user.id) {
    return (
      <Container maxWidth={'xl'}>
        <div className={'p-3 rounded my-4 border border-warning-300 bg-warning-50'}>
          <p className={'text-warning-500'}>
            개설 선생님만 확인할 수 있어요.
          </p>
        </div>
        <div>
          <p>
            개설 선생님:{' '}
            <Link
              to={PATH_TEACHER.teacher.detail.root(course.teacherUserId)}
              as={'span'}
              className={'clickable-primary'}
            >
              {course.teacherUser?.name}
            </Link>
          </p>
        </div>
      </Container>
    )
  }

  return (
    <Container maxWidth={'xl'}>
      <div className={'pb-4'}>
        <Breadcrumbs separator="›">
          <Link to={PATH_TEACHER.course.root}>
            <p className={'clickable-primary'}>
              수업/활동
            </p>
          </Link>
          <p className={'font-bold'}>
            {course?.title}
          </p>
        </Breadcrumbs>
      </div>
      <div className={'flex py-2 items-center'}>
        <img src={course.coverImageUrl} className={'w-[70px] h-[70px] rounded'}/>
        <div className={"pl-4"}>
          <p>
            {course.semester?.year} - {course.semester?.text}
          </p>
          <p>
            {course.title}
          </p>
        </div>
      </div>
      <TabContext value={tab}>
        <TabList
          className={'my-8'}
          onChange={(e, newValue) => _handleChangeTab(newValue)}>
          <Tab label={`과제 (${course.stat?.taskCount})`} value="TASK"/>
          <Tab label={`학생 (${course.stat?.registerCount})`} value="STUDENT"/>
          <Tab label="정보" value="INFO"/>
        </TabList>
        <TabPanel value={'TASK'}>
          <CourseDetailTaskTab/>
        </TabPanel>
        <TabPanel value={'STUDENT'}>
          <CourseDetailStudentTab/>
        </TabPanel>
        <TabPanel value={'INFO'}>
          <CourseDetailInfoTab course={course}/>
        </TabPanel>
      </TabContext>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TeacherCourseDetail);
