import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container, Typography, Box, ListItem, List, Divider,
  ListItemAvatar, Card, CardContent, CardActions, Chip, Avatar, ListItemText, Paper, Stack, Grid, Button,
  Dialog, DialogTitle, DialogContent, DialogContentText, TextField, InputBase, IconButton,
  Fab,
} from "@mui/material";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import NetworkStudent from "../../lib/NetworkStudent";
import LoadingComponent from "../../components/shared/LoadingComponent";
import * as Icon from '@mui/icons-material';
import StudentBookReportWriteSearchBookDialogComponent from '../../components/student/StudentBookReportWriteSearchBookDialogComponent';
import StudentSearchTeacherDialogComponent from '../../components/student/StudentSearchTeacherDialogComponent';
import StudentBookReportWriteAnswerComponent from '../../components/student/StudentBookReportWriteAnswerComponent';
import StudentBookReportWriteSaveDialogComponent from "../../components/student/StudentBookReportWriteSaveDialogComponent";
import { courseTypeText } from '../../constants/dataTypes';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import BottomFloatComponent from '../../components/NOT_Classified/BottomFloatComponent';
import { LoadingButton } from '@mui/lab';
import MessageBox from "../../components/shared/MessageBox";
import { PATH_STUDENT } from "../../routes/paths";


const StudentBookReportWritePage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const {collapseClick, isCollapse, onToggleCollapse} = useCollapseDrawer();
  const {taskId, courseId, bookReportId} = useParams();
  const isNew = !bookReportId;

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [bookSearchModalOpen, setBookSearchModalOpen] = useState(false);
  const [teacherSearchModalOpen, setTeacherSearchModalOpen] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [task, setTask] = useState({});
  const [bookReport, setBookReport] = useState({answers: []});
  const [changed, setChanged] = useState(false);


  const _load = async () => {
    if (!!bookReportId) {
      try {
        const res = await NetworkStudent
          .bookReportGET({bookReportId});
        setBookReport(res.bookReport);
        setTask(res.bookReport?.task || {});
        setIsLoaded(true);
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      }

    } else if (!!taskId) {
      try {
        const res = await NetworkStudent.taskGET({taskId});
        if (!!res.task?.bookReportMy?.id) {
          navigate(`/book-report/${res.task?.bookReportMy?.id}/edit`, {replace: true})
        } else {
          const answers = res.task?.questions?.map(question => ({
            content: "",
            imageUrls: [],
            videoUrls: [],
            fileUrls: [],
            questionId: question.id,
            question: question,
          }));
          setTask(res.task);
          setBookReport(prev => ({
            ...prev,
            title: '',
            answers,
            teacherUserId: res.task?.course?.teacherUserId || undefined,
            teacherUser: res.task.course?.teacherUser,
          }));
          setIsLoaded(true);
        }

      } catch (e) {
        window.alert(e.cause?.errorMessage);
      }

    }
  }
  useEffect(() => {
    _load();
  }, [bookReportId])

  const _upload = async () => {
    if (uploading) return;
    if (!bookReport.title || bookReport.title.trim() === '') return window.alert('제목을 입력해 주세요.');
    setUploading(true);
    if (isNew) {
      try {
        const res = await NetworkStudent
          .bookReportPOST({
            title: bookReport.title?.trim(),
            taskId,
            bookId: bookReport?.bookId || undefined,
            teacherUserId: bookReport.teacherUserId || undefined,
            answers: bookReport.answers,
          });
        navigate(`/book-report/${res.bookReport?.id}`)
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      } finally {
        setUploading(false);
      }

    } else {
      try {
        const res = await NetworkStudent
          .bookReportPUT({
            title: bookReport.title?.trim(),
            bookReportId,
            bookId: bookReport.bookId || undefined,
            teacherUserId: bookReport.teacherUserId || undefined,
            answers: bookReport.answers || [],
          });
        navigate(`/book-report/${bookReportId}`)
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      } finally {
        setUploading(false);
      }
    }
  };

  const _openBookSearchModal = () => {
    setBookSearchModalOpen(true);
  }
  const _openTeacherSearchModal = () => {
    setTeacherSearchModalOpen(true);
  }
  const _openSaveDialog = () => {
    setSaveDialogOpen(true);
  }


  if (!isLoaded) return <LoadingComponent/>

  const _renderTitle = () => {
    return (
      <Container maxWidth={'md'} sx={{mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: '800'}}>
          제목
        </Typography>
        <Typography variant={'subtitle1'}>
          제목을 입력해 주세요.
        </Typography>
        <Divider sx={{mt: 1, mb: 3}}/>
        <TextField
          sx={{}}
          fullWidth
          label={'제목'}
          value={bookReport?.title}
          onChange={(e) => {
            setBookReport(prev => ({...prev, title: e.target.value}))
          }}
        />
      </Container>
    )
  }
  const _renderBookPart = () => {
    // 책선택 부분
    if (!!bookReport.book?.id && bookReport.bookId) {
      return (
        <Container maxWidth={'md'} sx={{mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: '800'}}>
            도서 선택
          </Typography>
          <Typography variant={'subtitle1'}>
            책을 선택해 주세요.
          </Typography>
          <Divider sx={{mt: 1, mb: 1}}/>
          <Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <img
                className={'w-16 object-contain border border-black'}
                src={bookReport.book?.coverImageUrl}/>
              <Box sx={{pl: 2, flex: 1}}>
                <Typography className={'line-clamp-2'}>
                  {bookReport.book?.title}
                </Typography>
                <Typography>
                  {bookReport?.book?.authors}
                </Typography>
                <Typography>
                  {bookReport?.book?.publisher} ({bookReport?.book?.pubDate})
                </Typography>
              </Box>
            </Box>
            <Box sx={{mt: 3}}>
              <Button
                variant={'outlined'}
                onClick={_openBookSearchModal}>
                책 변경
              </Button>
              <Button
                // variant={'contained'}
                color={'error'}
                onClick={() => {
                  if (!window.confirm('책을 제거 하시겠습니까?')) return;
                  setBookReport(prev => ({...prev, bookId: null, book: null}))
                }}>
                책 제거
              </Button>
            </Box>
          </Box>
        </Container>
      )
    } else {
      return (
        <Container maxWidth={'md'} sx={{mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: '800'}}>
            도서 선택
          </Typography>
          <Typography variant={'subtitle1'}>
            책을 선택해 주세요.
          </Typography>
          <Divider sx={{mt: 1, mb: 1}}/>
          <div className={"border border-warning-500 bg-warning-50 bg-opacity-50 p-4 rounded-[8px] mb-4"}>
            <h4 className={'text-warning-600 font-bold'}>
              도서가 선택되지 않았습니다.
            </h4>
            <p className={'whitespace-pre-line text-warning-500'}>
              읽은 책이 있는 경우, 책을 선택해 주세요.
            </p>
          </div>
          <Button
            variant={'outlined'}
            onClick={_openBookSearchModal}>
            도서 검색
          </Button>
        </Container>
      )
    }
  }
  const _renderTargetTeacherUser = () => {
    return (
      <Container maxWidth={'md'} sx={{mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: '800'}}>
          제출 대상 선생님
        </Typography>
        {
          task.taskType === 'PRIVATE' ? (
            <>
              <Typography variant={'subtitle1'}>
                제출 대상 선생님을 선택해 주세요.
              </Typography>
              <Divider sx={{mt: 1, mb: 1}}/>
              <div className={"border border-warning-500 bg-warning-50 bg-opacity-50 p-4 rounded-[8px] mb-4"}>
                <p className={'whitespace-pre-line text-warning-500'}>
                  독후감을 저장하고 제출하면 제출 대상 선생님이 볼 수 있어요. 독후감 제출후에는 선생님을 변경 할 수 없으니, 확인해 주세요.
                </p>
              </div>
            </>
          ) : (
            <>
              <Typography variant={'subtitle1'}>
                <span className={'font-bold'}>{task?.course?.title}</span>은 [{courseTypeText[task?.course?.courseType]}] 수업 입니다. <br/>
                {task?.course?.courseType === "IRREGULAR" ? `비교과 활동은 제출대상 선생님을 변경할 수 있습니다.` : `교과 수업은 교과목 담당 선생님께 독후감이 제출됩니다.`}
              </Typography>
              <Divider sx={{mt: 1, mb: 1}}/>
              <div className={"border border-warning-500 bg-warning-50 bg-opacity-50 p-4 rounded-[8px] mb-4"}>
                <p className={'whitespace-pre-line text-warning-500'}>
                  교과 수업은 독후감 제출 대상 선생님을 변경할 수 없고, 비교과 활동은 변경할 수 있어요. <br/>
                  독후감을 저장하고 제출하면 제출 대상 선생님이 볼 수 있어요. 독후감 제출후에는 선생님을 변경 할 수 없으니, 확인해 주세요.
                </p>
              </div>
            </>
          )
        }
        {
          bookReport.teacherUser ? (
            <>
              <Box sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                <Avatar
                  className={'border border-gray-200'}
                  src={bookReport.teacherUser?.profileImageUrl}
                />
                <Typography sx={{ml: 2}}>
                  {bookReport.teacherUser?.name} ({bookReport.teacherUser?.displayName})
                </Typography>
              </Box>
              <Button
                disabled={task?.course?.courseType !== "IRREGULAR" && task.taskType === 'COURSE'}
                variant={'outlined'}
                onClick={_openTeacherSearchModal}>
                선생님 변경
              </Button>
            </>
          ) : (
            <>
              <Button
                disabled={task?.course?.courseType !== "IRREGULAR" && task.taskType === 'COURSE'}
                variant={'outlined'}
                onClick={_openTeacherSearchModal}>
                선생님 선택
              </Button>
            </>
          )
        }
      </Container>
    )
  }
  const _renderBookReportAnswers = () => {
    return (
      <Container maxWidth={'md'} sx={{mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: '800'}}>
          문항
        </Typography>
        <Typography variant={'subtitle1'}>
          총 {bookReport.answers?.length}개의 문항이 있습니다.
        </Typography>
        <Divider sx={{mt: 1, mb: 3}}/>
        {
          bookReport.answers?.map((answer, index) => {
            return (
              <StudentBookReportWriteAnswerComponent
                key={index}
                answer={answer}
                onUploadSetUploading={(value) => {
                  setUploading(value)
                }}
                onChange={(answer) => {
                  bookReport.answers[index] = answer;
                  setBookReport(prev => ({...bookReport}));
                  setChanged(true);
                }}
              />
            )
          })
        }
      </Container>
    )
  }

  if (!!bookReport.submittedAt && !bookReport.task?.permitEditAfterSubmit) {
    return (
      <Container maxWidth={'md'}>
        <MessageBox
          title={'제출 완료'}
          content={'제출되었습니다.'}
        >
          <Link to={PATH_STUDENT.bookReport.detail.root(bookReportId)}>
            <Button variant={'contained'}>
              제출된 독후감 보기
            </Button>
          </Link>
        </MessageBox>
      </Container>
    )
  }

  return (
    <div className={''}>
      {_renderTitle()}
      {_renderBookPart()}
      {_renderBookReportAnswers()}
      {_renderTargetTeacherUser()}
      <StudentBookReportWriteSearchBookDialogComponent
        open={bookSearchModalOpen}
        selectBook={(book) => {
          if (!book || !book.id) return;
          setBookReport(prev => ({...prev, book, bookId: book.id}));
        }}
        onClose={() => {
          setBookSearchModalOpen(false);
        }}
      />
      <StudentSearchTeacherDialogComponent
        open={teacherSearchModalOpen}
        selectTeacherUser={(teacherUser) => {
          if (!teacherUser || !teacherUser.id) return;
          setBookReport(prev => ({...prev, teacherUser, teacherUserId: teacherUser.id}));
        }}
        onClose={() => {
          setTeacherSearchModalOpen(false);
        }}
      />
      <StudentBookReportWriteSaveDialogComponent
        open={saveDialogOpen}
        uploading={uploading}
        onSave={_upload}
        onClose={() => {
          setSaveDialogOpen(false);
        }}
      />
      <BottomFloatComponent
        className={'bg-white py-4'}
        collapseClick={collapseClick}>
        <Container sx={{p: 0}} maxWidth={'md'}>
          <LoadingButton
            disabled={uploading || bookReport.title?.trim() === ''}
            variant={'contained'}
            onClick={_openSaveDialog}
            size={'large'}
          >
            <Icon.Save sx={{mr: 2}}/>
            저장
          </LoadingButton>
        </Container>
      </BottomFloatComponent>

    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentBookReportWritePage);
