import colors from "./colors";

const {tailwindColor, muiColor} = colors

const palette = {
  light: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: muiColor.blue["400"],
      main: muiColor.blue["700"],
      dark: muiColor.blue["800"],
      contrastText: muiColor.common.white,
    },
    secondary: {
      light: muiColor.purple["400"],
      main: muiColor.purple["700"],
      dark: muiColor.purple["800"],
      contrastText: muiColor.common.white,
    },
    error: {
      light: muiColor.red["400"],
      main: muiColor.red["700"],
      dark: muiColor.red["800"],
      contrastText: muiColor.common.white,
    },
    warning: {
      light: muiColor.orange["500"],
      main: muiColor.orange["700"],
      dark: muiColor.orange["900"],
      contrastText: muiColor.common.white,
    },
    info: {
      light: muiColor.lightBlue["500"],
      main: muiColor.lightBlue["700"],
      dark: muiColor.lightBlue["900"],
      contrastText: muiColor.common.white,
    },
    success: {
      light: muiColor.green["500"],
      main: muiColor.green["700"],
      dark: muiColor.green["900"],
      contrastText: muiColor.common.white,
    },
    notification: {
      light: muiColor.red["400"],
      main: muiColor.red["700"],
      dark: muiColor.red["800"],
      contrastText: muiColor.common.white,
    },
    like: {
      light: muiColor.red["400"],
      main: muiColor.red["700"],
      dark: muiColor.red["800"],
      contrastText: muiColor.common.white,
    },
    bookmark: {
      light: muiColor.green["500"],
      main: muiColor.green["700"],
      dark: muiColor.green["900"],
      contrastText: muiColor.common.white,
    },
    rate: {
      light: muiColor.amber["500"],
      main: muiColor.amber["700"],
      dark: muiColor.amber["900"],
      contrastText: muiColor.common.white,
    },
    something : {
      main: muiColor.grey[400],
    },
    divider: 'rgba(0,0,0,0.12)',
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0,0,0,0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0,0,0,0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
      disabled: 'rgba(0,0,0,0.38)',
      textInputPlaceHolder: muiColor.grey[700]
    },
    background: {
      paper: '#fff',
      default: '#fff',
      secondary: '#F2F4F6',
      box:'#F2F4F6',
      textInput: muiColor.grey[200],
      mockUp: muiColor.grey["400"]
      // neutral: GREY[200],
    },
    line: {
      textInput: '#F1F3F4',
    },
  },
  dark: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: muiColor.blue["50"],
      main: muiColor.blue["200"],
      dark: muiColor.blue["400"],
      contrastText: muiColor.common.black_87,
    },
    secondary: {
      light: muiColor.purple["50"],
      main: muiColor.purple["200"],
      dark: muiColor.purple["400"],
      contrastText: muiColor.common.black_87,
    },
    error: {
      light: muiColor.red["300"],
      main: muiColor.red["500"],
      dark: muiColor.red["700"],
      contrastText: muiColor.common.white,
    },
    warning: {
      light: muiColor.orange["300"],
      main: muiColor.orange["400"],
      dark: muiColor.orange["700"],
      contrastText: muiColor.common.black_87,
    },
    info: {
      light: muiColor.lightBlue["300"],
      main: muiColor.lightBlue["400"],
      dark: muiColor.lightBlue["700"],
      contrastText: muiColor.common.black_87,
    },
    success: {
      light: muiColor.green["300"],
      main: muiColor.green["400"],
      dark: muiColor.green["700"],
      contrastText: muiColor.common.black_87,
    },
    notification: {
      light: muiColor.red["300"],
      main: muiColor.red["500"],
      dark: muiColor.red["700"],
      contrastText: muiColor.common.white,
    },
    like: {
      light: muiColor.red["300"],
      main: muiColor.red["500"],
      dark: muiColor.red["700"],
      contrastText: muiColor.common.white,
    },
    bookmark: {
      light: muiColor.green["300"],
      main: muiColor.green["400"],
      dark: muiColor.green["700"],
      contrastText: muiColor.common.black_87,
    },
    rate: {
      light: muiColor.amber["300"],
      main: muiColor.amber["400"],
      dark: muiColor.amber["700"],
      contrastText: muiColor.common.black_87,
    },
    divider: 'rgba(255,255,255,0.12)',
    action: {
      active: '#fff',
      hover: 'rgba(255,255,255,0.04)',
      hoverOpacity: 0.08,
      selected: 'rgba(255,255,255,0.08)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255,255,255,0.26)',
      disabledBackground: 'rgba(255,255,255,0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255,255,255,0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
    text: {
      primary: '#fff',
      secondary: 'rgba(255,255,255,0.7)',
      disabled: 'rgba(255,255,255,0.5)',
      icon: 'rgba(255,255,255,0.5)',
      textInputPlaceHolder: muiColor.grey[100]
    },
    background: {
      paper: '#121212',
      default: '#121212',
      secondary: '#2C2C34',
      box:'#2C2C34',
      textInput: muiColor.grey[700],
      // neutral: GREY[500_16],
    },
    line: {
      textInput: 'gray',
    },
  },
}

export default palette
