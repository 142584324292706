import { FAILED, IDLE, PENDING, SUCCEEDED } from "../../constants/status";
import { notificationStudentActions } from "../notification-student/notification-student.store";

// action types
export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_FULFILLED = "LOAD_NOTIFICATIONS_FULFILLED";
export const FETCH_NOTIFICATIONS_REJECTED = "FETCH_NOTIFICATIONS_REJECTED";
export const READ_AND_RELOAD_NOTIFICATIONS = "READ_AND_RELOAD_NOTIFICATIONS";
export const CLICK_NOTIFICATION = "CLICK_NOTIFICATION";
export const CLICK_NOTIFICATION_FULFILLED = "CLICK_NOTIFICATION_FULFILLED";
export const CLICK_NOTIFICATION_REJECTED = "CLICK_NOTIFICATION_REJECTED";

export const notificationActions = {
  FETCH_TEACHER_NOTIFICATION: "FETCH_TEACHER_NOTIFICATION",
  FETCH_TEACHER_NOTIFICATION_SUCCESS: "FETCH_TEACHER_NOTIFICATION_SUCCESS",

  FETCH_TEACHER_NOTIFICATION_MORE: "FETCH_TEACHER_NOTIFICATION_MORE",
  FETCH_TEACHER_NOTIFICATION_MORE_SUCCESS: "FETCH_TEACHER_NOTIFICATION_MORE_SUCCESS",

  LOAD_NOTIFICATIONS,
  LOAD_NOTIFICATIONS_FULFILLED,
  FETCH_NOTIFICATIONS_REJECTED,
  READ_AND_RELOAD_NOTIFICATIONS,
  CLICK_NOTIFICATION,
  CLICK_NOTIFICATION_FULFILLED,
  CLICK_NOTIFICATION_REJECTED,
};

export const notificationActionCreators = {

  fetchNotificationTeacher: (cb) => ({type: notificationActions.FETCH_TEACHER_NOTIFICATION, cb}),
  fetchNotificationTeacherSuccess: ({notifications, endReached, cb}) => ({type: notificationActions.FETCH_TEACHER_NOTIFICATION_SUCCESS, notifications, endReached, cb}),

  fetchNotificationTeacherMore: (cb) => ({type: notificationActions.FETCH_TEACHER_NOTIFICATION_MORE, cb}),
  fetchNotificationTeacherMoreSuccess: ({notifications, endReached, cb}) => ({type: notificationActions.FETCH_TEACHER_NOTIFICATION_MORE_SUCCESS, notifications, endReached, cb}),

  loadNotifications: ({type}) => ({type: LOAD_NOTIFICATIONS, payload: {type}}),
  loadNotificationsFulfilled: (result) => ({type: LOAD_NOTIFICATIONS_FULFILLED, payload: result}),
  loadNotificationsRejected: (error) => ({type: FETCH_NOTIFICATIONS_REJECTED, payload: error, error: true}),
  readAndReloadNotifications: ({type, offset}) => ({type: READ_AND_RELOAD_NOTIFICATIONS, payload: {type, offset}}),
  clickNotification: ({notificationId}) => ({type: CLICK_NOTIFICATION, payload: {notificationId}}),
  clickNotificationFulfilled: (result) => ({type: CLICK_NOTIFICATION_FULFILLED, payload: result}),
  clickNotificationRejected: (result) => ({type: CLICK_NOTIFICATION_REJECTED, payload: result}),
};

// reducer
const initialState = {
  notifications: [],
  total: 0,
  unreadCount: 0,
  isLoaded: false,
  loadingMore: false,
  endReached: false,

  hasUnreadNotificaion: false,
  statusOnFetch: IDLE,
  statusOnClick: IDLE,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationActions.FETCH_TEACHER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.notifications || [],
        endReached: action.endReached,
        isLoaded: true,
      }
    case notificationActions.FETCH_TEACHER_NOTIFICATION_MORE:
      return {
        ...state,
        loadingMore: true,
      }
    case notificationActions.FETCH_TEACHER_NOTIFICATION_MORE_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
        endReached: action.endReached,
        loadingMore: false,
      }
    default:
      return state;

  }
};
