import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Box, Container, IconButton, LinearProgress, TextField, Divider, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import NetworkStudent from '../../lib/NetworkStudent';
import LoadingComponent from '../../components/shared/LoadingComponent';
import {fileUploadToFirebase} from '../../utils/fileUploadToFirebase';
import * as Icon from '@mui/icons-material';
import {moveItemInArrayFromIndexToIndex} from '../../assi-design-system/utils/array';
import LoadingButton from '@mui/lab/LoadingButton';
import {styled} from '@mui/material/styles';
import MessageBox from '../../components/shared/MessageBox';
import dayjs from 'dayjs';


const InquiryWritePage = (props) => {

  const {inquiryId} = useParams();
  const isNew = !inquiryId;
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({total: 0, current: 0, bytesTransferred: 0, totalBytes: 0});
  const [inquiry, setInquiry] = useState({
    title: "",
    question: "",
    questionImageUrls: [],
  });

  useEffect(() => {
    _load();
  }, [])

  const _load = async () => {
    if (isNew) {
      setInquiry({
        title: "",
        question: "",
        questionImageUrls: [],
      });
      setIsLoaded(true);
    } else {
      try {
        const res = await NetworkStudent.inquiryGET({inquiryId: inquiryId});

        if (!!res.inquiry?.answeredAt) {
          window.alert('답변이 완료된 문의는 수정할 수 없습니다.')
          navigate(`/inquiry/${inquiryId}`, {replace: true})
        } else {
          setInquiry(res.inquiry);
          setIsLoaded(true);
        }
      } catch (e) {

      }
    }
  };
  const _handleUpload = async () => {
    if (uploadingImage) return window.alert("이미지 업로드 중입니다.");
    if (!inquiry.title || inquiry.title?.length < 3) return window.alert("제목을 3글자 이상 입력해 주세요");
    if (!inquiry.question || inquiry.question?.length < 10) return window.alert("내용을 10글자 이상 입력해 주세요");
    setUploading(true);
    try {
      const res = await NetworkStudent.inquiryPOST({...inquiry});
      navigate(`/inquiry`, {replace: true})
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }
  };
  const _handleSelectImages = async (e) => {
    // console.log(e.target.files);
    setUploadingImage(true);
    setUploading(true);
    const questionImageUrls = [...inquiry.questionImageUrls];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const uploadedFile = await fileUploadToFirebase({
        file: file,
        bucketName: `school/${props.auth?.user?.schoolId}/user/${props.auth.user.id}/images/${dayjs().format('YYYYMM')}`,
        onProgress: (bytesTransferred, totalBytes) => setUploadStatus(prev => ({
          total: e.target.files.length,
          current: i + 1,
          bytesTransferred: bytesTransferred,
          totalBytes: totalBytes
        }))
      })
      console.log({uploadedFile})
      questionImageUrls.push(uploadedFile);
    }
    setInquiry(prev => ({...prev, questionImageUrls,}))
    setUploadingImage(false);
    setUploading(false);
  }

  if (!isLoaded) return <LoadingComponent/>;

  const _renderProgress = () => {
    if (!uploading && !uploadingImage) return null;
    const progress = (uploadStatus.current - 1 + (uploadStatus.bytesTransferred / uploadStatus.totalBytes)) / uploadStatus.total * 100
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{width: '100%', mr: 1}}>
          <LinearProgress variant="determinate"
                          value={progress}
          />
        </Box>
        <Box sx={{minWidth: 35}}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <div>
      <Container maxWidth={'sm'}>
        <MessageBox
          sx={{mb: 2}}
          title={'1:1 문의 안내'}
          content={'서비스 이용에 대해서 궁금한 점이 있으시면, 1:1문의로 알려주세요.\n최대 영업일 2일 이내에 답변을 드립니다. \n1:1문의는 학교 선생님에게 전달되지 않으며, 리더스 스쿨 운영회사에 전달됩니다. \n과제에 대한 질문이 있는 경우, 각 담당 선생님께 문의하세요.'}
        />
        <TextField
          sx={{mb: 2}}
          label={'제목'}
          fullWidth
          value={inquiry.title || ''}
          onChange={(e) => {
            setInquiry(prev => ({...prev, title: e.target.value}))
          }}
        />
        <TextField
          fullWidth
          label="내용"
          multiline
          rows={10}
          // defaultValue="Default Value"
          value={inquiry.question || ''}
          onChange={(e) => {
            setInquiry(prev => ({...prev, question: e.target.value}))
          }}
        />
        <Box sx={{}}>
          <Box>
            {
              inquiry.questionImageUrls?.map((item, index) => {
                return (
                  <Box
                    sx={{display: 'flex', mt: 1, mb: 1, alignItems: 'center',}}
                    key={index}>
                    <img
                      className={'w-24 h-24 object-contain border border-black rounded-2xl'}
                      src={item.url}
                    />
                    <Box sx={{pl: 2}}>
                      <IconButton
                        onClick={() => {
                          if (!window.confirm('삭제하시겠습니까?')) return;
                          inquiry.questionImageUrls.splice(index, 1);
                          setInquiry(prev => ({...prev, ...inquiry}))
                        }}
                        variant={'contained'}
                        color={'error'}>
                        <Icon.DeleteOutline/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          inquiry.questionImageUrls = moveItemInArrayFromIndexToIndex(inquiry.questionImageUrls, index, index - 1)
                          setInquiry(prev => ({...prev, ...inquiry}))
                        }}
                        disabled={index === 0}
                        variant={'outlined'}
                        color={'primary'}>
                        <Icon.ArrowUpward/>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          inquiry.questionImageUrls = moveItemInArrayFromIndexToIndex(inquiry.questionImageUrls, index, index + 1)
                          setInquiry(prev => ({...prev, ...inquiry}))
                        }}
                        disabled={index === inquiry?.questionImageUrls?.length - 1}
                        variant={'outlined'} color={'primary'}>
                        <Icon.ArrowDownward/>
                      </IconButton>
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
          <label>
            <input
              style={{display: 'none'}}
              onChange={_handleSelectImages}
              multiple
              accept="image/*"
              type="file"/>

            <LoadingButton
              size={'small'}
              sx={{mt: 2}}
              loading={uploadingImage}
              disabled={uploadingImage}
              variant={'outlined'}
              component={'span'}>
              이미지 첨부
            </LoadingButton>

          </label>
          <Box>
            {_renderProgress()}
          </Box>
          <Divider sx={{mt: 3, mb: 3}}/>
          <LoadingButton
            loading={uploading}
            disabled={uploading}
            onClick={_handleUpload}
            variant={'contained'}>
            <Icon.Upload sx={{mr: 2}}/>
            등록하기
          </LoadingButton>
        </Box>
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(InquiryWritePage);
