import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import Network from "../../lib/Network";
import * as XLSX from "xlsx-js-style";
import _ from "lodash";
import { downloadStudentCreateExcelTemplate } from "../../utils/downloadExcelTemplate";
import { Box, Button, Dialog, DialogActions, Table, TableHead, TableBody, TableRow, TableCell, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import MessageBox from "../shared/MessageBox";
import { displayNameRegex, emailRegex, nameRegex, phoneNumberRegex } from "../../utils/regexTest";
import { generateRandomString } from "../../utils/string";

const AdminStudentCreatePageTabBulk = (props) => {
  const [checking, setChecking] = useState(false);
  const [checkValid, setCheckValid] = useState(false);
  const [checkExistingValid, setCheckExistingValid] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const [newUserCandidatesFromExcel, setNewUserCandidatesFromExcel] = useState([]);
  const [existingUsers, setExistingUsers] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [excelUploadDialogOpen, setExcelUploadDialogOpen] = useState(false);

  const _checkExistingUsers = async () => {
    if (newUsers.length < 1) return;
    const emails = [...new Set(newUsers.map((o) => o.email).filter(Boolean))];
    const displayNames = [...new Set(newUsers.map((o) => o.displayName).filter(Boolean))];
    const phoneNumbers = [...new Set(newUsers.map((o) => o.phoneNumber).filter(Boolean))];

    try {
      setChecking(true);

      const res = await Network.usersCheckDuplicatedPOST({
        emails,
        displayNames,
        phoneNumbers,
      });
      setExistingUsers(res.users);
      if (res.meta?.total < 1) {
        setCheckExistingValid(true);
        // window.alert("중복된 정보가 없습니다. 업로드할 수 있습니다");
      } else {
        window.alert(`이미 등록된 ${res.meta.total}명의 유저와 중복된 정보가 있습니다`);
        setCheckExistingValid(true);
      }
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setChecking(false);
    }
  };

  const _addList = () => {
    const data = newUserCandidatesFromExcel.map((o) => ({
      ...o,
    }));
    setNewUsers(data);
    _handleCloseExcelUploadDialog();
  };

  useEffect(() => {
    const data = newUsers.map((o) => ({
      displayNameError: !o.displayName || !displayNameRegex.test(o.displayName),
      nameError: !o.name || !nameRegex.test(o.name),
      emailError: !o.email || !emailRegex.test(o.email),
      // phoneNumberError: !phoneNumberRegex.test(o.phoneNumber),
    }));
    if (data.filter((o) => o.displayNameError || o.nameError || o.emailError).length < 1) {
      setCheckValid(true);
    }
  }, [newUsers]);

  const _uploadMany = async () => {
    if (uploading) return;
    const a = window.confirm("생성하시겠습니까?");
    if (!a) return;

    const users = newUsers.map((o) => ({ ...o, userType: "STUDENT" }));
    try {
      setUploading(true);
      const res = await Network.usersStudentPOST({ users });
      setNewUsers([]);
      setNewUserCandidatesFromExcel([]);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const _readExcel = async (e) => {
    const file = e.target.files[0];
    if (!file) return window.alert("취소");
    const reader = new FileReader();
    reader.onload = function (e) {
      const workbook = XLSX.read(e.target.result, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const dataArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
      dataArray.splice(0, 1);
      if (dataArray.length < 1) return window.alert("데이터가 없습니다");
      if (dataArray.length > 500) return window.alert("한번에 500명까지 등록가능합니다.");

      let dataObj = dataArray.map((o) => ({
        name: o[0],
        displayName: o[0]?.replace?.(/ /gi, "") + generateRandomString("STRING", 3) + generateRandomString("NUMBER", 2),
        email: o[1],
        phoneNumber: o[2]?.replace?.(/-/gi, ""),
        year: o[3],
        grade: o[4],
        classNo: o[5],
        studentNo: o[6],
        userType: "USER",
      }));
      // dataObj = _.uniqBy(dataObj, (o) => o.email);
      // if (dataArray.length !== dataObj.length) {
      //   return window.alert("이메일이 중복된 데이터가 있습니다.");
      // }
      const numberOfInvalidDisplayName = _.filter(dataObj, (o) => !/^[a-zA-Z가-힣0-9]*$/.test(o.displayName))?.length || 0;
      if (numberOfInvalidDisplayName > 0) {
        return window.alert("닉네임이 잘못되어 있습니다.");
      }
      setNewUserCandidatesFromExcel(dataObj.slice(0, 500));
    };
    reader.readAsArrayBuffer(file);
    e.target.value = "";
  };
  const _downloadTemplateExcel = () => {
    downloadStudentCreateExcelTemplate();
  };

  const _handleOpenExcelUploadDialog = () => {
    setExcelUploadDialogOpen(true);
  };
  const _handleCloseExcelUploadDialog = () => {
    setExcelUploadDialogOpen(false);
    setNewUserCandidatesFromExcel([]);
  };

  const fileInputRef = React.useRef();

  return (
    <Box>
      <MessageBox
        sx={{ mb: 4 }}
        // title={'학생추가'}
        content={"이메일을 기준으로 기존 학생은 반 정보만 등록되며, 신규 학생은 계정이 생성되고, 반 정보도 함께 등록됩니다.\n초기 비밀번호는 12341234로 세팅됩니다."}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button size={"small"} variant={"outlined"} onClick={_handleOpenExcelUploadDialog}>
            {/*<Icon.Upload sx={{mr: 2}}/>*/}
            파일 업로드
          </Button>
          <Button sx={{ ml: 2 }} size={"small"} variant={"outlined"} onClick={_downloadTemplateExcel}>
            {/*<Icon.Download sx={{mr: 2}}/>*/}
            양식 다운로드
          </Button>
        </Box>
        <Box>
          <LoadingButton disabled={!checkValid || checking || newUsers.length < 1} loading={checking} variant={"outlined"} onClick={_checkExistingUsers}>
            기존유저 정보 충돌 확인
          </LoadingButton>
          <LoadingButton
            sx={{ ml: 2 }}
            disabled={!checkValid || uploading || !checkExistingValid}
            loading={uploading}
            variant={"contained"}
            // color={'success'}
            onClick={_uploadMany}
          >
            <Icon.UploadOutlined />
            학생 계정 등록하기
          </LoadingButton>
        </Box>
      </Box>
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>이름</TableCell>
            <TableCell>닉네임</TableCell>
            <TableCell>이메일</TableCell>
            <TableCell>전화번호</TableCell>
            <TableCell>학년도</TableCell>
            <TableCell>학년</TableCell>
            <TableCell>반</TableCell>
            <TableCell>번호</TableCell>
            <TableCell>중복확인</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {newUsers?.map((item, index) => {
            const existingUser = _.find(
              existingUsers,
              (o) => o.displayName === item.displayName || o.email === item.email || (o.phoneNumber === item.phoneNumber && !!item.phoneNumber),
            );
            return (
              <TableRow key={index}>
                <TableCell className={`${item.nameError ? "text-red-500" : ""}`}>{index + 1}</TableCell>
                <TableCell className={`${item.nameError ? "text-red-500" : ""}`}>{item.name}</TableCell>
                <TableCell className={`${item.displayNameError ? "text-red-500" : ""}`}>{item.displayName}</TableCell>
                <TableCell className={`${item.emailError ? "text-red-500" : ""}`}>{item.email}</TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{item.year}</TableCell>
                <TableCell>{item.grade}</TableCell>
                <TableCell>{item.classNo}</TableCell>
                <TableCell>{item.studentNo}</TableCell>
                <TableCell className={"whitespace-pre-line"}>
                  {!!existingUser && `${existingUser.name}\n${existingUser.displayName}\n${existingUser.phoneNumber}\n${existingUser.email}`}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      newUsers.splice(index, 1);
                      setNewUsers([...newUsers]);
                    }}
                    size={"small"}
                  >
                    행삭제
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Dialog fullWidth open={excelUploadDialogOpen} onClose={_handleCloseExcelUploadDialog}>
        <DialogTitle>엑셀로 업로드</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "450px",
          }}
        >
          <DialogContentText mb={2}>엑셀파일로 업로드 합니다.</DialogContentText>
          {/*<Button sx={{mr:2}} variant={"outlined"} onClick={_downloadTemplateExcel}>
           양식 다운로드
           </Button>*/}
          <Button variant={"outlined"} onClick={() => fileInputRef?.current?.click()}>
            <input hidden type={"file"} ref={fileInputRef} onChange={_readExcel} />
            엑셀파일 선택
          </Button>
          <Divider sx={{ mt: 2, mb: 3 }} />
          {newUserCandidatesFromExcel?.length > 0 ? (
            <Box>
              <Typography p sx={{ mt: 1 }}>
                {newUserCandidatesFromExcel?.length}건의 데이터가 있습니다.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography>파일을 선택해 주세요.</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={uploading} disabled={uploading} onClick={_handleCloseExcelUploadDialog}>
            취소
          </LoadingButton>
          <LoadingButton loading={uploading} disabled={uploading || newUserCandidatesFromExcel?.length < 1} onClick={_addList}>
            확인
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminStudentCreatePageTabBulk);
