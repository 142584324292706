import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

import { PATH_ADMIN, PATH_STUDENT, PATH_TEACHER } from "../../routes/paths";
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const Logo = ({ disabledLink = false, sx, ...props }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const PRIMARY_MAIN = theme.palette.primary.main;

  const PATH = props.auth.user.userType === "STUDENT" ? PATH_STUDENT : pathname.indexOf("/admin") !== -1 && props.auth.user.isAdmin ? PATH_ADMIN : PATH_TEACHER;

  const logo = (
    <Box sx={{ ...sx }}>
      <Typography variant="h6" noWrap component="div" color={PRIMARY_MAIN}>
        {PATH.title}
      </Typography>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <Link to={PATH.root}>{logo}</Link>;
};

const enhance = connect((state) => ({ ...state }), {});
export default enhance(Logo);

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};
