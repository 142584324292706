import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  Box,
  Card,
  ListItemIcon,
  ListItemText,
  InputLabel,
  ListSubheader,
  Select,
  FormControl,
  MenuItem,
  InputBase,
  Paper,
  Button,
  TextField,
  Container,
  Table,
  TableHead,
  TableRow,
  Stack,
  TableBody,
  Link as LinkMui,
} from "@mui/material";
import NetworkStudent from "../../lib/NetworkStudent";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import LoadingComponent from "../../components/shared/LoadingComponent";
import dayjs from "dayjs";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";

const StudentSearchPage = (props) => {
  const searchTargets = [
    {text: "책", value: "BOOK"},
    {text: "선생님", value: "TEACHER"},
    // {text: "태그", value: "TAG"},
    // { text: "학습활동", value: "GROUP" },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const keyword = query.get("keyword") || "";
  const selectedSearchTarget = query.get("target") || searchTargets[0].value;

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [searching, setSearching] = useState(false);
  const [books, setBooks] = useState([]);
  const [teacherUsers, setTeacherUsers] = useState([]);

  const _load = async () => {
    if (!keyword || keyword?.trim() === "") return;
    setSearching(true);
    switch (selectedSearchTarget) {
      case "BOOK": {
        try {
          const res = await NetworkStudent.bookSearchGET({
            offset: 0,
            limit: 50,
            keyword,
          });
          setBooks(res.books);
        } catch (e) {
        }
      }
        break;
      case "TEACHER": {
        try {
          const res = await NetworkStudent.usersGET({
            userType: "TEACHER",
            offset: 0,
            limit: 50,
            keyword,
          });
          setTeacherUsers(res.users);
        } catch (e) {
        } finally {
        }
        break;
      }
      case "STUDENT":
        break;
      case "COURSE":
        break;
      default:
        break;
    }
    setSearching(false);
  };

  useEffect(() => {
    _load();
  }, [keyword, selectedSearchTarget]);

  const _handleSearch = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      setSearchParams({keyword: keyword.trim(), target: selectedSearchTarget});
    }
  };

  const _renderBody = () => {
    if (searching) {
      return <LoadingComponent/>;
    }

    switch (selectedSearchTarget) {
      case "BOOK":
        return (
          <Box sx={{mb: 2, mt: 2}}>
            {books.map((book, i) => {
              return (
                <Box
                  // className={"flex mt-2 mt-2"}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    mt: 2,
                    mb: 2,
                    // minHeight:
                  }}
                  key={i}
                >
                  <Box className={"border border-gray-400"}>
                    <img
                      alt={book.title}
                      className={"object-contain w-16"}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "https://images.unsplash.com/photo-1648064568712-7891fa38bf65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";
                      }}
                      src={book.coverImageUrl}
                    />
                  </Box>
                  <Box sx={{flex: 1, pl: 2}}>
                    <LinkMui
                      component="button"
                      sx={{}}
                      onClick={() => {
                        navigate(`/search/book/${book.id}`);
                      }}
                    >
                      {book.title}
                    </LinkMui>
                    <Typography>{book.authors}</Typography>
                    <Typography>
                      {book.publisher} ({dayjs(book.pubDate).format("YYYY.MM")})
                    </Typography>
                    {/*<Button
                     to={`/search/book/${book.id}`}
                     as={Link}
                     variant={'contained'}
                     >
                     자세히보기
                     </Button>*/}
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      case "TEACHER":
        return (
          <Box sx={{mb: 2, mt: 2}}>
            {teacherUsers?.map((user, i) => {
              return (
                <Box sx={{mt: 2, mb: 2, display: "flex", alignItems: "center"}} key={i}>
                  <img className={"w-16 h-16 object-cover rounded-xl"} src={user.profileImageUrl}/>
                  <Box sx={{flex: 1, pl: 2}}>
                    <LinkMui
                      component="button"
                      sx={{}}
                      onClick={() => {
                        navigate(`/search/teacher/${user.id}`);
                      }}
                    >
                      {user.name} ({user.displayName})
                    </LinkMui>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth={"sm"}>
      <Card>
        <Stack spacing={1} direction={{xs: "column", sm: "row"}} sx={{width: "full", m: 2, alignItems: "center"}}>
          <TextField
            fullWidth
            select
            label="분류"
            displayEmpty
            value={selectedSearchTarget}
            onChange={({target}) => setSearchParams({target: target.value, keyword: keyword || ""})}
            sx={{
              maxWidth: {xs: "full", sm: 100},
              textTransform: "capitalize",
            }}
          >
            {searchTargets.map((item, index) => (
              <MenuItem value={item.value} key={index}>
                {item.text}
              </MenuItem>
            ))}
          </TextField>

          <Divider sx={{height: 48, mx: 3}} orientation="vertical"/>

          <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword} defaultValue={keyword}/>
        </Stack>
      </Card>
      {_renderBody()}
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSearchPage);
