import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {LoadingButton} from "@mui/lab";
import {Box, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MessageBox from "../shared/MessageBox";
import Network from "../../lib/Network";
import {PATH_ADMIN} from "../../routes/paths";
import {displayNameRegex, emailRegex, nameRegex, phoneNumberRegex} from "../../utils/regexTest";

const AdminStudentCreatePageTabOne = (props) => {

  const navigate = useNavigate();

  const [checking, setChecking] = useState(false);
  const [checkValid, setCheckValid] = useState(false);
  const [newUser, setNewUser] = useState({name: '', phoneNumber: '', displayName: '', email: '', userType: 'STUDENT'})
  const [newUsers, setNewUsers] = useState([]);
  const [existingUsers, setExistingUsers] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [formError, setFormError] = useState({name: false, phoneNumber: false, displayName: false, email: false})

  const _checkExistingUsers = async () => {
    if (( newUsers.length < 1 ) && ( !newUser.name || newUser.name === '' )) return;
    const emails = [...new Set(newUsers.map((o) => o.email).filter(Boolean)), newUser.email].filter(value => !!value);
    const displayNames = [...new Set(newUsers.map((o) => o.displayName).filter(Boolean)), newUser.displayName].filter(value => !!value);
    const phoneNumbers = [...new Set(newUsers.map((o) => o.phoneNumber).filter(Boolean)), newUser.phoneNumber].filter(value => !!value);
    setChecking(true);
    // return console.log({emails, displayNames, phoneNumbers})
    try {
      const res = await Network.usersCheckDuplicatedPOST({
        emails,
        displayNames,
        phoneNumbers,
      });
      setExistingUsers(res.users);
      if (res.meta?.total < 1) {
        setCheckValid(true);
        window.alert("중복된 정보가 없습니다. 업로드할 수 있습니다");
      } else {
        setCheckValid(false);
        window.alert(`이미 등록된 ${res.meta.total}명의 유저와 중복된 정보가 있습니다\n${res.users[0].name} ${res.users[0].email}`);
      }
    } catch (e) {
      window.alert(e.cause.errorMessage);

    } finally {
      setChecking(false);
    }

  };

  const _uploadOne = async () => {
    try {
      if (Object.values(formError).filter(Boolean).length > 0) return window.alert('데이터를 확인해 주세요.')
      const res = await Network.usersPOST({users: [newUser]})
      const goToDetail = window.confirm('생성된 학생페이지로 가시겠습니까?');
      if (goToDetail) {
        navigate(PATH_ADMIN.student.detail(res.users[0].id))
      } else {
        setNewUser({name: '', phoneNumber: '', displayName: '', email: '', userType: 'STUDENT'})
      }
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  }

  useEffect(() => {
    setCheckValid(false);
    setFormError({
      name: newUser.name !== '' && !nameRegex.test(newUser.name),
      displayName: newUser.displayName !== '' && !displayNameRegex.test(newUser.displayName),
      email: newUser.email !== '' && !emailRegex.test(newUser.email),
      phoneNumber: newUser.phoneNumber !== '' && !phoneNumberRegex.test(newUser.phoneNumber),
    })
  }, [newUser])


  return (
    <Box>
      <MessageBox
        sx={{mb: 4}}
        // title={'학생추가'}
        content={'학생을 등록한 후에, 학생 페이지에서 반 정보를 입력할 수 있습니다.'}
      />
      <TextField
        sx={{mb: 2}}
        fullWidth
        helperText={'본명을 입력해주세요.(2자이상 15자이내)'}
        label={'이름(필수)'}
        onChange={e => setNewUser(prev => ( {...prev, name: e.target.value.trim()} ))}
        value={newUser.name || ''}
        error={formError.name}
      />
      <TextField
        sx={{mb: 2}}
        fullWidth
        helperText={'동명이인의 학생을 구분하기 위해 사용합니다. 추후 학생 사용자가 변경할 수 있습니다. 닉네임은 중복될 수 없습니다.'}
        label={'닉네임(필수) - 한글/영어/숫자만 입력가능 2자이상 10자이내'}
        onChange={e => {
          let check = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]{0,10}$/;
          if (e.target.value?.trim().length > 10) return;
          if (!check.test(e.target.value) && e.target.value !== '') return alert('한글/영어/숫자만 입력가능합니다.');
          setNewUser(prev => ( {...prev, displayName: e.target.value.trim()} ))
        }}
        value={newUser.displayName || ''}
        error={formError.displayName}
      />
      <TextField
        sx={{mb: 2}}
        fullWidth
        helperText={'로그인에 사용 되는 이메일 입니다. 이메일은 중복될 수 없습니다.'}
        label={'email(필수)'}
        onChange={e => setNewUser(prev => ( {...prev, email: e.target.value.trim()} ))}
        value={newUser.email || ''}
        error={formError.email}
      />
      <TextField
        sx={{mb: 2}}
        fullWidth
        helperText={'비밀번호 찾기와, 알림을 위해 사용됩니다. 핸드폰은 중복될 수 없습니다. 추후 학생 사용자가 변경할 수 있습니다.'}
        label={'핸드폰 번호(선택) 10-11자'}
        onChange={(e) => {
          // setInquiry(prev => ({...prev, answer: e.target.value}))
          let check = /[0-9]{1,10}$/;
          if (!check.test(e.target.value) && e.target.value !== '') return alert('숫자만 입력하세요');
          if (e.target.value?.trim().length > 11) return;
          setNewUser(prev => ( {...prev, phoneNumber: e.target.value?.trim()} ));
        }}
        value={newUser.phoneNumber || ''}
        error={formError.phoneNumber}
      />
      <LoadingButton
        variant={'outlined'}
        disabled={checking || checkValid}
        loading={checking}
        onClick={_checkExistingUsers}
      >
        데이터 확인
      </LoadingButton>
      <LoadingButton
        sx={{ml: 2}}
        variant={'outlined'}
        color={'success'}
        disabled={!checkValid}
        loading={uploading}
        onClick={_uploadOne}>
        생성하기
      </LoadingButton>
    </Box>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminStudentCreatePageTabOne);
