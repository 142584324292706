import React, {useState, useRef, useEffect} from 'react';
import dayjs from 'dayjs';
import {useNavigate, useLocation, useParams, useMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select as SelectMui,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {debounce} from 'lodash';
import {Check} from '@mui/icons-material';


import {termTypesObj, courseTypesObj} from '../../constants';
import {getCourseType} from '../../utils';
import classNames from '../../assi-design-system-react/utils/classNames'
import {UploadOneFile as uploadOneFile} from '../../utils/firebaseStorageUpload';
import Network from '../../lib/Network';

import BoxReadMore from '../../components/shared/BoxReadMore';
import ResponsiveDatePicker from '../../components/shared/ResponsiveDatePicker';
import Loader from '../../assi-design-system-react/components/Loader';

const CourseCreateOrModifyPage = (props) => {
  const creatingCourse = !!useMatch('/course/create');
  const modifyingCourse = !!useMatch('/course/:courseId/modify');
  const navigate = useNavigate();
  const {state} = useLocation();
  const {courseId} = useParams();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const [isLoadedObj, setIsLoadedObj] = useState({course: false, subjects: false});
  const [isUploading, setUploading] = useState(false);
  const [course, setCourse] = useState({
    title: '',
    description: '',
    courseType: '',
    semesterId: null,
    subjectId: null,
    newSubject: '',
    maxNumberOfUser: null,
    startDate: null,
    endDate: null,
    registerStartDate: null,
    registerEndDate: null,
    coverImageUrl: 'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Freaders_school.png?alt=media&token=1516026d-bf20-4baa-9002-ad88dd8a7787',
  });
  const [coverImagePreviewUrl, setCoverImagePreviewUrl] = useState(
    'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Freaders_school.png?alt=media&token=1516026d-bf20-4baa-9002-ad88dd8a7787'
  );

  const [year, setYear] = useState('');
  const [semesters, setSemesters] = useState([]);
  const [grade, setGrade] = useState(null);
  const [subjects, setSubjects] = useState([]);

  const [isLimited, setLimited] = useState(false);

  const [newSubject, setNewSubject] = useState({});
  const [newSubjectModalOpen, setNewSubjectModalOpen] = useState(false);

  const requiredValid = !!course.title.trim() && !!course.courseType && !!course.startDate && !!course.endDate && !!course.coverImageUrl && !!course.semesterId;

  const optionalValid =
    (getCourseType.isRegular(course.courseType) && !!course.subjectId) ||
    (getCourseType.isIrregular(course.courseType) && !isLimited && !!course.registerStartDate && !!course.registerEndDate) ||
    (getCourseType.isIrregular(course.courseType) && !!isLimited && !!course.maxNumberOfUser && !!course.registerStartDate && !!course.registerEndDate);

  const fileUploadRef = useRef();
  const coverImageButtonRef = useRef();

  const _handleChange = debounce((key, value) => setCourse((prev) => ({...prev, [key]: value})), 300);

  const onChangeCoverImageUrl = async (file) => {
    setUploading(true);
    const url = await uploadOneFile(file, `school/${props.auth.user.schoolId}/user/${props.auth.user.id}/course`);
    if (!!url) {
      setCoverImagePreviewUrl(url);
    }
    setUploading(false);
  };

  // API
  const _loadSubjects = async () => {
    setIsLoadedObj((prev) => ({...prev, subjects: false}));
    try {
      const res = await Network.subjectsGET({grade, offset: 0, limit: 100});
      setSubjects(res.subjects || []);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setIsLoadedObj((prev) => ({...prev, subjects: true}));
  };

  const _createSubject = async () => {
    if (!newSubject.title || !newSubject.grade || !newSubject.groupName) return window.alert('정보를 모두 입력해주세요.');
    try {
      const res = await Network.subjectPOST({title: newSubject.title, grade: newSubject.grade, groupName: newSubject.groupName});
      _loadSubjects();
      setCourse((prev) => ({...prev, subjectId: res.subject?.id, subject: res.subject}));
      setGrade(newSubject.grade);
      setNewSubjectModalOpen(false);
      setNewSubject({});
      // return result.subject.id;
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  const _createCourse = async () => {
    try {
      const result = await Network.coursePOST({
        semesterId: course.semesterId,
        courseType: course.courseType,
        subjectId: course.subjectId,
        title: course.title.trim(),
        maxNumberOfUser: course.maxNumberOfUser,
        description: course.description.trim(),
        startDate: course.startDate,
        endDate: course.endDate,
        registerStartDate: course.registerStartDate,
        registerEndDate: course.registerEndDate,
        coverImageUrl: course.coverImageUrl,
      });
      setConfirmModalOpen(false);
      setTimeout(() => navigate(`/course/${result.course.id}`, {replace: true}), 1000);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  const _modifyCourse = async () => {
    try {
      const result = await Network.coursePUT({
        courseId,
        semesterId: course.semesterId,
        courseType: course.courseType,
        subjectId: course.subjectId,
        title: course.title.trim(),
        maxNumberOfUser: course.maxNumberOfUser,
        description: course.description.trim(),
        startDate: course.startDate,
        endDate: course.endDate,
        registerStartDate: course.registerStartDate,
        registerEndDate: course.registerEndDate,
        coverImageUrl: course.coverImageUrl,
      });
      setConfirmModalOpen(false);
      setTimeout(() => navigate(`/course/${result.course.id}`, {replace: true}), 1000);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  useEffect(() => {
    if (modifyingCourse) {
      setIsLoadedObj((prev) => ({...prev, course: false}));
      const _loadCourse = async () => {
        try {
          const result = await Network.courseGET({courseId});
          setCourse({
            ...result.course,
            startDate: dayjs(result.course.startDate).toISOString(),
            endDate: dayjs(result.course.endDate).toISOString(),
            registerStartDate: !!result.course?.registerStartDate ? dayjs(result.course.registerStartDate).toISOString() : null,
            registerEndDate: !!result.course?.registerEndDate ? dayjs(result.course.registerEndDate).toISOString() : result.course?.registerEndDate,
          });
          setYear(result.course.semester.year);
          setCoverImagePreviewUrl(result.course.coverImageUrl);
          setLimited(result?.course?.maxNumberOfUser > 0);
          setGrade(result.course?.subject?.grade);
        } catch (error) {
          window.alert(error.cause?.errorMessage || error);
        }
        setIsLoadedObj((prev) => ({...prev, course: true}));
      };
      _loadCourse();
    }
  }, [courseId]);

  useEffect(() => {
    if (year) {
      const _loadSemesters = async () => {
        try {
          const result = await Network.semestersGET({
            year,
            offset: 0,
            limit: 100,
          }); // year: dayjs().format("YYYY")
          setSemesters(result.semesters.reverse().map((semester) => ({...semester, name: termTypesObj[semester.term].name})) || []);
        } catch (error) {
          window.alert(error.cause?.errorMessage || error);
        }
      };
      _loadSemesters();
    }
  }, [year]);

  useEffect(() => {
    if (creatingCourse) {
      if (state?.courseType) {
        setCourse({...course, courseType: state?.courseType});
      }
      setIsLoadedObj((prev) => ({...prev, course: true}));
    }
  }, [state?.courseType]);

  useEffect(() => {
    if (semesters.length > 0 && !year && !!course.semesterId) {
      setYear(semesters?.find((semester) => semester.id === course.semesterId)?.year);
    }
  }, [semesters, course.semesterId]);

  useEffect(() => {
    _loadSubjects();
  }, [grade]);

  if (!isLoadedObj.course) return <Loader/>;

  const _renderNewSubjectDialog = () => (
    <Dialog
      fullWidth
      open={newSubjectModalOpen}
    >
      <DialogTitle>새로운 교과목 생성하기</DialogTitle>
      <Divider sx={{mt: 2, mb: 2}}/>
      <DialogContent sx={{pb: 2}}>
        <FormControl
          fullWidth
          sx={{minWidth: 250, mb: 2}}
        >
          <InputLabel>학년</InputLabel>
          <SelectMui
            label="학년"
            value={newSubject.grade || ''}
            onChange={({target}) => {
              setNewSubject((prev) => ({...prev, grade: target.value}));
            }}
          >
            {[1, 2, 3].map((value) => (
              <MenuItem
                key={String(value)}
                value={value}
              >
                {value} 학년
              </MenuItem>
            ))}
          </SelectMui>
          <FormHelperText>학년을 선태해 주세요.</FormHelperText>
        </FormControl>
        <TextField
          helperText="교과 군을 입력해 주세요. 수학, 영어, 사회, 과학 등.. 빈칸없이 입력해 주세요."
          label="교과군"
          // error={!displayNameRegex.test(newDisplayName)}
          fullWidth
          value={newSubject.groupName || ''}
          onChange={(e) => {
            setNewSubject((prev) => ({...prev, groupName: e.target.value}));
          }}
        />
        <TextField
          sx={{mt: 2}}
          helperText="과목 명을 입력해 주세요. 수학 1, 미적분, 공통사회 등.."
          label="교과명"
          // error={!displayNameRegex.test(newDisplayName)}
          fullWidth
          value={newSubject.title || ''}
          onChange={(e) => {
            setNewSubject((prev) => ({...prev, title: e.target.value}));
          }}
        />
      </DialogContent>
      <DialogActions sx={{p: 2}}>
        <Button
          onClick={async () => {
            await _createSubject();
          }}
          variant="outlined"
        >
          추가하기
        </Button>
        <Button
          onClick={() => {
            setNewSubjectModalOpen(false);
            setNewSubject({});
          }}
          variant="outlined"
        >
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );

  const selectedSubject = subjects?.find((s) => s.id === course.subjectId);

  if (!course.courseType) {
    // 비교과
    return (
      <div className="max-w-md mx-auto mt-28 space-y-2">
        <h3 className="mx-auto">수업 타입을 선택해주세요.</h3>
        <div className="grid md:grid-cols-2 space-y-2 md:space-y-0 md:space-x-2">
          <Button
            variant="contained"
            size="large"
            onClick={() => setCourse((prev) => ({...prev, courseType: courseTypesObj.REGULAR.type}))}
          >
            교과 수업
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => setCourse((prev) => ({...prev, courseType: courseTypesObj.IRREGULAR.type}))}
          >
            비교과 활동
          </Button>
        </div>
      </div>
    );
  } else {
    // 교과
    return (
      <div className="flex flex-col max-w-4xl mx-auto space-y-8 md:space-y-12">
        <div className="flex flex-row items-center justify-between pt-0 md:pt-6 space-y-1">
          <p className="text-xl font-bold">{`${courseTypesObj[course.courseType].name} ${getCourseType.isRegular(courseTypesObj[course.courseType].type) ? '수업' : '활동'} ${
            !!modifyingCourse ? '수정하기' : '생성'
          }`}</p>
        </div>

        <div className={classNames(getCourseType.isRegular(course.courseType) ? '' : 'hidden')}>
          <p className="text-lg font-bold mb-4">교과목 *</p>

          <div className="">
            <div className="flex flex-colum md:flex-row mb-4">
              <FormControl sx={{minWidth: 250, flex: 1}}>
                <InputLabel>학년</InputLabel>
                <SelectMui
                  label="학년"
                  value={grade || ''}
                  onChange={({target}) => {
                    setGrade(target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>전체</em>
                  </MenuItem>
                  {[1, 2, 3].map((value) => (
                    <MenuItem
                      key={String(value)}
                      value={value}
                    >
                      {value} 학년
                    </MenuItem>
                  ))}
                </SelectMui>
              </FormControl>
              <FormControl sx={{minWidth: 250, flex: 2, ml: 2}}>
                <InputLabel>과목</InputLabel>
                <SelectMui
                  label="과목"
                  value={course.subjectId || ''}
                  onChange={({target}) => {
                    setCourse((prev) => ({...prev, subjectId: target.value}));
                  }}
                >
                  <MenuItem value="">
                    <em>전체</em>
                  </MenuItem>
                  {subjects.map((subject) => (
                    <MenuItem
                      key={subject.id}
                      value={subject.id}
                    >
                      [{subject.grade}학년 {subject.groupName}] {subject.title}
                    </MenuItem>
                  ))}
                </SelectMui>
              </FormControl>
            </div>
            <Button
              variant="outlined"
              onClick={() => setNewSubjectModalOpen(true)}
            >
              없는 교과목 추가
            </Button>
            {_renderNewSubjectDialog()}
          </div>
        </div>

        <div className={classNames(getCourseType.isIrregular(course.courseType) ? '' : 'hidden')}>
          <div className="lg:col-start-2">
            <div className="text-lg font-bold mb-4">
              <div className="flex space-x-2 items-center">
                <FormControlLabel
                  label="인원제한"
                  control={
                    <Checkbox
                      checked={isLimited}
                      checkedIcon={<Check/>}
                      icon={<Check sx={{opacity: 0.2, color: 'common.black'}}/>}
                      onChange={(e) => {
                        setLimited(e.target.checked);
                        if (e.target.checked) {
                          setCourse((prev) => ({...prev, maxNumberOfUser: 100}));
                        } else {
                          setCourse((prev) => ({...prev, maxNumberOfUser: null}));
                        }
                      }}
                      sx={{marginRight: 2}}
                    />
                  }
                  sx={{marginLeft: 0, width: '100%'}}
                />
              </div>
            </div>
            <div className="text-sm opacity-60 mb-4">제한이 있을 경우만 체크합니다.</div>
            <TextField
              fullWidth
              onChange={(e) => _handleChange('maxNumberOfUser', e.target.valueAsNumber)}
              label="최대"
              type="number"
              name="maximum-number-of-class"
              id="maximum-number-of-class"
              placeholder="최대인원"
              defaultValue={course.maxNumberOfUser > 0 ? course.maxNumberOfUser : null}
              disabled={!isLimited}
            />
          </div>
        </div>

        <div>
          <p className="text-lg font-bold mb-4">진행 학기 *</p>
          <div className="text-sm opacity-60 mb-4">수업/활동이 주로 진행되거나, 시작될 학기를 선택해주세요.</div>
          <div className="flex w-full space-x-2">
            <TextField
              fullWidth
              label="연도"
              value={year}
              name="year"
              onChange={(e) => setYear(e.target.value)}
              select
            >
              {[2022].map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                >
                  {item}년
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="학기"
              value={course.semesterId}
              name="semesterId"
              placeholder={!year ? '학년도를 선택해주세요.' : '학기 선택'}
              helperText={(!year && '학년도를 선택해주세요.') || ''}
              onChange={(e) => setCourse((prev) => ({...prev, semesterId: e.target.value}))}
              select
              disabled={!course.semesterId && !year}
            >
              {semesters.map((item) => (
                <MenuItem
                  value={item.id}
                  key={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        <div>
          <p className="text-lg font-bold mb-4">진행 기간 *</p>
          <div className="flex w-full space-x-2">
            <ResponsiveDatePicker
              label="시작일"
              value={course.startDate}
              selectStart
              openTo="day"
              startDate={course.startDate}
              endDate={course.endDate}
              minDate={dayjs().subtract(3, 'month').startOf('day').toISOString()}
              maxDate={course.endDate || (course.endDate && dayjs(course.endDate))}
              onChange={(date) => setCourse((prev) => ({...prev, startDate: !date ? '' : dayjs(date).startOf('day').toISOString()}))}
            />
            <ResponsiveDatePicker
              label="종료일"
              value={course.endDate}
              selectEnd
              openTo="day"
              startDate={course.startDate}
              endDate={course.endDate}
              minDate={course.startDate || dayjs().subtract(3, 'month').startOf('day').toISOString()}
              maxDate={dayjs().add(1, 'year').endOf('day').toISOString()}
              onChange={(date) => setCourse((prev) => ({...prev, endDate: !date ? '' : dayjs(date).endOf('day').toISOString()}))}
            />
          </div>
        </div>

        {getCourseType.isIrregular(course.courseType) && (
          <div>
            <p className="text-lg font-bold mb-4">신청 기간 *</p>
            <div className="flex items-end gap-2">
              <ResponsiveDatePicker
                label="시작일"
                placeholderText={!course?.startDate || !course?.endDate ? '진행기간을 먼저 선택해주세요.' : '시작일'}
                disabled={!course?.startDate || !course.endDate}
                value={course.registerStartDate}
                selectStart
                openTo="month"
                startDate={course.registerStartDate}
                endDate={course.registerEndDate}
                minDate={course.startDate}
                maxDate={course?.registerEndDate || course?.endDate || dayjs().add(1, 'year').endOf('day').toISOString()}
                onChange={(date) => setCourse((prev) => ({...prev, registerStartDate: !date ? '' : dayjs(date).startOf('day').toISOString()}))}
              />
              <ResponsiveDatePicker
                label="종료일"
                disabled={!course?.startDate || !course.endDate}
                value={course.registerEndDate}
                selectEnd
                openTo="month"
                startDate={course.registerStartDate}
                endDate={course.registerEndDate}
                placeholderText={!course?.startDate || !course.endDate ? '진행기간을 먼저 선택해주세요.' : '종료일'}
                minDate={course.startDate || course.registerStartDate || dayjs().subtract(3, 'month').startOf('day').toISOString()}
                maxDate={course.registerEndDate || course.endDate || dayjs().add(1, 'year').endOf('day').toISOString()}
                onChange={(date) => setCourse((prev) => ({...prev, registerEndDate: !date ? '' : dayjs(date).endOf('day').toISOString()}))}
              />
            </div>
          </div>
        )}

        <div>
          <p className="text-lg font-bold mb-4">제목 *</p>
          <TextField
            fullWidth
            name="title"
            defaultValue={course.title}
            onChange={(e) => _handleChange('title', e.target.value)}
            placeholder="수업/활동 제목 입력"
            maxLength={50}
          />
        </div>

        <div>
          <p className="text-lg font-bold mb-4">소개</p>
          <div className="text-sm opacity-60 mb-4">수업/활동에 대한 소개 또는 과제 제출 규칙 등을 작성합니다.</div>
          <TextField
            fullWidth
            onChange={(e) => _handleChange('description', e.target.value)}
            defaultValue={course.description}
            placeholder="소개 입력"
            multiline
            minRows={3}
            inputProps={{maxLength: 1000}}
          />
        </div>

        <div>
          <div className="flex justify-between w-full space-x-8 mt-4 py-4">
            <div className="flex flex-col justify-between w-full">
              <p className="text-lg font-bold mb-4">커버이미지</p>
              <div className="bind-text-gray-400 text-sm font-semibold">
                <p>썸네일 이미지로 이용됩니다.</p>
                <p>권장 비율 1:1</p>
                <p>파일형식: PNG, JPG, GIF</p>
              </div>
              <LoadingButton
                variant="contained"
                ref={coverImageButtonRef}
                fullWidth
                onClick={() => setUploadModalOpen(true)}
                loading={isUploading}
              >
                {!course.coverImageUrl ? '커버이미지 선택' : '커버이미지 변경'}
              </LoadingButton>
            </div>
            <div>
              <div className={classNames(!!course.coverImageUrl ? 'relative w-40 md:w-60 ml-auto mr-0' : 'hidden')}>
                <div className="aspect-w-1 aspect-h-1 overflow-hidden ring-1 ring-gray-100 dark:ring-gray-700 dark:ring-opacity-50 shadow rounded-xl">
                  <img
                    alt="coverImageUrl"
                    src={course.coverImageUrl}
                    className="w-full object-cover object-center"
                  />
                </div>
              </div>
              <div className={classNames(isUploading ? 'cursor-wait relative w-40 md:w-60 ml-auto mr-0' : !course.coverImageUrl ? 'relative w-40 md:w-60 ml-auto mr-0' : 'hidden')}>
                <label
                  onClick={() => setUploadModalOpen(true)}
                  className={classNames(
                    !!course.coverImageUrl
                      ? 'hidden'
                      : 'cursor-pointer group w-full h-full rounded-xl flex aspect-w-1 aspect-h-1 border border-gray-300 dark:border-gray-600 border-dashed overflow-hidden hover:border-solid hover:bg-gray-50 dark:hover:bg-gray-750'
                  )}
                >
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-sm text-gray-500">1:1 비율로 보여집니다.</p>
                    <p className="text-sm text-gray-500">PNG, JPG, GIF</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mt-4">
          <Button
            color="error"
            className="row-start-2 sm:row-start-1 sm:col-span-1"
            onClick={() => setCancelModalOpen(true)}
          >
            취소
          </Button>
          <LoadingButton
            variant="contained"
            className="row-start-1 sm:row-start-1 sm:col-span-2"
            onClick={() => setConfirmModalOpen(true)}
            disabled={!(requiredValid && optionalValid)}
            loading={isUploading}
          >
            {modifyingCourse ? '수정하기' : '생성'}
          </LoadingButton>
        </div>

        <Dialog
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle sx={{p: 3}}>{modifyingCourse ? '수정 취소' : '작성 취소'}</DialogTitle>
          <DialogContent>
            <div>
              작성을 취소할까요?
              <br/>
              <span className="footnote">작성된 데이터는 저장되지 않습니다.</span>
            </div>
          </DialogContent>
          <DialogActions
            sx={{p: 3, pt: 0}}
            className="grid grid-cols-3"
          >
            <Button
              onClick={() => setCancelModalOpen(false)}
              className="col-start-1"
            >
              취소
            </Button>
            <Button
              variant="outlined"
              className="col-start-2 col-span-2"
              color="error"
              onClick={() => {
                setCancelModalOpen(false);
                modifyingCourse ? navigate(`/course/${courseId}`, {replace: true}) : navigate('/', {replace: true});
              }}
            >
              {modifyingCourse ? '수정 취소' : '작성 취소'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={uploadModalOpen}
          onClose={() => !isUploading && setUploadModalOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{p: 3}}>커버이미지</DialogTitle>
          <DialogContent>
            <div className="flex justify-between w-full space-x-4">
              <div className="flex flex-col justify-between w-full">
                <div className="bind-text-gray-400 text-sm font-semibold">
                  <p>수업/활동의 썸네일 이미지로 이용됩니다.</p>
                  <p>권장 비율 1:1</p>
                  <p>파일형식: PNG, JPG, GIF</p>
                </div>
                {!coverImagePreviewUrl ? (
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    onClick={() => fileUploadRef?.current.click()}
                    loading={isUploading}
                  >
                    파일 선택
                  </LoadingButton>
                ) : (
                  <div className="flex items-center space-x-1.5 w-full">
                    <LoadingButton
                      label="파일 선택"
                      onClick={() => fileUploadRef?.current.click()}
                      loading={isUploading}
                    >
                      파일 선택
                    </LoadingButton>
                    <LoadingButton
                      color="error"
                      onClick={() => {
                        setCoverImagePreviewUrl(
                          'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Freaders_school.png?alt=media&token=1516026d-bf20-4baa-9002-ad88dd8a7787'
                        );
                        setCourse((prev) => ({
                          ...prev,
                          coverImageUrl:
                            'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Freaders_school.png?alt=media&token=1516026d-bf20-4baa-9002-ad88dd8a7787',
                        }));
                      }}
                      loading={isUploading}
                    >
                      제거
                    </LoadingButton>
                  </div>
                )}
              </div>
              <div>
                <div
                  onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    e.dataTransfer.effectAllowed = 'copy';
                    e.dataTransfer.dropEffect = 'copy';
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    onChangeCoverImageUrl(e.dataTransfer.files[0]);
                    e.target.value = '';
                  }}
                  className={classNames(!!coverImagePreviewUrl ? 'group relative w-40 md:w-60 ml-auto mr-0' : 'hidden', '')}
                >
                  <div className="aspect-w-1 aspect-h-1 overflow-hidden ring-1 ring-gray-100 dark:ring-gray-700 dark:ring-opacity-50 shadow rounded-xl">
                    <img
                      alt="coverImageUrl"
                      src={coverImagePreviewUrl}
                      className="w-full object-cover object-center"
                    />
                  </div>
                </div>
                <div className={classNames(isUploading ? 'cursor-wait w-40 md:w-60 ml-auto mr-0' : !coverImagePreviewUrl ? 'relative w-40 md:w-60 ml-auto mr-0' : 'hidden')}>
                  <label
                    onDragOver={(e) => {
                      if (isUploading) return;
                      e.stopPropagation();
                      e.preventDefault();
                      e.dataTransfer.effectAllowed = 'copy';
                      e.dataTransfer.dropEffect = 'copy';
                    }}
                    onDrop={(e) => {
                      if (isUploading) return;
                      e.preventDefault();
                      onChangeCoverImageUrl(e.dataTransfer.files[0]);
                    }}
                    ref={fileUploadRef}
                    htmlFor="file-upload"
                    className={classNames(
                      !!coverImagePreviewUrl
                        ? 'hidden'
                        : 'cursor-pointer w-full h-full rounded-xl flex justify-center aspect-1 border border-gray-300 dark:border-gray-600 border-dashed overflow-hidden hover:border-solid hover:bg-gray-50 dark:hover:bg-gray-750'
                    )}
                  >
                    <div className="flex flex-col justify-center items-center">
                      <svg
                        className="h-8 w-8 mb-2 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <p className="bind-text-gray-400">커버이미지 올리기</p>
                      <p className="text-xs text-gray-500">클릭 또는 드래그앤드랍</p>
                      <p className="text-xs text-gray-500 mt-1">PNG, JPG, GIF</p>
                    </div>
                  </label>
                </div>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  accept="image/png, image/jpeg, image/gif, image/avif"
                  onChange={(e) => {
                    if (isUploading) return;
                    onChangeCoverImageUrl(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <p className="mt-8 bind-text-gray-600 font-bold">또는 기본 이미지에서 선택</p>
            <div className="flex overflow-x-scroll snap-mandatory snap-x gap-1.5 py-1">
              {[
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Folia-gozha-J4kK8b9Fgj8-unsplash.jpg?alt=media&token=88a65e15-a274-460e-b759-800e7c4e737d',
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Fpatrick-perkins-ETRPjvb0KM0-unsplash.jpg?alt=media&token=66399a54-c8bb-452e-ae5d-a0ee6fb70a1f',
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Ftoa-heftiba-FV3GConVSss-unsplash.jpg?alt=media&token=fa201191-0466-4006-894a-85ed53946121',
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Fpexels-photo-4947413.jpeg?alt=media&token=68fbc20b-eb03-41da-a03a-51e88395f6e7',
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Fpexels-photo-247839.jpeg?alt=media&token=fcc90f8a-330b-4795-b515-af0d7b2bf11e',
                'https://firebasestorage.googleapis.com/v0/b/readers-school-stage.appspot.com/o/imageTemplate%2Fpexels-photo-3747505.jpeg?alt=media&token=8ad4740a-003f-40db-8407-45c0fe454f9d',
              ].map((url, idx) => (
                <div
                  key={idx}
                  onClick={() => setCoverImagePreviewUrl(url)}
                  className="cursor-pointer aspect-1 snap-start snap-always scroll-ml-1 w-36 flex-shrink-0 block overflow-hidden ring-1 ring-gray-100 dark:ring-gray-700 dark:ring-opacity-50 shadow rounded"
                >
                  <img
                    alt="coverImageUrl"
                    src={url}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              ))}
            </div>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              fullWidth
              onClick={() => {
                if (!isUploading) {
                  setUploadModalOpen(false);
                  if (!!coverImagePreviewUrl) {
                    setCourse((prev) => ({...prev, coverImageUrl: coverImagePreviewUrl}));
                  }
                }
              }}
              loading={isUploading}
              vairant={!!coverImagePreviewUrl ? 'contained' : 'text'}
            >
              {!!coverImagePreviewUrl ? '완료' : '닫기'}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{p: 3}}>{`${courseTypesObj[course.courseType].name} ${getCourseType.isRegular(courseTypesObj[course.courseType].type) ? '수업' : '활동'} 확인`}</DialogTitle>
          <DialogContent>
            <>
              <div className="relative h-full overflow-y-scroll">
                {/* <div className="flex flex-col sm:flex-row justify-between"> */}
                <div className="float-right min-w-[8rem] sm:min-w-[16rem] w-auto ml-2">
                  {!!course.coverImageUrl && (
                    <div className="ring-1 ring-gray-200 dark:ring-gray-700 overflow-hidden aspect-w-1 aspect-h-1 rounded-xl">
                      <img
                        alt="coverImg Url"
                        src={course.coverImageUrl}
                        className="w-full object-cover object-center"
                      />
                    </div>
                  )}
                </div>
                {/* </div> */}
                <h2 className="sm:pt-8 pb-4 sm:py-0 text-xl sm:text-inherit">{course.title}</h2>
                <div className="py-2">
                  <h6>진행기간</h6>
                  <p>
                    {dayjs(course.startDate).format('YYYY년 M월 D일')} ~ {dayjs(course.endDate).format('YYYY년 M월 D일')}
                  </p>
                </div>
                {getCourseType.isIrregular(course.courseType) && (
                  <div className="py-2">
                    <h6>신청 기간</h6>
                    <p>
                      {dayjs(course.registerStartDate).format('YYYY년 M월 D일 (ddd)')} ~ {dayjs(course.registerEndDate).format('YYYY년 M월 D일 (ddd)')}
                    </p>
                  </div>
                )}
                <div className="py-2">
                  {getCourseType.isRegular(course.courseType) ? (
                    <>
                      <h6>교과목</h6>
                      <p>{!!selectedSubject ? `[${selectedSubject.grade}학년 ${selectedSubject.groupName}] ${selectedSubject.title}` : newSubject ? `[기타] ${newSubject}` : ''}</p>
                    </>
                  ) : (
                    getCourseType.isIrregular(course.courseType) && (
                      <>
                        <h6>인원제한</h6>
                        <p className={classNames(!!isLimited ? '' : 'text-gray-600')}>{!!isLimited ? `${course.maxNumberOfUser}명` : '-'}</p>
                      </>
                    )
                  )}
                </div>
                <div className="py-2">
                  <h6>소개</h6>
                  <div className={classNames(course.description ? '' : 'text-gray-600')}>
                    {course.description ? (
                      <BoxReadMore
                        rows={5}
                        text={course.description}
                        classText="whitespace-pre-line"
                      />
                    ) : (
                      <p className="bind-text-gray-400 text-sm">없음</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          </DialogContent>
          <DialogActions sx={{p: 3}}>
            <div className="flex space-x-2">
              <Button
                color="error"
                onClick={() => setConfirmModalOpen(false)}
              >
                취소
              </Button>
              <Button
                variant="contained"
                onClick={
                  modifyingCourse
                    ? () => {
                      _modifyCourse();
                    }
                    : () => {
                      _createCourse();
                    }
                }
                disabled={!(requiredValid && optionalValid)}
              >
                {!!modifyingCourse ? '수정하기' : '생성'}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

const enhance = connect((state) => ({...state}));
export default enhance(CourseCreateOrModifyPage);
