import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Container, Link as LinkMui, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import NetworkStudent from '../../lib/NetworkStudent';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { readStatusText, userBookReadStatuses } from '../../constants/dataTypes';
import dayjs from 'dayjs';
import LoadingComponent from '../../components/shared/LoadingComponent';
import SearchTextField from '../../assi-design-system-react/components/SearchTextField';
import Scrollbar from '../../components/NOT_Classified/Scrollbar';
import numeral from 'numeral';
import MessageBox from '../../components/shared/MessageBox';
import { PATH_STUDENT } from '../../routes/paths';

const StudentUserBookPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const keyword = query.get('keyword') || '';
  const selectedReadStatus = query.get('readStatus') || '';
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [books, setBooks] = useState([]);
  const LIMIT = 20;

  const inputRef = React.useRef(null);

  const _loadUserBooks = async () => {
    try {
      const res = await NetworkStudent.userMeBooksGET({
        offset: 0,
        limit: LIMIT,
        readStatus: !!selectedReadStatus ? selectedReadStatus : undefined,
        keyword: keyword || undefined,
      });
      if (!res.err) {
        setBooks(res.books);
        setMeta(res.meta);
        setIsLoaded(true);
      }
    } catch (e) {
    }
  };

  const _updateUserBook = async ({bookId, readStatus, rate, review}) => {
    setUploading(true);
    await NetworkStudent.userMeBookPUT({
      bookId,
      readStatus,
      rate,
      review,
    });
    setUploading(false);
  };

  useEffect(() => {
    setBooks([]);
    _loadUserBooks();
  }, [selectedReadStatus, keyword]);

  const _handleSearch = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get('keyword');
    if (keyword) {
      setSearchParams({keyword});
    }
  };

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            나의 책장
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format('0,0')}권
          </p>
        </div>
        <Button variant="contained" component={Link} to={PATH_STUDENT.search.root}>
          전체 책 검색
        </Button>
      </div>
      <MessageBox content={'내가 읽은 책 리스트를 정리해 보세요.\n추후 메모 기능과 분석 기능이 추가되어, 책을 추천해 드립니다.'}/>
      <Stack spacing={1} direction={{xs: 'column', sm: 'row'}} sx={{width: 'full', my: 2, alignItems: 'center'}}>
        <TextField
          fullWidth
          select
          label="독서상태"
          value={selectedReadStatus}
          onChange={(e) => navigate(`${location.pathname}${e.target.value === '' || e.target.value === 'ALL' ? '' : `?readStatus=${e.target.value}`}`, {replace: true})}
          InputLabelProps={{style: {}}}
          sx={{
            maxWidth: {xs: 'full', sm: 150},
            textTransform: 'capitalize',
            color: 'red',
          }}
        >
          <MenuItem
            sx={{
              mx: 1,
              my: 0.5,
              py: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
            value="ALL"
          >
            전체
          </MenuItem>
          {Object.keys(userBookReadStatuses).map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                my: 0.5,
                py: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {readStatusText[option]}
            </MenuItem>
          ))}
        </TextField>
        <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword} placeholder="내 책장 내 검색"/>
      </Stack>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
        renderItem={(item) => (
          <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}${!!selectedReadStatus && `readStatus=${selectedReadStatus}`}`} {...item} />
        )}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: 'relative'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell>제목</TableCell>
                <TableCell>저자</TableCell>
                <TableCell>출판사</TableCell>
                <TableCell>출판일</TableCell>
                <TableCell>상태</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {books?.map((book, index) => {
                return (
                  <TableRow key={book.id}>
                    <TableCell>
                      <Avatar src={book.coverImageUrl} variant="square"/>
                    </TableCell>
                    <TableCell>
                      <LinkMui as={Link} to={PATH_STUDENT.book.detail(book.id)} className={'line-clamp-1 max-w-[250px] text-primary-500'}>
                        {book?.title || '-'}
                      </LinkMui>
                    </TableCell>
                    <TableCell>{book.authors}</TableCell>
                    <TableCell>{book.publisher}</TableCell>
                    <TableCell>{dayjs(book.pubDate).format('YYYY.MM')}</TableCell>
                    <TableCell>
                      <TextField
                        select
                        size="small"
                        value={book.userBook?.readStatus}
                        onChange={({target}) => {
                          books[index].userBook = {
                            ...books[index].userBook,
                            readStatus: target.value,
                          };
                          setBooks((prev) => [...books]);
                          _updateUserBook({bookId: book.id, readStatus: target.value});
                        }}
                        sx={(theme) => ({...theme.typography.body3})}
                        inputProps={{sx: (theme) => ({...theme.typography.body3})}}
                      >
                        {Object.keys(userBookReadStatuses).map((value) => (
                          <MenuItem key={value} value={value} sx={(theme) => ({mx: 1, my: 0.5, borderRadius: 0.5, ...theme.typography.body3})}>
                            {readStatusText[value]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentUserBookPage);
