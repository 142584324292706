// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_TEACHER = "";
const ROOT_ADMIN = "/admin";
const ROOT_STUDENT = "";

// ----------------------------------------------------------------------

export const PATH_TEACHER = {
  title: "리더스 스쿨(선생님)",
  root: ROOT_TEACHER,
  book: {
    root: `${ROOT_TEACHER}/book`,
    detail: (bookId) => `${ROOT_TEACHER}/book/${bookId}`,
  },
  bookReport: {
    root: `${ROOT_TEACHER}/book-report`,
    detail: {
      root: (bookReportId) => `${ROOT_TEACHER}/book-report/${bookReportId}`,
      old: (bookReportId) => `${ROOT_TEACHER}/book-report/${bookReportId}/old`,
      comment: (bookReportId, bookReportCommentId) => `${ROOT_TEACHER}/book-report/${bookReportId}/comment/${bookReportCommentId}`,
    },
  },
  class: {
    root: `${ROOT_TEACHER}/class`,
    detail: {
      root: (classId) => `${ROOT_TEACHER}/class/${classId}`,
      studentDetail: (classId, studentUserId) => `${ROOT_TEACHER}/class/${classId}/student/${studentUserId}`,
    },
  },
  course: {
    root: `${ROOT_TEACHER}/course`,
    create: `${ROOT_TEACHER}/course/create`,
    detail: {
      root: (courseId) => `${ROOT_TEACHER}/course/${courseId}`,
      edit: (courseId) => `${ROOT_TEACHER}/course/${courseId}/modify`,
      student: {
        list: (courseId) => `${ROOT_TEACHER}/course/${courseId}?tab=STUDENT`,
        detail: {
          root: (courseId, studentUserId) => `${ROOT_TEACHER}/course/${courseId}/student/${studentUserId}`,
        },
      },


      information: (courseId) => `${ROOT_TEACHER}/course/${courseId}/information`,
      task: {
        list: (courseId) => `${ROOT_TEACHER}/course/${courseId}/task`,
        create: (courseId) => `${ROOT_TEACHER}/course/${courseId}/task/create`,
        createOld: (courseId) => `${ROOT_TEACHER}/course/${courseId}/task/create/old`,
        detail: {
          root: (courseId, taskId) => `${ROOT_TEACHER}/course/${courseId}/task/${taskId}`,
          edit: (courseId, taskId) => `${ROOT_TEACHER}/course/${courseId}/task/${taskId}/modify`,
          bookReport: {
            list: (courseId, taskId) => `${ROOT_TEACHER}/course/${courseId}/task/${taskId}/book-report`,
            detail: {
              root: (courseId, taskId, bookReportId) => `${ROOT_TEACHER}/course/${courseId}/task/${taskId}/book-report/${bookReportId}`,
              comment: (courseId, taskId, bookReportId, bookReportCommentId) => `${ROOT_TEACHER}/course/${courseId}/task/${taskId}/book-report/${bookReportId}/comment/${bookReportCommentId}`,
            },
          },
        },
      },
    },
  },
  help: {
    root: `${ROOT_TEACHER}/help`,
  },
  myAccount: {
    root: `${ROOT_TEACHER}/my-account`,
  },
  notification: {
    root: `${ROOT_TEACHER}/notification`,
  },
  student: {
    detail: {
      root: (studentUserId) => `${ROOT_TEACHER}/student/${studentUserId}`,
    },
  },
  task: {
    detail: {
      root: (taskId) => `${ROOT_TEACHER}/task/${taskId}`,
      edit: (taskId) => `${ROOT_TEACHER}/task/${taskId}/edit`,
      editOld: (taskId) => `${ROOT_TEACHER}/task/${taskId}/edit/old`,
      bookReport: (taskId) => `${ROOT_TEACHER}/task/${taskId}/book-report`,
    },
  },
  teacher: {
    detail: {
      root: (teacherUserId) => `${ROOT_TEACHER}/teacher/${teacherUserId}`,

    }
  },
};

export const PATH_ADMIN = {
  title: "리더스 스쿨 관리자",
  root: ROOT_ADMIN,
  book: {
    root: path(ROOT_ADMIN, "/book"),
    detail: (bookId) => path(ROOT_ADMIN, `/book/${bookId}`),
  },
  bookReport: {
    root: `${ROOT_ADMIN}/book-report`,
    detail: (bookReportId) => path(ROOT_ADMIN, `/book-report/${bookReportId}`),
  },
  class: {
    root: path(ROOT_ADMIN, "/class"),
    create: path(ROOT_ADMIN, "/class/create"),
    detail: (classId) => path(ROOT_ADMIN, `/class/${classId}`),
  },
  course: {
    root: path(ROOT_ADMIN, "/course"),
    create: path(ROOT_ADMIN, "/course/create"),
    detail: (courseId) => path(ROOT_ADMIN, `/course/${courseId}`),
  },
  student: {
    root: path(ROOT_ADMIN, "/student"),
    create: path(ROOT_ADMIN, "/student/create"),
    detail: (studentId) => path(ROOT_ADMIN, `/student/${studentId}`),
  },
  subject: {
    root: path(ROOT_ADMIN, "/subject"),
    create: path(ROOT_ADMIN, "/subject/create"),
    // detail: subjectId => path(ROOT_ADMIN, `/class/${subjectId}`),
  },
  task: {
    detail: (taskId) => path(ROOT_ADMIN, `/task/${taskId}`),
  },
  teacher: {
    root: path(ROOT_ADMIN, "/teacher"),
    create: path(ROOT_ADMIN, "/teacher/create"),
    temp: `${ROOT_ADMIN}/teacher/temp`,
    detail: (teacherUserId) => path(ROOT_ADMIN, `/teacher/${teacherUserId}`),
  },
};

export const PATH_STUDENT = {
  title: "리더스 스쿨",
  root: ROOT_STUDENT,
  book: {
    root: `${ROOT_STUDENT}/book`,
    detail: (bookId) => `${ROOT_STUDENT}/book/${bookId}`,
  },
  bookReport: {
    root: `${ROOT_STUDENT}/book-report`,
    writeSelectType: `${ROOT_STUDENT}/book-report/write/select-type`,
    writeCreateTask: `${ROOT_STUDENT}/book-report/write/create-task`,
    detail: {
      root: (bookReportId) => `${ROOT_STUDENT}/book-report/${bookReportId}`,
      edit: (bookReportId) => `${ROOT_STUDENT}/book-report/${bookReportId}/edit`,
    },
  },
  class: {
    root: `${ROOT_STUDENT}/class`,
    detail: {
      root: (classId) => `${ROOT_STUDENT}/class/${classId}`,
    },
  },
  course: {
    root: `${ROOT_STUDENT}/course`,
    detail: (courseId) => `${ROOT_STUDENT}/course/${courseId}`,
  },
  courseIrregular: {
    root: `${ROOT_STUDENT}/course-irregular`,
    detail: (courseId) => `${ROOT_STUDENT}/course-irregular/${courseId}`,
  },
  help: {
    root: `${ROOT_STUDENT}/help`,
  },
  inquiry: {
    root: `${ROOT_STUDENT}/inquiry`,
    create: `${ROOT_STUDENT}/inquiry/create`,
    detail: (inquiryId) => `${ROOT_STUDENT}/inquiry/${inquiryId}`,
  },
  task: {
    detail: (taskId) => `${ROOT_STUDENT}/task/${taskId}`,
  },
  teacher: {
    // root : path(ROOT_STUDENT, "/teacher"),
    detail: (teacherId) => path(ROOT_STUDENT, `/teacher/${teacherId}`),
  },
  search: {
    root: `${ROOT_STUDENT}/search`,
    book: {
      root: (bookId) => `${ROOT_STUDENT}/search/book/${bookId}`,
    },
  },
  searchBook: {
    root: `${ROOT_STUDENT}/search/book`,
    detail: (bookId) => `${ROOT_STUDENT}/search/book/${bookId}`,
  },

};

