import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Network from "../../lib/Network";
import NetworkStudent from "../../lib/NetworkStudent";
import { Box, Container, Avatar, Typography, FormControl, Select, MenuItem, InputLabel, TextField, Button } from "@mui/material";
import { authActionCreators } from "../../redux/auth/auth.store";
import { LoadingButton } from "@mui/lab";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";

const loginTypes = [
  {value: "EMAIL", name: "이메일"},
  {value: "PHONE", name: "핸드폰"},
];

const LoginPage = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const [isLoaded, setIsLoaded] = useState(false)
  const [schools, setSchools] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const [selectedLoginType, setSelectedLoginType] = useState(null);

  const [uploading, setUploading] = useState(false);

  // 이메일 또는 핸드폰에 인증번호 받아서 로그인할 때 필요한 데이터들
  const [phoneNumber, setPhoneNumber] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [code, setCode] = useState("");
  const [codeTyped, setCodeTyped] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const _loadSchools = async () => {
      try {
        const res = await Network
          .schoolsGET();
        if (!res.err) {
          setSchools(res.schools || []);
          setIsLoaded(true);
        }
      } catch (e) {
        window.alert(e.cause.errorMessage);
      }

    };
    _loadSchools();
  }, [])

  const _requestVerifyCodeByPhoneNumber = async () => {
    if (requesting) return;
    setRequesting(true);
    try {
      const res = await Network
        .signInWithPhoneNumberRequestVerifyCodePOST({
          phoneNumber: phoneNumber,
          schoolId: selectedSchoolId,
        });
      setCode(res.verificationCode);
      window.alert("문자 발송 완료\n문자 메시지를 확인해 주세요.");
    } catch (e) {
      setRequesting(false);
      alert(e.cause?.errorMessage);
    }
  };

  const _signInWithPhoneNumber = async () => {
    if (uploading) return;
    setUploading(true);
    try {
      const res = await Network
        .signInWithPhoneNumberPOST({
          phoneNumber: phoneNumber,
          schoolId: selectedSchoolId,
        });
      if (!!res.token) {
        localStorage.setItem('token', res.token)
        Network.setToken(res.token);
        NetworkStudent.setToken(res.token);
        props.fetchMyInfo()
        navigate(from, {replace: true});
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
    setUploading(false);
  };
  const _signInWithEmail = async () => {
    if (uploading) return;
    setUploading(true);
    try {
      const res = await Network
        .signInPOST({
          email,
          password,
          schoolId: selectedSchoolId,
        });
      if (!!res.token) {
        localStorage.setItem('token', res.token)
        Network.setToken(res.token);
        NetworkStudent.setToken(res.token);
        props.fetchMyInfo()
        navigate(from, {replace: true});
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
    setUploading(false);
  }

  const _renderEmailOrPhoneNumberPart = () => {
    if (!selectedLoginType) return;
    switch (selectedLoginType) {
      case 'EMAIL':
        return (
          <>
            <TextField
              sx={{my: 2}}
              required
              fullWidth
              label={'이메일'}
              name={'email'}
              type={'email'}
              id={'email'}
              onChange={(e) => setEmail(e.target.value?.trim())}
            />
            <TextField
              sx={{my: 2}}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value?.trim())}
            />
            <LoadingButton
              variant={"contained"}
              fullWidth
              sx={{mt: 2}}
              onClick={_signInWithEmail}
              loading={uploading}
              disabled={!email || !password || uploading}
            >
              로그인하기
            </LoadingButton>
          </>
        )
      case 'PHONE':
        return (
          <>
            <TextField
              id={'email'}
              key={'phone'}
              placeholder={"전화번호를 숫자로만 입력해 주세요."}
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {code === "" ? (
              <Button
                sx={{mt: 2}}
                variant={"contained"}
                onClick={_requestVerifyCodeByPhoneNumber}
                disabled={phoneNumber.substring(0, 3) === "010" ? phoneNumber.length < 11 : phoneNumber.length < 10}
              >
                인증번호받기
              </Button>
            ) : (
              <>
                <TextField
                  sx={{mt: 2}}
                  fullWidth
                  placeholder={"인증번호"}
                  value={codeTyped}
                  onChange={(e) => setCodeTyped(e.target.value.replace?.(/[^\d]/g, ""))}
                />
                <LoadingButton
                  variant={"contained"}
                  fullWidth
                  sx={{mt: 2}}
                  onClick={_signInWithPhoneNumber}
                  loading={uploading}
                  disabled={code !== codeTyped || uploading}
                >
                  로그인하기
                </LoadingButton>
              </>
            )}
          </>
        )
      default:
        return <></>
    }
  }

  if (props.authReducer?.isLoggedIn) return <Navigate to={from === "/login" ? "/" : from} replace/>;
  if (!isLoaded) return <LoadingComponent/>

  return (
    <main className={'max-w-sm mx-auto pt-[20vh]'}>
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sizes={'large'}
          src={`/logo512.png`}
        />
        <Typography component={"h1"} variant={"h5"}>
          리더스 스쿨
        </Typography>
        <Box sx={{mt: 3, width: "100%"}}>
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel>학교선택</InputLabel>
            <Select
              value={selectedSchoolId || ""}
              onChange={({target}) => {
                setSelectedSchoolId(target.value);
              }}
              placeholder={'학교선택'}
              label={'학교선택'}
            >
              {schools.map(o => (
                <MenuItem key={o.id} value={o.id}>
                  {o.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {
            !!selectedSchoolId && (
              <FormControl fullWidth sx={{my: 2}}>
                <InputLabel>로그인 타입</InputLabel>
                <Select
                  value={selectedLoginType || ""}
                  onChange={({target}) => {
                    setSelectedLoginType(target.value);
                  }}
                  label={'로그인 타입'}
                >
                  {loginTypes.map(o => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
          {_renderEmailOrPhoneNumberPart()}
        </Box>
      </Box>
    </main>

  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  },
);

export default enhance(LoginPage);
