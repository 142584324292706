import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputBase,
  IconButton,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import NetworkStudent from "../../lib/NetworkStudent";

const StudentSearchTeacherDialogComponent = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [keyword, setKeyword] = useState("");
  const [teacherUsers, setTeacherUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const LIMIT = 50;

  const _handleSearch = async (e) => {
    e.preventDefault();
    if (searching) return;
    setSearching(true);
    const res = await NetworkStudent.usersGET({
      offset: 0,
      limit: LIMIT,
      keyword,
      userType: "TEACHER",
    });
    if (!res.err) {
      setTeacherUsers(res.users);
      setIsLoaded(true);
    }
    setSearching(false);
  };

  const selectTeacherUser = (user) => {
    props.selectTeacherUser?.(user);
    props.onClose?.();
  };

  return (
    <Dialog fullWidth open={props.open || false}>
      <DialogTitle>
        선생님 선택
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box className={"bg-gray-100 rounded"} component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center" }} onSubmit={_handleSearch}>
          <InputBase
            value={keyword}
            name={"keyword"}
            sx={{ ml: 1, flex: 1 }}
            placeholder="이름으로 검색해 주세요."
            inputProps={{}}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
          {!!keyword && (
            <IconButton
              onClick={() => {
                setKeyword("");
              }}
              sx={{ p: "2px" }}
              aria-label="search"
            >
              <Icon.CloseRounded />
            </IconButton>
          )}
          <Button type="submit" variant={"outlined"} sx={{ p: "2px" }}>
            검색
          </Button>
        </Box>
      </DialogContent>
      <DialogContent sx={{ height: "50vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>이름</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {teacherUsers.map((user, index) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar src={user.profileImageUrl} />
                  </TableCell>
                  <TableCell>
                    {user.name} ({user.displayName})
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => selectTeacherUser(user)}>선택</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton
          onClick={props.onClose}
          // loading={uploading}
          // disabled={uploading || newTeacherUserCandidatesFromExcel?.length < 1}
          // onClick={_addManyUser}
        >
          닫기
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSearchTeacherDialogComponent);
