import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Network from "../../lib/Network";
import {Button, IconButton, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {PATH_TEACHER} from "../../routes/paths";
import {DeleteOutline} from "@mui/icons-material";
import {message} from "../../constants";

import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import StudentSearchDialog from "../../components/teacher/StudentSearchDialog";

const CourseDetailStudentTab = (props) => {

  const {courseId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const keyword = query.get("keyword") || "";

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [users, setUsers] = useState([])
  const [course, setCourse] = useState({});
  const [meta, setMeta] = useState({});

  const [studentAddModalVisible, setStudentAddModalVisible] = useState(false)

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }
  const _load = async () => {
    try {
      const res = await Network.courseUsersGET({
        courseId,
        offset: (page - 1) * perPage,
        limit: perPage,
        keyword,
      })
      setUsers(res.users);
      setCourse(res.course);
      setMeta(res.meta);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage)
    }
  }
  useEffect(() => {
    _load();
  }, [page, perPage, keyword])

  const _deleteCourseRegister = async (studentUserId) => {
    const a = window.confirm("수업에서 삭제할까요?");
    if (!a) return;
    try {
      setUploading(true);
      await Network.courseRegisterDELETE({courseId, userId: studentUserId});
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      await _load();
      setUploading(false);
    }
  };
  const _openStudentAddModal = () => {
    setStudentAddModalVisible(true);
  }
  const _hideStudentSearchDialog = () => {
    setStudentAddModalVisible(false)
  }
  const _inviteStudents = async (studentUserIds) => {
    if (!Array.isArray(studentUserIds) || studentUserIds.length < 1) return alert('추가할 학생을 선택해 주세요');
    setUploading(true);
    try {
      const res = await Network.courseRegisterPOST({courseId, userIds: studentUserIds})
      alert('추가되었습니다');
      await _load();
      setStudentAddModalVisible(false);
      setUploading(false);
      return true;
    } catch (e) {
      window.alert(e.cause?.errorMessage);
      setUploading(false);
      return false;
    }
  }


  if (!isLoaded) return <div/>
  return (
    <div className="space-y-2 mt-4">
      <Button
        onClick={_openStudentAddModal}
        variant={'contained'}>
        학생 추가하기
      </Button>
      <SearchTextField
        placeholder={message.placeholder.findStudentsByName}
        defaultValue={keyword}
        onClickClear={() => navigate(location.pathname)}
        onSubmit={_handleSearch}
      />
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 'max-content'}}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  [
                    {name: "No.", className: ""},
                    {name: "이름", className: ""},
                    {name: "학년", className: ""},
                    {name: "반", className: ""},
                    {name: "번호", className: ""},
                    {name: "-", className: ""},
                  ].map((head) => (
                    <TableCell key={head.name} className={head.className}>
                      {head.name}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map((user, i) => {
                  return (
                    <TableRow key={user.id} className={"hover:bg-hover"}>
                      <TableCell>
                        {(page - 1) * perPage + i + 1}
                      </TableCell>
                      <TableCell>
                        {/*<Link to={`${PATH_TEACHER.course.detail.student.detail.root(courseId, user.id)}`}>*/}
                        <Link to={`${PATH_TEACHER.course.detail.student.detail.root(courseId, user.id)}`}>
                          <div className={'flex items-center min-w-[40px] clickable-primary'}>
                            <img src={user?.profileImageUrl} className={'w-[30px] aspect-1 object-cover rounded-full'}/>
                            <p className={'pl-2'}>
                              {user?.name}
                            </p>
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {user.classRegister?.class?.grade}
                      </TableCell>
                      <TableCell>
                        {user.classRegister?.class?.classNo}
                      </TableCell>
                      <TableCell>
                        {user.classRegister?.studentNo}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color={'error'}
                          disabled={uploading}
                          onClick={() => _deleteCourseRegister(user.id)}>
                          <DeleteOutline/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <StudentSearchDialog
        year={course.semester?.year || 2022}
        onSelectComplete={_inviteStudents}
        onCancel={_hideStudentSearchDialog}
        open={studentAddModalVisible}/>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(CourseDetailStudentTab);
