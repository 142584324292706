import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, InputLabel, Link as LinkMui, MenuItem, Pagination, PaginationItem, Select, Select as SelectMui, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,} from "@mui/material";
import dayjs from "dayjs";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import numeral from "numeral";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";

// ----------------------------------------------------------------------

const YEAR_OPTIONS = [2022, 2021, 2020];

// ----------------------------------------------------------------------

const AdminSubjectPage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const year = query.get("year") || dayjs().format("YYYY");
  const grade = query.get("grade") || "";
  const keyword = query.get("keyword") || "";
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [newSubject, setNewSubject] = useState({});
  const [newSubjectModalOpen, setNewSubjectModalOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const _load = async () => {
    const res = await Network.subjectsGET({
      year,
      keyword,
      offset: (page - 1) * LIMIT,
      limit: LIMIT,
      grade,
    });

    if (!res.err) {
      setSubjects(res.subjects || []);
      setMeta(res.meta);
      setIsLoaded(true);
    }
  };
  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      setSearchParams({keyword, page: 1, year, grade});
    }
  };
  const _navigateToCourseListPage = (subjectId) => {
    navigate(`/admin/subject/${subjectId}/courses?year=${year}`);
  };

  const _createSubject = async () => {
    if (!newSubject.title || !newSubject.grade || !newSubject.groupName) return window.alert('정보를 모두 입력해주세요.');
    try {
      const res = await Network.subjectPOST({title: newSubject.title, grade: newSubject.grade, groupName: newSubject.groupName});
      setSearchParams({keyword: newSubject.title})
      setNewSubjectModalOpen(false)
      setNewSubject({});
      // return result.subject.id;
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  useEffect(() => {
    // setSubjects([]);
    // setMeta({total: 0})
    _load();
    window.scrollTo(0, 0)
  }, [page, keyword, year, grade]);

  if (!isLoaded) return <LoadingComponent/>;

  const _renderNewSubjectDialog = () => {
    return (
      <Dialog
        fullWidth
        open={newSubjectModalOpen}>
        <DialogTitle>
          새로운 교과목 생성하기
        </DialogTitle>
        <Divider sx={{mt: 2, mb: 2}}/>
        <DialogContent sx={{pb: 2}}>
          <FormControl
            fullWidth
            sx={{minWidth: 250, mb: 2}}
          >
            <InputLabel>학년</InputLabel>
            <SelectMui
              label={"학년"}
              value={newSubject.grade || ''}
              onChange={({target}) => {
                setNewSubject(prev => ({...prev, grade: target.value}))
              }}
            >
              <MenuItem value={""}>
                <em>전체</em>
              </MenuItem>
              {[1, 2, 3].map((value) => {
                return (
                  <MenuItem key={String(value)} value={value}>{value} 학년</MenuItem>
                )
              })}
            </SelectMui>
            <FormHelperText>
              학년을 선태해 주세요.
            </FormHelperText>
          </FormControl>
          <TextField
            helperText={'교과 군을 입력해 주세요. 수학, 영어, 사회, 과학 등.. 빈칸없이 입력해 주세요.'}
            label={'교과군'}
            // error={!displayNameRegex.test(newDisplayName)}
            fullWidth
            value={newSubject.groupName || ''}
            onChange={(e) => {
              setNewSubject(prev => ({...prev, groupName: e.target.value}))
            }}
          />
          <TextField
            sx={{mt: 2}}
            helperText={'과목 명을 입력해 주세요. 수학 1, 미적분, 공통사회 등..'}
            label={'교과명'}
            // error={!displayNameRegex.test(newDisplayName)}
            fullWidth
            value={newSubject.title || ''}
            onChange={(e) => {
              setNewSubject(prev => ({...prev, title: e.target.value}))
            }}
          />
        </DialogContent>
        <DialogActions sx={{p: 2}}>
          <Button
            onClick={_createSubject}
            variant={'outlined'}>
            추가하기
          </Button>
          <Button
            onClick={() => {
              setNewSubjectModalOpen(false);
              setNewSubject({});
            }}
            variant={'outlined'}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container maxWidth={'xl'}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            과목 관리
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}개
          </p>
        </div>
        <Button variant="contained" onClick={() => setNewSubjectModalOpen(true)}>
          신규등록
        </Button>
      </div>
      <Stack spacing={1} direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2, alignItems: "center"}}>
        <TextField
          fullWidth
          select
          label="연도"
          value={year}
          onChange={(e) => {
            if (e.target.value) {
              searchParams.set('year', e.target.value);
            } else {
              searchParams.delete('year');
            }
            searchParams.delete('page');
            navigate(`${location.pathname}?${searchParams.toString()}`)
          }}
          sx={{
            maxWidth: {xs: "full", sm: 100},
          }}
        >
          {YEAR_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="학년"
          value={grade}
          onChange={(e) => {
            if (e.target.value) {
              searchParams.set('grade', e.target.value);
            } else {
              searchParams.delete('grade');
            }
            searchParams.delete('page');
            navigate(`${location.pathname}?${searchParams.toString()}`)
          }}
          sx={{
            maxWidth: {xs: "full", sm: 100},
            textTransform: "capitalize",
          }}
        >
          <MenuItem value={""}>
            전체
          </MenuItem>
          {
            [1, 2, 3].map(value => {
              return (
                <MenuItem key={String(value)} value={value}>{value} 학년</MenuItem>
              )
            })
          }
        </TextField>
        <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword}/>
      </Stack>
      <Box sx={{position: "relative"}} mb={2}>
        <Pagination
          // sx={{mt: 3, mb: 3}}
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => {
            searchParams.set('page', item.page);
            let path = `${location.pathname}?${searchParams.toString()}`;
            return (
              <PaginationItem
                component={Link}
                to={path}
                {...item}
              />)
          }}
        />
      </Box>
      <Scrollbar>
        <TableContainer sx={{minWidth: "max-content"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={'30%'} head>
                  교과군
                </TableCell>
                <TableCell width={'30%'} head>
                  대상 학년
                </TableCell>
                <TableCell width={'30%'} head>
                  과목명
                </TableCell>
                <TableCell>
                  개설과목수
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subjects?.map((subject) => {
                return (
                  <TableRow key={subject.id}>
                    <TableCell>
                      {subject.groupName}
                    </TableCell>
                    <TableCell>
                      {subject.grade}
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        component="button"
                        onClick={() => _navigateToCourseListPage(subject.id)}
                      >
                        {subject.title}
                      </LinkMui>

                    </TableCell>
                    <TableCell>
                      <LinkMui
                        component="button"
                        onClick={() => _navigateToCourseListPage(subject.id)}
                        // to={`/admin/subject/${subject.id}/courses?year=${year}`}
                        // as={Link}
                      >
                        {subject.stats?.courseCount}
                      </LinkMui>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {_renderNewSubjectDialog()}
    </Container>
  )
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminSubjectPage);
