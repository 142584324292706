import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Avatar, Button, Container, TableCell, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Stack, Link as LinkMui} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import NetworkStudent from "../../lib/NetworkStudent";
import LoadingComponent from "../../components/shared/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import numeral from "numeral";
import MessageBox from '../../components/shared/MessageBox';
import {PATH_STUDENT} from '../../routes/paths';

const StudentClassMyPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [classes, setClasses] = useState([]);

  const _load = async () => {
    if (!props.auth?.user?.id) return window.alert("유저 정보가 없습니다");

    try {
      const res = await NetworkStudent.classesGET({
        offset: 0,
        limit: 10,
        studentUserId: props.auth?.user?.id,
      });
      setClasses(res.classes || []);
      setMeta(res.meta);
      setIsLoaded(true);
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };

  useEffect(() => {
    _load();
  }, []);

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            나의 반정보
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}개
          </p>
        </div>
      </div>
      <MessageBox content={'나의 반 정보는 관리자 선생님에 의해 자동으로 등록됩니다.\n정보가 잘못된 경우 1:1문의로 알려주세요.'}/>
      <Scrollbar sx={{mt: 3}}>
        <TableContainer sx={{minWidth: 800, position: "relative"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>학년도</TableCell>
                <TableCell align="center">
                  학년
                </TableCell>
                <TableCell align="center">
                  반
                </TableCell>
                <TableCell align="center">
                  번호
                </TableCell>
                <TableCell align="center">
                  담임선생님
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classes?.map((cls) => {
                return (
                  <TableRow key={cls.id}>
                    <TableCell>{cls.year}</TableCell>
                    <TableCell align="center">{cls.grade}</TableCell>
                    <TableCell align="center">{cls.classNo}</TableCell>
                    <TableCell align="center">{cls.classRegister?.studentNo}</TableCell>
                    <TableCell align="center">
                      <LinkMui
                        disabled={!cls.teacherUser?.id}
                        as={Link}
                        to={PATH_STUDENT.teacher.detail(cls.teacherUser?.id)}
                        className={"line-clamp-1 max-w-[250px] text-primary-500"}>
                        {cls.teacherUser?.name}
                      </LinkMui>

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentClassMyPage);
