import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {connect} from "react-redux";
import { Breadcrumbs, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';

import Network from '../../lib/Network';
import Loader from "../../assi-design-system-react/components/Loader";
import {PATH_TEACHER} from "../../routes/paths";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";

const TeacherCourseStudentDetail = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const page = parseInt(query.get('page')) || 1;
  const perPage = parseInt(query.get('perPage')) || 10;

  const offset = parseInt(searchParams?.get('offset')) || 0;
  const limit = 10;

  const navigate = useNavigate();
  const { courseId, userId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [student, setStudent] = useState({});
  const [course, setCourse] = useState({});
  const [tasks, setTasks] = useState([]);
  const [meta, setMeta] = useState({ total: 0 });

  const [submissionFactSheet, setSubmissionFactSheet] = useState([]);

  useEffect(() => {
    const _loadStudentUser = async () => {
      try {
        const { course } = await Network.courseGET({ courseId });
        const { semester } = course;
        if (!semester.year) throw { cause: { errorMessage: '수업에 연도 정보가 없습니다' } };
        setCourse(course);
        const { user } = await Network.userGET({ userId, year: semester.year });
        setStudent(user);

        const { tasks } = await Network.tasksGET({ userId, courseId });
        setTasks(tasks);
        setMeta(meta);
        setIsLoaded(true);
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      }
    };
    _loadStudentUser();
  }, [courseId, userId]);

  const divRef = useRef([]);

  const _deleteStudent = async (userId) => {
    try {
      const result = await Network.courseRegisterDELETE({ courseId, userId });
      navigate(`/course/${courseId}/student`);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  if (!isLoaded) return <Loader />;

  return (
    <Container maxWidth='xl'>
      <div className='pb-4'>
        <Breadcrumbs separator='›'>
          <Link to={PATH_TEACHER.course.root}>
            <p className='clickable-primary'>수업</p>
          </Link>
          <Link to={PATH_TEACHER.course.detail.root(courseId)}>
            <p className='clickable-primary'>{course.title}</p>
          </Link>
          <p className='font-bold'>{student?.name}</p>
        </Breadcrumbs>
      </div>
      <div className='py-4'>
        <p className='text-xl font-bold'>학생정보</p>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className='w-[150px]'>이름</TableCell>
                <TableCell>
                  <div className='flex items-center'>
                    <img
                      src={student.profileImageUrl}
                      className='w-[30px] h-[30px] rounded-full'
                    />
                    <p className='pl-2'>{student.name}</p>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>학년/반/번호</TableCell>
                <TableCell>
                  {student.classRegister?.class?.grade}학년 {student.classRegister?.class?.classNo}반 {student.classRegister?.studentNo}번
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>이메일</TableCell>
                <TableCell>{student.email || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>전화</TableCell>
                <TableCell>{student.phoneNumber || '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <p className='py-4 text-xl font-bold'>독후감 목록</p>
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>과제명</TableCell>
                <TableCell>기한</TableCell>
                <TableCell>독후감제목</TableCell>
                <TableCell>제출일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, i) => (
                <TableRow
                  key={task.id}
                  className='hover:bg-hover'
                >
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{task.title}</TableCell>
                  <TableCell>{dayjs(task.endDate).format('YYYY.MM.DD(ddd)HH:mm')}</TableCell>
                  <TableCell>
                    {!!task.bookReport?.id ? (
                      <Link to={PATH_TEACHER.bookReport.detail.root(task.bookReport?.id)}>
                        <div className='clickable-primary line-clamp-1 max-w-[300px]'>{task.bookReport?.title}</div>
                      </Link>
                    ) : (
                      '미제출'
                    )}
                  </TableCell>
                  <TableCell>{task.bookReport?.id && <span>{dayjs(task.bookReport?.submittedAt).format('YYYY.MM.DD(ddd)HH:mm')}</span>}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({}), );
export default enhance(TeacherCourseStudentDetail);
