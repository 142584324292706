export const MENU_OPTIONS_ADMIN = [
  {
    label: "선생님 모드 변경",
    linkTo: "/",
  },
];
 
export const MENU_OPTIONS_TEACHER = [
  {
    label: "관리자 모드 변경",
    linkTo: "/admin",
    isAdmin: (isAdmin) => !!isAdmin,
  },
  {
    label: "내 계정",
    linkTo: "/my-account",
  },
];

export const MENU_OPTIONS_STUDENT = [];
