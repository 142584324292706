const breakpoints = {
  keys: [
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
  ],
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    "2md": 992,
    lg: 1024,
    xl: 1280,
    "2xl": 1536


    // default
    // xs:0,
    // sm:600,
    // md: 900,
    // lg: 1200,
    // xl: 1536,
  },
  unit: "px",
};

export default breakpoints;
