import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Chip, Container, Link as LinkMui, Pagination, TableCell, PaginationItem, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import NetworkStudent from '../../lib/NetworkStudent';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import LoadingComponent from '../../components/shared/LoadingComponent';
import Scrollbar from '../../components/NOT_Classified/Scrollbar';
import numeral from 'numeral';
import MessageBox from '../../components/shared/MessageBox';
import { PATH_STUDENT } from '../../routes/paths';

const StudentBookReportMainPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const page = parseInt(query.get('page') || '1', 10);

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [bookReports, setBookReports] = useState([]);
  const LIMIT = 10;

  const _load = async () => {
    try {
      const res = await NetworkStudent.bookReportsGET({
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
      });
      setBookReports(res.bookReports);
      setMeta(res.meta);
      setIsLoaded(true);
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };

  useEffect(() => {
    _load();
  }, [page]);

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            나의 독후감
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format('0,0')}개
          </p>
        </div>
      </div>
      <MessageBox content={'작성한 모든 독후감 리스트입니다.'}/>
      <Button
        component={Link}
        to={PATH_STUDENT.bookReport.writeSelectType}
        sx={{mt: 3}}
        color={'success'}
        variant={'contained'}
      >
        새로운 독후감 작성
      </Button>
      <Pagination
        sx={{my: 3}}
        // sx={{mt: 3, mb: 3}}
        page={page}
        count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
        renderItem={(item) => <PaginationItem component={Link} to={`${location?.pathname}?page=${item.page}`} {...item} />}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: 'relative'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>제목</TableCell>
                <TableCell>수업</TableCell>
                <TableCell>과제명</TableCell>
                <TableCell>도서</TableCell>
                <TableCell>최근수정일</TableCell>
                <TableCell>제출일</TableCell>
                <TableCell>댓글 수</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports?.map((bookReport) => {
                return (
                  <TableRow key={bookReport.id}>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.bookReport.detail.root(bookReport.id)}
                        className={'text-[#1370F5] line-clamp-1 w-[100px]'}
                      >
                        {bookReport.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        disabled={!bookReport.task?.courseId}
                        to={PATH_STUDENT.course.detail(bookReport.task?.courseId)}
                        className={'text-[#1370F5] line-clamp-1 max-w-[100px]'}
                      >
                        {bookReport.task?.course?.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.task.detail(bookReport.taskId)}
                        className={'text-[#1370F5] line-clamp-1 max-w-[100px]'}
                      >
                        {bookReport.task?.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      {!!bookReport.book?.id ? (
                        <LinkMui
                          as={Link}
                          to={PATH_STUDENT.searchBook.detail(bookReport.bookId)}
                          className={'text-[#1370F5] line-clamp-1 max-w-[100px]'}
                        >
                          {bookReport.book?.title}
                        </LinkMui>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      {dayjs(bookReport.updatedAt).format('MM.DD(ddd) HH:mm')}
                    </TableCell>
                    <TableCell>
                      {!!bookReport.submittedAt ? (
                        <>{dayjs(bookReport.submittedAt).format('MM.DD(ddd) HH:mm')}</>
                      ) : (
                        <Chip variant="contained" color="error" label={'미제출'}/>
                      )}
                    </TableCell>
                    <TableCell align="right">{numeral(bookReport.stat?.bookReportCommentCount).format('0,0')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({...state}), {});
export default enhance(StudentBookReportMainPage);
