import 'dayjs/locale/ko';
import dayjs, {locale} from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {authActionCreators, authenticationActionCreators} from './redux/auth/auth.store';
import {notificationActionCreators} from './redux/notification/notification.store';

import Router from './routes';
import ThemeProvider from './assi-design-system-react/mui/theme';

import {notificationStudentActionCreators} from './redux/notification-student/notification-student.store';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
import Analytics from './components/NOT_Classified/Analytics';
import ChannelIo from './components/NOT_Classified/ChannelIo';
import ErrorBoundary from './components/NOT_Classified/ErrorBoundary';

locale('ko');
dayjs.extend(relativeTime);
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore);

const App = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (props.authReducer.isLoggedIn) {
      if (props.authReducer.user.userType === 'TEACHER') {
        props.fetchNotificationTeacher()
      } else {
        props.fetchNotificationStudent()
      }
    }
  }, [props.authReducer.isLoggedIn]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CollapseDrawerProvider>
        <ThemeProvider>
          <ErrorBoundary>
            <Router/>
            <Analytics/>
            <ChannelIo/>
          </ErrorBoundary>
        </ThemeProvider>
      </CollapseDrawerProvider>
    </LocalizationProvider>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }), {
    ...authActionCreators,
    ...authenticationActionCreators,
    ...notificationActionCreators,
    ...notificationStudentActionCreators,
  });
export default enhance(App);
