import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Breadcrumbs, Container, Tab, Table, TableBody, TableRow, TableCell, TableContainer, Button} from "@mui/material";
import dayjs from "dayjs";
import numeral from 'numeral';
import Network from "../../lib/Network";
import Loader from "../../assi-design-system-react/components/Loader";
import TaskDetailBookReportTab from "../../sections/teacher/TaskDetailBookReportTab";
import TaskDetailInfoTab from "../../sections/teacher/TaskDetailInfoTab";
import TaskDetailStudentTab from "../../sections/teacher/TaskDetailStudentTab";
import {PATH_TEACHER} from "../../routes/paths";
import classNames from '../../assi-design-system-react/utils/classNames';
import {scoreTypeObj} from "../../constants/scoreTypes";
import MoreButtonMenu from "../../components/shared/MoreButtonMenu";

const TeacherTaskDetail = (props) => {

  const {taskId} = useParams()

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const tab = query.get('tab') || 'BOOK_REPORT'
  const [searchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [task, setTask] = useState({})


  const _deleteTask = async () => {
    const a = window.confirm('삭제하시겠습니까?');
    if (!a) return;
    setFetching(true);
    try {
      const result = await Network.taskDELETE({taskId});
      !result.err && navigate(PATH_TEACHER.course.detail.root(task?.courseId));
    } catch (error) {
      window.alert(error.cause?.errorMessage)
    } finally {
      setFetching(false);
    }
  };
  const _goToTaskCreateOrModifyPageToDuplicateTask = async () => {
    const a = window.confirm('과제를 복사하시겠습니까?');
    if (!a) return;
    navigate(PATH_TEACHER.course.detail.task.create(task?.courseId), {
      state: {
        task: {
          title: task.title,
          description: task.description,
          bookIsbn13s: task.bookIsbn13s,
          fileUrls: task.fileUrls,
          scoreType: task.scoreType || 'SCORE',
          permitEditAfterSubmit: task.permitEditAfterSubmit,
        },
        books: task.books,
        questions: task.questions.map((q) => ({title: q.title, description: q.description, questionType: q.questionType})),
      },
    });
  };
  const _load = async () => {
    try {
      const res = await Network.taskGET({taskId});
      setTask(res.task);
      setIsLoaded(true);
      if (!res.task?.courseId) {
        window.alert('존재하지 않는 과제 입니다')
        navigate(PATH_TEACHER.course.root, {replace: true})
      }
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])
  const _handleChangeTab = (newTab) => {
    searchParams.set('tab', newTab);
    navigate(location.pathname + `?${searchParams.toString()}`, {replace: true})
  }

  if (!isLoaded) return <Loader/>

  return (
    <Container maxWidth="xl">
      <div className="py-4">
        <Breadcrumbs separator="›">
          <Link to={PATH_TEACHER.course.root}>
            <p className="clickable-primary">
              수업/활동
            </p>
          </Link>
          <Link to={PATH_TEACHER.course.detail.root(task?.courseId)}>
            <p className="clickable-primary">
              {task?.course?.title}
            </p>
          </Link>
          <p className="font-bold">
            {task.title}
          </p>
        </Breadcrumbs>
      </div>
      <div className="py-2 mb-2">
        <TableContainer sx={{maxWidth: 'sm'}}>
          <Table size="small">
            <TableBody className="border-t border-x">
              <TableRow>
                <TableCell className="w-[130px] border-r text-center font-bold">
                  제목
                </TableCell>
                <TableCell>
                  {task.title}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="w-[130px] border-r text-center font-bold">
                  기간
                </TableCell>
                <TableCell>
                  {dayjs(task.startDate).format('YYYY.MM.DD(ddd)')} - {dayjs(task.endDate).format('MM.DD(ddd) HH:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-r text-center font-bold">
                  평가 유형
                </TableCell>
                <TableCell>
                  {scoreTypeObj[task.scoreType]?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-r text-center font-bold">
                  제출후 수정
                </TableCell>
                <TableCell className={classNames(task.permitEditAfterSubmit ? 'text-green-500' : 'text-red-500')}>
                  {task.permitEditAfterSubmit ? '가능' : '불가능'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-r text-center font-bold">
                  공개 상태
                </TableCell>
                <TableCell className={classNames(task.isPublished ? 'text-green-500' : 'text-red-500')}>
                  {task.isPublished ? '공개됨' : '임시 저장'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-r text-center font-bold">책</TableCell>
                <TableCell>{numeral(task.bookIsbn13s.length).format('0,0')}권</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="border-r text-center font-bold">
                  편집
                </TableCell>
                <TableCell className="">
                  <MoreButtonMenu
                    text="편집"
                    options={[
                      {text: '수정하기', onClick: () => navigate(PATH_TEACHER.task.detail.edit(taskId))},
                      {text: <span className="text-red-500 ">삭제</span>, onClick: () => _deleteTask()},
                      {text: <span className="">복사</span>, onClick: () => _goToTaskCreateOrModifyPageToDuplicateTask()},
                    ]}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TabContext value={tab}>
        <TabList
          className="my-8"
          onChange={(e, newValue) => _handleChangeTab(newValue)}>
          <Tab label={`제출된 독후감(${task.stat?.bookReportCount})`} value="BOOK_REPORT"/>
          <Tab label={`문항(${task.stat?.questionCount})`} value="INFO"/>
          <Tab label="학생 리스트" value="STUDENT"/>
        </TabList>
        <TabPanel value="BOOK_REPORT">
          <TaskDetailBookReportTab task={task}/>
        </TabPanel>
        <TabPanel value="INFO">
          <TaskDetailInfoTab task={task}/>
        </TabPanel>
        <TabPanel value="STUDENT">
          <TaskDetailStudentTab/>
        </TabPanel>
      </TabContext>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TeacherTaskDetail);
