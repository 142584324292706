import { useRoutes } from "react-router-dom";
import { connect } from "react-redux";
import { routesForTeachers, routesForStudents } from "./routes";
import { authActionCreators } from "../redux/auth/auth.store";
import { useState, useEffect } from "react";

const Router = (props) => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    props.appInitialize(() => {
      setInitialized(true);
    });
  }, []);
  const routes = props.authReducer.user?.userType === "STUDENT" ? routesForStudents : routesForTeachers
  const element = useRoutes(routes);
  if (!initialized) return <div></div>

  return element;
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  });
export default enhance(Router);
