import axios from "axios";
import dayjs from "dayjs";

class Network {
  constructor() {
    this.API = process.env.REACT_APP_API_URL;
    this.devMode = process.env.REACT_APP_DEV_MODE || !process.env.REACT_APP_PROD_MODE;
    this.headers = {
      currentBuildNo: 1,
      isWeb: "true",
      Authorization: "", // token 아니라 authorization
    };
  }

  setToken(authorization) {
    this.headers.Authorization = authorization;
  }

  onError(err, url, method, data) {
    let errorMessage = "오류가 발생하였습니다.\n고객센터에 문의해주세요.";

    if (err.response) {
      this.devMode && console.error(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${err.response?.data?.status || err.response?.data?.code}`, err.response?.data);
      if (err.response?.data?.errorMessage) {
        errorMessage = err.response.data.errorMessage;
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (err.response?.data?.statusText) {
        errorMessage = err.response.data.statusText;
      }
    } else {
      this.devMode && console.error(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${err.response?.data?.status || err.response?.data?.code}`, err.message);
      if (err.message === "Network Error") {
        errorMessage = "네트워크 또는 서버에 연결되어 있지 않습니다.";
      } else {
        errorMessage = err.message;
      }
    }

    throw new Error(errorMessage, {
      cause: {
        err: true,
        status: err.response?.data?.status || err.response?.data?.code,
        statusText: err.response?.data?.statusText || err.response?.data?.code,
        message: err.message,
        errorMessage: errorMessage,
      },
    });
  }

  _sendRequest(url, params, method) {
    const now = dayjs();
    this.devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, params);

    return axios[method](this.API + url, {
      headers: this.headers,
      params,
      withCredentials: true,
    })
      .then((response) => {
        // this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url);
        this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch((err) => this.onError(err, url, method.toUpperCase(), params));
  }

  // post, put
  _sendRequestForData(url, data, method) {
    const now = dayjs();
    this.devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, data);

    return axios[method](this.API + url, data, {
      headers: this.headers,
      withCredentials: true,
    })
      .then((response) => {
        // this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url);
        this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch((e) => this.onError(e, url, method.toUpperCase(), data));
  }

  _sendRequestForDataReturningRaw(url, data, method, headers) {
    const now = dayjs();
    this.devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, data);

    return axios[method](this.API + url, data, {
      headers: { ...this.headers, ...headers, withCredentials: true },
    })
      .then((response) => {
        this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url);
        // this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response;
      })
      .catch((err) => {
        console.error(err);
        // console.log(err.message);
        return {
          err: true,
          errorMessage: err.response?.data?.errorMessage,
          message: err.response?.data?.errorMessage,
        };
      });
  }

  _get = (url, params, headers) => this._sendRequest(url, params, "get", headers);
  _post = (url, data) => this._sendRequestForData(url, data, "post");
  _postReturningRaw = (url, data) => this._sendRequestForDataReturningRaw(url, data, "post");
  _delete = (url, params, query) => this._sendRequest(url, params, "delete", query);
  _put = (url, data, headers) => this._sendRequestForData(url, data, "put", headers);

  // AUTH
  signInPOST = ({ email, phoneNumber, password, schoolId, loginType }) => this._post("/sign-in", { email, phoneNumber, password, schoolId, loginType });
  signInWithPhoneNumberPOST = ({ phoneNumber, schoolId }) => this._post("/sign-in/with-phone", { phoneNumber, schoolId });
  signInWithPhoneNumberRequestVerifyCodePOST = ({ phoneNumber, schoolId }) => this._post("/sign-in/with-phone/request-verify-code", { phoneNumber, schoolId });
  signInWithEmailPOST = ({ email, schoolId }) => this._post("/sign-in/with-email", { email, schoolId });
  signInWithEmailRequestVerifyCodePOST = ({ email, schoolId }) => this._post("/sign-in/with-email/request-verify-code", { email, schoolId });

  // BOOK 🔴
  bookSearchGET = ({ keyword, offset, limit }) => this._get("/book/search", { keyword, offset, limit });
  bookGET = ({ bookId }) => this._get(`/book/${bookId}`, {});

  // BOOK_REPORT 🟢
  bookReportsGET = ({ courseId, taskId, offset, limit, studentUserId, teacherUserId, isScored, keyword, includingAnswers, minSubmittedAt, maxSubmittedAt }) =>
    this._get("/book-reports", {
      courseId,
      taskId,
      offset,
      limit,
      studentUserId,
      teacherUserId,
      isScored,
      keyword,
      includingAnswers,
      minSubmittedAt,
      maxSubmittedAt,
    }); // 한 유저의 모든 독후감, userId넣으면 필터링!
  bookReportGET = ({ bookReportId }) => this._get(`/book-report/${bookReportId}`, {});
  bookReportPUT = ({ bookReportId, teacherUserId }) => this._put(`/book-report/${bookReportId}`, { teacherUserId });

  bookReportScorePUT = ({ bookReportId, score, answers }) => this._put(`/book-report/${bookReportId}/score`, { score, answers });
  bookReportScoreDELETE = ({ bookReportId }) => this._delete(`/book-report/${bookReportId}/score`);

  // BOOK_REPORT_COMMENT 🟢
  bookReportCommentsGET = ({ bookReportId, offset, limit }) => this._get("/book-report-comments", { bookReportId, offset, limit });
  bookReportCommentPUT = ({ bookReportCommentId, content }) => this._put(`/book-report-comment/${bookReportCommentId}`, { content });
  bookReportCommentPOST = ({ bookReportId, bookReportCommentId, content }) =>
    this._post("/book-report-comment", {
      bookReportId,
      bookReportCommentId,
      content,
    }); // 대댓글의 경우만 bookReportCommentId
  bookReportCommentDELETE = ({ bookReportCommentId }) => this._delete(`/book-report-comment/${bookReportCommentId}`, {});

  // BOOK_REPORT_COMMENT_REPORT 🟢🔴
  bookReportCommentReportPOST = ({ bookReportCommentId }) => this._post(`/book-report-comment/${bookReportCommentId}/report`, {}); // 대댓글의 경우만 bookReportCommentId
  bookReportCommentReportDELETE = ({ bookReportCommentId }) => this._delete(`/book-report-comment/${bookReportCommentId}/report`, {});

  // CLASS 🟢
  classesGET = ({ teacherUserId, studentUserId, year, grade, offset, limit }) => this._get("/classes", { teacherUserId, studentUserId, year, grade, offset, limit });
  classPOST = ({ year, grade, classNo, teacherUserId }) => this._post("/class", { year, grade, classNo, teacherUserId });
  classPUT = ({ classId, year, grade, classNo, teacherUserId }) => this._put(`/class/${classId}`, { year, grade, classNo, teacherUserId });
  classGET = ({ classId }) => this._get(`/class/${classId}`, {});
  classDELETE = ({ classId }) => this._delete(`/class/${classId}`, {});

  // CLASS_REGISTER 🟢
  classRegisterPOST = ({ classId, userIds }) => this._post(`/class/${classId}/register`, { userIds });
  classRegisterDELETE = ({ classId, userId }) => this._delete(`/class/${classId}/register`, { userId });
  // CLASS_USERS
  classUsersGET = ({ classId, offset, limit, keyword }) => this._get(`/class/${classId}/users`, { offset, limit, keyword });

  // COURSE  🟢
  // courseType  => 'REGULAR' 'IRREGULAR'
  coursesGET = ({ year, offset, limit, semesterId, studentUserId, teacherUserId, subjectId, keyword }) =>
    this._get("/courses", {
      year,
      offset,
      limit,
      semesterId,
      studentUserId,
      teacherUserId,
      subjectId,
      keyword,
    });
  coursePOST = ({ semesterId, courseType, subjectId, title, maxNumberOfUser, description, coverImageUrl, startDate, endDate, registerStartDate, registerEndDate }) =>
    this._post(`/course`, {
      semesterId,
      subjectId,

      courseType,
      title,
      description,
      coverImageUrl,

      maxNumberOfUser,
      startDate,
      endDate,

      registerStartDate,
      registerEndDate,
    });
  coursePUT = ({ courseId, semesterId, subjectId, courseType, title, maxNumberOfUser, description, startDate, endDate, coverImageUrl, registerStartDate, registerEndDate }) =>
    this._put(`/course/${courseId}`, {
      semesterId,
      courseType,
      subjectId,
      title,
      maxNumberOfUser,
      description,
      startDate,
      endDate,
      coverImageUrl,

      registerStartDate,
      registerEndDate,
    });
  courseGET = ({ courseId }) => this._get(`/course/${courseId}`, {});
  courseDELETE = ({ courseId }) => this._delete(`/course/${courseId}`);

  // COURSE_REGISTER 🟢
  courseUsersGET = ({ courseId, offset, limit, keyword }) => this._get(`/course/${courseId}/users`, { offset, limit, keyword });
  courseRegisterPOST = ({ courseId, userIds }) => this._post(`/course/${courseId}/register`, { userIds });
  courseRegisterDELETE = ({ courseId, userId }) => this._delete(`/course/${courseId}/register`, { userId });

  // NOTIFICATION 🟢
  notificationsGET = ({ offset, limit, type }) => this._get("/notifications", { offset, limit, type });
  notificationClickPUT = ({ notificationId }) => this._put(`/notification/${notificationId}/click`);
  notificationReadPUT = ({ type }) => this._put("/notification/read", { type });

  // SCHOOL 🟢
  schoolsGET = () => this._get("/schools", {}, { token: null });
  schoolPOST = ({ schoolType, name }) => this._post("/school", { schoolType, name });
  // schoolPUT = ({schoolId}) => this._put(`/school/${schoolId}`, {});
  schoolGET = ({ schoolId }) => this._get(`/school/${schoolId}`, {});

  // SEMESTER 🟢
  semestersGET = ({ year, offset, limit }) => this._get("/semesters", { year, offset, limit });
  semesterPOST = ({ year, term, startDate, endDate }) => this._post("/semester", { year, term, startDate, endDate });
  semesterGET = ({ semesterId }) => this._get(`/semester/${semesterId}`, {});

  // SUBJECT 🟢
  subjectsGET = ({ grade, offset, limit, keyword, year }) => this._get("/subjects", { grade, offset, limit, keyword, year });
  subjectPOST = ({ title, groupName, grade }) => this._post("/subject", { title, groupName, grade });
  subjectPUT = ({ subjectId, title }) => this._put(`/subject/${subjectId}`, { title });
  subjectGET = ({ subjectId }) => this._get(`/subject/${subjectId}`, {});
  subjectDELETE = ({ subjectId }) => this._delete(`/subject/${subjectId}`, {});

  // TASK 🟢
  tasksGET = ({ offset, limit, courseId, userId, keyword }) => this._get("/tasks", { offset, limit, courseId, userId, keyword }); // userId넣으면 과제 보낼때 독후감 정보가 옴
  taskPOST = ({ courseId, title, description, startDate, endDate, isPublished, bookIsbn13s, questions, fileUrls, scoreType,permitEditAfterSubmit }) =>
    this._post("/task", {
      courseId,
      title,
      description,
      startDate,
      endDate,
      isPublished,
      bookIsbn13s,
      questions,
      fileUrls,
      scoreType,permitEditAfterSubmit
    });
  taskPUT = ({ taskId, courseId, title, description, startDate, endDate, isPublished, bookIsbn13s, questions, fileUrls, scoreType,permitEditAfterSubmit }) =>
    this._put(`/task/${taskId}`, {
      courseId,
      title,
      description,
      startDate,
      endDate,
      isPublished,
      bookIsbn13s,
      questions,
      fileUrls,
      scoreType,permitEditAfterSubmit
    });
  taskGET = ({ taskId }) => this._get(`/task/${taskId}`, {});
  taskDELETE = ({ taskId }) => this._delete(`/task/${taskId}`, {});
  taskUsersGET = ({ taskId, offset, limit, keyword }) => this._get(`/task/${taskId}/users`, { offset, limit, keyword });

  // USER 🟢
  // userPUT 🔴
  userMePUT = ({ displayName, birthDate, phoneNumber, password, currentPassword, profileImageUrl }) =>
    this._put("/user/me", {
      displayName,
      birthDate,
      phoneNumber,
      password,
      currentPassword,
      profileImageUrl,
    });
  userMeGET = () => this._get("/user/me", {});
  userGET = ({ userId, year }) => this._get(`/user/${userId}`, { year });
  usersGET = ({ userType, offset, limit, year, grade, keyword }) => this._get("/users", { userType, offset, limit, year, grade, keyword }); // year해야 학년 반이 나옴
  usersCheckDuplicatedPOST = ({ emails, displayNames, phoneNumbers }) => this._post(`/users/check-duplicated`, { emails, displayNames, phoneNumbers });
  usersPOST = ({ users }) => this._post(`/users`, { users });
  usersStudentPOST = ({ users }) => this._post(`/users/student`, { users });
  userDELETE = ({ userId }) => this._delete(`/user/${userId}`);
  userPUT = ({ userId, name, displayName, email, phoneNumber, password }) => this._put(`/user/${userId}`, { name, displayName, email, phoneNumber, password });

  phoneVerificationPOST = ({phoneNumber}) => this._post(`/phone-verification`, {phoneNumber});

  // TEMP
  Z_temp_teacherTempGET = () => this._get("/z/teacher/temp");
}

export default new Network();
