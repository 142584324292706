import PropTypes from "prop-types";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

// @mui
import {styled, useTheme} from "@mui/material/styles";
import {Box, Stack, Drawer, IconButton} from "@mui/material";
import {ChevronRightOutlined, ChevronLeftOutlined, HomeOutlined} from "@mui/icons-material";
// hooks
import useResponsive from "../../assi-design-system-react/mui/hooks/useResponsive";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../assi-design-system-react/mui/utils/cssStyles";
// config
import {NAVBAR} from "../../config/mui.config";
// components
import Logo from "../../components/TO_BE_deleted/Logo";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {NavSectionVertical} from "../../components/TO_BE_deleted/nav-section";
//
import CollapseButton from "./CollapseButton";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({theme}) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

const NavbarVertical = ({isOpenSidebar, onCloseSidebar, navConfig}) => {
  const theme = useTheme();

  const location = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const {isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave} = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // console.log("isOpenSidebar", isOpenSidebar, "isCollapse", isCollapse);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {height: 1, display: "flex", flexDirection: "column"},
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && {alignItems: "center"}),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{height: 64, pl: 1}}>
          {!isCollapse && <Logo/>}

          {isDesktop &&
            (!isCollapse ? (
              <CollapseButton onToggleCollapse={onToggleCollapse} isCollapse={isCollapse} collapseClick={collapseClick}/>
            ) : (
              <>
                <ChevronRightOutlined color={'primary'}/>
              </>
            ))}
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse}/>

      <Box sx={{flexGrow: 1}}/>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{sx: {width: NAVBAR.DASHBOARD_WIDTH}}}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.short,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default NavbarVertical;

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  navConfig: PropTypes.array,
};
