import axios from "axios";
import dayjs from "dayjs";

class NetworkStudent {
  constructor() {
    this.API = process.env.REACT_APP_API_URL_STUDENT;
    this.devMode = process.env.REACT_APP_DEV_MODE || !process.env.REACT_APP_PROD_MODE;
    this.headers = {
      currentBuildNo: 1,
      Authorization: "",
    };
  }

  setToken(token) {
    this.headers.Authorization = token;
  }

  onError(err, url, method, data) {
    let errorMessage = "오류가 발생하였습니다.\n고객센터에 문의해주세요.";

    if (err.response) {
      this.devMode && console.error(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${err.response?.data?.status || err.response?.data?.code}`, err.response?.data);
      if (err.response?.data?.errorMessage) {
        errorMessage = err.response.data.errorMessage;
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (err.response?.data?.statusText) {
        errorMessage = err.response.data.statusText;
      }
    } else {
      this.devMode && console.error(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${err.response?.data?.status || err.response?.data?.code}`, err.message);
      if (err.message === "Network Error") {
        errorMessage = "홈페이지 네트워크에 연결되어 있지 않습니다.";
      } else {
        errorMessage = err.message;
      }
    }

    throw new Error(errorMessage, {
      cause: {
        err: true,
        status: err.response?.data?.status || err.response?.data?.code,
        statusText: err.response?.data?.statusText || err.response?.data?.code,
        message: err.message,
        errorMessage: errorMessage,
      },
    });
  }

  _sendRequest(url, params, method) {
    const now = dayjs();
    this.devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, params);

    return axios[method](this.API + url, {
      headers: this.headers,
      params,
      withCredentials: true,
    })
      .then((response) => {
        // this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url);
        this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch((err) => this.onError(err, url, method.toUpperCase(), params));
  }

  // post, put
  _sendRequestForData(url, data, method) {
    const now = dayjs();
    this.devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, data);

    return axios[method](this.API + url, data, {
      headers: this.headers,
      withCredentials: true,
    })
      .then((response) => {
        // this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url);
        this.devMode && console.log(`[🟢 RES] ${dayjs().diff(now, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch((e) => this.onError(e, url, method.toUpperCase(), data));
  }

  _get = (url, params, headers) => this._sendRequest(url, params, "get", headers);
  _post = (url, data) => this._sendRequestForData(url, data, "post");
  _delete = (url, params, query) => this._sendRequest(url, params, "delete", query);
  _put = (url, data, headers) => this._sendRequestForData(url, data, "put", headers);


  // BOOK
  bookGET = ({bookId}) => this._get(`/book/${bookId}`);
  bookSearchGET = ({offset, limit, keyword}) => this._get("/book/search", {offset, limit, keyword});


  // BOOK REPORT
  bookReportsGET = ({offset, limit, courseId, teacherUserId}) => this._get(`/book-reports`, {offset, limit, courseId, teacherUserId});
  bookReportGET = ({bookReportId}) => this._get(`/book-report/${bookReportId}`);
  bookReportPUT = ({title, bookReportId, bookId, teacherUserId, answers}) =>
    this._put(`/book-report/${bookReportId}`, {title, bookId, teacherUserId, answers});
  bookReportPOST = ({title, taskId, bookId, teacherUserId, answers}) =>
    this._post(`/book-report`, {title, taskId, bookId, teacherUserId, answers});

  bookReportSubmitPOST = ({bookReportId}) => this._post(`/book-report/${bookReportId}/submit`, {});

  bookReportCommentsGET = ({bookReportId, offset, limit}) => this._get("/book-report-comments", {bookReportId, offset, limit});
  bookReportCommentPOST = ({bookReportId, content, bookReportCommentId}) => this._post(`/book-report-comment`, {bookReportId, content, bookReportCommentId});
  bookReportCommentPUT = ({bookReportCommentId, content}) => this._put(`/book-report-comment/${bookReportCommentId}`, {content});
  bookReportCommentDELETE = ({bookReportCommentId}) => this._delete(`/book-report-comment/${bookReportCommentId}`);

  // CLASS
  classesGET = ({offset, limit, studentUserId}) => this._get(`/classes`, {offset, limit, studentUserId});

  // COURSE
  coursesGET = ({keyword, isRegistered, year, offset, limit, courseType}) => this._get("/courses", {keyword, isRegistered, year, offset, limit, courseType});
  courseGET = ({courseId}) => this._get(`/course/${courseId}`);
  courseRegisterPOST = ({courseId}) => this._post(`/course/${courseId}/register`)
  courseRegisterDELETE = ({courseId}) => this._delete(`/course/${courseId}/register`)

  // INQUIRY
  inquiriesGET = ({offset, limit}) => this._get("/inquiries", {offset, limit});
  inquiryGET = ({inquiryId}) => this._get(`/inquiry/${inquiryId}`);
  inquiryPOST = ({question, title, questionImageUrls}) => this._post(`/inquiry`, {question, title, questionImageUrls});
  inquiryPUT = ({inquiryId, question, title, questionImageUrls}) => this._put(`/inquiry/${inquiryId}`, {question, title, questionImageUrls});
  inquiryDELETE = ({inquiryId}) => this._delete(`/inquiry/${inquiryId}`);

  notificationsGET = ({offset, limit, read}) => this._get("/notifications", {offset, limit, read});
  notificationClickPUT = ({notificationId}) => this._put(`/notification/${notificationId}/click`);
  notificationReadPUT = () => this._put(`/notification/read`);


  // SCHOOL
  schoolsGET = () => this._get("/schools", {});

  // USER
  userMePUT = ({displayName, birthDate, phoneNumber, password, currentPassword, profileImageUrl}) => this._put("/user/me", {
    displayName,
    birthDate,
    phoneNumber,
    password,
    currentPassword,
    profileImageUrl,
  });
  userMeGET = () => this._get("/user/me", {});
  userMeStatGET = () => this._get("/user/me/stat", {});
  userGET = ({userId}) => this._get(`/user/${userId}`, {});
  usersGET = ({userType, offset, limit, keyword, year, grade}) => this._get(`/users/`, {userType, offset, limit, keyword, year, grade});

  // USER ME BOOK
  userMeBooksGET = ({offset, limit, readStatus, keyword}) => this._get("/user/me/books", {offset, limit, readStatus, keyword});
  userMeBookPUT = ({bookId, readStatus, rate, review}) => this._put(`/user/me/book/${bookId}`, {readStatus, rate, review});
  userMeBookDELETE = ({bookId}) => this._delete(`/user/me/book/${bookId}`);

  // USER ME BOOK READ HISTORY
  userMeBookReadHistoriesGET = ({offset, limit, bookId}) =>
    this._get(`/user/me/book/${bookId}/read-histories`, {offset, limit});
  userMeBookReadHistoryPOST = ({bookId, startDate, endDate, memo}) =>
    this._post(`/user/me/book/${bookId}/read-history`, {startDate, endDate, memo});
  userMeBookReadHistoryPUT = ({bookId, userBookReadHistoryId, startDate, endDate, memo}) =>
    this._put(`/user/me/book/${bookId}/read-history/${userBookReadHistoryId}`, {startDate, endDate, memo});
  userMeBookReadHistoryDELETE = ({bookId, userBookReadHistoryId}) =>
    this._delete(`/user/me/book/${bookId}/read-history/${userBookReadHistoryId}`);
  userMeBookReadHistoryGET = ({bookId, userBookReadHistoryId}) =>
    this._get(`/user/me/book/${bookId}/read-history/${userBookReadHistoryId}`);

  // USER ME PUSH SETTING
  userMePushSettingPUT = ({task, bookReport, course}) => this._put("/user/me/push-setting", {task, bookReport, course});
  userMePushSettingGET = () => this._get("/user/me/push-setting");


  // TASK
  tasksGET = ({offset, limit, courseId, isOngoing, example = false}) =>
    this._get(`/tasks`, {offset, limit, courseId, isOngoing, example});
  taskGET = ({taskId}) => this._get(`/task/${taskId}`);
  taskPOST = ({questions}) => this._post(`/task`, {questions});


  phoneVerificationPOST = ({phoneNumber}) => this._post(`/phone-verification`, {phoneNumber});
  pushTokenPOST = ({token, os, deviceInfo}) => this._post("/push-token", {token, os, deviceInfo});

}

export default new NetworkStudent();
