import { useEffect } from "react";
import ChannelService from "../../lib/ChannelService";
import { connect } from "react-redux";

const ChannelIo = (props) => {
  const {user} = props.authReducer;

  useEffect(() => {
    const initializeChannelIo = () => {
      if (!!user?.id) {
        ChannelService.boot({
          pluginKey: "4888b942-5073-4ee2-933e-e0e4d24ab69a",
          memberId: user.id,
          profile: {
            name: user.name,
            mobileNumber: user.phoneNumber,
            email: user.email,
            avatarUrl: user.profileImageUrl,
          },
        });
        // });
      } else {
        ChannelService.boot({pluginKey: "4888b942-5073-4ee2-933e-e0e4d24ab69a"});
      }
    }
    initializeChannelIo();
    return () => {
      ChannelService.shutdown();
    };
  }, [user.id]);

  return <></>;
};

const enhance = connect((state) => ({...state}), {});
export default enhance(ChannelIo);
