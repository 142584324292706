import React from 'react';
import {  TextField } from '@mui/material';
import { DesktopDateTimePicker, DatePicker, MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import useResponsive from "../../assi-design-system-react/mui/hooks/useResponsive";

export default function ResponsiveDatePicker ({ label, value, selectStart, selectEnd, startDate, endDate, minDate, maxDate, onChange, openTo, placeholderText, disabled }){

  const isDesktop = useResponsive('up', 'lg');
  const isTablet = useResponsive('up', 'sm');

  if (isDesktop) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDateTimePicker
          label={label}
          value={value}
          selectStart={selectStart}
          selectEnd={selectEnd}
          inputFormat='YYYY. MM. DD (dd)'
          views={['year', 'month', 'day']}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          openTo={openTo}
          renderInput={(params) => (
            <TextField
              {...params}
              margin='normal'
              fullWidth
              disabled={disabled}
              placeholderText={placeholderText}
            />
          )}
        />
      </LocalizationProvider>
    );
  } else if (isTablet) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          value={value}
          selectStart={selectStart}
          selectEnd={selectEnd}
          inputFormat='YYYY. MM. DD (dd)'
          views={['year', 'month', 'day']}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          openTo={openTo}
          renderInput={(params) => (
            <TextField
              {...params}
              margin='normal'
              fullWidth
              disabled={disabled}
              placeholderText={placeholderText}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={label}
        value={value}
        selectStart={selectStart}
        selectEnd={selectEnd}
        inputFormat='YYYY. MM. DD (dd)'
        views={['year', 'month', 'day']}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        openTo={openTo}
        renderInput={(params) => (
          <TextField
            {...params}
            margin='normal'
            fullWidth
            disabled={disabled}
            placeholderText={placeholderText}
          />
        )}
      />
    </LocalizationProvider>
  );

}
