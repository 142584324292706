import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Avatar, Button, Container, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, TextField} from "@mui/material";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import {PATH_ADMIN} from "../../routes/paths";
import numeral from "numeral";

// ----------------------------------------------------------------------

const YEAR_OPTIONS = [2022, 2021, 2020];
const GRADE_OPTIONS = [1, 2, 3];

// ----------------------------------------------------------------------

const AdminStudentPage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const year = query.get("year") || "2022";
  const grade = query.get("grade") || "";
  const keyword = query.get("keyword") || "";
  const [searchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [users, setUsers] = useState([]);

  const _load = async () => {
    const res = await Network.usersGET({
      keyword,
      grade,
      userType: "STUDENT",
      offset: (page - 1) * LIMIT,
      limit: LIMIT,
      year,
    });
    if (!res.err) {
      setIsLoaded(true);
      setUsers(res.users);
      setMeta(res.meta);
    }
  };
  useEffect(() => {
    _load();
  }, [page, year, grade, keyword]);


  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  };
  const _onChangeGrade = ({target}) => {
    if (target.value) {
      searchParams.set('grade', target.value)
    } else {
      searchParams.delete('grade');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }
  const _onChangeYear = ({target}) => {
    if (target.value) {
      searchParams.set('year', target.value)
    } else {
      searchParams.delete('year');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'xl'}>

      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            학생 관리
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}명
          </p>
        </div>
        <Button variant="contained" component={Link} to={PATH_ADMIN.student.create}>
          신규등록
        </Button>
      </div>

      <Stack spacing={1} direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2, alignItems: "center"}}>
        <TextField
          fullWidth
          select
          label="연도"
          value={year}
          onChange={_onChangeYear}
          sx={{maxWidth: {xs: "full", sm: 100},}}
        >
          {YEAR_OPTIONS.map((option) => (
            <MenuItem
              key={option.toString()}
              value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="학년"
          value={grade}
          onChange={_onChangeGrade}
          sx={{
            maxWidth: {xs: "full", sm: 100},
            textTransform: "capitalize",
          }}
        >
          <MenuItem value={""}>
            전체
          </MenuItem>
          {GRADE_OPTIONS.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
        <SearchTextField
          label={'검색'}
          margin="normal"
          placeholder={'이름으로 검색하세요.'}
          fullWidth
          defaultValue={keyword || ""}
          onSubmit={_handleSearch}
        />
      </Stack>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 'max-content'}}>
          <Table>
            <TableHead className={""}>
              <TableRow>
                <TableCell className={'w-[80px]'}>학년</TableCell>
                <TableCell className={'w-[80px]'}>반</TableCell>
                <TableCell className={'max-w-[120px]'}>이름</TableCell>
                <TableCell>이메일/핸드폰</TableCell>

                <TableCell className={'w-[80px]'}>수업(개)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                return (
                  <TableRow key={user.id} className={'hover:bg-hover'}>
                    <TableCell>{user.classRegister?.class?.grade}</TableCell>
                    <TableCell>{user.classRegister?.class?.classNo}</TableCell>
                    <TableCell>
                      <Link to={PATH_ADMIN.student.detail(user.id)}>
                        <div className={'flex items-center group'}>
                          <Avatar sx={{mr: 1, width: "2rem", height: "2rem"}} src={user.profileImageUrl}/>
                          <div>
                            <p className={'text-primary-500 group-hover:underline line-clamp-1'}>
                              {user.name}
                            </p>
                            <p className={'text-sm text-gray-600 line-clamp-1'}>
                              {user.displayName}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {user.email || "-"} / {user.phoneNumber}
                    </TableCell>
                    <TableCell>{user.stat?.courseRegisterCount}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminStudentPage);
