import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {LoadingButton} from "@mui/lab";
import {
  Box, MenuItem, Select, Stack,
  DialogTitle, Dialog, DialogActions, Divider, DialogContent,
  TextField, Button, FormControl, InputLabel, Alert, Snackbar, InputBase, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Avatar,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import MessageBox from "../shared/MessageBox";
import Network from "../../lib/Network";
import {PATH_ADMIN} from "../../routes/paths";
import {displayNameRegex, emailRegex, nameRegex, phoneNumberRegex} from "../../utils/regexTest";
import {readStatusText, userBookReadStatuses} from "../../constants/dataTypes";
import _ from "lodash";
import * as Icon from "@mui/icons-material";

const AdminClassCreatePageTabOne = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [newClass, setNewClass] = useState({year: 2022, grade: 1, classNo: 1, teacherUserId: null})
  const [searchedTeacherUsers, setSearchedTeacherUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({open: false, message: '', key: '', color: ''});

  const _load = async () => {

  }

  const _upload = async () => {

    try {
      setUploading(true);
      const res = await Network
        .classPOST({
          ...newClass,
        })
      _handleSnackbarOpen({key: res.class?.id, message: `${res.class?.year}학년도 ${res.class?.grade}학년 ${res.class?.classNo}반 생성되었습니다.`, color: 'success'})
      setNewClass({year: 2022, grade: 1, classNo: 1, teacherUserId: null})
    } catch (e) {

      window.alert(e.cause.errorMessage);
      _handleSnackbarOpen({key: 'error', message: '등록에 실패하였습니다.', color: 'error'})
    } finally {
      setUploading(false);
    }
  }

  const _handleSearch = async (e) => {
    e.preventDefault?.();
    try {
      setSearching(true);
      const res = await Network.usersGET({
        userType: "TEACHER",
        keyword,
        offset: 0,
        limit: 50,
      })
      setSearchedTeacherUsers(res.users);
    } catch (e) {
      window.alert(e.cause.errorMessage);

    } finally {
      setSearching(false);

    }
  }

  useEffect(() => {
    _load();
  }, [])

  const _openSearchDialog = () => {
    setSearchDialogOpen(true);
  }
  const _closeSearchDialog = () => {
    setSearchDialogOpen(false)
  }

  const _renderTeacherUser = () => {
    if (!newClass.teacherUserId) {
      return (
        <div className={'p-4 bg-warning-50 border border-warning-500 rounded mt-8'}>
          <h3 className={'text-warning-500'}>
            담임선생님 선택
          </h3>
          <p className={'text-warning-500'}>
            담임선생님을 추가해 주세요.
            담임선생님은 추후에 등록/변경할 수 있습니다.
          </p>
          <Button
            sx={{mt: 2}}
            color={'warning'}
            variant={'outlined'}
            onClick={_openSearchDialog}>
            선생님 검색
          </Button>
        </div>
      )
    } else {
      return (
        <div className={'mt-4'}>
          <p className={'text-gray-600 mb-4'}>
            선생님
          </p>
          <div className={'flex items-center mb-4'}>
            <img className={'w-12 h-12 object-contain rounded-full mr-2'}
                 src={newClass.teacherUser?.profileImageUrl}/>
            <div className={'text-gray-600 font-bold'}>
              {newClass.teacherUser?.name} ({newClass.teacherUser?.displayName})
            </div>
          </div>
          <div className={'mt-4'}>
            <Button
              variant={'outlined'}
              onClick={_openSearchDialog}>
              선생님 변경
            </Button>
            <Button
              color={'error'}
              variant={'outlined'}
              sx={{ml: 2}}
              onClick={() => {
                setNewClass(prev => ( {...prev, teacherUser: null, teacherUserId: null} ))
              }}>
              선생님 제거
            </Button>
          </div>
        </div>
      )
    }
  }

  const _handleSnackbarOpen = ({key, message, color}) => {
    setSnackbarState({key, message, open: true, color});
  }
  const _handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState(prev => ( {...prev, open: false} ));
  }

  return (
    <div>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={snackbarState.open}
        onClose={_handleSnackbarClose}
        key={snackbarState.key}
      >
        <Alert onClose={_handleSnackbarClose} severity={snackbarState.color} sx={{width: '100%'}}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
      <MessageBox
        sx={{mb: 4}}
        // title={'학생추가'}
        content={'학급을 한 개씩 생성합니다.'}
      />
      <Stack spacing={1} direction={{xs: "column", sm: "row"}}>
        <FormControl
          fullWidth
          // sx={{minWidth: 250}}
          size={'small'}
        >
          <InputLabel>연도</InputLabel>
          <Select
            size={'medium'}
            label={'연도'}
            value={newClass.year}
            onChange={({target}) => {
              setNewClass(prev => ( {...prev, year: target.value} ))
            }}
          >
            {
              [2022].map(value => {
                return (
                  <MenuItem key={String(value)} value={value}>{value}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          // sx={{minWidth: 250}}
          size={'small'}
        >
          <InputLabel>학년</InputLabel>
          <Select
            size={'medium'}
            label={'학년'}
            value={newClass.grade}
            onChange={({target}) => {
              setNewClass(prev => ( {...prev, grade: target.value} ))
            }}
          >
            {
              [1, 2, 3, 4, 5, 6].map(value => {
                return (
                  <MenuItem key={String(value)} value={value}>{value}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          // sx={{minWidth: 250}}
          size={'small'}
        >
          <InputLabel>반</InputLabel>
          <Select

            size={'medium'}
            label={'반'}
            value={newClass.classNo}
            onChange={({target}) => {
              setNewClass(prev => ( {...prev, classNo: target.value} ))
            }}
          >
            {
              _.times(20, o => o + 1).map(value => {
                return (
                  <MenuItem key={String(value)} value={value}>{value}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Stack>
      {_renderTeacherUser()}
      <div className={'my-4'}>
        <LoadingButton
          variant={'contained'}
          loading={uploading}
          disabled={uploading}
          onClick={_upload}>
          만들기
        </LoadingButton>
      </div>
      <Dialog fullWidth maxWidth={'md'} open={searchDialogOpen}>
        <DialogTitle>
          선생님 검색
        </DialogTitle>
        <Divider sx={{mt: 2}}/>
        <DialogContent>
          <Box className={"bg-gray-100 rounded"} component="form" sx={{p: "2px 4px", display: "flex", alignItems: "center"}} onSubmit={_handleSearch}>
            <InputBase
              value={keyword}
              name={"keyword"}
              sx={{ml: 1, flex: 1}}
              placeholder="이름으로 검색해 주세요."
              inputProps={{}}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            {!!keyword && (
              <IconButton
                onClick={() => {
                  setKeyword("");
                }}
                sx={{p: "2px"}}
                aria-label="search"
              >
                <Icon.CloseRounded/>
              </IconButton>
            )}
            <Button type="submit" variant={"outlined"} sx={{p: "2px"}}>
              검색
            </Button>
          </Box>
        </DialogContent>
        <DialogContent sx={{height: "50vh"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>이름</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchedTeacherUsers.map((user, index) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Avatar src={user.profileImageUrl}/>
                    </TableCell>
                    <TableCell>
                      {user.name} ({user.displayName})
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => {
                        setNewClass(prev => ( {...prev, teacherUserId: user.id, teacherUser: user} ))
                        setSearchedTeacherUsers([]);
                        _closeSearchDialog();
                      }}>
                        선택
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={_closeSearchDialog}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminClassCreatePageTabOne);
