import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import NetworkStudent from '../../lib/NetworkStudent';
import {Link, useLocation} from 'react-router-dom';
import {Chip, Container, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import LoadingComponent from '../../components/shared/LoadingComponent';
import numeral from 'numeral';
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import MessageBox from '../../components/shared/MessageBox';
import dayjs from 'dayjs';
import {PATH_STUDENT} from '../../routes/paths';

const StudentTaskMainPage = (props) => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({total: 0});
  const [tasks, setTasks] = useState([]);
  const LIMIT = 10;

  const _load = async () => {
    const res = await NetworkStudent
      .tasksGET({
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
        // isOngoing: true,
      });
    if (!res.err) {
      setIsLoaded(true);
      setMeta(res.meta);
      setTasks(res.tasks || []);
    }
  };

  useEffect(() => {
    _load();
  }, [page]);

  if (!isLoaded) return <LoadingComponent/>;

  const _renderMyBookReportStatus = (task) => {
    if (!task.bookReportMy) {
      return <Chip size={'small'} label={'미작성'} color={'error'} variant={'outlined'} sx={{mb: 0.3}}/>
    } else if (!task.bookReportMy.submittedAt) {
      return <Chip size={'small'} label={'미제출-작성중'} color={'error'} variant={'outlined'} sx={{mb: 0.3}}/>
    } else {
      return (
        <Link to={PATH_STUDENT.bookReport.detail.root(task?.bookReportMy?.id)} className={'hover:underline text-primary-500'}>
          제출 : {dayjs(task.bookReportMy?.submittedAt).format('YY.MM.DD(ddd) HH:mm')}
        </Link>
      )
    }
  }


  return (
    <Container maxWidth={'lg'}>
      <div className={'flex-1'}>
        <p className={'text-xl font-bold'}>
          전체 과제
        </p>
        <p className={'text-lg font-light'}>
          총 {numeral(meta.total).format('0,0')}개
        </p>
      </div>
      <MessageBox content={'모든 과제가 표시됩니다.'}/>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
        renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: "relative"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>수업</TableCell>
                <TableCell>과제</TableCell>
                <TableCell>시작</TableCell>
                <TableCell>종료</TableCell>
                <TableCell className={'max-w-[100px]'}>나의 독후감</TableCell>
                <TableCell>-</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tasks.map((task, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <Link
                          to={PATH_STUDENT.course.detail(task?.courseId)}
                          className={'w-[150px] line-clamp-1 hover:underline text-primary-500'}>
                          {task.course?.title}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={PATH_STUDENT.task.detail(task?.id)}
                              className={'w-[150px] line-clamp-1 hover:underline text-primary-500'}>
                          {task.title}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {dayjs(task.startDate).format('YYYY.MM.DD(ddd)')}
                      </TableCell>
                      <TableCell>
                        {dayjs(task.endDate).format('YYYY.MM.DD(ddd)')}
                      </TableCell>
                      <TableCell>
                        {_renderMyBookReportStatus(task)}
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(StudentTaskMainPage);
