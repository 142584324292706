import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import MessageBox from '../../components/shared/MessageBox';
import {Container} from '@mui/material';

const AdminTaskDetailPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <Container maxWidth={'lg'}>
      <MessageBox
        title={'과제 상세보기'}
        content={'추후 제공될 예정입니다.'}
      />
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(AdminTaskDetailPage);
