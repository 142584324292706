import PropTypes from 'prop-types';
import React from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import {Typography, ListItemText, ListItemAvatar, ListItemButton} from '@mui/material';
import {AccessTimeRounded} from '@mui/icons-material';
// components
import dayjs from 'dayjs';
import {notificationTypes} from '../../../constants';
import Image from '../../../components/TO_BE_deleted/Image';
import {PATH_TEACHER} from '../../../routes/paths';
import classNames from '../../../assi-design-system-react/utils/classNames';

const NotificationItem = ({notification, onClose, clickNotification}) => {
  const navigate = useNavigate();
  const {image, title, link, content} = renderContent(notification);
  const isCommentNotification =
    notification.type === notificationTypes.TEACHER_BOOK_REPORT_COMMENT.type || notification.type === notificationTypes.STUDENT_BOOK_REPORT_COMMENT.type;

  return (
    <ListItemButton
      disableGutters={isCommentNotification}
      onClick={() => {
        navigate(link);
        onClose();
        clickNotification && clickNotification({notificationId: notification.id});
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.click && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={
          <>
            {title}
            {content}
          </>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'start',
              color: 'text.disabled',
            }}
          >
            <AccessTimeRounded/>
            <span>{dayjs(new Date(notification.createdAt)).fromNow()}</span>
          </Typography>
        }
      />
      {!isCommentNotification && <ListItemAvatar>{image}</ListItemAvatar>}
    </ListItemButton>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    // createdAt: PropTypes.instanceOf(Date),
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

// ----------------------------------------------------------------------

const renderContent = (notification) => {
  let icon;
  let link;
  let content;
  switch (notification.type) {
    case notificationTypes.TEACHER_BOOK_REPORT_SUBMITTED.type:
      icon = 'eva:message-circle-outline';
      link = PATH_TEACHER.bookReport.detail.root(notification.customField.bookReportId);
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.bookReport?.title}
        </Typography>
      );
      break;
    case notificationTypes.TEACHER_NEW_COURSE_REGISTER.type:
      icon = 'eva:message-circle-outline';
      link = PATH_TEACHER.course.detail.student.list(notification.customField.courseId);
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.user?.name}
          <span className="bind-text-gray-500">{notification.customField?.user?.displayName}</span>
        </Typography>
      );
      break;
    case notificationTypes.TEACHER_BOOK_REPORT_COMMENT.type:
      icon = 'eva:message-circle-outline';
      link = PATH_TEACHER.bookReport.detail.root(notification.customField.bookReportId);
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.bookReportComment?.content}
        </Typography>
      );
      break;
    case notificationTypes.STUDENT_COURSE_INVITED.type:
      icon = 'eva:message-circle-outline';
      link = `/course/${notification.customField?.course?.id || notification.customField?.task?.course?.id}`;
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.course?.title}
        </Typography>
      );
      break;
    case notificationTypes.STUDENT_TASK_PUBLISHED.type:
      icon = 'eva:message-circle-outline';
      link = `/task/${notification.customField?.task?.id}`;
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.task?.title}
        </Typography>
      );
      break;
    case notificationTypes.STUDENT_BOOK_REPORT_COMMENT.type:
      icon = 'eva:message-circle-outline';
      link = `/book-report/${notification.customField?.bookReportId}`;
      content = (
        <Typography variant="subtitle3" sx={{color: 'text.secondary', ...(!notification.click && {})}} className="line-clamp-1 text-ellipsis">
          {notification.customField?.bookReportComment?.content}
        </Typography>
      );
      break;
    default:
      link = '';
      break;
  }

  const title = (
    <Typography variant="caption" sx={{color: 'primary.main', fontWeight: 800, display: 'flex', alignItems: 'start'}}>
      {notificationTypes[notification.type]?.name || '알 수 없는 알림'}
      <Typography component="span" variant="caption" sx={{color: notification.click ? 'text.secondary' : 'primary.main'}}>
        &nbsp; {notificationTypes[notification.type]?.description || '알 수 없는 알림입니다.'}
      </Typography>
    </Typography>
  );

  const image = (
    <div className={classNames(!!notification.customField.course?.coverImageUrl ? "" : "hidden", "relative", 'rounded-sm w-12 h-12 overflow-hidden flex-shrink-0 shadow')}>
      <img
        alt={notification.customField.course?.title}
        src={notification.customField.course?.coverImageUrl}
        className="w-full h-full object-center object-cover"
      />
    </div>
  );

  return {link, image, title, content};
};
