import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import NetworkStudent from '../../lib/NetworkStudent';
import {notificationType, questionTypeText} from '../../constants/dataTypes';
import {
  Button, Container, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Chip, IconButton,
  Box
} from "@mui/material";
import * as Icon from '@mui/icons-material';
import {getFromNowOrDate} from '../../assi-design-system/utils/time';
import {notificationRouter} from '../../utils/notificationRouter';

const StudentNotificationComponent = (props) => {

  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false)
  const [notification, setNotification] = useState(props.notification || {});


  const _updateNotificationClick = async () => {
    setNotification(prev => ({...prev, click: true}));
    await NetworkStudent.notificationClickPUT({notificationId: notification?.id});
  };

  const _handleClick = () => {
    notificationRouter(notification, navigate);
    if (!notification.click) _updateNotificationClick();
  };


  if (!notification.id) return null;

  const _renderUnClickMark = () => {
    if (notification.click) return null;
    return (
      <Box sx={{
        width: 20, height: 20,
        borderRadius: 10,
        bgcolor: 'primary.main'
      }}/>
    )
  }

  switch (notification.type) {
    case notificationType.STUDENT_BOOK_REPORT_COMMENT:
      return (
        <ListItem
          onClick={_handleClick}
          button
          sx={{pt: 2, pb: 2}}>
          <ListItemAvatar>
            <Avatar
              src={notification?.customField?.user?.profileImageUrl}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography>
                  독후감에 새로운 댓글이 달렸어요
                </Typography>
                <Typography sx={{fontWeight: 'bold',}} className={'line-clamp-2'}>
                  {notification?.customField?.bookReportComment?.content}
                </Typography>
              </>
            }
            secondary={getFromNowOrDate(notification.createdAt, 1.5)}
          />
          {_renderUnClickMark()}
        </ListItem>
      )
    case notificationType.STUDENT_COURSE_INVITED:
      return (
        <ListItem
          onClick={_handleClick}
          button
          sx={{pt: 2, pb: 2}}>
          <ListItemAvatar>
            <Avatar
              src={notification?.customField?.course?.coverImageUrl}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography>
                  수업에 초대 되었어요.
                </Typography>
                <Typography sx={{fontWeight: 'bold',}} className={'line-clamp-1'}>
                  {notification?.customField?.course?.title}
                </Typography>
              </>
            }
            secondary={getFromNowOrDate(notification.createdAt, 1.5)}
          />
          {_renderUnClickMark()}
        </ListItem>
      )
    case notificationType.STUDENT_TASK_PUBLISHED:
      return (
        <ListItem
          onClick={_handleClick}
          button
          alignItems="flex-start"
          sx={{pt: 2, pb: 2}}>
          <ListItemAvatar>
            <Avatar
              src={notification?.customField?.course?.coverImageUrl}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                {/*<Chip
                 size={'small'}
                 label={'새로운 과제'}/>*/}
                <Typography>
                  새로운 과제
                </Typography>
                <Typography sx={{fontWeight: 'bold',}} className={'line-clamp-1'}>
                  {notification?.customField?.task?.title}
                </Typography>
              </>
            }
            secondary={getFromNowOrDate(notification.createdAt, 1.5)}
          />
          {_renderUnClickMark()}
        </ListItem>
      )
    default:
      return (
        <ListItem
          sx={{pt: 2, pb: 2}}>
          <ListItemAvatar>
            <Avatar>
              <Icon.NotificationsOutlined/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={'현재 버전에서 지원되지 않는 종류의 알림입니다.'}
            secondary={getFromNowOrDate(notification.createdAt, 1.5)}
          />
          {_renderUnClickMark()}
        </ListItem>
      )
  }


}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(StudentNotificationComponent);
