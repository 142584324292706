import React from 'react';
import {connect} from 'react-redux';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Box, Container, Tab, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import MessageBox from "../../components/shared/MessageBox";
import AdminStudentCreatePageTabOne from "../../components/admin/AdminStudentCreatePageTabOne";
import AdminStudentCreatePageTabBulk from "../../components/admin/AdminStudentCreatePageTabBulk";

const AdminStudentCreatePage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get("tab") || "BULK";

  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  }

  return (
    <Container maxWidth={'lg'}>
      <Typography variant="h4" gutterBottom>
        학생추가
      </Typography>
      <TabContext value={tab}>
        <Box sx={{borderBottom: 1, borderColor: "divider", mb: 3, mt:2}}>
          <TabList onChange={(e, newValue) => _handleChangeTab(newValue)}>
            <Tab label="엑셀 등록" value="BULK"/>
            <Tab label="한명씩 등록" value="ONE"/>
          </TabList>
        </Box>
        <TabPanel value={"ONE"}>
          <AdminStudentCreatePageTabOne/>
        </TabPanel>
        <TabPanel value={"BULK"}>
          <AdminStudentCreatePageTabBulk/>
        </TabPanel>
      </TabContext>
    </Container>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminStudentCreatePage);
