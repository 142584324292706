export const userTypes = [
  { id: "ADMIN", type: "ADMIN", name: "관리자" },
  { id: "STAFF", type: "STAFF", name: "교직원" },
  { id: "TEACHER", type: "TEACHER", name: "선생님" },
  { id: "STUDENT", type: "STUDENT", name: "학생" },
];

export const userTypesObj = {
  ADMIN: { type: "ADMIN", name: "관리자" },
  STAFF: { type: "STAFF", name: "교직원" },
  TEACHER: { type: "TEACHER", name: "선생님" },
  STUDENT: { type: "STUDENT", name: "학생" },
};
