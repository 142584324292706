import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Tab, TextField } from "@mui/material";
import { connect } from "react-redux";
import { PATH_TEACHER } from "../../routes/paths";
import { useNavigate } from "react-router-dom";

const NewCourseDialog = ({open, onCancel}) => {

  const navigate = useNavigate();

  const _handleCancel = () => {
    onCancel?.();
  }

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={open}>
      <DialogTitle>
        새로운 수업/활동 만들기
      </DialogTitle>
      <Divider sx={{my: 1}}/>
      <DialogContent className={'flex flex-col space-y-12'}>
        <div className={'rounded flex-1'}>
          <p className={'text-lg font-bold text-gray-600'}>
            교과목 수업
          </p>
          <p>
            선생님이 학생을 직접 초대해야합니다.
            <br/>
            교과목 선생님께 모든 독후감이 제출됩니다.
          </p>
          <Button
            onClick={() =>
              navigate(PATH_TEACHER.course.create, {
                state: {courseType: "REGULAR"},
              })}

            sx={{marginLeft: 'auto', mt: 2}} variant={"contained"}>
            교과 수업 만들기
          </Button>
        </div>
        <div className={'rounded flex-1'}>
          <p className={'text-lg font-bold text-gray-600'}>
            비교과 활동
          </p>
          <p>
            선생님이 학생을 초대하거나, 학생들이 직접 신청할 수 있어요.
            <br/>
            독후감 제출 대상 선생님을 학생이 변경할 수 있습니다.
          </p>
          <Button
            onClick={() =>
              navigate(PATH_TEACHER.course.create, {
                state: {courseType: "IRREGULAR"},
              })
            }
            sx={{marginLeft: 'auto', mt: 2}} variant={"contained"}>
            비교과 활동 만들기
          </Button>
        </div>
      </DialogContent>
      <Divider sx={{my: 1}}/>
      <DialogActions>
        <Button onClick={_handleCancel}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}


const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(NewCourseDialog);
