import * as XLSX from "xlsx-js-style";


export const downloadTeacherCreateExcelTemplate = () => {
  const workBook = XLSX.utils.book_new();
  const heading = [
    [
      { t: "s", v: "이름(5자이내)", s: { font: { bold: true } } },
      { t: "s", v: "이메일", s: { font: { bold: true } } },
      { t: "s", v: "핸드폰(번호만)", s: { font: { bold: true } } },
    ],
  ];
  const workSheet = XLSX.utils.json_to_sheet(heading, { skipHeader: true });
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.writeFile(workBook, `선생님추가용탬플릿.xlsx`);
};

export const downloadStudentCreateExcelTemplate = () => {
  const workBook = XLSX.utils.book_new();
  const heading = [
    [
      { t: "s", v: "이름(2자-10자)", s: { font: { bold: true } } },
      { t: "s", v: "이메일(필수)", s: { font: { bold: true } } },
      { t: "s", v: "핸드폰(선택) 번호만", s: { font: { bold: true } } },
      { t: "s", v: "학년도", s: { font: { bold: true } } },
      { t: "s", v: "학년", s: { font: { bold: true } } },
      { t: "s", v: "반", s: { font: { bold: true } } },
      { t: "s", v: "번호", s: { font: { bold: true } } },
    ],
  ];
  const workSheet = XLSX.utils.json_to_sheet(heading, { skipHeader: true });
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.writeFile(workBook, `학생추가용템플릿.xlsx`);
};

export const downloadSubjectCreateExcelTemplate = () => {
  const workBook = XLSX.utils.book_new();
  const heading = [
    [
      { t: "s", v: "과목명", s: { font: { bold: true } } },
    ],
  ];
  const workSheet = XLSX.utils.json_to_sheet(heading, { skipHeader: true });
  XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
  XLSX.writeFile(workBook, `과목추가용탬플릿.xlsx`);
};
