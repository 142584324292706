import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { debounce } from 'lodash';

import { VisibilityOffRounded, VisibilityRounded } from '@mui/icons-material';
import { authActionCreators, authenticationActionCreators } from '../../redux/auth/auth.store';
import { UploadOneFile as uploadOneFile } from '../../utils/firebaseStorageUpload';
import Network from '../../lib/Network';

const MyAccountPage = (props) => {
  const fileUploadRef = useRef();

  const [code, setCode] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [loadingObj, setLoadingObj] = useState({ modifyDisplayName: false, modifyPassword: false, modifyProfileImage: false, modifyPhoneNumber: false });
  const [my, setMy] = useState({
    loadedAt: '',
    schoolId: 0,
    id: -1,
    name: '',
    displayName: '',
    birthDate: '',
    email: '',
    phoneNumber: '',
    currentPassword: '',
    profileImageUrl: '',
    // new
    newDisplayName: '',
    newPhoneNumber: '',
    newProfileImageUrl: '',
  });

  const [voiceOfCustomer, setVOC] = useState({ id: -1, section: '', content: '' });
  const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', confirmedNewPassword: '' });
  const [passwordsShownObj, setPasswordsShownObj] = useState({ currentPassword: false, newPassword: false, confirmedNewPassword: false });

  const [profileImageModalOpen, setProfileImageModalOpen] = useState(false);
  const [displayNameModalOpen, setDisplayNameModalOpen] = useState(false);
  const [phoneNumberModalOpen, setPhoneNumberModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [csModalOpen, setCsModalOpen] = useState(false);

  const isValidPassword =
    !!passwords.currentPassword &&
    !!passwords.newPassword &&
    !!passwords.confirmedNewPassword &&
    passwords.currentPassword.length >= 6 &&
    passwords.newPassword.length >= 6 &&
    passwords.confirmedNewPassword.length >= 6 &&
    passwords.currentPassword !== passwords.confirmedNewPassword &&
    passwords.newPassword === passwords.confirmedNewPassword;

  const _handleChangeMy = debounce((key, value) => setMy((prev) => ({ ...prev, [key]: value })));
  const _handleChangePasswords = debounce((key, value) => setPasswords((prev) => ({ ...prev, [key]: value })));
  const _handleChangeVoiceOfCustomers = debounce((key, value) => setVOC((prev) => ({ ...prev, [key]: value })));

  const _onChangeProfileImageUrl = async (file) => {
    setUploading(true);
    const url = await uploadOneFile(file, `school/${props.auth.user.schoolId}/user/${props.auth.user.id}/profileImage`);
    if (!!url) {
      setMy({ ...my, newProfileImageUrl: url });
    }
    setUploading(false);
  };

  const _modifyDisplayName = async () => {
    if (!my.newDisplayName) return;
    setLoadingObj((prev) => ({ ...prev, modifyDisplayName: true }));
    try {
      const result = await Network.userMePUT({ displayName: my.newDisplayName });
      setMy({ ...my, ...result.user, loadedAt: dayjs().toISOString() });
      props.fetchMyInfo();
      setDisplayNameModalOpen(false);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    } finally {
      setLoadingObj((prev) => ({ ...prev, modifyDisplayName: false }));
    }
  };

  const _modifyProfileImage = async () => {
    if (!my.newProfileImageUrl) return;
    setLoadingObj((prev) => ({ ...prev, modifyProfileImage: true }));
    try {
      const result = await Network.userMePUT({ profileImageUrl: my.newProfileImageUrl });
      setMy({ ...my, ...result.user, loadedAt: dayjs().toISOString() });
      props.fetchMyInfo();
      setProfileImageModalOpen(false);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    } finally {
      setLoadingObj((prev) => ({ ...prev, modifyProfileImage: false }));
    }
  };

  const _modifyPassword = async () => {
    setLoadingObj((prev) => ({ ...prev, modifyPassword: true }));
    try {
      const result = await Network.userMePUT({
        password: passwords.confirmedNewPassword,
        currentPassword: passwords.currentPassword,
      });
      setMy({ ...my, ...result.user, loadedAt: dayjs().toISOString() });

      setPasswordModalOpen(false);
      setPasswordsShownObj({ currentPassword: false, newPassword: false, confirmedNewPassword: false });
      setPasswords({ currentPassword: '', newPassword: '', confirmedNewPassword: '' });
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setLoadingObj((prev) => ({ ...prev, modifyPassword: false }));
  };

  const _modifyPhoneNumber = async () => {
    if (!my.newPhoneNumber) return;
    setLoadingObj((prev) => ({ ...prev, modifyPhoneNumber: true }));
    try {
      const result = await Network.userMePUT({ phoneNumber: my.newPhoneNumber });
      setMy({ ...my, ...result.user, loadedAt: dayjs().toISOString() });
      props.fetchMyInfo();
      setPhoneNumberModalOpen(false);
      setCode(null);
      setVerificationCode(null);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    } finally {
      setLoadingObj((prev) => ({ ...prev, modifyPhoneNumber: false }));
    }
  };

  const _requestCode = async () => {
    setCode(null);
    setRequesting(true);
    try {
      const res = await Network.phoneVerificationPOST({ phoneNumber: my.newPhoneNumber });
      setCode(res.verificationCode);
      alert('문자메시지를 확인해 주세요');
    } catch (e) {
      alert(e.cause?.errorMessage);
    } finally {
      setRequesting(false);
    }
  };

  useEffect(() => {
    props.fetchMyInfo();
    return () => {
      // 이 함수를 하고 안하고로 클린업 관련 에러 확인 가능
      const cleanUp = () => {
        setMy({
          loadedAt: '',
          schoolId: 0,
          id: -1,
          name: '',
          displayName: '',
          birthDate: '',
          email: '',
          phoneNumber: '',
          currentPassword: '',
          profileImageUrl: '',
          // new
          newDisplayName: '',
          newPhoneNumber: '',
          newProfileImageUrl: '',
        });
        setPasswords({ currentPassword: '', newPassword: '', confirmedNewPassword: '' });
      };
      cleanUp();
    };
  }, []);

  useEffect(() => {
    setMy({
      ...props.auth.user,
      newDisplayName: props.auth.user.displayName,
      newProfileImageUrl: props.auth.user.profileImageUrl,
      newPhoneNumber: props.auth.user.phoneNumber,
      loadedAt: dayjs().toISOString(),
    });
  }, [props.auth.user]);

  const renderMy = useMemo(
    () => (
      <div className='md:grid md:grid-cols-5 md:gap-12 px-4 xs:px-10 md:max-w-6xl mx-auto mt-12'>
        <div className='flex flex-col items-center space-y-2'>
          <img
            alt={my.displayName}
            src={my.profileImageUrl}
            className='aspect-1 object-cover object-center w-full rounded-full shrink-0'
          />
          <Button onClick={() => setProfileImageModalOpen(true)}>변경</Button>
        </div>
        <div className='md:col-start-2 md:col-end-6 space-y-6'>
          <table className=' w-full divide-y divide-gray-200 dark:divide-gray-700'>
            <tr className='h-12'>
              <td className='font-medium'>이름</td>
              <td>{my.name}</td>
            </tr>
            <tr className='h-12'>
              <td className='font-medium'>이메일</td>
              <td>{my.email}</td>
            </tr>
            <tr className='h-12'>
              <td>전화번호</td>
              <td>{[my.phoneNumber?.slice(0, 3), my.phoneNumber?.slice(3, 7), my.phoneNumber?.slice(7, 11)].join('-')}</td>
              <td className='text-right'>
                <Button onClick={() => setPhoneNumberModalOpen(true)}>변경</Button>
              </td>
            </tr>
            <tr className='h-12'>
              <td>비밀번호</td>
              <td>******</td>
              <td className='text-right'>
                <Button onClick={() => setPasswordModalOpen(true)}>변경</Button>
              </td>
            </tr>
            <tr className='h-12'>
              <td>닉네임</td>
              <td>{my.displayName}</td>
              <td className='text-right'>
                <Button onClick={() => setDisplayNameModalOpen(true)}>변경</Button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    ),
    [my.loadedAt]
  );

  return (
    <div className='md:max-w-4xl md:mx-auto'>
      <h2>내 계정</h2>

      {renderMy}

      <Dialog
        open={profileImageModalOpen}
        onClose={() => !uploading && !loadingObj.modifyProfileImage && setProfileImageModalOpen(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>프로필 이미지 변경</DialogTitle>
        <DialogContent sx={{ overflow: 'visible', py: 0 }}>
          <div className='flex justify-between items-center'>
            <img
              alt={my.displayName}
              src={my.newProfileImageUrl || my.profileImageUrl}
              className='w-32 h-32 rounded-full'
            />
            <div className='flex flex-col space-y-2'>
              <Button
                variant='contained'
                onClick={() => fileUploadRef?.current?.click()}
                disabled={uploading || loadingObj.modifyProfileImage}
              >
                사진 올리기
              </Button>
              <Button
                disabled={uploading || loadingObj.modifyProfileImage}
                onClick={() => setMy((prev) => ({ ...prev, newProfileImageUrl: 'https://firebasestorage.googleapis.com/v0/b/kyobo-stagram.appspot.com/o/user%2F0%2Ftemp_user.png?alt=media' }))}
              >
                기본사진으로
              </Button>
              <input
                ref={fileUploadRef}
                id='profileImageUrl-upload'
                name='profileImageUrl-upload'
                type='file'
                className='sr-only'
                accept='image/png, image/jpeg, image/gif'
                onChange={(e) => _onChangeProfileImageUrl(...e.target.files)}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <LoadingButton
            variant='contained'
            loading={loadingObj.modifyProfileImage}
            disabled={uploading}
            type='submit'
            onClick={_modifyProfileImage}
          >
            변경 완료
          </LoadingButton>
          <Button
            color='error'
            onClick={() => {
              setProfileImageModalOpen(false);
              setMy((prev) => ({ ...prev, newProfileImageUrl: '' }));
            }}
            disabled={uploading || loadingObj.modifyProfileImage}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={displayNameModalOpen}
        onClose={() => setDisplayNameModalOpen(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>닉네임 변경</DialogTitle>
        <DialogContent sx={{ overflow: 'visible', py: 0 }}>
          <TextField
            fullWidth
            label='닉네임'
            placeholder='닉네임'
            defaultValue={my.displayName}
            onChange={(e) => _handleChangeMy('newDisplayName', e.target.value)}
            sx={{ mb: 1.5 }}
          />
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <LoadingButton
            variant='contained'
            loading={loadingObj.modifyDisplayName}
            type='submit'
            onClick={_modifyDisplayName}
            disabled={!my.newDisplayName || my.displayName === my.newDisplayName}
          >
            변경 완료
          </LoadingButton>
          <Button
            color='error'
            onClick={() => {
              setDisplayNameModalOpen(false);
              setMy((prev) => ({ ...prev, newDisplayName: prev.displayName }));
            }}
            disabled={loadingObj.modifyDisplayName}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={phoneNumberModalOpen}
        onClose={() => setPhoneNumberModalOpen(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>핸드폰 번호 등록/변경</DialogTitle>
        <DialogContent sx={{ overflow: 'visible', py: 0 }}>
          <TextField
            fullWidth
            label='핸드폰 번호'
            placeholder='핸드폰 번호'
            type='contact'
            defaultValue={my.phoneNumber}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/gi, '');
              e.target.value = value;
              _handleChangeMy('newPhoneNumber', value);
            }}
            sx={{ mb: 1.5 }}
          />
          {code ? (
            <TextField
              fullWidth
              label='인증 번호'
              placeholder='인증 번호'
              type='contact'
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{ mb: 1.5 }}
            />
          ) : (
            <LoadingButton
              loading={requesting}
              variant='contained'
              onClick={_requestCode}
              disabled={my.phoneNumber === my.newPhoneNumber}
            >
              인증번호 요청
            </LoadingButton>
          )}
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <LoadingButton
            variant='contained'
            loading={loadingObj.modifyPhoneNumber}
            disabled={!code || !verificationCode || code !== verificationCode}
            type='submit'
            onClick={_modifyPhoneNumber}
          >
            변경 완료
          </LoadingButton>
          <Button
            color='error'
            onClick={() => {
              setPhoneNumberModalOpen(false);
              setMy((prev) => ({ ...prev, newProfileImageUrl: '' }));
              setCode(null);
              setVerificationCode(null);
            }}
            disabled={loadingObj.modifyPhoneNumber}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={passwordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>비밀번호 변경</DialogTitle>
        <DialogContent sx={{ overflow: 'visible', py: 0 }}>
          <TextField
            fullWidth
            label='현재 비밀번호'
            placeholder='현재 비밀번호 입력'
            defaultValue={passwords.currentPassword}
            onChange={(e) => _handleChangePasswords('currentPassword', e.target.value)}
            type={passwordsShownObj.currentPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setPasswordsShownObj((prev) => ({ ...prev, currentPassword: !prev.currentPassword }))}>
                  {passwordsShownObj.currentPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                </IconButton>
              ),
            }}
            sx={{ mb: 1.5 }}
          />
          <TextField
            fullWidth
            label='새 비밀번호'
            placeholder='새로운 비밀번호 (6글자 이상)'
            defaultValue={passwords.newPassword}
            onChange={(e) => _handleChangePasswords('newPassword', e.target.value)}
            type={passwordsShownObj.newPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setPasswordsShownObj((prev) => ({ ...prev, newPassword: !prev.newPassword }))}>
                  {passwordsShownObj.newPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                </IconButton>
              ),
            }}
            sx={{ mb: 1.5 }}
          />
          <TextField
            fullWidth
            label='새 비밀번호 확인'
            placeholder='새로운 비밀번호 (6글자 이상)'
            defaultValue={passwords.confirmedNewPassword}
            onChange={(e) => _handleChangePasswords('confirmedNewPassword', e.target.value)}
            type={passwordsShownObj.confirmedNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setPasswordsShownObj((prev) => ({ ...prev, confirmedNewPassword: !prev.confirmedNewPassword }))}>
                  {passwordsShownObj.confirmedNewPassword ? <VisibilityRounded /> : <VisibilityOffRounded />}
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <LoadingButton
            variant='contained'
            loading={loadingObj.modifyPassword}
            disabled={!isValidPassword}
            onClick={_modifyPassword}
          >
            비밀번호 변경
          </LoadingButton>
          <Button
            color='error'
            onClick={() => {
              setPasswordModalOpen(false);
              setPasswordsShownObj({ currentPassword: false, newPassword: false, confirmedNewPassword: false });
              setPasswords({ currentPassword: '', newPassword: '', confirmedNewPassword: '' });
            }}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={csModalOpen}
        onClose={() => setCsModalOpen(false)}
      >
        <DialogTitle>문의</DialogTitle>
        <div className='my-4 divide-y divide-gray-200 dark:divide-gray-700'>
          <TextField
            fullWidth
            label='분야'
            placeholder='분야'
            defaultValue={voiceOfCustomer.id}
            onChange={(e) => setVOC({ ...voiceOfCustomer, id: e.target.value })}
            select
          >
            {[
              { id: 0, name: '개인 정보' },
              { id: 1, name: '학교 정보' },
              { id: 2, name: '수업' },
              { id: 3, name: '독후감' },
              { id: 4, name: '과제' },
              { id: 5, name: '기타' },
            ].map((item) => (
              <MenuItem
                value={item.id}
                key={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            multiline
            rows={5}
            label='문의 내용 입력'
            placeholder='문의 내용 입력'
            defaultValue={voiceOfCustomer.content}
            onChange={(e) => _handleChangeVoiceOfCustomers('content', e.target.value)}
            maxLength={500}
          />
        </div>
        <DialogActions sx={{ p: 3 }}>
          <Button
            color='error'
            onClick={() => setCsModalOpen(false)}
          >
            취소
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              // _sendVOC();
            }}
          >
            변경 완료
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...authenticationActionCreators,
  }
);
export default enhance(MyAccountPage);
