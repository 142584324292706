import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container, Grid, Table, TableHead, TableRow, TableBody, TableCell,Box, Avatar, Link as LinkMui} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import LoadingComponent from "../../components/shared/LoadingComponent";
import NetworkStudent from "../../lib/NetworkStudent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {PATH_STUDENT} from "../../routes/paths";
import dayjs from "dayjs";

const StudentSearchCourseIrregularPage = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const keyword = query.get("keyword") || "";

  const [isLoaded, setIsLoaded] = useState(false)
  const [courses, setCourses] = useState([])

  const _load = async () => {
    try {
      const res = await NetworkStudent
        .coursesGET({
          keyword: keyword || undefined,
          courseType: "IRREGULAR",
        })
      setCourses(res.courses);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])


  if (!isLoaded) return <LoadingComponent/>

  return (
    <Container>
      <h4>
        비교과 활동
      </h4>
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                수업명
              </TableCell>
              <TableCell>
                담당 선생님
              </TableCell>
              <TableCell>
                시작일
              </TableCell>
              <TableCell>
                종료일
              </TableCell>
              <TableCell>

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              courses.map((course, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.course.detail(course.id)}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                          <Avatar src={course.coverImageUrl} variant="square" sx={{mr: 1}}/>
                          <div className={'text-primary-500 line-clamp-1 w-[130px]'}>
                            {course?.title || "-"}
                          </div>
                        </Box>
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      {course.teacherUser?.name}
                    </TableCell>
                    <TableCell>
                      {dayjs(course.startDate).format('YYYY.MM.DD(ddd)')}
                    </TableCell>
                    <TableCell>
                      {dayjs(course.endDate).format('YYYY.MM.DD(ddd)')}
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </Scrollbar>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSearchCourseIrregularPage);
