import dayjs from "dayjs";

import React, {useState,  useEffect} from "react";
import * as XLSX from "xlsx-js-style"; // import XLSX from "xlsx"; 모듈 default import 에러
import {Link, useParams} from "react-router-dom";
import {connect} from "react-redux";
import { Container, Table, TableContainer, TableHead, TableRow, TableBody, TableCell, Button, Breadcrumbs } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';

import Network from "../../lib/Network";

import {PATH_TEACHER} from "../../routes/paths";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import Loader from '../../assi-design-system-react/components/Loader';

const TeacherClassStudentDetail = (props) => {
  const {classId, userId} = useParams();
  const [student, setStudent] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [bookReports, setBookReports] = useState([]);

  const _saveListAsExcel = () => {
    _downloadExcel(
      bookReports.map((item) => ({
        title: item.title || '',
        book: item.book?.title || '',
        course: item.task?.course?.title || '',
        teacher: item.teacherUser?.name,
        date: `${dayjs(item.submittedAt).format('YYYY-MM-DD HH:mm')}`,
      })),
      student
    );
  };


  useEffect(() => {
    const _loadStudentUser = async () => {
      try {
        const { class: cls } = await Network.classGET({ classId });
        const { year } = cls;
        if (!year) throw { cause: { errorMessage: '수업에 연도 정보가 없습니다' } };
        const { user } = await Network.userGET({ userId, year });
        if (user.classRegister?.classId !== Number(classId)) throw { cause: { errorMessage: `해당 반에 등록된 학생이 아닙니다` } };
        setStudent(user);
        const { bookReports, meta } = await Network.bookReportsGET({
          studentUserId: userId,
          offset: 0,
          limit: 100,
        });
        setBookReports(bookReports);
        setMeta(meta);
        setIsLoaded(true);
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      }
    };
    _loadStudentUser();
  }, [classId, userId]);


  if (!isLoaded) return <Loader/>;

  return (
    <Container maxWidth='xl'>
      <div className='pb-4'>
        <Breadcrumbs separator='›'>
          <Link to={PATH_TEACHER.class.root}>
            <p className='clickable-primary'>우리반</p>
          </Link>
          <Link to={PATH_TEACHER.class.detail.root(student.classRegister?.classId)}>
            <p className='clickable-primary'>
              {student.classRegister?.class?.grade}학년 {student.classRegister?.class?.classNo}반
            </p>
          </Link>
          <p className='font-bold'>
            {student.classRegister?.studentNo}번 {student?.name}
          </p>
        </Breadcrumbs>
      </div>
      <div className='py-4'>
        <p className='text-xl font-bold'>학생정보</p>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className='w-[150px]'>이름</TableCell>
                <TableCell>
                  <div className='flex items-center'>
                    <img
                      src={student.profileImageUrl}
                      className='w-[30px] h-[30px] rounded-full'
                    />
                    <p className='pl-2'>{student.name}</p>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>학년/반/번호</TableCell>
                <TableCell>
                  {student.classRegister?.class?.grade}학년 {student.classRegister?.class?.classNo}반 {student.classRegister?.studentNo}번
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>이메일</TableCell>
                <TableCell>{student.email || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className='w-[150px]'>전화</TableCell>
                <TableCell>{student.phoneNumber || '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='py-4 flex items-center justify-between'>
        <p className='text-xl font-bold'>독후감 목록</p>
        <Button
          startIcon={<SaveOutlined />}
          onClick={_saveListAsExcel}
          variant='outlined'
        >
          엑셀로 저장
        </Button>
      </div>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 'max-content' }}>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { name: 'No.', className: '' },
                  { name: '제목', className: 'min-w-[200px] md:w-fit' },
                  { name: '제출일' },
                  { name: '책', className: '' },
                  { name: '수업/과제' },
                  { name: '담당선생님' },
                ].map((head) => (
                  <TableCell
                    key={head.name}
                    className={head.className}
                  >
                    {head.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports.map((bookReport, index) => (
                <TableRow
                  key={bookReport.id}
                  className='hover:bg-hover'
                >
                  <TableCell>{1 + index}</TableCell>
                  <TableCell>
                    <Link to={PATH_TEACHER.bookReport.detail.root(bookReport.id)}>
                      <p className='clickable-primary text-hidden'>{bookReport.title}</p>
                    </Link>
                  </TableCell>
                  <TableCell>{dayjs(bookReport.submittedAt).format(dayjs().isSame(bookReport.submittedAt, 'year') ? 'M.D(dd) HH:mm' : 'YYYY.M.D(dd) HH:mm')}</TableCell>
                  <TableCell className='w-[200px]'>
                    <div className='line-clamp-1'>{bookReport.book?.title || '-'}</div>
                  </TableCell>
                  <TableCell className='w-[250px]'>
                    <div className='line-clamp-1'>{bookReport.task?.taskType === 'PRIVATE' ? '개별독후감' : bookReport.task?.course?.title || '-'}</div>
                    <p className='line-clamp-1'>{bookReport.task?.title}</p>
                  </TableCell>
                  <TableCell>{bookReport?.teacherUser?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({ ...state }));
export default enhance(TeacherClassStudentDetail);


const _downloadExcel = (columns, student) => {
  const wb = XLSX.utils.book_new();

  const _student = {
    이름: student.name,
    이메일: student.email,
    전화번호: student.phoneNumber,
    학년: student.classRegister.class.grade,
    반: student.classRegister.class.classNo,
    번호: student.classRegister.studentNo,
  };

  const ws = XLSX.utils.json_to_sheet([...Object.keys(_student).map((k) => [k, !!_student[k] ? _student[k] : ""])], {origin: 1, skipHeader: true});

  const heading = [
    [
      {t: "s", v: "독후감 제목", s: {font: {bold: true}}},
      {t: "s", v: "책 제목", s: {font: {bold: true}}},
      {t: "s", v: "수업", s: {font: {bold: true}}},
      {t: "s", v: "담당선생님", s: {font: {bold: true}}},
      {t: "s", v: "작성일", s: {font: {bold: true}}},
    ],
  ];
  XLSX.utils.sheet_add_aoa(ws, heading, {origin: "A11"});
  XLSX.utils.sheet_add_json(ws, columns, {origin: "A12", skipHeader: true});

  ws["!cols"] = [{wch: 50}, {wch: 20}, {wch: 30}, {wch: 10}, {wch: 30}];

  [...Array(6).keys()].map((idx) => {
    ["A", "B"].map((column) => {
      const cell = [column, idx + 2].join("");
      ws[cell].s = {
        font: {
          bold: column === "A" && true,
        },
        border: {
          left: column === "A" && {style: "thin", color: {rgb: "#000000"}},
          right: column === "B" && {style: "thin", color: {rgb: "#000000"}},
          top: idx === 0 && {style: "thin", color: {rgb: "#000000"}},
          bottom: idx === 5 && {style: "thin", color: {rgb: "#000000"}},
        },
      };
      return ws;
    });
  });

  XLSX.utils.book_append_sheet(wb, ws, "학생 독후감 엑셀");
  // console.dir(ws);
  XLSX.writeFile(wb, `${student.displayName}_${dayjs().format("YYYY-MM-DD")}.xlsx`, {cellStyles: true});
};
