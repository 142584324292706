import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Network from "../../lib/Network";
import { Table, TableContainer, TableRow, TableHead, TableBody, TableCell, PaginationItem, Pagination } from "@mui/material";
import { PATH_TEACHER } from "../../routes/paths";
import dayjs from "dayjs";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import * as XLSX from "xlsx-js-style";
import { termTypes } from "../../constants";
import { LoadingButton } from "@mui/lab";
import { SaveOutlined } from "@mui/icons-material";

const TaskDetailBookReportTab = ({task}) => {

  const location = useLocation();
  const navigate = useNavigate();
  const {taskId} = useParams();
  const [searchParams] = useSearchParams();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const perPage = parseInt(query.get("perPage") || "20", 10);
  const keyword = query.get('keyword') || ""
  const [isLoaded, setIsLoaded] = useState(false)
  const [bookReports, setBookReports] = useState([])
  const [meta, setMeta] = useState({total: 0});

  useEffect(() => {
    const _load = async () => {
      try {
        const res = await Network.bookReportsGET({
          taskId,
          offset: (page - 1) * perPage,
          limit: perPage,
          keyword,
        })
        setBookReports(res.bookReports);
        setMeta(res.meta);
        setIsLoaded(true);
      } catch (e) {
        alert(e.cause?.errorMessage);
      }
    }
    _load();
  }, [page, perPage, keyword])

  const [isLoadingForAllTheBookReports, setLoadingForAllTheBookReports] = useState(false);

  const _downloadExcel = (courseAndTask, columns, maxNumberOfAnswers) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(columns, {origin: "A8", skipHeader: true});

    const headingStyle = {
      t: "s",
      s: {
        font: {bold: true},
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: "1",
        },
      },
    };

    const title = [[{v: courseAndTask.title, t: "s", s: {font: {bold: true, sz: 16}}}]];
    XLSX.utils.sheet_add_aoa(worksheet, title, {origin: "A2"});

    const booksText = courseAndTask.books?.map((book) => `${book.title} (${book.authors})`) || [];
    const taskInfo = [
      [
        {v: "과제 제목", ...headingStyle},
        {v: "연도", ...headingStyle},
        {v: "학기", ...headingStyle},
        {v: "기간", ...headingStyle},
        {v: "첨부파일", ...headingStyle},
        {v: "설명", ...headingStyle},
        {v: "지정 도서", ...headingStyle},
      ],
      [
        {v: courseAndTask.title},
        {v: courseAndTask.semester.year},
        {v: termTypes.find((termType) => termType.type === courseAndTask.semester.term)?.name},
        {v: `${dayjs(courseAndTask.startDate).format("YYYY-MM-DD")}~${dayjs(courseAndTask.endDate).format("YYYY-MM-DD")}`},
        {v: courseAndTask.fileUrls.map((file) => file.url).join("\n")},
        {v: courseAndTask.description},
        {v: booksText.join("\n") + ` (${booksText.length}권)`},
      ],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, taskInfo, {origin: "A4"});

    const subheading = [
      [
        {v: "이름", ...headingStyle},
        {v: "학년", ...headingStyle},
        {v: "반", ...headingStyle},
        {v: "번호", ...headingStyle},
        {v: "과제 타입", ...headingStyle},
        {v: "책", ...headingStyle},
        {v: "책 저자", ...headingStyle},
        {v: "독후감 제목", ...headingStyle},
        {v: "제출일", ...headingStyle},
        {v: "점수", ...headingStyle},
        ...courseAndTask.questions.map((question, idx) => ({
          v: `${idx + 1}. ${question.title}`,
          t: "s",
          s: {
            font: {bold: true},
            alignment: {
              vertical: "center",
              horizontal: "left",
              wrapText: "1",
            },
          },
        })),
      ],
    ];

    XLSX.utils.sheet_add_aoa(worksheet, subheading, {origin: "A7"});
    // worksheet["!merges"] = [
    //   { s: { r: 6, c: 0 }, e: { r: 6, c: 3 } },
    //   { s: { r: 6, c: 4 }, e: { r: 7, c: 4 } },
    //   { s: { r: 6, c: 5 }, e: { r: 6, c: 6 } },
    //   { s: { r: 6, c: 7 }, e: { r: 6, c: 10 } },
    //   { s: { r: 6, c: 11 }, e: { r: 6, c: 11 + maxNumberOfAnswers - 1 } },
    // ];
    worksheet["!cols"] = [
      {wch: 12},
      {wch: 4},
      {wch: 4},
      {wch: 4},
      {wch: 16},
      {wch: 32},
      {wch: 12},
      {wch: 32},
      {wch: 16},
      {wch: 4},
      ...[...Array(maxNumberOfAnswers)].map((empty, idx) => ({wch: 60})),
    ];
    worksheet["!rows"] = [{}, {hpt: 40}, {}, {}, {}, {}, {hpt: 24}];

    XLSX.utils.book_append_sheet(workbook, worksheet, "학생 독후감 엑셀");
    XLSX.writeFile(workbook, `${task?.title}_독후감_${dayjs().format("YYYY-MM-DD_a_hh:mm")}.xlsx`, {cellStyles: true});
  };
  const _loadForExcel = async () => {
    let allTheBookReports = [];
    let allTaskUsers = [];

    const limit = 500;

    const loadCourseInfo = async () => {
      const result = await Network.courseGET({courseId: task?.courseId});
      return result.course || {};
    };

    const loadBookReportsBy500 = async (offset = 0) => {
      const result = await Network.bookReportsGET({
        courseId: task?.courseId,
        taskId,
        offset,
        keyword,
        limit,
        includingAnswers: true,
      });

      allTheBookReports = allTheBookReports.concat(result.bookReports);

      if (result.bookReports.length < limit) {
        return;
      } else if (result.bookReports.length === limit) {
        return await loadBookReportsBy500(allTheBookReports.length);
      }
    };

    const loadStudentsBy500 = async (offset = 0) => {
      const result = await Network.taskUsersGET({
        taskId,
        offset,
        limit,
        keyword,
      });

      allTaskUsers = allTaskUsers.concat(result.users);

      if (result.users.length < limit) {
        return;
      } else if (result.users.length === limit) {
        return await loadStudentsBy500(allTaskUsers.length);
      }
    };

    try {
      const courseInfo = await loadCourseInfo();
      await loadBookReportsBy500();
      await loadStudentsBy500();
      return {courseInfo, allTheBookReports, allTaskUsers};
    } catch (error) {
      window.alert(error.cause?.errorMessage);
      return [];
    }
  };
  const _downloadBookReportsInExcel = async () => {
    setLoadingForAllTheBookReports(true);

    const {courseInfo, allTheBookReports, allTaskUsers} = await _loadForExcel();

    const bookReportWithUsers = allTaskUsers.map((user) => ({
      id: user.id,
      name: user.name,
      year: user.classRegister.class.year,
      grade: user.classRegister.class.grade,
      classNo: user.classRegister.class.classNo,
      studentNo: user.classRegister.studentNo,
      bookReport: allTheBookReports.find((bookReport) => bookReport.user.id === user.id),
    }));

    // const numberOfAnswers = allTheBookReports.map((bookReport) => bookReport.answers.length)||[];
    const numberOfAnswers = task.questions.length;

    const answers = bookReportWithUsers.map((item) => {
      if (item.bookReport) {
        const answers = {};
        item.bookReport?.answers.map((answer, idx) => {
          answers[`answer_${idx + 1}`] =
            answer.content ||
            answer.imageUrls.map((image) => image.url).join("\n") ||
            answer.fileUrls.map((file) => file.url).join("\n") ||
            answer.videoUrls.map((video) => video.url).join("\n");
        });
        return answers;
      } else return;
    });

    _downloadExcel(
      {...courseInfo, ...task},
      bookReportWithUsers.map((item, idx) => ({
        name: item.name,
        grade: item.grade,
        classNo: item.classNo,
        studentNo: item.studentNo,
        ...(item.bookReport?.id
          ? {
            taskType: item.bookReport.task.taskType === "PRIVATE" ? "개별 제출" : "수업/활동 제출",
            book: item.bookReport.book?.title || "",
            author: item.bookReport.book?.authors || "",
            title: item.bookReport.title || "",
            submittedAt: `${dayjs(item.bookReport.submittedAt).format("YYYY-MM-DD HH:mm")}`,
            score: item.bookReport.score || "",
            ...answers[idx],
          }
          : {
            taskType: "",
            book: "",
            author: "",
            title: "",
            submittedAt: "미제출",
          }),
      })),
      numberOfAnswers,
    );

    setLoadingForAllTheBookReports(false);
  };


  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  if (!isLoaded) return <div/>

  return (
    <div className={'py-2 space-y-2'}>
      <div className={'flex flex-col space-y-1 md:space-y-0  md:flex-row md:justify-between'}>
        <SearchTextField onSubmit={_handleSearch} sx={{maxWidth: {xs: 'full', md: 300}}}/>
        <LoadingButton
          startIcon={<SaveOutlined/>}
          sx={{alignSelf: 'flex-end'}}
          variant={'outlined'}
          size={'large'}
          loading={isLoadingForAllTheBookReports}
          onClick={_downloadBookReportsInExcel}
          disabled={isLoadingForAllTheBookReports}
        >
          엑셀
        </LoadingButton>
      </div>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: "max-content"}}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  [
                    {name: "No.", className: ''},
                    {name: "이름", className: 'min-w-[120px]'},
                    {name: "학년", className: "min-w-[55px]"},
                    {name: "반", className: "min-w-[55px]"},
                    {name: "번호", className: "min-w-[55px]"},
                    {name: "제목", className: "min-w-[200px] md:w-fit"},
                    {name: "제출일"},
                    {name: "책 / 수업"},
                    {name: "담당선생님"},
                    {name: "점수"},
                  ].map((head) => (
                    <TableCell key={head.name} className={head.className}>
                      {head.name}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports.map((bookReport, index) => {
                return (
                  <TableRow key={bookReport.id} className={'hover:bg-hover'}>
                    <TableCell>
                      {(page - 1) * perPage + 1 + index}
                    </TableCell>
                    <TableCell>
                      {/*<Link to={`${PATH_TEACHER.student.detail.root(bookReport.userId)}?year=${dayjs(bookReport.submittedAt).format('YYYY')}`}>*/}
                      <Link to={PATH_TEACHER.course.detail.student.detail.root(bookReport.task?.courseId, bookReport.userId)}>
                        <div className={'flex items-center min-w-[40px] clickable-primary'}>
                          <img src={bookReport.user?.profileImageUrl} className={'w-[30px] aspect-1 object-cover rounded-full'}/>
                          <p className={'pl-2'}>
                            {bookReport.user?.name}
                          </p>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.class?.grade}
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.class?.classNo}
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.studentNo}
                    </TableCell>
                    <TableCell>
                      <Link to={PATH_TEACHER.bookReport.detail.root(bookReport.id)}>
                        <p className="clickable-primary text-hidden">
                          {bookReport.title}
                        </p>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {dayjs(bookReport.submittedAt).format(dayjs().isSame(bookReport.submittedAt, "year") ? "M.D(dd) HH:mm" : "YYYY.M.D(dd) HH:mm")}
                    </TableCell>
                    <TableCell className={'w-[250px]'}>
                      <div className={'line-clamp-1'}>
                        {bookReport.book?.title || '-'}
                      </div>
                      <div className={'line-clamp-1'}>
                        {
                          bookReport.task?.taskType === 'PRIVATE' ? '개별독후감' : (bookReport.task?.course?.title || '-')
                        }

                      </div>
                    </TableCell>
                    <TableCell>
                      {bookReport?.teacherUser?.name}
                    </TableCell>
                    <TableCell>
                      {bookReport.score === null ? <span className={'text-red-700'}>평가 전</span> : bookReport.score}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TaskDetailBookReportTab);
