import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FlatList from "../../assi-design-system-react/components/FlatList";
import { questionTypeText } from "../../constants/dataTypes";
import {Box, Chip} from "@mui/material";

const TaskDetailInfoTab = ({task}) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const {questions} = task;

  if (!task) return <div/>

  return (
    <Box sx={{mt: 5, mb: 10, boxShadow: 5, p: 4}}>
      <FlatList
        data={questions}
        renderItem={({item: question, index}) => {
          return (
            <div className={'p-4'}>
              <div className={'flex'}>
                <div className={'w-[30px] h-[30px] rounded-full bg-primary-500 justify-center items-center flex'}>
                  <p className={'text-white font-bold text-sm'}>
                    {question.order + 1}
                  </p>
                </div>
                <p className={'pl-2 pt-1'}>
                  {question.title}
                </p>
              </div>
              {
                !!question.description && (
                  <p className={'whitespace-pre-line line-clamp-3 my-2'}>
                    {question.description}
                  </p>
                )
              }
              <Chip
                className={'my-2'}
                variant="contained"
                label={`답변 유형: ${questionTypeText[question.questionType]}`}
              />

            </div>
          )
        }}/>

    </Box>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TaskDetailInfoTab);
