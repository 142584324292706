import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {Avatar, Button, Box, TableCell, Container, Pagination, PaginationItem, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Stack, Link as LinkMui} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import NetworkStudent from "../../lib/NetworkStudent";
import dayjs from "dayjs";
import LoadingComponent from "../../components/shared/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import numeral from "numeral";
import {termTypesObj, courseTypesObj} from "../../constants";
import MessageBox from '../../components/shared/MessageBox';
import { PATH_ADMIN, PATH_STUDENT } from '../../routes/paths';

const StudentCourseMainPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [courses, setCourses] = useState([]);
  const LIMIT = 10;

  const _load = async () => {
    try {
      const res = await NetworkStudent.coursesGET({
        isRegistered: true,
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
      });
      setCourses(res.courses);
      setMeta(res.meta);
      setIsLoaded(true);
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };

  useEffect(() => {
    _load();
  }, [page]);

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={"lg"}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            나의 수업/활동
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}개
          </p>
        </div>
      </div>
      <MessageBox content={'지금까지 참여한 모든 수업이 표시됩니다(교과/비교과 포함)'}/>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
        renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: "relative"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>제목</TableCell>
                <TableCell>선생님</TableCell>
                <TableCell>학기</TableCell>
                <TableCell>구분</TableCell>
                <TableCell>시작일</TableCell>
                <TableCell>종료일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses?.map((course) => {
                return (
                  <TableRow key={course.id}>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.course.detail(course.id)}
                      >
                        <Box sx={{display: "flex", alignItems: "center"}}>
                          <Avatar src={course.coverImageUrl} variant="square" sx={{mr: 1}}/>
                          <div className={'text-primary-500 line-clamp-1 w-[130px]'}>
                            {course?.title || "-"}
                          </div>
                        </Box>
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui className={'text-[#1370F5]'} as={Link} to={PATH_STUDENT.teacher.detail(course.teacherUser?.id)}>
                        {course.teacherUser?.displayName}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      {course.semester?.year} - {termTypesObj[course.semester?.term].name}
                    </TableCell>
                    <TableCell>
                      {courseTypesObj[course.courseType].name}
                    </TableCell>
                    <TableCell>{dayjs(course.startDate).format("YYYY.MM.DD(ddd)")}</TableCell>
                    <TableCell>{dayjs(course.endDate).format("YYYY.MM.DD(ddd)")}</TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentCourseMainPage);
