import { connect } from "react-redux";
import { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { userTypesObj } from "../../constants";
import { analytics } from "../../config/firebase.config";
// GA
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { PAGE_TITLE } from "../../routes/pageTitles";

const Analytics = (props) => {
  const location = useLocation();

  useEffect(() => {
    let matchedPage;
    Object.keys(PAGE_TITLE).map((path) => {
      const match = matchPath({path}, location.pathname);
      if (!!match) {
        matchedPage = PAGE_TITLE[match.pattern.path];
        return matchedPage;
      }
    });
    if (!!analytics) {
      logEvent(analytics, "page_view", {page_title: matchedPage || "지정하지 않은 페이지", page_path: location.pathname});
      setUserId(analytics, props.authReducer.user?.id);
      setUserProperties(analytics, {user_type: userTypesObj[props.authReducer.user?.userType]?.name || "지정외 사용자"});
    }
  }, [location.pathname]);

  return <></>;
};

const enhance = connect((state) => ({...state}), {});
export default enhance(Analytics);
