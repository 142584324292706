import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import NetworkStudent from '../../lib/NetworkStudent';
import {
  FormControlLabel,
  Checkbox,
  Chip,
  Button, Card, Box, Paper, CardActions, CardContent, Container, Divider, Grid, Typography,
  Dialog, DialogTitle, DialogContent, DialogContentText, InputBase, DialogActions,
  Avatar, TextField,
} from "@mui/material";
import {userTypeText} from '../../constants/dataTypes';
import dayjs from 'dayjs';
import auth from '../../redux/auth/auth.store';
import LoadingButton from '@mui/lab/LoadingButton';

const BookReportComment = (props) => {

  const [bookReportComment, setBookReportComment] = useState(props.bookReportComment || {});
  const [isDeleted, setIsDeleted] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [showCoCommentTextField, setShowCoCommentTextField] = useState(false);
  const [uploadingComment, setUploadingComment] = useState(false);
  const [newComment, setNewComment] = useState('');

  const _deleteBookReportComment = async () => {
    const a = window.confirm('삭제하시겠습니까?');
    if (!a) return;
    setUploading(true);
    try {
      const res = await NetworkStudent.bookReportCommentDELETE({bookReportCommentId: bookReportComment.id});
      if (!res.err) {
        setIsDeleted(true);
        props.loadBookReportComment?.();
      }
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }

    setUploading(false);
  };
  useEffect(() => {
    setBookReportComment(props.bookReportComment);
  }, [props.bookReportComment?.id]);

  const _uploadBookReportComments = async () => {
    if (newComment?.trim() === "") return;
    setUploadingComment(true);
    try {
      const res = await NetworkStudent.bookReportCommentPOST(
        {
          bookReportId: bookReportComment.bookReportId,
          bookReportCommentId: bookReportComment.id,
          content: newComment,
        },
      );
      setNewComment("");
      await props.loadBookReportComment?.();
      setShowCoCommentTextField(false)
    } catch (e) {

    } finally {
      setUploadingComment(false);
    }
  }


  if (!bookReportComment) return null;
  if (!!isDeleted) return null;
  const isMine = props.auth?.user?.id === bookReportComment.userId;


  return (
    <>
      <Box sx={{
        mb: 4,
        ml: !!bookReportComment?.bookReportCommentId ? 6 : 0,
      }}>
        <Box sx={{display: 'flex',}}>
          <Avatar className={'border border-gray-300'} src={bookReportComment?.user?.profileImageUrl}/>
          <Box sx={{pl: 2}}>
            <Typography sx={{fontWeight: 'bold'}}>
              {bookReportComment.user?.name} {/*<Chip size={'small'} color={'primary'} label={userTypeText[bookReportComment.user?.userType]}/>*/}
            </Typography>
            <Typography sx={{color: 'gray'}}>
              {dayjs(bookReportComment?.createdAt).format("YYYY.MM.DD(ddd) HH:mm")}
            </Typography>
          </Box>
        </Box>
        <div className={'mt-2 p-6 bg-gray-100 max-w-[70%] rounded-tr-2xl rounded-b-2xl'}>
          {bookReportComment?.content}
        </div>
        <Box>
          {/* 대댓글이 없는 댓글만 대댓글을 달 수 있음*/}

          {
            !bookReportComment?.bookReportCommentId && (
              <Button
                onClick={() => setShowCoCommentTextField(!showCoCommentTextField)}
                sx={{mt: 1}}
                size={'small'}
              >
                {!showCoCommentTextField ? '대댓글' : '닫기'}
              </Button>
            )
          }
          {/*내 것인 경우 삭제*/}
          {
            isMine && (
              <Button
                onClick={_deleteBookReportComment}
                sx={{mt: 1}}
                size={'small'}
                color={'error'}
              >
                삭제
              </Button>
            )
          }
        </Box>
        {
          (!bookReportComment?.bookReportCommentId && showCoCommentTextField) && (
            <Box sx={{
              ml: 6,
              mt: 2,
              mb: 5,
              maxWidth: '70%',
            }}>
              <TextField
                fullWidth
                label="댓글을 달아보세요."
                multiline
                rows={4}
                value={newComment || ''}
                onChange={(e) => {
                  if (e.target.value?.length > 1000) return;
                  setNewComment(e.target.value);
                }}
                placeholder={'과목 담당선생님과 독후감을 받아보시는 선생님은 댓글을 확인할 수 있습니다.'}
              />
              <Typography sx={{mt: 1, mb: 2}}>
                {newComment?.length > 0 ? `${newComment.length}/` : ''}1000자 이내
              </Typography>
              <LoadingButton
                onClick={_uploadBookReportComments}
                loading={uploadingComment}
                disabled={newComment?.trim()?.length < 1 || uploadingComment}
                variant={'contained'}
              >
                의견 남기기
              </LoadingButton>
              <Button onClick={() => {
                setNewComment('');
                setShowCoCommentTextField(false);
              }}>
                취소
              </Button>
            </Box>
          )
        }

      </Box>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BookReportComment);
