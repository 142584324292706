import firebaseApp from "../config/firebase.config";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // uploadBytes
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";

export default async function StorageUpload(files, _ref) {
  const result = [];
  await Promise.all(
    files.map(async (value) => {
      const url = await UploadOneFile(value, _ref);
      result.push({ url });
    }),
  );
  return result;
}

export function UploadOneFile(file, _ref = "image") {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
  const storage = getStorage(firebaseApp);
  const fileExtensionStartAt = file.name.lastIndexOf(".") + 1;
  const storageRef = ref(storage, `${_ref}/${uuidv4()}.${file.name.slice(fileExtensionStartAt, file.name.length)}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const _progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // setProgress(_progress);
        console.log("Upload is " + _progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        //   reject();
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          resolve(downloadURL);
        });
      },
    );
  });
}
