import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash';
import QuestionFormComponent from '../../components/teacher/QuestionFormComponent';
import Network from '../../lib/Network';
import LoadingSpinner from '../../components/shared/LoadingSpinner';

import { PATH_TEACHER } from '../../routes/paths';
import { scoreTypeObj } from '../../constants/scoreTypes';

import classNames from '../../assi-design-system-react/utils/classNames'
import Loader from '../../assi-design-system-react/components/Loader';

import storageUpload from '../../utils/firebaseStorageUpload';
import BookFinder from '../../sections/BookFinder';

import ResponsiveDatePicker from '../../components/shared/ResponsiveDatePicker';

const TeacherTaskCreateOrEdit = (props) => {
  const { courseId, taskId } = useParams();

  const isNew = !taskId;

  const navigate = useNavigate();
  const { state } = useLocation();

  const [messages, setMessages] = useState({ title: '', questions: '', startDate: '', endDate: '' });

  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState({});

  const [isUploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [questionOnFocus, setQuestionOnFocus] = useState('');

  const [isPublishConfirmed, setIsPublishConfirmed] = useState(false);

  const [openObj, setOpenObj] = useState({
    complete: false,
    cancel: false,
    confirmSaveOnly: false,
    confirmPublish: false,
    bookFinder: false,
  });

  const _handleOpenOpenObj = (key) => setOpenObj((prev) => ({ ...prev, [key]: true }));
  const _handleCloseOpenObj = (key) => setOpenObj((prev) => ({ ...prev, [key]: false }));

  const [task, setTask] = useState({
    title: '',
    description: '',
    startDate: null,
    endDate: null,
    isPublished: false,
    bookIsbn13s: [],
    books: [],
    fileUrls: [],
    permitEditAfterSubmit: false,
    scoreType: 'SCORE',
  });
  const [taskQuestions, setTaskQuestions] = useState([]);

  const taskQuestionsRef = useRef();
  const fileUploadRef = useRef();
  const questionFormRef = useRef();

  const taskQuestionsValid = taskQuestions?.length > 0 && !!taskQuestions[0].title && !!taskQuestions[0].questionType;
  const questionValid = (taskQuestions?.map((question) => Object.values({ title: question.title.trim(), questionType: question.questionType }).map((value) => value)) || [])
    .flat()
    .every((value) => !!value);

  const basicInfoValid = !!task.title.trim() && !!task.startDate && !!task.endDate;
  const requiredValid = basicInfoValid && taskQuestionsValid && questionValid;

  const _handleChange = debounce((key, value) => setTask((prev) => ({ ...prev, [key]: value })), 300);

  const _onChangeQuestion = ({ questionId, key, value }) => {
    setTaskQuestions((prev) =>
      prev.map((o) => {
        if (o.id === questionId) {
          return { ...o, [key]: value };
        }
        return o;
      })
    );
  };

  const _onForwardQuestion = (question) => {
    const idx = taskQuestions.findIndex((o) => o.id === question.id);
    if (idx === -1) return;
    const questionsReplaced = taskQuestions.filter((o) => o.id !== question.id);
    questionsReplaced.splice(idx - 1, 0, question);
    setTaskQuestions(questionsReplaced);
  };

  const _onBackwardQuestion = (question) => {
    const idx = taskQuestions.findIndex((o) => o.id === question.id);
    if (idx === -1) return;
    const questionsReplaced = taskQuestions.filter((o) => o.id !== question.id);
    questionsReplaced.splice(idx + 1, 0, question);
    setTaskQuestions(questionsReplaced);
  };

  const _onDuplicateQuestion = (question = { id: undefined, questionType: undefined, title: '', description: '' }) => {
    const idx = taskQuestions.findIndex((q) => q.id === question.id);
    console.log(idx, question);
    if (idx === -1) {
      setTaskQuestions((prev) => [...prev, { ...question, id: uuidv4() }]);
    } else {
      const replacedTaskQuestions = [...taskQuestions];
      replacedTaskQuestions.splice(idx + 1, 0, { ...question, id: uuidv4() });
      setTaskQuestions(replacedTaskQuestions);
    }
  };

  const _onDeleteQuestion = (questionId) => {
    setTaskQuestions((prev) => prev.filter((o) => o.id !== questionId));
  };

  const _onAddMultipleQuestions = (questionId, questions = []) => {
    const idx = taskQuestions.findIndex((o) => o.id === questionId);
    if (idx === -1) return;
    const _taskQuestions = [...taskQuestions];
    _taskQuestions.splice(idx + 1, 0, ...questions);
    setTaskQuestions(_taskQuestions);
  };

  const _onCompleteSelectBooks = (selectedBooks = []) => {
    setTask((prev) => ({ ...prev, books: [...prev.books, ...selectedBooks] }));
  };

  const _uploadFileUrls = async (_files) => {
    setUploading(true);
    const files = Object.values(_files);
    try {
      const urls = await storageUpload(files, `school/${props.authReducer.user.schoolId}/user/${props.authReducer.user.id}/task`);
      if (urls) {
        setTask((prev) => ({ ...prev, fileUrls: [...prev.fileUrls, urls] }));
      }
    } catch (error) {
      console.log(error);
    }
    setUploading(false);
  };

  const _createTask = async (step) => {
    try {
      setTask((prev) => ({ ...prev, isPublished: step === 'PUBLISH' }));
      const result = await Network.taskPOST({
        courseId,
        title: task.title.trim(),
        description: task.description.trim(),
        startDate: task.startDate,
        endDate: task.endDate,
        permitEditAfterSubmit: task.permitEditAfterSubmit,
        isPublished: step === 'PUBLISH',
        bookIsbn13s: task.books?.map((o) => o.isbn13),
        fileUrls: task.fileUrls,
        scoreType: task.scoreType,
        questions:
          taskQuestions?.map((q) => ({
            title: q.title,
            description: q.description,
            questionType: q.questionType,
          })) || [],
      });
      setFetching(false);
      _handleOpenOpenObj('complete');
      setTimeout(() => navigate(PATH_TEACHER.task.detail.root(result.task?.id), { replace: true }), 1000);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  const _modifyTask = async (step) => {
    try {
      setTask((prev) => ({ ...prev, isPublished: task.isPublished ? true : step === 'PUBLISH' }));

      const uuidv4Reges = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');

      await Network.taskPUT({
        taskId,
        courseId: task.courseId,
        title: task.title.trim(),
        description: task.description.trim(),
        startDate: task.startDate,
        permitEditAfterSubmit: task.permitEditAfterSubmit,
        scoreType: task.scoreType,
        endDate: task.endDate,
        isPublished: task.isPublished ? true : step === 'PUBLISH',
        bookIsbn13s: task.books?.map((o) => o.isbn13),
        fileUrls: task.fileUrls,
        questions:
          taskQuestions?.map((q) => {
            if (uuidv4Reges.test(q?.id)) {
              return {
                title: q.title,
                description: q.description,
                questionType: q.questionType,
              };
            } else
              return {
                id: q.id,
                title: q.title,
                description: q.description,
                questionType: q.questionType,
              };
          }) || [],
      });

      setFetching(false);
      _handleOpenOpenObj('complete');
      setTimeout(() => navigate(PATH_TEACHER.task.detail.root(taskId), { replace: true }), 1500);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
  };

  useEffect(() => {
    // setIsLoaded(false);
    dayjs.extend(utc);
    if (!taskId) {
      const _loadCourse = async () => {
        if (courseId) {
          try {
            const result = await Network.courseGET({ courseId });
            setCourse(result.course);
            setIsLoaded(true);
          } catch (error) {
            window.alert(error.cause.errorMessages);
          }
        }
      };
      _loadCourse();
    } else {
      const _loadTask = async () => {
        try {
          const result = await Network.taskGET({ taskId });
          setTask({
            ...result.task,
            startDate: dayjs(result.task.startDate).toISOString(),
            endDate: dayjs(result.task.endDate).toISOString(),
            books: result.task.books || [],
            bookIsbn13s: result.task.bookIsbn13s || [],
          });
          setTaskQuestions(result.task?.questions?.map((o) => ({ ...o, isUploaded: true })) || []);
          if (result.task.courseId) {
            const { course } = await Network.courseGET({ courseId: result.task?.courseId });
            setCourse(course);
          }
          setIsLoaded(true);
        } catch (error) {
          window.alert(error.cause?.errorMessage || error);
        }
      };
      _loadTask();
    }
    if (state) {
      // console.log(state);
      setTaskQuestions(state.questions?.map((q) => ({ ...q, id: uuidv4() })));
    }
  }, [courseId, taskId]);

  useEffect(() => {
    if (!isLoaded) return;
    if (taskQuestionsValid) {
      taskQuestionsRef.current.classList.add('bg-opacity-0', 'ring-transparent');
      taskQuestionsRef.current.classList.remove('msg-400-div');
      setMessages({ ...messages, questions: '' });
    }
  }, [isLoaded, taskQuestionsValid, task.startDate, task.endDate]);

  if (!isLoaded) return <Loader />;

  const _renderFileUrls = (fileUrl, idx) => (
    <div
      key={fileUrl.url}
      className='flex space-x-2 items-center justify-between bg-gray-50 dark:bg-gray-750 rounded-xl p-2 bind-text-gray-600'
    >
      <p className='badge'>{fileUrl.fileType}</p>
      <p className='line-clamp-1 whitespace-pre-line text-ellipsis overflow-hidden w-full'>{fileUrl.fileName}</p>
      <div className='grid grid-cols-2 gap-2 mr-4 flex-shrink-0'>
        {!!idx && (
          <IconButton
            onClick={() => {
              if (isUploading) return;
              const fileUrls = task.fileUrls.filter(({ url }) => url !== fileUrl.url);
              fileUrls.splice(idx - 1, 0, fileUrl);
              setTask((prev) => ({ ...prev, fileUrls }));
            }}
            disabled={isUploading}
            className={classNames(idx === 0 ? 'invisible' : '', 'col-start-1 w-fit h-fit focus:ring-2 ring-primary-500')}
          >
            <KeyboardArrowUp />
          </IconButton>
        )}

        {idx !== task.fileUrls.length - 1 && (
          <IconButton
            onClick={() => {
              if (isUploading) return;
              const fileUrls = task.fileUrls.filter(({ url }) => url !== fileUrl.url);
              fileUrls.splice(idx + 1, 0, fileUrl);
              setTask((prev) => ({ ...prev, fileUrls }));
            }}
            disabled={isUploading}
            className={classNames(idx === task.books?.length - 1 ? 'invisible' : '', 'col-start-2 w-fit h-fit focus:ring-2 ring-primary-500')}
          >
            <KeyboardArrowDown />
          </IconButton>
        )}
      </div>
      <Button
        color='error'
        onClick={() => setTask((prev) => ({ ...prev, fileUrls: prev.fileUrls.filter(({ url }) => url !== fileUrl.url) }))}
      >
        삭제
      </Button>
    </div>
  );

  const _renderBook = (book, idx) => (
    <div
      key={book.id}
      className='group flex items-center space-x-4 w-full h-32'
    >
      <img
        alt={book.title}
        src={book.coverImageUrl}
        className='w-20 object-contain object-center'
      />

      <div className='flex-1 flex flex-col h-full'>
        <p className='flex-1 line-clamp-2 text-ellipsis md:group-hover:underline'>{book.title}</p>
        <p className='line-clamp-1 text-ellipsis text-sm'>
          <span className='bind-text-gray-400 font-bold'>작가</span> {book.authors?.replace(/\^/gi, ', ') || ''}
        </p>
        <p className='line-clamp-1 text-ellipsis text-sm'>
          <b className='bind-text-gray-400 font-bold'>출판사</b> {book.publisher}
        </p>
      </div>

      <div className='grid grid-cols-2 gap-1'>
        <IconButton
          onClick={() => {
            const booksReplaced = task.books?.filter((o) => o.id !== book.id);
            booksReplaced.splice(idx - 1, 0, book);
            setTask((prev) => ({ ...prev, books: booksReplaced }));
          }}
          className={classNames(idx === 0 ? 'invisible' : '', 'col-start-1 w-fit h-fit focus:ring-2 ring-primary-500')}
        >
          <KeyboardArrowUp />
        </IconButton>
        <IconButton
          onClick={() => {
            const booksReplaced = task.books?.filter((o) => o.id !== book.id);
            booksReplaced.splice(idx + 1, 0, book);
            setTask((prev) => ({ ...prev, books: booksReplaced }));
          }}
          className={classNames(idx === task.books?.length - 1 ? 'invisible' : '', 'col-start-2 w-fit h-fit focus:ring-2 ring-primary-500')}
        >
          <KeyboardArrowDown />
        </IconButton>
      </div>

      <Button
        size='small'
        variant='contained'
        color='error'
        onClick={() => setTask((prev) => ({ ...prev, books: prev.books.filter((o) => o.id !== book.id) }))}
      >
        삭제
      </Button>
    </div>
  );

  let taskTypeChipLabel = `상태: `;
  if (isNew) {
    taskTypeChipLabel += '신규 과제';
  } else if (task.isPublished) {
    taskTypeChipLabel += '공개된 과제';
  } else {
    taskTypeChipLabel += '임시저장';
  }

  return (
    <Container maxWidth='xl'>
      <div className='relative flex flex-col max-w-4xl mx-auto space-y-8 md:space-y-12'>
        <div className=''>
          <Chip
            variant='outlined'
            label={taskTypeChipLabel}
            sx={{ mb: 1 }}
          />
          <p className='text-xl font-bold'>과제 {isNew ? '만들기' : '수정'}</p>
          <p className='bind-text-gray-600 text-sm mt-4'>과제를 만들면 참가하고 있는 학생들에게 푸시 메시지로 알려줍니다.</p>
        </div>

        <div>
          <p className='text-lg font-bold mb-4'>과제 제목 *</p>
          <TextField
            placeholder='과제 제목 입력'
            fullWidth
            defaultValue={task.title}
            onChange={(e) => _handleChange('title', e.target.value)}
            helperText={messages.title}
            error={!!messages?.title}
            inputProps={{ maxLength: 50 }}
          />
        </div>

        <div>
          <div className='text-lg font-bold mb-2'>간략한 설명</div>
          <div className='text-sm opacity-60 mb-4'>과제에 대한 간략한 소개를 작성해주세요.</div>
          <TextField
            placeholder='과제 설명 입력'
            fullWidth
            multiline
            rows={3}
            defaultValue={task.description}
            onChange={(e) => _handleChange('description', e.target.value)}
            inputProps={{ maxLength: 2000 }}
          />
        </div>
        <div>
          <div className='text-lg font-bold mb-2'>점수 평가 유무</div>
          <div className='text-sm opacity-60 whitespace-pre-line mb-4'>
            평가 있음: 평가 되지 않은 독후감에 대해 주기적으로 알림을 보내드려요.{'\n'}
            평가 없음: 최초 제출시에만 앱으로 알림이 제공됩니다. (추후)
          </div>
          <TextField
            fullWidth
            label='점수 평가 유무'
            value={task.scoreType || ''}
            sx={{ maxWidth: { sm: 'full', md: 200 } }}
            name='scoreType'
            onChange={(e) => {
              setTask((prev) => ({ ...prev, scoreType: e.target.value }));
            }}
            select
          >
            {Object.values(scoreTypeObj).map((item) => (
              <MenuItem
                value={item.type}
                key={item.type}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div>
          <div className='text-lg font-bold mb-2'>제출 후 수정가능</div>
          <div className='text-sm opacity-60 whitespace-pre-line mb-4'>학생이 독후감을 제출한 후에도 내용을 수정할 수 있습니다.</div>
          <TextField
            fullWidth
            label='점수 평가 유무'
            value={task.permitEditAfterSubmit}
            sx={{ maxWidth: { sm: 'full', md: 200 } }}
            name='permitEditAfterSubmit'
            onChange={(e) => setTask((prev) => ({ ...prev, permitEditAfterSubmit: e.target.value }))}
            select
          >
            {[
              { value: false, name: '수정불가' },
              { value: true, name: '수정가능' },
            ].map((item) => (
              <MenuItem
                value={item.value}
                key={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div>
          <div className='text-lg font-bold mb-2'>과제 제출 기간 *</div>
          <div className='text-sm opacity-60 whitespace-pre-line mb-4'>
            시작일과 종료일은 해당 수업/활동 진행 기간내에서 설정 가능합니다.
            <p>
              수업/활동 진행 기간: {dayjs(course.startDate).format('YYYY.MM.DD(ddd)')} - {dayjs(course.endDate).format('YYYY.MM.DD(ddd)')}
            </p>
          </div>
          <div className='space-y-3'>
            <div className='flex items-end gap-3 sm:text-sm'>
              <ResponsiveDatePicker
                label='시작일'
                value={task.startDate}
                selectStart
                openTo='month'
                startDate={task.startDate}
                endDate={task.endDate}
                minDate={dayjs(course.startDate).toISOString()}
                maxDate={task.endDate ? task.endDate : dayjs(course.endDate).startOf('day').toISOString()}
                onChange={(date) => setTask((prev) => ({ ...prev, startDate: !date ? '' : dayjs(date).startOf('day').toISOString() }))}
              />
              <ResponsiveDatePicker
                label='종료일'
                value={task.endDate}
                selectEnd
                openTo='month'
                startDate={task.startDate}
                endDate={task.endDate}
                minDate={task.startDate ? task.startDate : dayjs(course.startDate).toISOString()}
                maxDate={dayjs(course.endDate).toISOString()}
                onChange={(date) => setTask((prev) => ({ ...prev, endDate: !date ? '' : dayjs(date).endOf('day').toISOString() }))}
              />
            </div>
          </div>
        </div>

        <div>
          <div className='text-lg font-bold mb-2'>첨부 파일</div>
          <div className='text-sm opacity-60 mb-4'>
            과제 설명을 위한 파일 첨부가 필요한 경우 파일을 첨부해 주세요.
            <br />
            (PDF 형식 권장)
          </div>

          <div className='space-y-1'>
            {task.fileUrls?.map(_renderFileUrls)}
            {isUploading ? (
              <div className='group w-full rounded-xl h-24 relative border border-gray-300 dark:border-gray-600 border-dashed overflow-hidden hover:border-solid hover:bg-gray-50 dark:hover:bg-gray-750 cursor-wait'>
                <LoadingSpinner />
              </div>
            ) : (
              <label
                onDragOver={(e) => {
                  if (isUploading) return;
                  e.stopPropagation();
                  e.preventDefault();
                  e.dataTransfer.effectAllowed = 'copy';
                  e.dataTransfer.dropEffect = 'copy';
                }}
                onDrop={(e) => {
                  if (isUploading) return;
                  e.preventDefault();
                  _uploadFileUrls(e.dataTransfer.files);
                }}
                htmlFor='file-upload'
                className='group w-full rounded-xl flex flex-col items-center justify-center h-24 pt-1 border border-gray-300 dark:border-gray-600 border-dashed overflow-hidden hover:border-solid hover:bg-gray-50 dark:hover:bg-gray-750 cursor-pointer'
              >
                <Button
                  variant='contained'
                  onClick={() => !isUploading && fileUploadRef?.current?.click()}
                >
                  파일 선택
                </Button>
                <p className='text-xs text-gray-500 mt-1'>클릭 또는 드래그앤드랍</p>
                <input
                  id='file-upload'
                  name='file-upload'
                  type='file'
                  className='sr-only'
                  multiple
                  ref={fileUploadRef}
                  onChange={(e) => {
                    if (isUploading) return;
                    _uploadFileUrls(e.target.files);
                    e.target.value = '';
                  }}
                />
              </label>
            )}
          </div>
        </div>

        <div>
          <div
            htmlFor='requiredSelect'
            className='text-lg font-bold mb-2'
          >
            도서 지정하기
          </div>
          <div className='text-sm opacity-60 mb-4'>과제에 추천 도서가 있는 경우 선택합니다.</div>

          <div className='space-y-2'>
            <div className={classNames(task.books?.length > 0 ? 'py-5 space-y-5' : 'hidden')}>{task.books?.map(_renderBook)}</div>

            <Button
              variant='contained'
              onClick={() => _handleOpenOpenObj('bookFinder')}
            >
              도서 추가
            </Button>
          </div>
        </div>

        <div className='mt-4'>
          <h2>문항</h2>
          <div className='text-sm opacity-60 whitespace-pre-line'>
            과제가 공개된 이후에는 문항을 추가하거나 삭제할 수 없습니다.{'\n'}
            과제 공개 전까지만 문항 추가/삭제가 가능합니다.
          </div>
        </div>
        <div>
          <div
            className='space-y-4 mb-6 msg-div'
            ref={taskQuestionsRef}
          >
            {taskQuestions?.map((question, idx) => (
              <QuestionFormComponent
                ref={questionFormRef}
                key={question?.id}
                order={idx}
                question={question}
                onChange={_onChangeQuestion}
                onForward={_onForwardQuestion}
                onBackward={_onBackwardQuestion}
                onDuplicate={_onDuplicateQuestion}
                onDelete={_onDeleteQuestion}
                onAddMultiple={_onAddMultipleQuestions}
                total={taskQuestions.length}
                disabledChange={task.isPublished}
                teacherUserId={props.authReducer.user.id}
                useQuestionOnFocus={[questionOnFocus, setQuestionOnFocus]}
              />
            ))}
          </div>

          <Button
            variant='contained'
            disabled={task.isPublished}
            onClick={() => !task.isPublished && _onDuplicateQuestion()}
          >
            문항 추가
          </Button>
        </div>

        <div className='flex justify-between'>
          <Button
            onClick={() => _handleOpenOpenObj('cancel')}
            className='row-start-2 sm:row-start-1 sm:col-span-1'
          >
            취소
          </Button>
          <div className='flex space-x-2'>
            {!task.isPublished && (
              <Button
                disabled={!requiredValid || task.isPublished}
                onClick={() => _handleOpenOpenObj('confirmSaveOnly')}
                className='col-start-3'
              >
                저장
              </Button>
            )}
            <Button
              variant='contained'
              disabled={!requiredValid}
              onClick={() => _handleOpenOpenObj('confirmPublish')}
            >
              {isNew ? '과제 만들기 (공개)' : '공개 저장'}
            </Button>
          </div>
        </div>
      </div>

      <BookFinder
        open={openObj.bookFinder}
        onClose={() => _handleCloseOpenObj('bookFinder')}
        onCompleteSelect={_onCompleteSelectBooks}
        onAddMultiple={_onAddMultipleQuestions}
        includedBookIds={task.books?.map((o) => o.id)}
      />

      <Dialog
        open={openObj.cancel}
        onClose={() => _handleCloseOpenObj('cancel')}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>{isNew ? '작성 취소' : '수정 취소'}</DialogTitle>
        <DialogContent>
          <div>
            작성을 취소할까요?
            <br />
            <span className='footnote'>작성된 데이터는 저장되지 않습니다.</span>
          </div>
        </DialogContent>
        <DialogActions
          sx={{ p: 3, pt: 0 }}
          className='grid grid-cols-3'
        >
          <Button
            onClick={() => _handleCloseOpenObj('cancel')}
            className='col-start-1'
          >
            취소
          </Button>
          <Button
            variant='outlined'
            className='col-start-2 col-span-2'
            color='error'
            onClick={() => {
              _handleCloseOpenObj('cancel');
              if (isNew) {
                navigate(PATH_TEACHER.course.detail.root(courseId), { replace: true });
              } else {
                navigate(PATH_TEACHER.task.detail.root(taskId), { replace: true });
              }
            }}
          >
            {isNew ? '작성 취소' : '수정 취소'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openObj.confirmSaveOnly}
        onClose={() => _handleCloseOpenObj('confirmSaveOnly')}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>임시 저장</DialogTitle>
        <DialogContent>
          {openObj.complete ? (
            <div>{isNew ? '임시 저장 완료' : '수정 및 임시저장 완료'}</div>
          ) : (
            <div className='relative'>
              <div className={classNames(fetching ? 'fixed inset-0 z-10 bg-gray-50 dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 backdrop-filter backdrop-blur-sm' : 'hidden')}>
                <LoadingSpinner />
              </div>
              {isNew ? '새로운 과제를 임시 저장합니다.' : '저장된 과제를 수정합니다.'}
              <br />
              <span className='footnote'>이 과제는 공개되지 않습니다.</span>
            </div>
          )}
        </DialogContent>
        {!openObj.complete && (
          <DialogActions
            sx={{ p: 3, pt: 0 }}
            className='grid grid-cols-3'
          >
            <Button
              fullWidth
              onClick={() => _handleCloseOpenObj('confirmSaveOnly')}
              className='col-start-1'
            >
              취소
            </Button>
            <Button
              variant='contained'
              className='col-start-2 col-span-2'
              onClick={() => {
                if (isNew) {
                  _createTask();
                } else {
                  _modifyTask();
                }
                setFetching(true);
              }}
            >
              임시 저장
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        open={openObj.confirmPublish}
        onClose={() => _handleCloseOpenObj('confirmPublish')}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ p: 3 }}>과제 공개</DialogTitle>
        <DialogContent>
          {openObj.complete ? (
            <div>공개 저장 완료</div>
          ) : (
            <div className='relative'>
              <div className={classNames(fetching ? 'fixed inset-0 z-10 bg-gray-50 dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 backdrop-filter backdrop-blur-sm' : 'hidden')}>
                <LoadingSpinner />
              </div>
              {!isNew ? <>{task.isPublished ? '공개된 과제를 공개 및 저장합니다.' : '임시 저장된 과제를 공개 및 저장합니다.'}</> : '새로운 과제를 공개 저장합니다.'}
              <br />
              {isNew && <span className='footnote'>과제가 공개되면 학생들에게 푸시 알림이 갑니다.</span>}
              <TextField
                sx={{ mt: 1 }}
                fullWidth
                placeholder={task.isPublished ? "'저장'을 입력해주세요." : "'공개하기'를 입력해주세요."}
                helperText={task.isPublished ? "'저장'을 입력하면 저장 버튼이 활성화됩니다." : "'공개하기'를 입력하면 공개 버튼이 활성화됩니다."}
                defaultValue=''
                onChange={(e) => {
                  if (task.isPublished) {
                    if (e.target.value === '저장') {
                      setIsPublishConfirmed(true);
                    } else {
                      setIsPublishConfirmed(false);
                    }
                  } else {
                    if (e.target.value === '공개하기') {
                      setIsPublishConfirmed(true);
                    } else {
                      setIsPublishConfirmed(false);
                    }
                  }
                }}
              />
            </div>
          )}
        </DialogContent>
        {!openObj.complete && (
          <DialogActions
            sx={{ p: 3, pt: 0 }}
            className='grid grid-cols-3'
          >
            <Button
              onClick={() => _handleCloseOpenObj('confirmPublish')}
              className='col-start-1'
            >
              취소
            </Button>
            <Button
              variant='contained'
              color={isPublishConfirmed ? 'success' : 'primary'}
              disabled={!isPublishConfirmed}
              className='col-start-2 col-span-2'
              onClick={() => {
                if (isNew) {
                  _createTask('PUBLISH');
                } else {
                  _modifyTask('PUBLISH');
                }
                setFetching(true);
              }}
            >
              {task.isPublished ? '저장' : '공개 완료'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Container>
  );
};;

const enhance = connect((state) => ({ ...state }), );

export default enhance(TeacherTaskCreateOrEdit);
