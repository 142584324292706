import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { authActionCreators } from "../../redux/auth/auth.store";

const NotFound = (props) => {
  let location = useLocation();
  const {state} = location
  const navigate = useNavigate();
  const _navigatePrevious = () => navigate(-1);
  const _navigateToHome = () => navigate("/");

  if (!props.authReducer?.isLoggedIn) {
    return <Navigate to={'/login'} state={{from: location}}/>
  }

  return (
    <div className="min-h-full max-h-full h-full pt-16 pb-12 flex flex-col my-auto">
      <main className="flex-grow flex flex-col justify-center max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <p className="font-semibold text-primary-600 dark:text-primary-500 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold bind-text-gray-900 sm:text-5xl mx-auto">페이지를 찾을 수 없습니다.</h1>
            {state?.errorMessage && <p className="mt-2 text-base text-gray-500">{state?.errorMessage}</p>}
            <p className="mt-2 text-base text-gray-500">죄송합니다. 해당 페이지를 찾을 수 없습니다. 이 문제가 계속된다면 고객센터로 연락주세요.</p>
            <div className="mt-6 flex justify-center">
              <div onClick={_navigatePrevious} className="cursor-pointer px-2 text-base font-medium text-primary-600 dark:text-primary-500 hover:text-primary-500">
                이전으로
              </div>
              <div onClick={_navigateToHome} className="cursor-pointer px-2 text-base font-medium text-primary-600 dark:text-primary-500 hover:text-primary-500">
                홈으로
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex flex-col justify-center items-center">
          <a href="#" className="font-medium text-gray-500 hover:text-gray-600">
            고객센터 000-0000-0000 (오전 10:00 ~ 오후 4:00)
          </a>
        </nav>
      </footer>
    </div>
  );
};


const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
  },
);

export default enhance(NotFound);
