import React, { Component } from "react";

class ErrorBoundary extends Component {
  state = { hasError: false, error: {}, componentStack: "" };

  componentDidCatch(error, { componentStack }) {
    console.dir({ error, componentStack });
    this.setState({ hasError: true, error: error, componentStack: componentStack });
  }

  render() {
    if (this.state.hasError) {
      const _componentStackSlitted = this.state.componentStack?.split("\n    at ") || [];

      // TODO : 이 오류를 로그를 남기게 해야 하는가?
      return (
        <div className="min-h-full max-h-full h-full pt-16 pb-12 flex flex-col my-auto">
          <main className="flex-grow flex flex-col justify-center max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="font-semibold text-primary-600 dark:text-primary-500 uppercase tracking-wide">{this.state?.error?.name}</p>
                <h1 className="mt-2 text-4xl font-extrabold bind-text-gray-900 sm:text-5xl mx-auto">오류가 발생했습니다.</h1>
                <p className="mt-2 text-gray-500">죄송합니다. 해당 페이지를 찾을 수 없습니다. 이 문제가 계속된다면 고객센터로 연락주세요.</p>

                <p className="box-p-auto p-1 text-left mt-10 mx-auto text-sm bind-text-gray-500 font-semibold">{this.state?.error?.message}</p>
                <div className="box-p-auto p-1 text-left mb-10 mx-auto text-sm bind-text-gray-500 font-semibold">
                  {_componentStackSlitted.map((stack, idx) => {
                    if (!!stack) return <p key={idx}>{stack}</p>;
                  })}
                </div>

                <div className="mt-6 flex justify-center">
                  <a href="" className="cursor-pointer px-2 font-medium text-primary-600 dark:text-primary-500 hover:text-primary-500">
                    <span aria-hidden="true"> &larr;</span>이전으로
                  </a>
                  <a href="/" className="cursor-pointer px-2 font-medium text-primary-600 dark:text-primary-500 hover:text-primary-500">
                    홈으로<span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <footer className="flex-shrink-0 max-w-5xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="flex flex-col justify-center items-center">
              <a href="#" className="font-medium text-gray-500 hover:text-gray-600">
                고객센터 000-0000-0000 (오전 10:00 ~ 오후 4:00)
              </a>
            </nav>
          </footer>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
