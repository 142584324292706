import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as Icon from '@mui/icons-material'
import {Container, Typography, Box, Grid, Avatar, Button} from "@mui/material";
import StudentSettingPushNotificationComponent from "../../components/student/StudentSettingPushNotificationComponent";
import StudentSettingPhoneNumberChangeComponent from "../../components/student/StudentSettingPhoneNumberChangeComponent";
import StudentSettingPasswordChangeComponent from "../../components/student/StudentSettingPasswordChangeComponent";
import StudentSettingProfileImageChangeComponent from "../../components/student/StudentSettingProfileImageChangeComponent";

const StudentSettingPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <>
      <Container maxWidth={'md'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Avatar>
                <Icon.Password/>
              </Avatar>
              <Typography sx={{ml: 1}}>
                비밀번호 변경
              </Typography>
            </Box>
            <Box sx={{bgcolor: 'background.paper', boxShadow: 2, p: 2, mt: 2, borderRadius: 2}}>
              <StudentSettingPasswordChangeComponent/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Avatar>
                <Icon.PhoneIphoneOutlined/>
              </Avatar>
              <Typography sx={{ml: 1}}>
                핸드폰 번호
              </Typography>
            </Box>
            <Box sx={{bgcolor: 'background.paper', boxShadow: 2, p: 2, mt: 2, borderRadius: 2}}>
              <StudentSettingPhoneNumberChangeComponent/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
              <Avatar>
                <Icon.FaceOutlined/>
              </Avatar>
              <Typography sx={{ml: 1}}>
                프로필 사진 변경
              </Typography>
            </Box>
            <Box sx={{bgcolor: 'background.paper', boxShadow: 2, p: 2, mt: 2, borderRadius: 2}}>
              <StudentSettingProfileImageChangeComponent/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
              <Avatar>
                <Icon.NotificationsOutlined/>
              </Avatar>
              <Typography sx={{ml: 1}}>
                푸시알림 설정
              </Typography>
            </Box>
            <Box sx={{bgcolor: 'background.paper', boxShadow: 2, p: 2, mt: 2, borderRadius: 2}}>
              <StudentSettingPushNotificationComponent/>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSettingPage);
