import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import MessageBox from '../../components/shared/MessageBox';

const AdminBookDetailPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])


  return (
    <div>
      <MessageBox
        title={'추후 제공될 예정입니다.'}
      />
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(AdminBookDetailPage);
