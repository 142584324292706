import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import numeral from "numeral";
import { Container, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";

import Network from '../../lib/Network';
import Scrollbar from '../../components/NOT_Classified/Scrollbar';

import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import ListEmpty from "../../components/shared/ListEmpty";
import { PATH_TEACHER } from "../../routes/paths";

const YEAR_OPTIONS = [2022, 2021, 2020];


const TeacherClassMain = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const year = parseInt(query.get("year")) || 2022;
  const keyword = query.get("keyword") || "";

  const [searchParams] = useSearchParams();

  const [isLoaded, setLoaded] = useState(false);
  const [classes, setClasses] = useState([]);
  const [meta, setMeta] = useState({total: 0});


  useEffect(() => {
    const _loadClasses = async () => {
      try {
        const result = await Network.classesGET({teacherUserId: props.auth.user.id, year});
        setMeta(result.meta);
        setClasses(result.classes || []);
        setLoaded(true);
      } catch (error) {
        window.alert(error.cause?.errorMessage);
      }
    }
    _loadClasses();
  }, [year, page, perPage, keyword]);

  const _onChangeYear = ({target}) => {
    if (target.value) {
      searchParams.set('year', target.value)
    } else {
      searchParams.delete('year');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'xl'}>
      <div className="space-y-1">
        <p className={'text-xl font-bold'}>
          우리반
        </p>
        <p className={'text-gray-400'}>
          담임을 맡고 있는 학급입니다.
        </p>
      </div>
      <Stack
        spacing={1}
        direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2, alignItems: "center"}}>
        <TextField
          fullWidth
          select
          label="연도"
          value={year}
          onChange={_onChangeYear}
          sx={{maxWidth: {xs: "full", sm: 100}}}
        >
          {YEAR_OPTIONS.map((option) => (
            <MenuItem
              key={option.toString()}
              value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Scrollbar>
        <TableContainer sx={{minWidth: 'max-content'}}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  [
                    {name: "No.", className: "w-1/5"},
                    {name: "학년도", className: "w-1/5"},
                    {name: "학년", className: "w-1/5"},
                    {name: "반", className: "w-1/5"},
                    {name: "학생수", className: "w-1/5"},
                  ].map((head) => (
                    <TableCell key={head.name} className={head.className}>
                      {head.name}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                classes.map((cls, i) => {
                  return (
                    <TableRow
                      onClick={() => navigate(PATH_TEACHER.class.detail.root(cls.id))}
                      key={cls.id} className={'hover:bg-hover cursor-pointer'}>
                      <TableCell>
                        {(page - 1) * perPage + i + 1}
                      </TableCell>
                      <TableCell>
                        {cls.year}
                      </TableCell>
                      <TableCell>
                        {cls.grade}
                      </TableCell>
                      <TableCell className={'clickable-primary'}>
                        {cls.classNo}
                      </TableCell>
                      <TableCell>
                        {numeral(cls.stats?.studentCount).format('0,0')}
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {classes.length === 0 && <ListEmpty/>}
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({ ...state }));
export default enhance(TeacherClassMain);
