exports.generateRandomString = (type = "STRING", length) => {
  let result = "";
  let characters = type === "STRING"
    ? "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789"
    : "0123456789"
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
