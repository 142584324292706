import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, TextField } from "@mui/material";
import NetworkStudent from '../../lib/NetworkStudent';
import { authActionCreators, authenticationActionCreators } from '../../redux/auth/auth.store';

const StudentSettingPhoneNumberChangeComponent = (props) => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(props.authReducer?.user?.phoneNumber || "");
  const [code, setCode] = useState("");
  const [codeTyped, setCodeTyped] = useState("");


  const _requestCode = async () => {
    setCode(null);
    setRequesting(true);
    try {
      const res = await NetworkStudent
        .phoneVerificationPOST({phoneNumber});
      setCode(res.verificationCode);
      alert('문자메시지를 확인해 주세요');

    } catch (e) {
      alert(e.cause?.errorMessage);
      setRequesting(false);
    }
  };

  const _changePhoneNumber = async () => {
    setUploading(true);
    try {
      const res = await NetworkStudent
        .userMePUT({
          phoneNumber: phoneNumber,
        });
      setCode('');
      setRequesting(false);
      setCodeTyped('');
      setDialogOpen(false);
      props.fetchMyInfo(() => {

      });
    } catch (e) {
      alert(e.cause?.errorMessage);
    } finally {
      setUploading(false);
    }
  };


  const _handleOpenDialog = () => {
    setDialogOpen(true);
  }
  const _handleCloseDialog = () => {
    setDialogOpen(false);
  }

  return (
    <>
      <List
        sx={{bgcolor: 'background.paper'}}
        // subheader={<ListSubheader>푸시 설정</ListSubheader>}
      >
        <ListItem>
          <ListItemText
            primary={props.authReducer?.user?.phoneNumber}
          />
          <Button onClick={_handleOpenDialog}>
            변경하기
          </Button>
        </ListItem>
      </List>
      <Dialog
        fullWidth
        open={dialogOpen}>
        <DialogTitle>
          핸드폰 번호 등록/변경
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            핸드폰 번호를 등록/변경합니다.
          </DialogContentText>
        </DialogContent>
        <DialogContent sx={{
          pb: 3,
        }}>
          <TextField
            sx={{pb: 3}}
            fullWidth
            label="핸드폰 번호"
            type="text"
            // defaultValue="Default Value"
            value={phoneNumber}
            onChange={(e) => {
              // setInquiry(prev => ({...prev, answer: e.target.value}))
              let check = /[0-9]{1,10}$/;
              if (!check.test(e.target.value) && e.target.value !== '') return alert('숫자만 입력하세요');
              if (e.target.value?.trim().length > 11) return;
              setPhoneNumber(e.target.value?.trim())
            }}
          />
          {
            (requesting && !!code) ? (
              <TextField
                sx={{}}
                fullWidth
                label="인증번호 입력"
                type="text"
                // defaultValue="Default Value"
                value={codeTyped}
                onChange={(e) => {
                  // setInquiry(prev => ({...prev, answer: e.target.value}))
                  let check = /[0-9]{1,10}$/;
                  if (!check.test(e.target.value) && e.target.value !== '') return alert('숫자만 입력하세요');
                  if (e.target.value?.trim().length > 5) return;
                  setCodeTyped(e.target.value?.trim())
                }}
              />
            ) : (
              <Button
                disabled={requesting}
                onClick={_requestCode}
                variant={'outlined'}>
                인증번호 요청
              </Button>
            )
          }

        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            disabled={uploading || code === '' || code !== codeTyped}
            onClick={_changePhoneNumber}>
            확인
          </Button>
          <Button onClick={_handleCloseDialog}>
            취소
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authActionCreators,
    ...authenticationActionCreators,
  },
);

export default enhance(StudentSettingPhoneNumberChangeComponent);
