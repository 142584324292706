import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
  Button,
  Container,
  Pagination,
  PaginationItem,
  Table,
  Avatar,
  InputLabel,
  Typography,
  TableHead,
  TableBody,
  Box,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  TableContainer,
  TableCell,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link as LinkMui,
} from "@mui/material";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import dayjs from "dayjs";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import {TabContext, TabList, TabPanel} from "@mui/lab";

const AdminCourseDetailPage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const tab = query.get("tab") || "STUDENT";
  const {courseId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [course, setCourse] = useState({});
  const [meta, setMeta] = useState({total: 0});
  const [users, setUsers] = useState([]);
  const [userIsLoaded, setUserIsLoaded] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskIsLoaded, setTaskIsLoaded] = useState(false);

  const _loadCourse = async () => {
    try {
      const res = await Network.courseGET({courseId});
      setCourse(res.course);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }

  };
  const _deleteCourse = async () => {
    if (!window.confirm("삭제하시겠습니까?")) return;

    try {
      setUploading(true);
      await Network.courseDELETE({courseId});
      navigate(`/admin/course`, {replace: true});
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
    setUploading(false);
  };
  const _loadUsers = async () => {
    if (!courseId) return;
    if (tab !== "STUDENT") {
      setUsers([]);
      setUserIsLoaded(false)
      return;
    }

    try {
      const res = await Network.courseUsersGET({
        courseId,
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
      });
      setUsers(res.users);
      setMeta(res.meta);
    } catch (e) {
      window.alert(e.cause.errorMessage);

    }

  };
  const _deleteCourseRegister = async (studentUserId) => {
    const a = window.confirm("수업에서 삭제할까요?");
    if (!a) return;
    try {
      setUploading(true);
      await Network.courseRegisterDELETE({courseId, userId: studentUserId});
    } catch (e) {
      window.alert(e.cause?.errorMessage);

    } finally {
      await _loadUsers();
      setUploading(false);
    }
  };
  const _loadTasks = async () => {
    if (!courseId) return;
    if (tab !== "TASK") return;
    try {
      const res = await Network.tasksGET({
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
        courseId,
      });
      setTasks(res.tasks);
      setMeta(res.meta);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }

  };
  const _deleteTask = async (taskId) => {
    const a = window.confirm("과제를 삭제할까요?");
    if (!a) return;

    try {
      setUploading(true);
      await Network.taskDELETE({taskId});
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    } finally {
      await _loadTasks();
      setUploading(false);
    }


  };

  useEffect(() => {
    _loadCourse();
  }, []);

  useEffect(() => {
    _loadTasks();
    _loadUsers();
  }, [tab]);

  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  };

  const _navigateToAdminTaskPage = (taskId) => {
    navigate(`/admin/task/${taskId}`);
  };
  const _navigateToAdminStudentDetailPage = (userId) => {
    navigate(`/admin/student/${userId}`);
  };

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <div>
      <Container maxWidth={"lg"}>
        <Typography gutterBottom variant={"h5"}>
          {course.title}
        </Typography>
        <Typography>수업 관리</Typography>
      </Container>
      <Container maxWidth={"lg"} sx={{mt: 5}}>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider"}}>
            <TabList onChange={(e, newValue) => _handleChangeTab(newValue)} aria-label="lab API tabs example">
              <Tab label="학생" value="STUDENT"/>
              <Tab label="과제" value="TASK"/>
              <Tab label="기본정보" value="INFO"/>
            </TabList>
          </Box>

          <TabPanel value={"STUDENT"}>
            <Pagination
              sx={{mb: 3, mt: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: "max-content"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>이름</TableCell>
                      <TableCell>학년</TableCell>
                      <TableCell>반</TableCell>
                      <TableCell>번호</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user) => {
                      return (
                        <TableRow key={user.id}>
                          <TableCell>
                            <LinkMui
                              component="button"
                              onClick={() => _navigateToAdminStudentDetailPage(user.id)}
                            >
                              {user.name}
                            </LinkMui>
                          </TableCell>
                          <TableCell>{user.classRegister?.class?.grade}</TableCell>
                          <TableCell>{user.classRegister?.class?.classNo}</TableCell>
                          <TableCell>{user.classRegister?.studentNo}</TableCell>
                          <TableCell>
                            <LinkMui component="button"
                                     className={'text-red-500'}
                                     onClick={() => _deleteCourseRegister(user.id)}
                            >
                              수업에서 삭제
                            </LinkMui>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={"TASK"}>
            <Pagination
              sx={{mb: 3, mt: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: "max-content"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>제목</TableCell>
                      <TableCell>시작일</TableCell>
                      <TableCell>종료일</TableCell>
                      <TableCell>문항수</TableCell>
                      <TableCell>독후감수</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks?.map((task) => {
                      return (
                        <TableRow key={task.id}>
                          <TableCell>
                            <LinkMui component={'button'} onClick={() => _navigateToAdminTaskPage(task.id)}>
                              {task.title}
                            </LinkMui>
                          </TableCell>
                          <TableCell>{dayjs(task.startDate).format("YYYY.MM.DD(ddd) HH:mm")}</TableCell>
                          <TableCell>{dayjs(task.endDate).format("YYYY.MM.DD(ddd) HH:mm")}</TableCell>
                          <TableCell>{task.stat?.questionCount}</TableCell>
                          <TableCell>{task.stat?.bookReportCount}</TableCell>
                          <TableCell>
                            <LinkMui
                              className={'text-red-500'}
                              component={'button'} onClick={() => _deleteTask(task.id)}>
                              삭제
                            </LinkMui>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={"INFO"}>
            <Paper className={"mt-2 max-w-sm"}>
              <List>
                {[
                  /*{
                   title: '이름', content: user.name,
                   secondaryActionTitle: '수정',
                   secondaryAction: () => {
                   },
                   },
                   {
                   title: 'email',
                   content: user.email,
                   secondaryActionTitle: '수정',
                   secondaryAction: () => {
                   },
                   },
                   {
                   title: '전화',
                   content: user.phoneNumber,
                   secondaryActionTitle: '수정',
                   secondaryAction: () => {
                   },
                   },
                   {
                   title: '비밀번호',
                   content: '*****',
                   secondaryAction: () => {
                   },
                   secondaryActionTitle: '초기화',
                   },*/
                ].map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item.title} secondary={item.content}/>
                      {item.secondaryAction && (
                        <ListItemSecondaryAction>
                          <Button onClick={() => {
                          }}>{item.secondaryActionTitle}</Button>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
            <Button variant={"contained"} color={"error"} onClick={_deleteCourse} disabled={uploading}>
              수업 삭제하기
            </Button>
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminCourseDetailPage);
