import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Button, Container, Pagination, PaginationItem, Table, TableCell, TextField, Stack, TableHead, TableBody, TableRow, MenuItem, Paper, TableContainer, Card, Box, Link as LinkMui} from "@mui/material";
import dayjs from "dayjs";
import numeral from "numeral";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {PATH_ADMIN} from "../../routes/paths";

// ----------------------------------------------------------------------

const YEAR_OPTIONS = [2022, 2021, 2020];
const GRADE_OPTIONS = [1, 2, 3];

// ----------------------------------------------------------------------

const AdminClassPage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const year = query.get("year") || dayjs().format("YYYY");
  const grade = query.get("grade") || "";
  const keyword = query.get("keyword") || "";
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [classes, setClasses] = useState([]);
  const [years, setYears] = useState(YEAR_OPTIONS)

  const _load = async () => {
    const res = await Network.classesGET({
      year,
      offset: (page - 1) * LIMIT,
      limit: LIMIT,
      grade: grade || undefined,
    });

    if (!res.err) {
      setClasses(res.classes || []);
      setMeta(res.meta);
      setIsLoaded(true);
      setYears(res.years || YEAR_OPTIONS)
    }
  };


  useEffect(() => {
    // setClasses([]);
    // setMeta({total: 0})
    _load();
  }, [page, year, grade]);

  const _navigateToDetailPage = (classId) => {
    navigate(`/admin/class/${classId}`);
  };
  const _navigateToTeacherDetailPage = (teacherUserId) => {
    navigate(`/admin/teacher/${teacherUserId}`);
  }


  if (!isLoaded) return <LoadingComponent/>;

  return (
    <div>
      <Container maxWidth={"xl"}>
        <div className={'flex items-start'}>
          <div className={'flex-1'}>
            <p className={'text-xl font-bold'}>
              학급 관리
            </p>
            <p className={'text-lg font-light'}>
              총 {numeral(meta.total).format("0,0")}개
            </p>
          </div>
          <Button variant="contained" component={Link} to={PATH_ADMIN.class.create}>
            신규등록
          </Button>
        </div>
      </Container>
      <Container maxWidth={"xl"} sx={{mt: 3}}>
        <Stack spacing={1} direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2}}>
          <TextField
            fullWidth
            select
            label="연도"
            value={year}
            onChange={(e) => navigate(`${location.pathname}?year=${e.target.value}`, {replace: true})}

            sx={{
              maxWidth: {xs: "full", sm: 100},
              textTransform: "capitalize",
            }}
          >
            {years.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="학년"
            value={grade}
            onChange={(e) => navigate(`${location.pathname}?year=${year}&grade=${e.target.value}`, {replace: true})}

            sx={{
              maxWidth: {xs: "100%", sm: 100},
              textTransform: "capitalize",
            }}
          >
            {GRADE_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Box sx={{position: "relative"}} mb={2}>
          <Pagination
            page={page}
            count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
            renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}&year=${year}`} {...item} />}
          />
        </Box>

        <Scrollbar>
          <TableContainer sx={{minWidth: "max-content"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={"center"} variant={"head"}>
                    연도
                  </TableCell>
                  <TableCell align={"center"}>학년</TableCell>
                  <TableCell align={"center"}>반</TableCell>
                  <TableCell align={"center"}>담임</TableCell>
                  <TableCell align={"center"}>학생수</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classes?.map((cls) => {
                  return (
                    <TableRow key={cls.id}>
                      <TableCell align={"center"}>{cls.year}</TableCell>
                      <TableCell align={"center"}>{cls.grade}</TableCell>
                      <TableCell align={"center"}>
                        <LinkMui
                          component="button"
                          onClick={() => _navigateToDetailPage(cls.id)}
                        >
                          {cls.classNo}
                        </LinkMui>
                      </TableCell>
                      <TableCell align={"center"}>
                        <LinkMui
                          disabled={!cls.teacherUser?.id}
                          component="button"
                          onClick={() => _navigateToTeacherDetailPage(cls.teacherUserId)}
                        >
                          {cls.teacherUser?.name || "-"}
                        </LinkMui>

                      </TableCell>
                      <TableCell align={"right"}>{numeral(cls.stats?.studentCount).format("0,0")}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Container>
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminClassPage);
