import { combineReducers } from "redux";
import { authReducer } from "./auth/auth.store";
import { notificationReducer } from "./notification/notification.store";
import { notificationStudentReducer } from "./notification-student/notification-student.store";

const reducers = combineReducers({
  auth: authReducer, // 삭제할 예정
  authReducer: authReducer,
  notificationReducer,
  notificationStudentReducer: notificationStudentReducer,
});

const rootReducer = (state, action) => {
  return reducers(state, action);
};

export default rootReducer;
