import classNames from '../../assi-design-system-react/utils/classNames'
LoadingSpinner.defaultProps = {
  white: false,
};

export default function LoadingSpinner({ white }) {
  return (
    <div className="absolute inset-x-0 -top-2 w-full h-full flex flex-col justify-center items-center bind-text-gray-600 opacity-50">
      <div className={classNames(!!white ? "text-white" : "text-primary-500", "loader")} />
    </div>
  );
}
