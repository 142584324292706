import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box, Button,
  Divider,
  Dialog, DialogTitle, DialogContent, DialogContentText, TextField, InputBase, IconButton, DialogActions, Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell, Avatar,
} from '@mui/material';
import * as Icon from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import NetworkStudent from '../../lib/NetworkStudent';

const StudentBookReportWriteSaveDialogComponent = (props) => {

  return (
    <Dialog
      fullWidth
      open={props.open || false}>
      <DialogTitle>
        저장할까요?
        <DialogContentText>
          저장 후에 제출할 수 있어요. <br/>
          제출 전에는 수정이 가능합니다.
        </DialogContentText>
      </DialogTitle>
      <DialogActions>
        <LoadingButton
          onClick={props.onClose}
          // loading={uploading}
          // disabled={uploading || newTeacherUserCandidatesFromExcel?.length < 1}
          // onClick={_addManyUser}
        >
          취소
        </LoadingButton>
        <LoadingButton
          disabled={props.uploading}
          loading={props.uploading}
          variant={'contained'}
          onClick={props.onSave}
          // disabled={uploading || newTeacherUserCandidatesFromExcel?.length < 1}
          // onClick={_addManyUser}
        >
          저장
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentBookReportWriteSaveDialogComponent);
