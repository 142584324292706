import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import MessageBox from '../../components/shared/MessageBox';
import {Box, Container, Divider, TextField, Typography} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import Network from '../../lib/Network';
import StudentAnswerComponent from '../../components/student/StudentAnswerComponent';
import dayjs from 'dayjs';
import {PATH_ADMIN} from '../../routes/paths';
import BookReportComment from '../../components/teacher/BookReportComment';
import Loader from "../../assi-design-system-react/components/Loader";

const AdminBookReportDetailPage = (props) => {
  const {bookReportId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [bookReport, setBookReport] = useState({});
  const [bookReportComments, setBookReportComments] = useState([]);

  const _load = async () => {
    if (!bookReportId) return;
    try {
      const res = await Network.bookReportGET({bookReportId});

      setBookReport(res.bookReport);
      setIsLoaded(true);
      _loadBookReportComment();
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  };
  const _loadBookReportComment = async () => {
    try {
      const res = await Network.bookReportCommentsGET({
        bookReportId,
        offset: 0,
        limit: 100, // TODO 여기 더 불러오기 형태로 바꿔야 하는데...
      });
      setBookReportComments(res.bookReportComments);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    } finally {
    }
  };

  useEffect(() => {
    _load();
  }, [])


  if (!isLoaded) return <Loader/>;
  const _renderBookPart = () => {
    if (!bookReport?.book?.id) {
      return (
        <Box sx={{mt: 5, mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            선택 도서
          </Typography>
          <Divider sx={{mb: 3, mt: 1}}/>
          <p className={"text-gray-500"}>도서가 선택되지 않았습니다.</p>
        </Box>
      );
    } else {
      return (
        <Box sx={{mt: 5, mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            읽은 책
          </Typography>
          <Divider sx={{mb: 3, mt: 1}}/>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img className={"w-16 object-contain border border-black"} src={bookReport.book?.coverImageUrl}/>
            <Box sx={{pl: 2, flex: 1}}>
              <Typography className={"line-clamp-2"}>{bookReport.book?.title}</Typography>
              <Typography>{bookReport?.book?.authors}</Typography>
              <Typography>
                {bookReport?.book?.publisher} ({bookReport?.book?.pubDate})
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  };
  const _renderAnswerPart = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          문항
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        {bookReport?.answers?.map((item, index) => {
          return <StudentAnswerComponent key={index} answer={item}/>;
        })}
      </Box>
    );
  };
  const _renderTaskAndCourseInfo = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          기본 정보
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        <Box sx={{}}>
          <p className={"text-gray-500 font-bold text-sm"}>제출일</p>
          {!!bookReport.submittedAt ? (
            <Typography>{dayjs(bookReport.submittedAt).format("YYYY.MM.DD(ddd) HH:mm")}</Typography>
          ) : (
            <Typography>아직 독후감이 제출되지 않았습니다.</Typography>
          )}
        </Box>
        <Box sx={{mt: 2}}>
          <p className={"text-gray-500 font-bold text-sm"}>기한</p>
          <Typography>{dayjs(bookReport.task?.endDate).format("YYYY.MM.DD(ddd) HH:mm")}</Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <p className={"text-gray-500 font-bold text-sm"}>과제명</p>
          <Typography>{bookReport.task?.title}</Typography>
        </Box>
        {!!bookReport?.task?.course && (
          <Box sx={{mt: 2}}>
            <p className={"text-gray-500 font-bold text-sm"}>수업명</p>
            <Typography>{bookReport?.task?.course?.title}</Typography>
          </Box>
        )}
        {bookReport?.teacherUser?.id && (
          <Box sx={{mt: 2}}>
            <p className={"text-gray-500 font-bold text-sm"}>제출 대상 선생님</p>
            <Typography className={"text-blue-600 hover:underline"}>
              <Link to={PATH_ADMIN.teacher.detail(bookReport.teacherUserId)}>
                {bookReport?.teacherUser?.name}
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  const _renderReportCommentPart = () => {
    return (
      <Box sx={{mt: 5, mb: 5}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          의견 ({bookReportComments.length})
        </Typography>
        <Divider sx={{mb: 1, mt: 1}}/>
        <MessageBox sx={{mb: 3}} content={"독후감이 제출된 이후에는\n채점을 과목 담당선생님과 독후감을 받아보시는 선생님은 댓글을 작성하거나 확인할 수 있습니다."}/>
        {bookReportComments.map((item, index) => {
          return (
            <Box key={item.id}>
              <BookReportComment loadBookReportComment={_loadBookReportComment} bookReportComment={item}/>
              {item.bookReportComments?.map((comment, i) => {
                return <BookReportComment key={i} loadBookReportComment={_loadBookReportComment} bookReportComment={comment}/>;
              })}
            </Box>
          );
        })}
      </Box>
    );
  };
  const _renderTitle = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"subtitle1"} sx={{fontWeight: "800"}}>
          독후감 제목
        </Typography>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          {bookReport?.title}
        </Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth={'sm'}>
      <Link to={PATH_ADMIN.student.detail(bookReport.userId)}>
        <Box className={'mb-8 bg-gray-50 rounded p-4 hover:bg-primary-50 group cursor-pointer'}>
          <img
            className={"w-[50px] h-[50px] rounded-full"}
            src={bookReport?.user?.profileImageUrl}/>
          <div className={'mt-2'}>
            <p className={'group-hover:underline'}>
              {bookReport.user?.name} ({bookReport.user?.displayName})
            </p>
            {bookReport.user?.classRegister?.class && (
              <p className="bind-text-gray-500">
                {bookReport.user.classRegister.class.grade}학년 {bookReport.user.classRegister.class.classNo}반 {bookReport.user.classRegister.studentNo}번
              </p>
            )}
          </div>

        </Box>
      </Link>
      {_renderTitle()}
      {_renderBookPart()}
      {_renderAnswerPart()}
      {_renderTaskAndCourseInfo()}
      {_renderReportCommentPart()}
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(AdminBookReportDetailPage);
