import { createLogic } from "redux-logic";
import Network from "../../lib/Network";
import { notificationActionCreators, notificationActions } from "./notification.store";

const LIMIT = 50;

const fetchNotificationStudentLogic = createLogic({
  type: notificationActions.FETCH_TEACHER_NOTIFICATION,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    try {
      const res = await Network
        .notificationsGET({offset: 0, limit: LIMIT});
      dispatch(notificationActionCreators.fetchNotificationTeacherSuccess(
        {
          notifications: [...res.notifications],
          endReached: res.notifications?.length < LIMIT,
        }));
    } catch (e) {

    } finally {
      action.cb?.();
      done();
    }

  },
});

const fetchNotificationStudentMoreLogic = createLogic({
  type: notificationActions.FETCH_TEACHER_NOTIFICATION_MORE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const {notifications, endReached} = getState().notificationReducer;
    try {
      if (!endReached) {
        const res = await Network
          .notificationsGET({offset: notifications.length, limit: LIMIT});
        dispatch(notificationActionCreators.fetchNotificationTeacherMoreSuccess(
          {
            notifications: [...res.notifications],
            endReached: res.notifications?.length < LIMIT,
          }));
      } else {
        dispatch(notificationActionCreators.fetchNotificationTeacherMoreSuccess(
          {
            notifications: [],
            endReached: true,
          }));
      }

    } catch (e) {

    } finally {
      action.cb?.();
      done();
    }

  },
});

const loadNotifications = createLogic({
  type: notificationActions.LOAD_NOTIFICATIONS,
  latest: true,

  validate({getState, action}, allow, reject) {
    allow(action);
  },
  async process({getState, action}, dispatch, done) {
    try {
      const result = await Network.notificationsGET({offset: 0, limit: 50, type: action.payload?.type});
      const trueNotifications = result.notifications || [];
      const hasUnreadNotificaion = trueNotifications.map(({read}) => read).includes(false);
      const unreadCount = trueNotifications.filter(({read}) => !read).length;

      dispatch({
        type: notificationActions.LOAD_NOTIFICATIONS_FULFILLED,
        payload: {notifications: trueNotifications?.slice(0, 15), total: result.meta.total, hasUnreadNotificaion, unreadCount},
      });
    } catch (error) {
      dispatch({
        type: notificationActions.FETCH_NOTIFICATIONS_REJECTED,
        error: true,
        errorMessage: error.cause?.errorMessage || error,
        payload: error.cause,
      });
      window.alert(error.cause?.errorMessage);
    } finally {
      done();
    }
  },
});
// 알림을 더 불러오는 작업은 이 함수를 사용할때만 일어난다.
const readAndReloadNotifications = createLogic({
  type: notificationActions.READ_AND_RELOAD_NOTIFICATIONS,
  latest: true,

  async process({getState, action}, dispatch, done) {
    try {
      const read_result = await Network.notificationReadPUT({type: action.payload?.type});
      if (!read_result.err) {
        const result = await Network.notificationsGET({offset: action.payload?.offset || 0, limit: 50, type: action.payload?.type});

        const trueNotifications = result.notifications || [];
        trueNotifications.concat(getState().notificationReducer.notifications);

        const hasUnreadNotificaion = trueNotifications.map(({read}) => read).includes(false);
        const unreadCount = trueNotifications.filter(({read}) => !read).length;

        dispatch({
          type: notificationActions.LOAD_NOTIFICATIONS_FULFILLED,
          payload: {notifications: trueNotifications?.slice(0, 15), total: result.meta.total, hasUnreadNotificaion, unreadCount},
        });
      }
    } catch (error) {
      dispatch({
        type: notificationActions.FETCH_NOTIFICATIONS_REJECTED,
        error: true,
        errorMessage: error.cause?.errorMessage || error,
        payload: error.cause,
      });
      window.alert(error.cause?.errorMessage);
    } finally {
      done();
    }
  },
});

const clickNotification = createLogic({
  type: notificationActions.CLICK_NOTIFICATION,
  latest: true,
  async process({getState, action}, dispatch, done) {
    try {
      const {notificationId} = action.payload;

      const result = await Network.notificationClickPUT({notificationId});
      const changedNotifiactions = getState().notificationReducer.notifications.map((noti) => {
        if (noti.id === notificationId) {
          return {...noti, click: true};
        } else return noti;
      });
      dispatch({
        type: notificationActions.CLICK_NOTIFICATION_FULFILLED,
        payload: {notifications: changedNotifiactions},
      });
    } catch (error) {
      dispatch({
        type: notificationActions.CLICK_NOTIFICATION_REJECTED,
        error: true,
        errorMessage: error.cause?.errorMessage || error,
        payload: error.cause,
      });
      window.alert(error.cause?.errorMessage);
    } finally {
      done();
    }
  },
});

const logics = [
  loadNotifications,
  readAndReloadNotifications,
  clickNotification,
  fetchNotificationStudentLogic,
  fetchNotificationStudentMoreLogic];

export default logics;
