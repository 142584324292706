import {
  Box,
  Container,
  InputLabel,
  MenuItem,
  Paper,
  Slider,
  Select,
  Typography,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import React, { Component, useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { readStatusText, userBookReadStatuses } from "../../constants/dataTypes";
import LoadingComponent from "../../components/shared/LoadingComponent";
import NetworkStudent from "../../lib/NetworkStudent";
import { TramRounded } from "@mui/icons-material";

const StudentBookDetailPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { bookId } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [refreshing, setRefreshing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [book, setBook] = useState(false);
  const [myRecordOn, setMyRecordOn] = useState(false);
  const [userBookState, setUserBookState] = useState({ readStatus: null, rate: null });

  const _load = async () => {
    try {
      const res = await NetworkStudent.bookGET({ bookId });

      setBook(res.book || {});
      setIsLoaded(true);
    } catch (e) {}
  };

  const _updateUserBook = async ({ readStatus, rate, review }) => {
    setUploading(true);
    const userBook = { ...book.userBook };
    if (readStatus) userBook.readStatus = readStatus;
    if (rate) userBook.rate = rate;
    if (review) userBook.review = review;
    setBook((prev) => ({ ...prev, userBook }));

    try {
      const res = await NetworkStudent.userMeBookPUT({
        bookId,
        readStatus,
        rate,
        review,
      });
      setBook((prev) => ({ ...prev, userBook: res.userBook }));
      setMyRecordOn(false);
      // alert("도서가 책장에 저장되었어요.");
    } catch (e) {
    } finally {
      setUploading(false);
    }
  };

  const _deleteUserBook = async () => {
    setUploading(true);

    try {
      const res = await NetworkStudent.userMeBookDELETE({ bookId });
      if (!res.err) {
        setBook((prev) => ({ ...prev, userBook: null }));
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }

    setUploading(false);
    // _closeReadStatusActionSheet();
  };

  const _onRefresh = async () => {
    setRefreshing(true);
    await _load();
    setRefreshing(false);
  };

  useEffect(() => {
    _load();
  }, []);

  useEffect(() => {
    setUserBookState({ readStatus: null, rate: null });
  }, [myRecordOn]);

  if (!isLoaded) return <LoadingComponent />;
  return (
    <div>
      <Container maxWidth={"sm"} sx={{ mt: 5, mb: 5 }} className="group">
        <Box sx={{ display: "flex" }}>
          <div className="relative ">
            <img className={"w-28 object-contain"} alt={book.title} src={book.coverImageUrl} />
          </div>
          <Box sx={{ pl: 2 }}>
            <Typography variant={"h5"} sx={{ fontWeight: "800" }}>
              {book.title}
            </Typography>
            <Typography variant={"subtitle1"} gutterBottom>
              {book.subTitle}
            </Typography>
            <Typography>{book.authors}</Typography>
            <Typography>{book.publisher}</Typography>
            <Typography>{dayjs(book.pubDate).format("YYYY. MM")}</Typography>
          </Box>
        </Box>
      </Container>

      <Container maxWidth={"sm"} sx={{ mt: !!book.userBook ? 9 : 7, mb: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2, position: "relative" }}>
          {!!book.userBook && (
            <figure className="absolute -top-7 flex items-end">
              <div className="text-blue-500 drop-shadow-md">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                  <path fill="currentColor" d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                </svg>
              </div>
              <p className="text-sm pt-1 font-bold tracking-tight text-blue-500 ml-1 min-w-max">책장에 저장된 책이에요.</p>
            </figure>
          )}
          <Typography variant={"h5"} sx={{ fontWeight: "800" }}>
            나의 기록
          </Typography>
          {/* <Box> */}
          <Button
            sx={{ ml: 2 }}
            variant={"contained"}
            onClick={() => {
              if (!!book?.userBook?.readStatus) {
                const a = window.confirm('"독서 상태, 평점, 리뷰가 삭제됩니다"');
                if (!a) return;
                _deleteUserBook();
              } else {
                setMyRecordOn(true);
              }
            }}
            color={!!book?.userBook?.readStatus ? "error" : "primary"}
          >
            {!!book?.userBook?.readStatus ? "빼기" : "내 책장에 추가"}
          </Button>
          {/* <Button sx={{ display: !!book?.userBook?.readStatus ? "block" : "none", ml: 2 }} variant={"contained"} onClick={() => setMyRecordOn(true)} color="primary">
              수정
            </Button>
          </Box> */}
        </Box>

        <Box sx={{ display: !!book.userBook ? "block" : "none", width: 300 }}>
          <Typography variant={"h6"} sx={{ mb: 1 }}>
            독서 상태
          </Typography>
          <FormControl sx={{ minWidth: 250, mb: 4 }} size={"medium"}>
            <InputLabel>독서 상태</InputLabel>
            <Select
              size={"medium"}
              label={"독서 상태"}
              value={book.userBook?.readStatus || ""}
              onChange={({ target }) => {
                _updateUserBook({ readStatus: target.value });
              }}
            >
              {Object.keys(userBookReadStatuses).map((value) => {
                return (
                  <MenuItem key={value} value={value}>
                    {readStatusText[value]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Typography variant={"h6"} sx={{ mb: 1 }}>
            평점
          </Typography>
          <Slider
            value={book?.userBook?.rate || 0}
            disabled={!book?.userBook?.readStatus}
            defaultValue={0}
            getAriaValueText={(value) => <>{value}%%</>}
            // valueLabelDisplay="on"
            valueLabelFormat={(value) => value}
            step={0.5}
            marks={[0, 1, 2, 3, 4, 5].map((o) => ({ value: o, label: String(o) }))}
            min={0}
            max={5}
            onChange={(event, newValue) => {
              const userBook = { ...book.userBook };
              userBook.rate = newValue;
              setBook((prev) => ({ ...prev, userBook }));
            }}
            onChangeCommitted={(event, newValue) => {
              // console.log(newValue);
              _updateUserBook({ rate: newValue });
            }}
          />
        </Box>

        <Dialog fullWidth open={myRecordOn}>
          <DialogTitle>내 책장에 추가</DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <Typography variant={"h6"} sx={{ mb: 1 }}>
              독서 상태
            </Typography>
            <FormControl sx={{ minWidth: 250, mb: 4 }} size={"medium"}>
              <InputLabel>독서 상태</InputLabel>
              <Select
                size={"medium"}
                label={"독서 상태"}
                value={userBookState.readStatus || ""}
                onChange={({ target }) => {
                  setUserBookState({ ...userBookState, readStatus: target.value });
                }}
              >
                {Object.keys(userBookReadStatuses).map((value) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {readStatusText[value]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography variant={"h6"} sx={{ mb: 1 }}>
              평점
            </Typography>
            <Box sx={{ width: 300 }}>
              <Slider
                value={userBookState.rate || 0}
                disabled={!userBookState.readStatus}
                defaultValue={0}
                getAriaValueText={(value) => <>{value}%%</>}
                // valueLabelDisplay="on"
                valueLabelFormat={(value) => value}
                step={0.5}
                marks={[0, 1, 2, 3, 4, 5].map((o) => ({ value: o, label: String(o) }))}
                min={0}
                max={5}
                onChange={(event, newValue) => {
                  setUserBookState({ ...userBookState, rate: newValue });
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={() => setMyRecordOn(false)}
              // loading={uploading}
            >
              취소
            </LoadingButton>
            <LoadingButton variant="contained" color="success" onClick={() => _updateUserBook({ readStatus: userBookState.readStatus, rate: userBookState.rate })}>
              저장
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Container>

      <Container maxWidth={"sm"} sx={{ mt: 7, mb: 5 }}>
        <Typography variant={"h5"} sx={{ fontWeight: "800", mb: 3 }}>
          책 정보
        </Typography>
        <Typography variant={"h6"} sx={{ mb: 3 }}>
          설명 요약
        </Typography>
        <Typography className={"whitespace-pre-line"}>{book.description}</Typography>
        <Button onClick={() => window.open(`https://book.naver.com/bookdb/book_detail.naver?bid=${book.naverBookId}`)} sx={{ mt: 3 }} variant={"outlined"}>
          네이버에서 책정보 보기
        </Button>
      </Container>
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentBookDetailPage);
