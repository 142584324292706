import PropTypes from "prop-types";
import {Box, IconButton} from "@mui/material";
import {ChevronRightOutlined} from "@mui/icons-material";

CollapseButton.propTypes = {
  isCollapse: PropTypes.bool,
  collapseClick: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
};

export default function CollapseButton({onToggleCollapse, isCollapse, collapseClick}) {
  return (
    <IconButton onClick={onToggleCollapse}>
      <Box
        sx={{
          lineHeight: 0,
          /*transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),*/
          ...(collapseClick && {
            transform: "rotate(180deg)",
          }),
          ...(isCollapse && {
            transform: "rotate(180deg)",
          }),
        }}
      >
        <ChevronRightOutlined/>
      </Box>
    </IconButton>
  );
}
