import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, CardContent, CardMedia, Grid, CardActions, Button, Typography} from "@mui/material";
import {courseTypeText} from "../../constants/dataTypes";
import {Link} from "react-router-dom";

const StudentCourseComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const {course} = props;
  if (!course) return null;

  return (
    <Grid item xs={12} sm={4} md={4}>
      <Card>
        <CardMedia
          sx={{height: 194}}
          component="img"
          src={course.coverImageUrl}
          // image={course.coverImageUrl}
          // alt="Paella dish"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {course.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            구분: {courseTypeText[course.courseType]} - {course.subject?.title} <br/>
            선생님: {course.teacherUser?.name}

          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant={'contained'}
            to={`/course/${course?.id}`}
            as={Link}
            // size="small"
          >
            자세히
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

// export default enhance(StudentCourseComponent);
export default StudentCourseComponent
