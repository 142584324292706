import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import NetworkStudent from "../../lib/NetworkStudent";
import {Container, Typography, List, ListSubheader, ListItem, ListItemIcon, ListItemText, Checkbox, Switch} from "@mui/material";
import * as Icon from '@mui/icons-material';
import LoadingComponent from "../shared/LoadingComponent";

const StudentSettingPushNotificationComponent = (props) => {

  const [uploading, setUploading] = useState(false);

  const [pushSettingIsLoaded, setPushSettingIsLoaded] = useState(false);
  const [pushSetting, setPushSetting] = useState({task: true, course: true, bookReport: true,});

  const menus = [
    {
      text: "과제",
      key: "task",
    },
    {
      text: "수업",
      key: "course",
    },
    {
      text: "독후감",
      key: "bookReport",
    },
  ];

  const _loadPushSetting = async () => {
    try {
      const res = await NetworkStudent
        .userMePushSettingGET();
      setPushSetting(res.pushSetting);
      setPushSettingIsLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }

  };

  useEffect(() => {
    _loadPushSetting();
  }, [])

  useEffect(() => {

  })

  /*if (!pushSettingIsLoaded) return <List sx={{bgcolor: 'background.paper'}}
   subheader={<ListSubheader>푸시 설정</ListSubheader>}/>*/

  return (
    <List
      sx={{bgcolor: 'background.paper'}}
      subheader={<ListSubheader>푸시 설정</ListSubheader>}
    >
      {
        menus.map((item, index) => {
          return (
            <ListItem
              key={index}
              style={{
                flexDirection: "row",
                paddingHorizontal: 20,
                paddingVertical: 15,
                alignItems: "center",
              }}>
              <ListItemText
                primary={item.text}
                // secondary={item.key}
              />
              <Switch
                // disabled={uploading || !pushSettingIsLoaded}
                value={pushSetting[item.key]}
                checked={pushSetting[item.key]}
                edge="end"
                // onChange={handleToggle('bluetooth')}
                // checked={checked.indexOf('bluetooth') !== -1}
                onChange={async (event, newValue) => {
                  // console.log({newValue})
                  pushSetting[item.key] = newValue;
                  setPushSetting(prev => ({...pushSetting}));
                  setUploading(true);
                  await NetworkStudent.userMePushSettingPUT({[item.key]: newValue});
                  setUploading(false);
                }}
              />

            </ListItem>
          )
        })
      }
    </List>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSettingPushNotificationComponent);
