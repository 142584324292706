import {notificationType} from "../constants/dataTypes";

export const notificationRouter = (notification, navigate) => {
  const type = notification?.data?.type || notification.type;
  const data = notification.data || notification.customField || {};

  switch (type) {
    case notificationType.STUDENT_BOOK_REPORT_COMMENT:
      if (!!data.bookReportId) navigate(`/book-report/${data?.bookReportId}`)
      break;

    case notificationType.STUDENT_COURSE_INVITED:
      if (!!data.courseId) navigate(`/course/${data?.courseId}`)
      break;

    case notificationType.STUDENT_TASK_PUBLISHED:
      if (!!data.taskId) navigate(`/task/${data?.taskId}`)
      break;
    default:
      break;
  }
}
