import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Avatar, Box, Button, Container, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import * as Icon from "@mui/icons-material";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {PATH_ADMIN} from "../../routes/paths";
import numeral from "numeral";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";

// ----------------------------------------------------------------------

const AdminTeacherPage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const keyword = query.get('keyword') || "";

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [users, setUsers] = useState([]);


  const _load = async () => {
    const res = await Network.usersGET({
      userType: "TEACHER",
      offset: (page - 1) * LIMIT,
      limit: LIMIT,
      keyword,
    });
    if (!res.err) {
      setIsLoaded(true);
      setUsers(res.users);
      setMeta(res.meta);
    }
  };
  useEffect(() => {
    _load();
  }, [keyword, page]);

  const _navigateToTeacherDetailPage = (teacherUserId) => {
    navigate(`/admin/teacher/${teacherUserId}`);
  };

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  };
  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'xl'}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            선생님 관리
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}명
          </p>
        </div>
        <Button variant="contained" component={Link} to={PATH_ADMIN.teacher.create}>
          신규등록
        </Button>
      </div>
      <SearchTextField
        sx={{my: 2}}
        onSubmit={_handleSearch}
        onClickClear={() => navigate(location.pathname)}
        keyword={keyword}
      />

      <Box sx={{position: "relative"}} mb={2}>
        <Pagination
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => {
            searchParams.set('page', item.page);
            let path = `${location.pathname}?${searchParams.toString()}`;
            return (
              <PaginationItem
                component={Link}
                to={path}
                {...item}
              />
            )
          }}
        />
      </Box>

      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: "relative"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>이름</TableCell>
                <TableCell>닉네임</TableCell>
                <TableCell>이메일</TableCell>
                <TableCell>핸드폰</TableCell>
                <TableCell align="center">
                  관리자
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                return (
                  <TableRow key={user.id} className={'hover:bg-hover'}>
                    <TableCell>
                      <Link className={'text-[#1370F5] hover:underline'} to={PATH_ADMIN.teacher.detail(user.id)}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                          <Avatar sx={{mr: 1, width: "2rem", height: "2rem"}} src={user.profileImageUrl}/>
                          {user.name}
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>{user.displayName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phoneNumber || "-"}</TableCell>
                    <TableCell align="center">{user.isAdmin ? <Icon.AdminPanelSettingsOutlined/> : ""}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({...state}), {});

export default enhance(AdminTeacherPage);
