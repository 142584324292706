import React, {Component, useState, useEffect} from 'react';
import {CircularProgress, Box, Typography} from "@mui/material";

const Loader = (props) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '80vh',
      alignItems: 'center',
      justifyContent: 'center',
      // bgcolor:'red',
    }}>
      <CircularProgress
        // sx={{margin: 'auto'}}
      />
      <Typography sx={{mt:3}}>
        {props.text || '불러오는 중입니다'}
      </Typography>
    </Box>
  )
}


export default Loader
