import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Box, Button, Card, CardContent, Container, Grid, Stack} from '@mui/material';
import {Link} from 'react-router-dom';
import {PATH_STUDENT} from '../../routes/paths';

const StudentBookReportWriteSelectTypePage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();

  }, [])


  return (
    <div>
      <Container maxWidth={'lg'}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card sx={{boxShadow: 3}}>
              <CardContent>
                <p className={'font-bold text-lg mb-2'}>
                  수업/비교과 과제<br/>
                  독후감 작성
                </p>
                <p>
                  수업에서 출제된 과제에 제출하기 위한 독후감을 작성합니다.
                </p>
              </CardContent>

              <CardContent>
                <Button
                  component={Link}
                  to={`/task`}
                  variant={'contained'}>
                  나의 과제 보기
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{boxShadow: 3}}>
              <CardContent>
                <p className={'font-bold text-lg mb-2'}>
                  자유<br/>
                  독후감 작성
                </p>
                <p>
                  개별적으로 선생님께 제출하기 위한 독후감을 작성합니다.
                </p>
              </CardContent>
              <CardContent>

                <Button
                  component={Link}
                  to={PATH_STUDENT.bookReport.writeCreateTask}
                  variant={'contained'}>
                  자유 독후감 작성
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(StudentBookReportWriteSelectTypePage);
