import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Tab, TextField} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import Network from "../../lib/Network";
import {ChevronRightOutlined, KeyboardDoubleArrowLeftOutlined, KeyboardDoubleArrowRightOutlined, RemoveOutlined} from "@mui/icons-material";
import FlatList from "../../assi-design-system-react/components/FlatList";

const StudentSearchDialog = ({year, open = false, onSelectComplete, onCancel}) => {

  const [uploading, setUploading] = useState(false)
  const [selectedSearchTab, setSelectedSearchTab] = useState('CLASS') //CLASS OR SEARCH

  // 현재까지 선택된 사람
  const [selectedUsers, setSelectedUsers] = useState([]);

  // 학년반 검색 결과
  const [classSearchOption, setClassSearchOption] = useState({grade: null, classId: null, classes: []})
  const [classSearchedUsers, setClassSearchedUsers] = useState([]);

  // 전체 검색 결과
  const [searchedUsers, setSearchedUsers] = useState([]);


  useEffect(() => {
    const _loadClasses = async () => {
      try {
        const res = await Network.classesGET({
          year,
          grade: classSearchOption.grade,
          offset: 0,
          limit: 100,
        })
        if (!res.err) {
          setClassSearchOption(prev => ({...prev, classes: res.classes}))
        }
      } catch (e) {
        window.alert(e.cause?.errorMessage);
      }
    }
    if (classSearchOption.grade > 0) {
      _loadClasses()
    } else {

    }
  }, [classSearchOption.grade])

  const _loadClassUsers = async (keyword) => {
    try {
      const res = await Network.classUsersGET({
        classId: classSearchOption.classId,
        offset: 0,
        limit: 200,
        keyword,
      })
      setClassSearchedUsers(res.users);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  }
  useEffect(() => {

    if (classSearchOption.classId) {
      _loadClassUsers()
    } else {
      setClassSearchedUsers([]);
    }
  }, [classSearchOption.classId])

  const _loadUsers = async (keyword) => {
    if (!keyword) return setSearchedUsers([]);
    try {
      const res = await Network.usersGET({
        userType: 'STUDENT',
        offset: 0,
        limit: 200,
        year,
        keyword,
      })
      setSearchedUsers(res.users);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  }


  const _handleClassUserSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    _loadClassUsers(keyword);
  }
  const _handleAllUserSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    console.log({keyword})
    _loadUsers(keyword);
  }

  const _addUserToCandidateList = (user) => {
    const included = selectedUsers.findIndex(u => u.id === user.id) > -1;
    if (included) return;
    setSelectedUsers(prev => ([...prev, user]))
  }
  const _removeUserFromCandidateList = (index) => {
    const newSelectedUsers = [...selectedUsers];
    newSelectedUsers.splice(index, 1);
    setSelectedUsers(newSelectedUsers);
  }

  const _clearSelectedUser = () => {
    const a = window.confirm('모두 삭제할까요?');
    if (!a) return;
    setSelectedUsers([])
  }

  const _handleCancel = async () => {
    onCancel?.();
  }
  const _handleComplete = async () => {
    setUploading(true);
    const result = await onSelectComplete?.(selectedUsers.map(u => u.id))
    if (result) {
      setSearchedUsers([]);
      setClassSearchedUsers([]);
      setSelectedUsers([])
      setClassSearchOption({grade: null, classId: null, classes: []})
    }
    setUploading(false);
  }


  const _renderTabPanel = () => {
    switch (selectedSearchTab) {
      case 'CLASS':
        return (
          <TabPanel value={'CLASS'} className={'flex flex-col h-full'}>
            <div className={'flex space-x-1'}>
              <TextField
                fullWidth
                select
                label="학년"
                value={classSearchOption.grade || ""}
                onChange={({target}) => {
                  setClassSearchOption(prev => ({...prev, grade: target.value, classId: null, classes: []}))
                }}
                sx={{maxWidth: {xs: "full", sm: 100}}}
              >
                {
                  [1, 2, 3].map((grade, i) => {
                    return (
                      <MenuItem key={i} value={grade}>
                        {grade}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
              <TextField
                disabled={!classSearchOption.grade}
                fullWidth
                select
                label="반"
                value={classSearchOption.classId || ""}
                onChange={({target}) => {
                  setClassSearchOption(prev => ({...prev, classId: target.value}))
                }}
                sx={{maxWidth: {xs: "full", sm: 100}}}
              >
                {
                  classSearchOption.classes?.map((cls, i) => {
                    return (
                      <MenuItem key={i} value={cls.id}>
                        {cls.classNo}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
              <SearchTextField onSubmit={_handleClassUserSearch}/>
            </div>
            <div className={"flex-1"}>
              {classSearchedUsers.map(user => {
                return (
                  <div key={user.id} className={'flex px-2 py-3 items-center'}>
                    <img src={user.profileImageUrl} className={'w-[30px] h-[30px] rounded-full '}/>
                    <p className={'pl-2 flex-1 text-sm'}>
                      {user.name} <br/>
                      {user.classRegister?.class?.grade}학년 {user.classRegister?.class?.classNo}반 {user.classRegister?.studentNo}번
                    </p>
                    <IconButton onClick={() => _addUserToCandidateList(user)}>
                      <ChevronRightOutlined/>
                    </IconButton>
                  </div>
                )
              })}
            </div>
          </TabPanel>
        )
      case 'SEARCH':
        return (
          <TabPanel value={'SEARCH'} className={'flex flex-col h-full'}>
            <div>
              <SearchTextField onSubmit={_handleAllUserSearch}/>
            </div>
            <div className={"flex-1"}>
              {searchedUsers.map(user => {
                return (
                  <div key={user.id} className={'flex px-2 py-3 items-center'}>
                    <img src={user.profileImageUrl} className={'w-[30px] h-[30px] rounded-full '}/>
                    <p className={'pl-2 flex-1 text-sm'}>
                      {user.name} <br/>
                      {user.classRegister?.class?.grade}학년 {user.classRegister?.class?.classNo}반 {user.classRegister?.studentNo}번
                    </p>
                    <IconButton onClick={() => _addUserToCandidateList(user)}>
                      <ChevronRightOutlined/>
                    </IconButton>
                  </div>
                )
              })}
            </div>
          </TabPanel>
        )
      default:
        return <></>
    }
  }

  return (
    <TabContext value={selectedSearchTab}>
      <Dialog
        maxWidth={'lg'}
        fullWidth
        open={open}>
        <DialogTitle>
          <TabList onChange={(e, newValue) => setSelectedSearchTab(newValue)}>
            <Tab label={'학년/반'} value={'CLASS'}/>
            <Tab label={'검색'} value={'SEARCH'}/>
          </TabList>
        </DialogTitle>
        <Divider/>
        <DialogContent className={'h-[70vh]'}>
          <div className={'flex h-full'}>
            <div className={'flex-1'}>
              {_renderTabPanel()}
            </div>
            <div className={'px-1 h-full flex flex-col justify-center'}>
              <div className={'flex-1 mx-auto w-[1px] bg-gray-500 mb-2'}/>
              {
                selectedSearchTab === 'CLASS' && (
                  <Button
                    disabled={classSearchedUsers.length < 1}
                    onClick={() => {
                      classSearchedUsers.forEach(u => {
                        _addUserToCandidateList(u);
                      })
                    }}
                  >
                    전체 추가
                    {/*<KeyboardDoubleArrowRightOutlined/>*/}
                  </Button>
                )
              }
              <Button
                disabled={selectedUsers.length < 1}
                onClick={_clearSelectedUser}>
                전체 삭제
                {/*<KeyboardDoubleArrowLeftOutlined/>*/}
              </Button>
              <div className={'flex-1 mx-auto w-[1px] bg-gray-500 mt-2'}/>
            </div>
            <div className={'flex-1'}>
              <FlatList
                data={selectedUsers}
                renderItem={({item: user, index}) => {
                  return (
                    <div className={'p-2 bg-white flex items-center'}>
                      <img src={user.profileImageUrl} className={'w-[30px] h-[30px] rounded-full '}/>
                      <p className={'pl-2 flex-1 text-sm'}>
                        {user.name} <br/>
                        {user.classRegister?.class?.grade}학년 {user.classRegister?.class?.classNo}반 {user.classRegister?.studentNo}번
                      </p>
                      <IconButton onClick={() => _removeUserFromCandidateList(index)}>
                        <RemoveOutlined/>
                      </IconButton>
                    </div>
                  )
                }}
              />
            </div>
          </div>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button
            variant={'outlined'}
            onClick={_handleCancel}>
            취소
          </Button>
          <Button
            disabled={uploading || selectedUsers.length < 1}
            variant={'contained'}
            onClick={_handleComplete}
          >
            추가하기
          </Button>
        </DialogActions>
      </Dialog>
    </TabContext>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSearchDialog);
