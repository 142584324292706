import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Container, Dialog, DialogActions, DialogContent, Typography, Box} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import NetworkStudent from '../../lib/NetworkStudent';
import LoadingComponent from '../../components/shared/LoadingComponent';
import {LoadingButton} from "@mui/lab";
import dayjs from 'dayjs';

const StudentInquiryDetailPage = (props) => {

  const {inquiryId} = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoaded, setIsLoaded] = useState(false)
  const [deleting, setDeleting] = useState(false);
  const [inquiry, setInquiry] = useState({});
  const [selectedQuestionImageIndex, setSelectedQuestionImageIndex] = useState(-1);
  const [questionImageDialogOpen, setQuestionImageDialogOpen] = useState(false);
  const [selectedAnswerImageIndex, setSelectedAnswerImageIndex] = useState(-1);
  const [answerImageDialogOpen, setAnswerImageDialogOpen] = useState(false);

  const _load = async () => {
    if (!inquiryId) return;
    try {
      const res = await NetworkStudent
        .inquiryGET({inquiryId});
      setIsLoaded(true);
      setInquiry(res.inquiry);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  }

  useEffect(() => {
    _load();
  }, [])

  const _handleCloseQuestionImageModal = () => {
    setQuestionImageDialogOpen(false);
    setSelectedQuestionImageIndex(-1);
  };
  useEffect(() => {
    if (selectedQuestionImageIndex > -1) {
      setQuestionImageDialogOpen(true);
    } else {

    }
  }, [selectedQuestionImageIndex]);

  const _handleCloseAnswerImageModal = () => {
    setAnswerImageDialogOpen(false);
    setSelectedAnswerImageIndex(-1);
  };
  useEffect(() => {
    if (selectedAnswerImageIndex > -1) {
      setAnswerImageDialogOpen(true);
    } else {

    }
  }, [selectedAnswerImageIndex]);

  const _handleDelete = async () => {
    if (!window.confirm('삭제하시겠습니까?')) return;

    setDeleting(true);
    const res = await NetworkStudent
      .inquiryDELETE({inquiryId});
    if (!res.err) {
      navigate(-1, {replace: true})
    }
  };

  if (!isLoaded) return <LoadingComponent/>;

  const _renderQuestionImagesDialog = () => {
    return (
      <Dialog
        fullWidth
        open={questionImageDialogOpen}>
        <DialogContent>
          <img
            src={inquiry?.questionImageUrls?.[selectedQuestionImageIndex]?.url || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setQuestionImageDialogOpen(false);
            setSelectedQuestionImageIndex(-1);
          }}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderAnswerImagesDialog = () => {
    return (
      <Dialog
        fullWidth
        open={answerImageDialogOpen}>
        <DialogContent>
          <img
            src={inquiry?.answerImageUrls?.[selectedAnswerImageIndex]?.url || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setAnswerImageDialogOpen(false);
            setSelectedAnswerImageIndex(-1);
          }}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      <Container maxWidth={'md'}>
        <Box sx={{
          ml: 2, bgcolor: '#efefef', p: 3,
          borderRadius: 2,
          borderBottomRightRadius: 0,
        }}>
          <Typography
            sx={{mb: 1}}
            variant={'h5'}>
            {inquiry.title}
          </Typography>
          <Typography
            variant={'body'}
            className={'whitespace-pre-line mt-10'}
          >
            {inquiry.question}
          </Typography>
          <Typography sx={{mt: 2}}>
            {dayjs(inquiry.createdAt).format('YYYY.MM.DD(ddd) HH:mm')}
          </Typography>
          {
            inquiry.questionImageUrls?.length > 0 && (
              <Box sx={{mt: 2}}>
                <div className={'flex overflow-x-scroll pb-2'}>
                  {inquiry.questionImageUrls.map((item, index) => {
                    return (
                      <img
                        onClick={() => setSelectedQuestionImageIndex(index)}
                        key={item.url}
                        className={'border border-gray-800 !w-24 !h-24 object-cover mr-2 rounded-xl flex-shrink-0 hover:cursor-pointer'}
                        src={item.url}
                      />
                    )
                  })}
                </div>
                {_renderQuestionImagesDialog()}
              </Box>
            )
          }
        </Box>
        {
          !inquiry?.answeredAt ? (
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2,}}>
              <LoadingButton
                loading={deleting}
                disabled={deleting}
                onClick={_handleDelete}
                variant={'contained'}
                color={'error'}>
                삭제
              </LoadingButton>
            </Box>
          ) : (
            <Box sx={{
              mt: 5,
              mr: 2, bgcolor: '#efefef',
              p: 3,
              borderRadius: 2,
              borderBottomLeftRadius: 0,
            }}>
              <Typography
                variant={'body'}
                className={'whitespace-pre-line mt-10'}
              >
                {inquiry.answer}
              </Typography>
              <Typography sx={{mt: 2}}>
                {dayjs(inquiry.answeredAt).format('YYYY.MM.DD(ddd) HH:mm')}
              </Typography>
              {
                inquiry.answerImageUrls?.length > 0 && (
                  <Box sx={{mt: 2}}>
                    <div className={'flex overflow-x-scroll pb-2'}>
                      {inquiry.answerImageUrls.map((item, index) => {
                        return (
                          <img
                            onClick={() => setSelectedAnswerImageIndex(index)}
                            key={item.url}
                            className={'border border-gray-800 !w-24 !h-24 object-cover mr-2 rounded-xl flex-shrink-0 hover:cursor-pointer'}
                            src={item.url}
                          />
                        )
                      })}
                    </div>
                    {_renderAnswerImagesDialog()}
                  </Box>
                )
              }
            </Box>
          )
        }
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentInquiryDetailPage);
