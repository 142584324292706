import React from "react";
import Layout from "./Layout";
// config
import { NAV_CONFIG_ADMIN } from "./navConfig";
import { MENU_OPTIONS_ADMIN } from "./header/accountPopoverConfig";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

const AdminLayout = (props) => {

  if (!props.authReducer?.user?.isAdmin) {
    window.alert("관리자만 사용가능합니다.");
    return <Navigate to="/" replace/>;
  }
  return <Layout accountPopoverConfig={MENU_OPTIONS_ADMIN} navConfig={NAV_CONFIG_ADMIN}/>;
};

const enhance = connect((state) => ({...state}), {});
export default enhance(AdminLayout);
