// TEACHER_NEW_BOOK_REPORT: "TEACHER_BOOK_REPORT_SUBMITTED",     // 새로운 과제가 제출되었을 때,
// TEACHER_NEW_COURSE_REGISTER: "TEACHER_NEW_COURSE_REGISTER",   // 학생이 새롭게 등록하였을 때,
// TEACHER_BOOK_REPORT_COMMENT: "TEACHER_BOOK_REPORT_COMMENT"

const notificationTypes = {
  TEACHER_NEW_COURSE_REGISTER: { type: "TEACHER_NEW_COURSE_REGISTER", name: "수업에 신규 학생 등록", description: "학생이 새로 등록되었어요." },
  TEACHER_BOOK_REPORT_SUBMITTED: { type: "TEACHER_BOOK_REPORT_SUBMITTED", name: "새로운 독후감", description: "과제에 새 독후감이 제출되었어요." },
  TEACHER_BOOK_REPORT_COMMENT: { type: "TEACHER_BOOK_REPORT_COMMENT", name: "새로운 댓글", description: "새 댓글이 작성되었어요." },

  STUDENT_COURSE_INVITED: { type: "STUDENT_COURSE_INVITED", name: "수업에 초대", description: "수업에 초대되었어요." }, // 학생이 초대 되었을 때,
  STUDENT_TASK_PUBLISHED: { type: "STUDENT_TASK_PUBLISHED", name: "새로운 과제 출제", description: "새로운 과제가 출제되었어요." }, // 새로운 과제가 출제 되었을 때,
  STUDENT_BOOK_REPORT_COMMENT: { type: "STUDENT_BOOK_REPORT_COMMENT", name: "독후감 새 댓글", description: "독후감에 새로운 댓글이 달렸어요." }, // 독후감에 댓글 달렸을 때,
};

export const notificationTypesTeacher = {
  TEACHER_NEW_COURSE_REGISTER: { type: "TEACHER_NEW_COURSE_REGISTER", name: "수업에 신규 학생 등록", description: "학생이 새로 등록되었어요." },
  TEACHER_BOOK_REPORT_SUBMITTED: { type: "TEACHER_BOOK_REPORT_SUBMITTED", name: "새로운 독후감", description: "과제에 새 독후감이 제출되었어요." },
  TEACHER_BOOK_REPORT_COMMENT: { type: "TEACHER_BOOK_REPORT_COMMENT", name: "새로운 댓글", description: "새 댓글이 작성되었어요." },
};

export const notificationTypesStudent = {
  STUDENT_COURSE_INVITED: { type: "STUDENT_COURSE_INVITED", name: "수업에 초대", description: "수업에 초대되었어요." }, // 학생이 초대 되었을 때,
  STUDENT_TASK_PUBLISHED: { type: "STUDENT_TASK_PUBLISHED", name: "새로운 과제 출제", description: "새로운 과제가 출제되었어요." }, // 새로운 과제가 출제 되었을 때,
  STUDENT_BOOK_REPORT_COMMENT: { type: "STUDENT_BOOK_REPORT_COMMENT", name: "독후감 새 댓글", description: "독후감에 새로운 댓글이 달렸어요." }, // 독후감에 댓글 달렸을 때,
};

export default notificationTypes;
