import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, List, ListItem, ListItemText, Typography, Dialog, TextField, DialogActions, DialogContentText, DialogTitle, DialogContent } from "@mui/material";
import NetworkStudent from "../../lib/NetworkStudent";

const StudentSettingPasswordChangeComponent = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");

  const _handleConfirm = async () => {
    if (currentPassword?.length < 4) return alert("현재 비밀번호를 입력해 주세요");
    if (newPassword !== newPasswordRepeat) return alert("비밀 번호를 동일하게 입력해 주세요");
    setUploading(true);

    try {
      const res = await NetworkStudent.userMePUT({
        currentPassword: currentPassword,
        password: newPassword,
      });
      alert("비밀번호가 정상적으로 변경되었습니다");
      _handleCloseDialog();
    } catch (e) {
      alert("현재 비밀번호를 확인해 주세요");
    } finally {
      setUploading(false);
    }
  };

  const _handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const _handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <List
        sx={{ bgcolor: "background.paper" }}
        // subheader={<ListSubheader>푸시 설정</ListSubheader>}
      >
        <ListItem>
          <ListItemText primary={"***"} />
          <Button onClick={_handleOpenDialog}>변경하기</Button>
        </ListItem>
      </List>
      <Dialog fullWidth open={dialogOpen}>
        <DialogTitle>비밀번호 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>비밀번호를 변경합니다.</DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            sx={{ mb: 5 }}
            fullWidth
            label="현재 비밀번호"
            type="password"
            // defaultValue="Default Value"
            value={currentPassword}
            onChange={(e) => {
              // setInquiry(prev => ({...prev, answer: e.target.value}))
              setCurrentPassword(e.target.value?.trim());
            }}
          />
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            label="새 비밀번호"
            type="password"
            // defaultValue="Default Value"
            value={newPassword}
            onChange={(e) => {
              // setInquiry(prev => ({...prev, answer: e.target.value}))
              setNewPassword(e.target.value?.trim());
            }}
          />
          <TextField
            fullWidth
            label="새 비밀번호(한번더)"
            // defaultValue="Default Value"
            value={newPasswordRepeat}
            type="password"
            onChange={(e) => {
              // setInquiry(prev => ({...prev, answer: e.target.value}))
              setNewPasswordRepeat(e.target.value?.trim());
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} disabled={uploading} onClick={_handleConfirm}>
            확인
          </Button>
          <Button onClick={_handleCloseDialog}>취소</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentSettingPasswordChangeComponent);
