import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import Network from "../../lib/Network";
import {
  Tab,
  Container,
  TextField,
  Grid,
  Table,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  PaginationItem,
  TableBody,
  TableContainer,
  Box,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, Avatar, Link as LinkMui,
} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {courseTypeText} from "../../constants/dataTypes";
import {termTypesObj} from "../../constants";
import {PATH_ADMIN} from '../../routes/paths';
import {displayNameRegex, emailRegex, nameRegex, phoneNumberRegex} from "../../utils/regexTest";

const AdminStudentDetailPage = (props) => {
  const {userId} = useParams();
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const year = query.get("year") || dayjs().format("YYYY");
  const tab = query.get("tab") || "INFO";

  const [confirmText, setConfirmText] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [newName, setNewName] = useState('');
  const [newDisplayName, setNewDisplayName] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useState({});
  const [meta, setMeta] = useState({total: 0});
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [bookReports, setBookReports] = useState([]);

  const [dialogOpen, setDialogOpen] = useState({name: false, email: false, displayName: false, phoneNumber: false, password: false});


  const _loadUser = async () => {
    if (!userId) return;
    try {
      const res = await Network.userGET({userId, year});
      setIsLoaded(true);
      setUser(res.user);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }

  };
  const _deleteUser = async () => {
    if (!window.confirm("삭제하시겠습니까?")) return;
    try {
      const res = await Network.userDELETE({userId});
      navigate(-1, {replace: true});
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }

  };
  useEffect(() => {
    _loadUser();
  }, []);

  const _loadClasses = async () => {
    if (!userId) return;
    if (tab !== "CLASS") return;
    try {
      const res = await Network.classesGET({
        studentUserId: userId,
        offset: ( page - 1 ) * LIMIT,
        limit: LIMIT,
      });
      setClasses(res.classes || []);
      setMeta(res.meta);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {

    }

  };
  const _loadCourses = async () => {
    if (!userId) return;
    if (tab !== "COURSE") return;
    try {
      const res = await Network.coursesGET({
        studentUserId: userId,
        offset: ( page - 1 ) * LIMIT,
        limit: LIMIT,
      });
      setCourses(res.courses);
      setMeta(res.meta);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  };
  const _loadBookReports = async () => {
    if (!userId) return;
    if (tab !== "BOOK_REPORT") return;
    try {
      const res = await Network.bookReportsGET({
        studentUserId: userId,
        offset: ( page - 1 ) * LIMIT,
        limit: LIMIT,
      });
      setBookReports(res.bookReports);
      setMeta(res.meta);

    } catch (e) {
      window.alert(e.cause.errorMessage);

    }

  };
  useEffect(() => {
    _loadClasses();
    _loadCourses();
    _loadBookReports();
  }, [tab]);

  const _updateUser = async ({name, displayName, email, phoneNumber, password}) => {
    if (!userId) return;
    setUploading(true);
    try {
      await Network.userPUT({userId, name, displayName, email, password, phoneNumber})
      await _loadUser();
      setDialogOpen({password: false, phoneNumber: false, name: false, displayName: false, email: false})
      setConfirmText('');
      setNewPhoneNumber('');
      setNewName('');
      setNewDisplayName('');
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }


  }

  const _navigateToClassDetailPage = (clsId) => {
    if (!clsId) return;
    navigate(`/admin/class/${clsId}`);
  };
  const _navigateToCourseDetailPage = (courseId) => {
    if (!courseId) return;
    navigate(`/admin/course/${courseId}`);
  };
  const _navigateToBookReportDetailPage = (bookReportId) => {
    if (!bookReportId) return;
    navigate(`/admin/book-report/${bookReportId}`);
  };
  const _navigateToTeacherDetailPage = (teacherUserId) => {
    if (!teacherUserId) return;
    navigate(`/admin/teacher/${teacherUserId}`);
  }

  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  };
  const _handleCloseAllDialog = () => {
    setDialogOpen({password: false, email: false, phoneNumber: false, displayName: false, name: false})
  }

  if (!isLoaded) return <LoadingComponent/>;

  const _renderDisplayNameDialog = () => {
    return (
      <Dialog fullWidth open={dialogOpen.displayName}>
        <DialogTitle>
          닉네임 변경
        </DialogTitle>
        <DialogContent>
          <p className={'text-gray-600 mb-6'}>
            닉네임을 변경합니다.
          </p>
          <TextField
            helperText={'동명이인의 학생을 구분하기 위해 사용합니다. 추후 학생 사용자가 변경할 수 있습니다. 닉네임은 중복될 수 없습니다.'}
            label={'닉네임(필수) - 한글/영어/숫자만 입력가능 2자이상 10자이내'}
            error={!displayNameRegex.test(newDisplayName)}
            fullWidth
            value={newDisplayName}
            onChange={(e) => {
              setNewDisplayName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!displayNameRegex.test(newDisplayName)}
            onClick={() => {
              _updateUser({displayName: newDisplayName.trim()})
            }}>
            확인
          </Button>
          <Button onClick={_handleCloseAllDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderNameDialog = () => {
    return (
      <Dialog fullWidth open={dialogOpen.name}>
        <DialogTitle>
          이름 변경
        </DialogTitle>
        <DialogContent>
          <p className={'text-gray-600 mb-6'}>
            이름을 변경합니다.
          </p>
          <TextField
            helperText={'본명을 입력해주세요.(2자이상 15자이내)'}
            label={'이름(필수)'}
            error={!nameRegex.test(newName)}
            fullWidth
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!nameRegex.test(newName)}
            onClick={() => {
              _updateUser({name: newName.trim()})
            }}
          >
            확인
          </Button>
          <Button onClick={_handleCloseAllDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderEmailDialog = () => {
    return (
      <Dialog fullWidth open={dialogOpen.email}>
        <DialogTitle>
          이메일 변경
        </DialogTitle>
        <DialogContent>
          <p className={'text-gray-600 mb-6'}>
            이메일을 변경합니다.
          </p>
          <TextField
            helperText={'로그인에 사용 되는 이메일 입니다. 이메일은 중복될 수 없습니다.'}
            label={'email(필수)'}
            error={!emailRegex.test(newEmail)}
            fullWidth
            value={newEmail}
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!emailRegex.test(newEmail)}
            onClick={() => {
              _updateUser({email: newEmail.trim()})
            }}
          >
            확인
          </Button>
          <Button onClick={_handleCloseAllDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderPhoneDialog = () => {
    return (
      <Dialog fullWidth open={dialogOpen.phoneNumber}>
        <DialogTitle>
          전화번호
        </DialogTitle>
        <DialogContent>
          <p className={'text-gray-600 mb-6'}>
            숫자로만 입력해주세요.
          </p>
          <TextField
            fullWidth
            label={'전화번호'}
            placeholder={'숫자로만 입력해주세요.'}
            value={newPhoneNumber}
            onChange={(e) => {
              setNewPhoneNumber(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => _updateUser({phoneNumber: newPhoneNumber})}
            disabled={!phoneNumberRegex.test(newPhoneNumber)}
          >
            확인
          </Button>
          <Button onClick={_handleCloseAllDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderPasswordDialog = () => {
    return (
      <Dialog fullWidth open={dialogOpen.password}>
        <DialogTitle>
          비밀번호
        </DialogTitle>
        <DialogContent>
          <p className={'text-gray-600 mb-6'}>
            비밀번호를 12341234로 초기화합니다. <br/>
            <b>변경하기</b>라고 입력하고 확인을 눌러주세요.
          </p>
          <TextField
            fullWidth
            label={'변경하기'}
            placeholder={'"변경하기"를 입력해주세요.'}
            value={confirmText}
            onChange={(e) => {
              setConfirmText(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => _updateUser({password: '12341234'})}
            disabled={confirmText !== '변경하기'}
          >
            확인
          </Button>
          <Button onClick={_handleCloseAllDialog}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      <Container maxWidth={"lg"}>
        <Typography gutterBottom variant={"h5"}>
          {user.name}
        </Typography>
        <Typography>유저 관리</Typography>
      </Container>
      <Container maxWidth={"lg"} sx={{mt: 5}}>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider", mb: 3}}>
            <TabList onChange={(e, newValue) => _handleChangeTab(newValue)} aria-label="lab API tabs example">
              <Tab label="기본정보" value="INFO"/>
              <Tab label="반정보" value="CLASS"/>
              <Tab label="수업" value="COURSE"/>
              <Tab label="독후감" value="BOOK_REPORT"/>
            </TabList>
          </Box>
          <TabPanel value={"INFO"}>
            {_renderNameDialog()}
            {_renderDisplayNameDialog()}
            {_renderEmailDialog()}
            {_renderPhoneDialog()}
            {_renderPasswordDialog()}
            <Paper className={"mt-2 max-w-sm"}>
              <List>
                {[
                  {
                    title: "이름",
                    content: user.name,
                    secondaryActionTitle: "수정",
                    secondaryAction: () => {
                      setDialogOpen(prev => ( {...prev, name: true} ))
                    },
                  },
                  {
                    title: "닉네임",
                    content: user.displayName,
                    secondaryActionTitle: "수정",
                    secondaryAction: () => {
                      setDialogOpen(prev => ( {...prev, displayName: true} ))
                    },
                  },
                  {
                    title: "email",
                    content: user.email,
                    secondaryActionTitle: "수정",
                    secondaryAction: () => {
                      setDialogOpen(prev => ( {...prev, email: true} ))
                    },
                  },
                  {
                    title: "전화",
                    content: user.phoneNumber,
                    secondaryActionTitle: "수정",
                    secondaryAction: () => {
                      setDialogOpen(prev => ( {...prev, phoneNumber: true} ))
                    },
                  },
                  {
                    title: "비밀번호",
                    content: "*****",
                    secondaryAction: () => {
                      setDialogOpen(prev => ( {...prev, password: true} ))
                    },
                    secondaryActionTitle: "초기화",
                  },
                ].map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <ListItemText primary={item.title} secondary={item.content}/>
                      {item.secondaryAction && (
                        <ListItemSecondaryAction>
                          <Button onClick={() => {
                            item.secondaryAction?.();
                          }}>{item.secondaryActionTitle}</Button>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
            <Button color={"error"} variant={"contained"} onClick={_deleteUser}>
              계정 삭제
            </Button>
          </TabPanel>
          <TabPanel value={"CLASS"}>
            <Pagination
              sx={{mb: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + ( meta.total % LIMIT === 0 ? 0 : 1 )}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: "max-content"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>연도</TableCell>
                      <TableCell>학년</TableCell>
                      <TableCell>반</TableCell>
                      <TableCell>번호</TableCell>
                      <TableCell>담임</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classes?.map((cls) => {
                      return (
                        <TableRow key={cls.id}>
                          <TableCell>{cls.year}</TableCell>
                          <TableCell>{cls.grade}</TableCell>
                          <TableCell>
                            <LinkMui
                              component={'button'}
                              onClick={() => _navigateToClassDetailPage(cls.id)}>
                              {cls.classNo}
                            </LinkMui>
                          </TableCell>
                          <TableCell>
                            {cls.classRegister?.studentNo}
                          </TableCell>
                          <TableCell>
                            <LinkMui
                              component={'button'}
                              disabled={!cls.teacherUser?.id}
                              onClick={() => _navigateToTeacherDetailPage(cls.teacherUserId)}>
                              {cls.teacherUser?.name}
                            </LinkMui>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/*<Button variant={"contained"} sx={{mt: 3}}>
              반 추가
            </Button>*/}
          </TabPanel>
          <TabPanel value={"COURSE"}>
            <Pagination
              sx={{mb: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + ( meta.total % LIMIT === 0 ? 0 : 1 )}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: "max-content"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>연도</TableCell>
                      <TableCell>학기</TableCell>
                      <TableCell>교과/비교과</TableCell>
                      <TableCell>과목</TableCell>
                      <TableCell>강의명</TableCell>
                      <TableCell>선생님</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {courses?.map((course) => {
                      return (
                        <TableRow key={course.id}>
                          <TableCell>{course.semester?.year}</TableCell>
                          <TableCell>{termTypesObj[course.semester.term]?.name}</TableCell>
                          <TableCell>{courseTypeText[course.courseType]}</TableCell>
                          <TableCell>{course.subject?.title}</TableCell>
                          <TableCell>
                            <LinkMui
                              className={"line-clamp-1 max-w-sm"}
                              component="button" onClick={() => _navigateToCourseDetailPage(course.id)}>
                              {course.title}
                            </LinkMui>
                          </TableCell>
                          <TableCell>
                            <LinkMui
                              component="button" onClick={() => _navigateToTeacherDetailPage(course?.teacherUserId)}>
                              {course.teacherUser?.name}
                            </LinkMui>

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value={"BOOK_REPORT"}>
            <Pagination
              sx={{mb: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + ( meta.total % LIMIT === 0 ? 0 : 1 )}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: "max-content"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>제목</TableCell>
                      <TableCell>제출일</TableCell>
                      <TableCell>과목</TableCell>
                      <TableCell>과제</TableCell>
                      <TableCell>제출대상 선생님</TableCell>
                      <TableCell>책</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bookReports?.map((bookReport) => {
                      return (
                        <TableRow key={bookReport.id}>
                          <TableCell>
                            <LinkMui
                              as={Link}
                              to={PATH_ADMIN.bookReport.detail(bookReport.id)}
                              className={"line-clamp-1 w-[100px] text-primary-500"}
                            >
                              {bookReport.title}
                            </LinkMui>
                          </TableCell>
                          <TableCell>
                            {dayjs(bookReport.submittedAt).format('YYYY-MM-DD(ddd) HH:mm')}
                          </TableCell>
                          <TableCell>
                            <LinkMui
                              as={Link}
                              to={PATH_ADMIN.course.detail(bookReport.task?.course?.id)}
                              className={"line-clamp-1 max-w-[150px] text-primary-500"}>
                              {bookReport.task?.course?.title}
                            </LinkMui>
                          </TableCell>
                          <TableCell>
                            <LinkMui
                              as={Link}
                              to={PATH_ADMIN.task.detail(bookReport.task?.id)}
                              className={"line-clamp-1 max-w-[150px] text-primary-500"}>
                              {bookReport.task?.title}
                            </LinkMui>
                          </TableCell>
                          <TableCell>
                            {bookReport.teacherUser?.name}
                          </TableCell>

                          <TableCell>
                            <LinkMui
                              as={Link}
                              to={PATH_ADMIN.book.detail(bookReport.bookId)}
                              className={"line-clamp-1 max-w-[150px] text-primary-500"}>
                              {bookReport?.book?.title}
                            </LinkMui>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  );
};

const enhance = connect(
  (state) => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminStudentDetailPage);
