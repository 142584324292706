import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Loader from '../../assi-design-system-react/components/Loader';

const StudentHelpMainPage = (props) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [isLoaded, setLoaded] = useState(false);

  if (!isLoaded) return <Loader />;

  return (
    <>
      <div className="h-32 flex flex-col justify-between w-full mb-3">
        <div className="space-y-1">
          <h1>헬퍼</h1>
          <p className="bind-text-gray-500 text-sm">헬퍼 페이지</p>
        </div>
      </div>
    </>
  );
};

const enhance = connect((state) => ({ ...state }), {});
export default enhance(StudentHelpMainPage);
