import React from "react";

const ListEmpty = ({text = '리스트가 비었어요'}) => {
  return (
    <div className={'bg-gray-100 dark:bg-gray-750 rounded-xl flex items-center justify-center py-8 bind-text-gray-300 select-none mt-4'}>
      <p className={'text-gray-600 text-center'}>
        {text}
      </p>
    </div>
  )
}

export default ListEmpty
