import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Container, Link as LinkMui, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import NetworkStudent from '../../lib/NetworkStudent';
import LoadingComponent from '../../components/shared/LoadingComponent';
import Scrollbar from '../../components/NOT_Classified/Scrollbar';
import numeral from 'numeral';
import dayjs from 'dayjs';
import { PATH_STUDENT } from '../../routes/paths';
import MessageBox from '../../components/shared/MessageBox';

const StudentInquiryMainPage = (props) => {

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [inquiries, setInquiries] = useState([]);
  const LIMIT = 10;

  const _load = async () => {
    try {
      const res = await NetworkStudent
        .inquiriesGET({
          offset: (page - 1) * LIMIT,
          limit: LIMIT,
        });
      setInquiries(res.inquiries);
      setMeta(res.meta);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  }

  useEffect(() => {
    _load();
  }, [])

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'lg'}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            1:1문의
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format('0,0')}개
          </p>
        </div>
        <Button variant="contained" component={Link} to={PATH_STUDENT.inquiry.create}>
          신규등록
        </Button>
      </div>
      <MessageBox
        sx={{my: 3}}
        title={'1:1 문의 안내'}
        content={'서비스 이용에 대해서 궁금한 점이 있으시면, 1:1문의로 알려주세요.\n최대 영업일 2일 이내에 답변을 드립니다. \n1:1문의는 학교 선생님에게 전달되지 않으며, 리더스 스쿨 운영회사에 전달됩니다. \n과제에 대한 질문이 있는 경우, 각 담당 선생님께 문의하세요.'}
      />
      <Box sx={{position: 'relative'}} my={2}>
        <Pagination
          // sx={{mt: 3, mb: 3}}
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
        />
      </Box>
      <Scrollbar>
        <TableContainer sx={{minWidth: 800, position: 'relative'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>상태</TableCell>
                <TableCell>제목</TableCell>
                <TableCell>내용</TableCell>
                <TableCell>작성일</TableCell>
                <TableCell>답변일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inquiries?.map((inquiry) => {
                const {answeredAt} = inquiry;
                return (
                  <TableRow key={inquiry.id}>
                    <TableCell>
                      {!!answeredAt ? `답변 완료` : '대기중'}
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.inquiry.detail(inquiry.id)}
                        className={'line-clamp-1 max-w-[250px] text-primary-500'}>
                        {inquiry?.title || '-'}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.inquiry.detail(inquiry.id)}
                        className={'line-clamp-1 max-w-[250px] text-primary-500'}>
                        {inquiry.question?.replace?.(/\n/gi, ' ')}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      {dayjs(inquiry.createdAt).format('MM.DD (ddd)')}
                    </TableCell>
                    <TableCell>
                      {
                        !!answeredAt && (
                          <Typography variant={'tableContent'}>
                            {dayjs(inquiry.answeredAt).format('MM.DD (ddd)')}
                          </Typography>
                        )
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>

  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentInquiryMainPage);
