import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Network from "../../lib/Network";
import {Button, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import {message} from "../../constants";
import dayjs from "dayjs";
import numeral from "numeral";
import {PATH_TEACHER} from "../../routes/paths";

const CourseDetailTaskTab = (props) => {

  const {courseId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const keyword = query.get("keyword") || "";

  const [isLoaded, setLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const _load = async () => {
      try {
        const result = await Network.tasksGET({
          courseId,
          page: (page - 1) * perPage,
          limit: perPage,
          keyword,
        });
        setMeta(prev => ({...prev, ...result.meta}));
        setTasks(result.tasks || []);
        setLoaded(true);
      } catch (error) {
        window.alert(error.cause?.errorMessage);
      }
    }
    _load()
  }, [courseId, page, perPage, keyword]);

  useEffect(() => {
    return () => {
      setLoaded(false); // 안하면 라우팅했다가 페이지 옮길때 로딩전 페이지가 보임
    };
  }, []);

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  if (!isLoaded) return <div/>

  return (
    <div className="space-y-2 mt-4">
      <Link to={PATH_TEACHER.course.detail.task.create(courseId)}>
        <Button variant={'contained'}>
          과제 만들기
        </Button>
      </Link>
      <SearchTextField
        placeholder={message.placeholder.findTasks}
        defaultValue={keyword}
        onClickClear={() => navigate(location.pathname)}
        onSubmit={_handleSearch}
      />
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <TableContainer sx={{minWidth: 'max-content'}}>
        <Table>
          <TableHead>
            <TableRow>
              {
                [
                  {name: "No.", className: ""},
                  {name: "과제명", className: "w-1/4"},
                  {name: "과제상태", className: ""},
                  {name: "기간", className: ""},
                  {name: "진행상태", className: ""},
                  {name: "문항", className: "w-1/8"},
                  {name: "독후감", className: "w-1/8"},
                ].map((head) => (
                  <TableCell key={head.name} className={head.className}>
                    {head.name}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tasks.map((task, i) => {
                return (
                  <TableRow key={task.id}>
                    <TableCell>
                      {(page - 1) * perPage + 1 + i}
                    </TableCell>
                    <TableCell>
                      <Link to={PATH_TEACHER.task.detail.root(task.id)}>
                        <p className="clickable-primary text-hidden w-64 md:w-fit max-w-[16rem] md:max-w-none">
                          {task.title}
                        </p>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {!task.isPublished ? <span className="italic">임시저장</span> : "공개"}
                    </TableCell>
                    <TableCell>
                      {dayjs(task.startDate).format("YYYY.M.D (dd)")} ~ {dayjs(task.endDate).format("YYYY.M.D (dd)")}
                    </TableCell>
                    <TableCell>
                      {dayjs(task.startDate) > dayjs() ? (
                        `D-${dayjs(task.startDate).diff(dayjs(), "day")}`
                      ) : dayjs(task.startDate) <= dayjs() && (dayjs().isSame(task.endDate, "day") || dayjs(task.endDate) > dayjs()) ? (
                        <span className="text-success-500">진행중</span>
                      ) : (
                        "진행종료"
                      )}
                    </TableCell>
                    <TableCell>
                      {numeral(task.stat.questionCount).format("0,0")}
                    </TableCell>
                    <TableCell>
                      {numeral(task.stat.bookReportCount).format("0,0")}
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {tasks.length === 0 && (
        <div className={'p-2 rounded border border-gray-100 bg-gray-50 mt-4'}>
          <p className={'text-gray-400 text-center'}>
            데이터가 없습니다.
          </p>
        </div>
      )}
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(CourseDetailTaskTab);
