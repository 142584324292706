import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { Button, Card, Container, Grid } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';

import {termTypesObj} from "../../constants";
import Network from "../../lib/Network";
import message from '../../constants/message';

import BookReport from "../../components/teacher/BookReport";
import FlatList from "../../assi-design-system-react/components/FlatList";
import {PATH_TEACHER} from "../../routes/paths";
import ListEmpty from "../../components/shared/ListEmpty";
import Course from "../../components/teacher/Course";
import Loader from "../../assi-design-system-react/components/Loader";
import NewCourseDialog from '../../components/teacher/NewCourseDialog';

const TeacherHome = (props) => {

  const [newCourseModalVisible, setNewCourseModalVisible] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [courses, setCourses] = useState([]);
  const [bookReports, setBookReports] = useState([]);

  const [semesters, setSemesters] = useState([]);
  // TODO semester 는 어디에다가 쓰려고 내가 보냈을까..  22.9.9


  useEffect(() => {
    const _loadBookReports = async () => {
      try {
        const result = await Network.bookReportsGET({
          offset: 0,
          limit: 5,
          teacherUserId: props.auth.user?.id,
          isScored: false,
        });
        setBookReports(result.bookReports);
        setMeta({total: result.meta.total});
        return true;
      } catch (error) {
        window.alert(error.cause?.errorMessage)
        return false;
      }
    }
    const _loadCourses = async () => {
      try {
        const result = await Network.coursesGET({
          offset: 0,
          limit: 5,
          teacherUserId: props.authReducer?.user?.id,
        });
        setMeta({...meta, total: result.meta.total});
        setCourses(result.courses || []);

        const semesters = result.semesters?.map((s) => ({...s, name: termTypesObj[s.term]?.name})) || [];
        setSemesters(semesters);
        return true;
      } catch (error) {
        window.alert(error.cause?.errorMessage)
        return false;
      }
    }
    const _load = async () => {
      await _loadCourses();
      await _loadBookReports();
      setIsLoaded(true);
    }
    _load();
  }, []);


  if (!isLoaded) return <Loader/>;

  return (
    <Container maxWidth={'xl'}>
      <div className="grid md:grid-cols-2 gap-2 space-x-0 md:space-x-4 space-y-2 md:space-y-0">
        <div className="relative">
          <div className="flex flex-col justify-between h-20">
            <div className="flex justify-between items-center">
              <p className={'text-xl font-bold'}>
                내 최근 수업/활동
              </p>
              <Link to={PATH_TEACHER.course.root} className="clickable-primary">
                전체보기
              </Link>
            </div>

            <Button
              sx={{alignSelf: 'flex-start'}}
              startIcon={<AddOutlined/>}
              onClick={() => setNewCourseModalVisible(true)}
              variant={'contained'}
            >
              새로운 수업/활동 만들기
            </Button>
          </div>
          <div className="my-6 max-w-2xl space-y-1">
            <FlatList
              data={courses}
              ListEmptyComponent={<ListEmpty text={message.blank.emptyCourseOpened}/>}
              renderItem={({item}) => <Course key={item.id} course={item}/>}/>
          </div>
        </div>
        <div className="relative">
          <div className="flex flex-col justify-between h-20">
            <div className="flex justify-between items-center">
              <p className={'text-xl font-bold'}>
                채점하지 않은 독후감
              </p>
              <Link to={PATH_TEACHER.bookReport.root + '?isScored=false'} className="clickable-primary">
                전체보기
              </Link>
            </div>
          </div>
          <div className="my-6 space-y-1">
            <FlatList
              data={bookReports}
              renderItem={({item, index}) => <BookReport bookReport={item}/>}
              ListEmptyComponent={<ListEmpty text={message.blank.emptyNotScoredBookReports}/>}
            />
          </div>
        </div>
      </div>
      <NewCourseDialog open={newCourseModalVisible} onCancel={() => setNewCourseModalVisible(false)}/>
    </Container>

  );
};

const enhance = connect((state) => ({ ...state }));
export default enhance(TeacherHome);
