import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NetworkStudent from "../../lib/NetworkStudent";
import LoadingComponent from "../../components/shared/LoadingComponent";
import LoadingButton from "@mui/lab/LoadingButton";
import numeral from "numeral";
import dayjs from "dayjs";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Card,
  Box,
  Paper,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputBase,
  DialogActions,
} from "@mui/material";
import StudentAnswerComponent from "../../components/student/StudentAnswerComponent";
import BookReportComment from "../../components/teacher/BookReportComment";
import * as Icon from "@mui/icons-material";
import MessageBox from "../../components/shared/MessageBox";
import BottomFloatComponent from "../../components/NOT_Classified/BottomFloatComponent";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { PATH_STUDENT } from "../../routes/paths";

const StudentBookReportDetailPage = (props) => {
  const {bookReportId} = useParams();
  const {collapseClick, isCollapse, onToggleCollapse} = useCollapseDrawer();

  const navigate = useNavigate();
  const location = useLocation();

  // 독후감 데이터 관련
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [bookReport, setBookReport] = useState({});

  // 제출 관련
  const [uploading, setUploading] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  // 댓글 관련
  const [bookReportComments, setBookReportComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [uploadingComment, setUploadingComment] = useState(false);

  const _load = async () => {
    if (!bookReportId) return;
    try {
      const res = await NetworkStudent.bookReportGET({bookReportId});

      setBookReport(res.bookReport);
      setIsLoaded(true);
      if (!!res.bookReport.submittedAt) {
        _loadBookReportComment();
      }
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  };
  const _submit = async () => {
    if (uploading) return;
    setUploading(true);
    try {
      const res = await NetworkStudent.bookReportSubmitPOST({bookReportId});
      window.alert("제출 되었습니다");
      await _load();
      _closeSubmitDialog();
      window.scrollTo(0, 0)
    } catch (e) {
    } finally {
      setUploading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [bookReportId]);

  const _loadBookReportComment = async () => {
    try {
      const res = await NetworkStudent.bookReportCommentsGET({
        bookReportId,
        offset: 0,
        limit: 100, // TODO 여기 더 불러오기 형태로 바꿔야 하는데...
      });
      setBookReportComments(res.bookReportComments);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    } finally {
    }
  };
  const _uploadBookReportComments = async () => {
    if (newComment?.trim() === "") return;
    setUploadingComment(true);
    try {
      const res = await NetworkStudent.bookReportCommentPOST({
        bookReportId,
        content: newComment,
      });
      setNewComment("");
      await _loadBookReportComment();
    } catch (e) {
    } finally {
      setUploadingComment(false);
    }
  };

  const _openSubmitDialog = () => {
    setSubmitModalOpen(true);
  };
  const _closeSubmitDialog = () => {
    setSubmitModalOpen(false);
    setConfirmChecked(false);
  };

  const _goToEditPage = () => {
    navigate(`/book-report/${bookReportId}/edit`);
  };

  if (!isLoaded) return <LoadingComponent/>;

  const _renderSubmitDialog = () => {
    return (
      <Dialog fullWidth open={submitModalOpen}>
        <DialogTitle>독후감을 제출합니다.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            작성하지 않은 문항이 있는지, 제출선생님을 올바르게 선택하였는지 확인해 주세요.
            <br/>
            제출후에는 변경할 수 없습니다.
          </DialogContentText>
        </DialogContent>
        <Divider/>
        <DialogContent sx={{height: "40vh"}}>
          <Box sx={{mb: 2}}>
            <Typography variant={"h6"}>도서 선택</Typography>
            {!!bookReport?.bookId ? <Typography>[{bookReport?.book?.title?.slice(0, 15)}]</Typography> : <Typography>선택하지 않았어요.</Typography>}
          </Box>
          <Box>
            <Typography variant={"h6"}>문항</Typography>
            {bookReport.answers?.map((item, index) => {
              let text = "";
              let completed = true;
              switch (item.question?.questionType) {
                case "TEXT": {
                  if (item.content?.length < 1) {
                    text = `작성하지 않았어요.`;
                    completed = false;
                  } else {
                    text = `${numeral(item.content?.length).format("0,0")} (공백 제외 ${
                      numeral(item.content?.replace?.(/ /gi, "")?.replace?.(/\n/gi, "").length).format("0,0") || "0"
                    }) 자 작성했어요.`;
                  }
                  break;
                }
                case "VIDEO": {
                  if (item.videoUrls?.length < 1) {
                    text = `작성하지 않았어요.`;
                    completed = false;
                  } else {
                    text = `${numeral(item.videoUrls?.length).format("0,0")} 개 동영상을 첨부했어요.`;
                  }
                  break;
                }
                case "IMAGE": {
                  if (item.imageUrls?.length < 1) {
                    text = `작성하지 않았어요.`;
                    completed = false;
                  } else {
                    text = `${numeral(item.imageUrls?.length).format("0,0")} 개 이미지를 첨부했어요.`;
                  }
                  break;
                }
                case "FILE": {
                  if (item.fileUrls?.length < 1) {
                    text = `작성하지 않았어요.`;
                    completed = false;
                  } else {
                    text = `${numeral(item.fileUrls?.length).format("0,0")} 개 파일을 첨부했어요.`;
                  }
                  break;
                }
                default:
              }
              return (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: "#efefef",
                    borderRadius: 1,
                    borderColor: completed ? "transparent" : "#F04452",
                    borderWidth: 3,
                    p: 1,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: 30,
                      height: 30,
                      bgcolor: completed ? "primary.main" : "#F04452",
                      borderRadius: 2,
                      alignItems: "center",
                      justifyContent: "center",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.question?.order + 1}
                    </Typography>
                  </Box>
                  <Typography sx={{color: completed ? "" : "#F04452"}}>{text}</Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{mb: 2}}>
            <Typography variant={"h6"}>제출 선생님</Typography>
            <Typography>{bookReport?.teacherUser?.name}</Typography>
          </Box>
        </DialogContent>
        <Divider/>
        <DialogContent>
          <FormControlLabel control={<Checkbox onChange={(e, checked) => setConfirmChecked(checked)} checked={confirmChecked}/>} label={"작성 내용을 확인 하였습니다."}/>
        </DialogContent>
        <DialogActions>
          <Button variant={"contained"} disabled={!confirmChecked} onClick={_submit}>
            제출
          </Button>
          <Button onClick={_closeSubmitDialog}>취소</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const _renderBookPart = () => {
    if (!bookReport?.book?.id) {
      return (
        <Box sx={{mt: 5, mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            선택 도서
          </Typography>
          <Divider sx={{mb: 3, mt: 1}}/>
          <p className={"text-gray-500"}>도서가 선택되지 않았습니다.</p>
        </Box>
      );
    } else {
      return (
        <Box sx={{mt: 5, mb: 10}}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            읽은 책
          </Typography>
          <Divider sx={{mb: 3, mt: 1}}/>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img className={"w-16 object-contain border border-black"} src={bookReport.book?.coverImageUrl}/>
            <Box sx={{pl: 2, flex: 1}}>
              <Typography className={"line-clamp-2"}>{bookReport.book?.title}</Typography>
              <Typography>{bookReport?.book?.authors}</Typography>
              <Typography>
                {bookReport?.book?.publisher} ({bookReport?.book?.pubDate})
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
  };
  const _renderAnswerPart = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          문항
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        {bookReport?.answers?.map((item, index) => {
          return <StudentAnswerComponent key={index} answer={item}/>;
        })}
      </Box>
    );
  };
  const _renderTaskAndCourseInfo = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          기본 정보
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        <Box sx={{}}>
          <p className={"text-gray-500 font-bold text-sm"}>제출일</p>
          {!!bookReport.submittedAt ? (
            <Typography>{dayjs(bookReport.submittedAt).format("YYYY.MM.DD(ddd) HH:mm")}</Typography>
          ) : (
            <Typography>아직 독후감이 제출되지 않았습니다.</Typography>
          )}
        </Box>
        <Box sx={{mt: 2}}>
          <p className={"text-gray-500 font-bold text-sm"}>기한</p>
          <Typography>{dayjs(bookReport.task?.endDate).format("YYYY.MM.DD(ddd) HH:mm")}</Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <p className={"text-gray-500 font-bold text-sm"}>과제명</p>
          <Typography>{bookReport.task?.title}</Typography>
        </Box>
        {!!bookReport?.task?.course && (
          <Box sx={{mt: 2}}>
            <p className={"text-gray-500 font-bold text-sm"}>수업명</p>
            <Typography>{bookReport?.task?.course?.title}</Typography>
          </Box>
        )}
        {bookReport?.teacherUser?.id && (
          <Box sx={{mt: 2}}>
            <p className={"text-gray-500 font-bold text-sm"}>제출 대상 선생님</p>
            <Typography className={"text-blue-600 hover:underline"}>
              <Link to={PATH_STUDENT.teacher.detail(bookReport.teacherUserId)}>
                {bookReport?.teacherUser?.name}
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  const _renderBottomButtons = () => {
    if(!!bookReport.submittedAt && !bookReport.task?.permitEditAfterSubmit) return <div/>
    return (
      <BottomFloatComponent className={"bg-white shadow-[0_35px_60px_-25px_rgba(0,0,0,0.3)] py-4"} collapseClick={collapseClick}>
        <Container sx={{}} maxWidth={"md"}>
          <Button onClick={_goToEditPage} size={"large"} variant={"contained"}>
            <Icon.Edit sx={{mr: 2}}/>
            수정하기
          </Button>
          {
            !bookReport.submittedAt && (
              <Button
                disabled={!bookReport.teacherUserId}
                onClick={_openSubmitDialog}
                sx={{ml: 3}}
                size={"large"}
                color={"success"}
                variant={"contained"}
              >
                <Icon.PublishOutlined sx={{mr: 2}}/>
                {!!bookReport.teacherUserId ? `제출하기` : `제출대상 선생님을 선택해주세요.`}
              </Button>
            )
          }
          {_renderSubmitDialog()}
        </Container>
      </BottomFloatComponent>
    );
  };
  const _renderReportCommentPart = () => {
    return (
      <Box sx={{mt: 5, mb: 5}}>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          의견
        </Typography>
        <Divider sx={{mb: 1, mt: 1}}/>
        <MessageBox sx={{mb: 3}} content={"독후감이 제출된 이후에는\n채점을 과목 담당선생님과 독후감을 받아보시는 선생님은 댓글을 작성하거나 확인할 수 있습니다."}/>
        {bookReportComments.map((item, index) => {
          return (
            <Box key={item.id}>
              <BookReportComment loadBookReportComment={_loadBookReportComment} bookReportComment={item}/>
              {item.bookReportComments?.map((comment, i) => {
                return <BookReportComment key={i} loadBookReportComment={_loadBookReportComment} bookReportComment={comment}/>;
              })}
            </Box>
          );
        })}
        <TextField
          fullWidth
          label="댓글을 달아보세요."
          multiline
          rows={4}
          // defaultValue="Default Value"
          value={newComment || ""}
          onChange={(e) => {
            // setInquiry(prev => ({...prev, answer: e.target.value}))
            if (e.target.value?.length > 1000) return;
            setNewComment(e.target.value);
          }}
          placeholder={"과목 담당선생님과 독후감을 받아보시는 선생님은 댓글을 확인할 수 있습니다."}
        />
        <Typography sx={{mt: 1, mb: 2}}>{newComment?.length > 0 ? `${newComment.length}/` : ""}1000자 이내</Typography>
        <LoadingButton onClick={_uploadBookReportComments} loading={uploadingComment} disabled={newComment?.trim()?.length < 1 || uploadingComment} variant={"contained"}>
          의견 남기기
        </LoadingButton>
      </Box>
    );
  };
  const _renderTitle = () => {
    return (
      <Box sx={{mt: 5, mb: 10}}>
        <Typography variant={"subtitle1"} sx={{fontWeight: "800"}}>
          독후감 제목
        </Typography>
        <Typography variant={"h5"} sx={{fontWeight: "800"}}>
          {bookReport?.title}
        </Typography>
      </Box>
    );
  };

  const _renderNotSubmittedMessage = () => {
    if (!!bookReport.submittedAt) return null;
    return (
      <div className={"border border-error-500 bg-error-50 bg-opacity-50 p-4 rounded-[8px]"}>
        <h4 className={"text-error-600 font-bold"}>독후감을 제출해야 합니다.</h4>
        <p className={"whitespace-pre-line text-error-500"}>독후감이 아직 제출되지 않았어요. 작성을 마무리 하고 제출해 주세요.</p>
        <p className={"whitespace-pre-line text-error-500"}>마감: {dayjs(bookReport?.task?.endDate).format("YYYY.MM.DD(ddd)")}</p>
      </div>
    );
  };

  return (
    <Container maxWidth={"md"} sx={{}}>
      {_renderNotSubmittedMessage()}
      {_renderTitle()}
      {_renderBookPart()}
      {_renderAnswerPart()}
      {_renderTaskAndCourseInfo()}
      {!!bookReport.submittedAt && _renderReportCommentPart()}
      {_renderBottomButtons()}
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentBookReportDetailPage);
