import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Box, Button, Container, Link as LinkMui, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, TextField,} from "@mui/material";
import dayjs from "dayjs";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import {courseTypesObj, termTypesObj} from "../../constants";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import {PATH_ADMIN} from "../../routes/paths";
import numeral from "numeral";

// ----------------------------------------------------------------------

const YEAR_OPTIONS = [2022, 2021, 2020];

// ----------------------------------------------------------------------

const AdminCoursePage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const year = query.get("year") || dayjs().format("YYYY");
  const semesterId = query.get("semesterId") || "";
  const keyword = query.get("keyword") || "";
  const {subjectId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [searchParams] = useSearchParams();
  const [meta, setMeta] = useState({total: 0});
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);

  const _load = async () => {
    const res = await Network.coursesGET({
      year,
      offset: (page - 1) * LIMIT,
      limit: LIMIT,
      semesterId: semesterId || undefined,
      subjectId: subjectId || undefined,
      keyword: keyword || undefined,
    });
    if (!res.err) {
      setIsLoaded(true);
      setCourses(res.courses);
      setMeta(res.meta);
    }
  };

  const _loadSemesters = async () => {
    const res = await Network.semestersGET({year});
    if (!res.err) {
      setSemesters(res.semesters);
    }
  };

  useEffect(() => {
    _load();
  }, [year, page, semesterId, keyword]);
  useEffect(() => {
    _loadSemesters();
  }, [year]);

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  };

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={"xl"}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            수업 관리
          </p>
          <p className={'text-lg font-light'}>
            총 {numeral(meta.total).format("0,0")}개
          </p>
        </div>
        <Button variant="contained" component={Link} to={PATH_ADMIN.course.create} >
          신규등록
        </Button>
      </div>

      <Stack spacing={1} direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2, alignItems: "center"}}>
        <TextField
          fullWidth
          select
          label="연도"
          value={year}
          onChange={(e) => navigate(`${location.pathname}?year=${e.target.value}`, {replace: true})}
          sx={{
            maxWidth: {xs: "full", sm: 100},
            textTransform: "capitalize",
          }}
        >
          {YEAR_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="학기"
          value={semesterId}
          onChange={(e) => navigate(`${location.pathname}?year=${year}&semesterId=${e.target.value}`, {replace: true})}
          sx={{
            maxWidth: {xs: "full", sm: 100},
            textTransform: "capitalize",
          }}
        >
          <MenuItem value={""}>
            <em>전체</em>
          </MenuItem>
          {semesters.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {termTypesObj[option.term]?.name}
            </MenuItem>
          ))}
        </TextField>
        <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword}/>
      </Stack>

      <Box sx={{position: "relative"}} mb={2}>
        <Pagination
          page={page}
          count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}&year=${year}&semesterId=${semesterId}`} {...item} />}
        />
      </Box>

      <Scrollbar>
        <TableContainer sx={{minWidth: "max-content"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>학년도</TableCell>
                <TableCell>학기</TableCell>
                <TableCell>제목</TableCell>
                <TableCell>교과/비교과</TableCell>
                <TableCell>과목</TableCell>
                <TableCell>선생님</TableCell>
                <TableCell>학생수</TableCell>
                <TableCell>과제수</TableCell>
                <TableCell>시작일</TableCell>
                <TableCell>종료일</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses?.map((course) => {
                return (
                  <TableRow key={course.id}>
                    <TableCell>{course.semester?.year || "미지정"}</TableCell>
                    <TableCell>{course.semester?.term ? termTypesObj[course.semester?.term].name : "미지정"}</TableCell>
                    <TableCell>
                      <LinkMui className={'text-[#1370F5]'} as={Link} to={PATH_ADMIN.course.detail(course.id)}>
                        <div className={"line-clamp-1 max-w-[150px]"}>
                          {course.title?.slice(0, 30)}
                        </div>
                      </LinkMui>

                    </TableCell>
                    <TableCell>{courseTypesObj[course.courseType]?.name}</TableCell>
                    <TableCell>
                      <div className={"line-clamp-1 max-w-[100px]"}>
                        {course.subject?.title || "-"}
                      </div>
                    </TableCell>
                    <TableCell>{course.teacherUser?.name || "-"}</TableCell>
                    <TableCell>{course.stat?.registerCount}</TableCell>
                    <TableCell>{course.stat?.taskCount}</TableCell>
                    <TableCell>{dayjs(course.startDate).format("YY.MM.DD(ddd)")}</TableCell>
                    <TableCell>{dayjs(course.endDate).format("YY.MM.DD(ddd)")}</TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
};

const enhance = connect((state) => ({...state}), {});
export default enhance(AdminCoursePage);
