import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import Network from "../../lib/Network";
import { Button, Container, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import classNames from '../../assi-design-system-react/utils/classNames'
import { PATH_TEACHER } from "../../routes/paths";

const TaskDetailStudentTab = (props) => {

  const {taskId} = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const keyword = query.get("keyword") || "";

  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({})
  const [users, setUsers] = useState([]);


  useEffect(() => {
    const _load = async () => {
      try {
        const res = await Network.taskUsersGET({
          taskId,
          offset: (page - 1) * perPage,
          limit: perPage,
          keyword,
        })
        setUsers(res.users);
        setMeta(res.meta);
      } catch (e) {
        window.alert(e.cause.errorMessage);
      }
    }
    _load();
  }, [])

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              No.
            </TableCell>
            <TableCell>
              이름
            </TableCell>
            <TableCell>
              학년
            </TableCell>
            <TableCell>
              반
            </TableCell>
            <TableCell>
              번호
            </TableCell>
            <TableCell>
              제출여부
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            users.map((user, index) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    {(page - 1) * perPage + 1 + index}
                  </TableCell>
                  <TableCell>
                    {user.name}
                  </TableCell>
                  <TableCell>
                    {user.classRegister?.class?.grade}
                  </TableCell>
                  <TableCell>
                    {user.classRegister?.class?.classNo}
                  </TableCell>
                  <TableCell>
                    {user.classRegister?.studentNo}
                  </TableCell>
                  <TableCell className={classNames(!!user.bookReport?.id ? 'text-green-500' : 'text-red-500')}>
                    {!!user.bookReport?.id ? (
                      <Link to={PATH_TEACHER.bookReport.detail.root(user.bookReport?.id)} className={'clickable-primary'}>
                        제출
                      </Link>
                    ) : '미제출'}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TaskDetailStudentTab);
