import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { NAVBAR } from "../../config/mui.config";

const BottomFloatComponent = styled(Box, { shouldForwardProp: (prop) => prop !== "collapseClick" })(({ collapseClick, theme }) => ({
  borderColor: theme.palette.grey[500_24],
  borderTopWidth: 1,
  borderTopStyle: "dashed",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  padding: "1rem 0",
  display: "flex",
  [theme.breakpoints.up("lg")]: {
    left: NAVBAR.DASHBOARD_WIDTH,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    // transition: theme.transitions.create("margin-left", {
    //   duration: theme.transitions.duration.shorter,
    // }),
    ...(collapseClick && {
      left: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export default BottomFloatComponent;
