import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Chip, Container, Divider, Link as LinkMui, Typography } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import NetworkStudent from '../../lib/NetworkStudent';
import LoadingComponent from '../../components/shared/LoadingComponent';
import dayjs from 'dayjs';
import { questionTypeText } from '../../constants/dataTypes';

const StudentTaskDetailPage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  const {taskId, courseId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [task, setTask] = useState({});

  const _load = async () => {
    try {
      const res = await NetworkStudent
        .taskGET({taskId});
      setTask(res.task || {});
      setIsLoaded(true);
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  }
  useEffect(() => {
    _load();
  }, [])

  const _navigateToBookReportWriteScreen = () => {
    // console.log(location.pathname)
    navigate(`${location.pathname}/write-report`)
  }
  const _navigateToBookReportDetailScreen = () => {
    if (!task.bookReportMy?.id) return;
    navigate(`/book-report/${task.bookReportMy?.id}`)
  }

  if (!isLoaded) return <LoadingComponent/>

  const _renderBooks = () => {
    if (task.books?.length > 0) {
      return (
        <Box sx={{my: 5}}>
          <Typography variant={'h5'} sx={{fontWeight: '800'}}>
            추천 도서
          </Typography>
          <Typography variant={'subtitle1'}>
            과제에 선생님이 추천한 도서입니다.
          </Typography>
          <Divider sx={{mb: 3, mt: 1}}/>
          <Box sx={{mt: 2, mb: 2}}>
            {
              task.books?.map((book, i) => {
                return (
                  <Box key={i} sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    <img
                      className={'w-16 object-contain border border-black'}
                      src={book?.coverImageUrl}/>
                    <Box sx={{pl: 2, flex: 1}}>
                      <LinkMui
                        as={Link}
                        target={'_blank'}
                        to={`/book/${book.id}`}
                        className={'line-clamp-2'}>
                        {book?.title}
                      </LinkMui>
                      <Typography>
                        {book?.authors}
                      </Typography>
                      <Typography>
                        {book?.publisher} ({book?.pubDate})
                      </Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      )
    } else {
      return null;
    }

  }
  const _renderQuestions = () => {
    return (
      <Box sx={{my: 5}}>
        <Typography variant={'h5'} sx={{fontWeight: '800'}}>
          문항
        </Typography>
        <Typography variant={'subtitle1'}>
          문항에 따라 답해 주세요.
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        <div className={'w-full mt-4 mb-4'}>
          {
            task.questions?.map((question, index) => {
              return (
                <div className={'mb-4'} key={index}>
                  <Box sx={{display: 'flex', mb: 1}}>
                    <Box sx={{
                      display: 'flex',
                      width: 30,
                      height: 30,
                      bgcolor: 'primary.main',
                      borderRadius: 2,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      <Typography
                        sx={{
                          color: 'white',
                          fontWeight: 'bold',
                        }}>
                        {question?.order + 1}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{pl: 1, fontWeight: 'bold', mt: 0.2, fontSize: '1.2rem'}}
                    >
                      {question?.title}
                    </Typography>
                  </Box>
                  <p className={'whitespace-pre-line text-gray-500 line-clamp-3 mb-2'}>
                    {question?.description}
                  </p>
                  <Chip variant="contained" // color="success"
                        sx={{mb: 2}}
                        label={`답변 유형: ${questionTypeText[question?.questionType]}`}
                  />
                </div>
              )
            })
          }
        </div>
      </Box>
    )
  }
  const _renderAttachedFile = () => {
    if (task.fileUrls?.length < 1) return null;
    return (
      <Box sx={{my: 5}}>
        <Typography variant={'h5'} sx={{fontWeight: '800'}}>
          첨부 파일
        </Typography>
        <Typography variant={'subtitle1'}>
          선생님이 첨부한 파일이 있는경우 표시됩니다.
        </Typography>
        <Divider sx={{mb: 3, mt: 1}}/>
        <Box sx={{width: '100%', mt: 2, mb: 2}}>
          {task.fileUrls?.map((fileUrl, index) => {
            return (
              <Box key={index} sx={{mt: 1, mb: 1, display: 'flex', justifyContent: 'space-between'}}>
                <p>
                  {fileUrl.fileName}
                </p>
                <a target={'_blank'}
                   href={fileUrl.url}
                   className="line-clamp-1 whitespace-pre-line text-ellipsis overflow-hidden"
                >

                  <Button
                    variant={'outlined'}
                    size={'small'}
                  >
                    다운받기
                  </Button>
                </a>
              </Box>
            )
          })}
        </Box>
      </Box>
    )
  }
  const _renderMyBookReportPart = () => {
    if (dayjs().isBefore(task.startDate)) {
      return (
        <div className={'border border-[#FFC107] bg-[#FFF7CD] p-4 rounded-[8px]'}>
          <h4 className={'text-[#B78103] font-bold'}>
            과제 시작 전
          </h4>
          <p className={'whitespace-pre-line text-[#B78103]'}>
            독후감 작성은 과제 시작일({dayjs(task.startDate).format('YYYY.MM.DD(ddd) HH:mm')})부터 가능합니다.
          </p>
        </div>
      )
    }
    if (!task?.bookReportMy) {
      return (
        <div className={'border border-primary-500 bg-primary-50 bg-opacity-50 p-4 rounded-[8px]'}>
          <h4 className={'text-primary-600 font-bold'}>
            독후감 작성하기
          </h4>
          <p className={'whitespace-pre-line text-primary-500'}>
            독후감이 작성 되지 않았습니다.
          </p>
          <Button
            sx={{mt: 3}}
            variant={'contained'}
            onClick={_navigateToBookReportWriteScreen}
          >
            작성하기
          </Button>
        </div>
      )

    } else if (!task.bookReportMy?.submittedAt) {
      return (
        <div className={'border border-error-500 bg-error-50 bg-opacity-50 p-4 rounded-[8px]'}>
          <h4 className={'text-error-600 font-bold'}>
            독후감을 제출해야 합니다.
          </h4>
          <p className={'whitespace-pre-line text-error-500'}>
            마감: {dayjs(task?.endDate).format('YYYY.MM.DD(ddd)')}
          </p>

          <Button
            sx={{mt: 3}}
            color={'error'}
            variant={'contained'}
            onClick={_navigateToBookReportDetailScreen}
          >
            내 독후감 보기
          </Button>
        </div>
      )
    } else {
      return (
        <div className={'border border-success-500 bg-success-50 bg-opacity-50 p-4 rounded-[8px]'}>
          <h4 className={'text-success-600 font-bold'}>
            독후감을 제출하였습니다.
          </h4>
          <p className={'whitespace-pre-line text-success-500'}>
            제출일 : {dayjs(task.bookReportMy?.submittedAt).format('YYYY.MM.DD(ddd) HH:mm')}
          </p>
          <p className={'whitespace-pre-line text-success-500'}>
            제목 : {task.bookReportMy?.title}
          </p>
          <Button
            sx={{mt: 3}}
            color={'success'}
            variant={'contained'}
            onClick={_navigateToBookReportDetailScreen}
          >
            내 독후감 보기
          </Button>
        </div>
      )
    }
  }
  return (
    <Container maxWidth={'lg'}>
      <Box sx={{mt: 5, mb: 5}}>
        <Typography variant={'h5'} gutterBottom>
          {task.title}
        </Typography>
        <Typography variant={'subtitle1'} gutterBottom className={'whitespace-pre-line'}>
          {task.description}
        </Typography>
        <Typography>
          시작: {dayjs(task.startDate).format('YY.MM.DD(ddd) HH:mm')}
        </Typography>
        <Typography>
          마감: {dayjs(task.endDate).format('YY.MM.DD(ddd) HH:mm')}
        </Typography>
      </Box>
      {_renderMyBookReportPart()}
      {_renderAttachedFile()}
      {_renderQuestions()}
      {_renderBooks()}

    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentTaskDetailPage);
