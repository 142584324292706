import Network from "../../lib/Network";
import { createLogic } from "redux-logic";
import { authActions, authActionCreators } from "./auth.store";
import NetworkStudent from "../../lib/NetworkStudent";

const appInitializeLogic = createLogic({
  type: authActions.APP_INITIALIZE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const token = localStorage.getItem("token");
    if (!!token) {
      Network.setToken(token);
      NetworkStudent.setToken(token);
      dispatch(authActionCreators.fetchMyInfo(() => {
        action?.cb?.();
      }));
    } else {
      action?.cb?.();
    }
    done();
  },
});

const fetchMyInfoLogic = createLogic({
  type: authActions.FETCH_MY_INFO,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    try {
      const res = await Network.userMeGET();
      dispatch(authActionCreators.fetchMyInfoSuccess({user: res.user}));
      if (!!res.token) {
        localStorage.setItem("token", res.token);
      }
    } catch (error) {
      window.alert('로그인 정보가 잘못되었습니다');
      if (error.cause.status === 401 || error.cause.status === 419) {
        localStorage.removeItem("token");
        dispatch(authActionCreators.logout())
      }
    }
    action?.cb?.();
    done();
  },
});

const logoutLogic = createLogic({
  type: authActions.LOGOUT,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    localStorage.clear()
    done();
  },
})


export default [
  appInitializeLogic,
  fetchMyInfoLogic,
  logoutLogic,
]
