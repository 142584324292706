import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notificationActionCreators } from "../../redux/notification/notification.store";
import { message } from "../../constants";
import Notification from "../../components/teacher/Notification";
import { Container, MenuItem, TextField } from "@mui/material";
import FlatList from "../../assi-design-system-react/components/FlatList";
import ListEmpty from "../../components/shared/ListEmpty";
import Loader from "../../assi-design-system-react/components/Loader";


const TeacherNotification = (props) => {

  const _onClickNotification = () => {
  }
  const _renderNotification = ({item, index}) => <Notification key={index} notification={item} onClickNotification={_onClickNotification}/>

  if (!props.notificationReducer?.isLoaded) return <Loader/>

  return (
    <Container maxWidth={'sm'}>
      <FlatList
        data={props.notificationReducer?.notifications || []}
        renderItem={_renderNotification}
        ListEmptyComponent={<ListEmpty text={message.blank.emptyNotifications}/>}
        onEndReached={props.fetchNotificationTeacherMore}
        loadingMore={props.notificationReducer?.loadingMore}
      />
    </Container>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...notificationActionCreators,
  });
export default enhance(TeacherNotification);
