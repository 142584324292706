import React, { useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { Add, ArrowBack, ArrowForward, Check, Remove } from '@mui/icons-material';
import numeral from 'numeral';

import Network from '../lib/Network';
import classNames from '../assi-design-system-react/utils/classNames'
import useResponsive from '../assi-design-system-react/mui/hooks/useResponsive';
import FlatList from '../assi-design-system-react/components/FlatList';

const BookFinder = ({ open = false, onClose = () => {}, onCompleteSelect = () => {}, onAddMultiple = () => {}, includedBookIds = [] }) => {
  const bookFinderDivRef = useRef();
  const LIMIT = 10;

  const isMobile = useResponsive('down', 'sm');

  const [viewingSelectedBooks, setViewingSelectedBooks] = useState(false);
  // 책 찾기
  const [books, setBooks] = useState([]);
  const [booksMeta, setBooksMeta] = useState({ total: 0 });
  const [searching, setSearching] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [endReached, setEndReached] = useState(false);
  // 책 담기
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [keyword, setBookKeyword] = useState('');

  const idOfSelectedBooks = selectedBooks.map(({ id }) => id);
  const idOfSelectedBooksOnceRemoved = selectedBooks.filter(({ isRemoved }) => isRemoved).map(({ id }) => id);
  const selectedBooksNotRemoved = selectedBooks.filter((o) => !o.isRemoved);

  const _onAddBook = (book, isOnceSelected) => {
    if (isOnceSelected) {
      setSelectedBooks((prev) =>
        prev.map((o) => {
          if (o.id === book.id) {
            return { ...o, isRemoved: false };
          } else return o;
        })
      );
    } else {
      setSelectedBooks((prev) => [...prev, { ...book, isRemoved: false }]);
    }
  };

  const _onRemoveBook = (book) => {
    if (viewingSelectedBooks) {
      setSelectedBooks((prev) => [...prev.map((o) => (o.id === book.id ? { ...o, isRemoved: true } : o))]);
    } else {
      setSelectedBooks((prev) => prev.filter((o) => o.id !== book.id));
    }
  };

  const _renderBookSearched = (book) => {
    const isSelected = idOfSelectedBooks.includes(book.id) && !idOfSelectedBooksOnceRemoved.includes(book.id);
    const isOnceSelected = idOfSelectedBooksOnceRemoved.includes(book.id);
    const isAlreadySelected = includedBookIds.includes(book.id);
    if (book.isRemoved) {
      return (
        <div
          key={book.id}
          className='flex items-center justify-between h-32'
        >
          <p className='text-error-500 font-bold'>삭제된 도서</p>
          <Button
            onClick={() => _onAddBook(book, isOnceSelected)}
            sx={{ flexShrink: 0, height: 'fit-content', margin: 'auto 0' }}
            startIcon={<Add />}
          >
            다시 선택
          </Button>
        </div>
      );
    }

    return (
      <div
        key={book.id}
        className='group flex items-stretch space-x-4 w-full h-32 max-h-[8rem] py-2'
      >
        <img
          alt={book.title}
          src={book.coverImageUrl}
          className='min-w-[5rem] w-24 object-contain object-center'
        />
        <div className='flex flex-col w-full'>
          <p className='flex-1 line-clamp-2 text-ellipsis md:group-hover:underline'>{book.title}</p>
          <p className='line-clamp-1 text-ellipsis text-sm'>
            <span className='bind-text-gray-400 font-bold'>작가</span> {book.authors?.replace(/\^/gi, ', ') || ''}
          </p>
          <p className='line-clamp-1 text-ellipsis text-sm'>
            <b className='bind-text-gray-400 font-bold'>출판사</b> {book.publisher}
          </p>
        </div>
        <Button
          color={!isAlreadySelected && isSelected ? 'error' : 'primary'}
          onClick={() => (isSelected ? _onRemoveBook(book) : _onAddBook(book))}
          sx={{ flexShrink: 0, height: 'fit-content', margin: 'auto 0' }}
          startIcon={isAlreadySelected ? <Check /> : isSelected ? <Remove /> : <Add />}
          disabled={isAlreadySelected}
        >
          {isAlreadySelected ? '이미 추가됨' : isSelected ? '빼기' : '선택'}
        </Button>
      </div>
    );
  };

  const _renderItem = ({ item: book }) => _renderBookSearched(book);

  const _search = async (keyword) => {
    try {
      setSearching(true);
      setBooks([]);
      setBookKeyword(keyword);
      const result = await Network.bookSearchGET({
        keyword,
        offset: 0,
        limit: LIMIT,
      });
      setBooks(result.books || []);
      setBooksMeta(result.meta);
      setEndReached(result.books?.length < LIMIT);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    } finally {
      bookFinderDivRef?.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      setSearching(false);
    }
  };

  const _searchMore = async () => {
    if (searching || endReached || loadingMore) return;
    setLoadingMore(true);
    try {
      const result = await Network.bookSearchGET({
        keyword,
        offset: books.length,
        limit: LIMIT,
      });
      setBooks((prev) => [...prev, ...result.books]);
      setEndReached(result.books?.length < LIMIT);
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    } finally {
      setLoadingMore(false);
    }
  };

  const _renderBody = () => {
    if (viewingSelectedBooks) {
      return (
        <div className='pt-6 flex-1'>
          <Button
            onClick={() => setSelectedBooks([])}
            sx={{ ml: 'auto', mr: 0, mb: 2 }}
          >
            모두 비우기
          </Button>
          {selectedBooks.map(_renderBookSearched)}
        </div>
      );
    }

    return (
      <>
        <div
          className={classNames(
            !!booksMeta.total ? 'border-b border-gray-200 dark:border-gray-700' : '',
            'sticky top-0 py-4 z-20 space-x-2 flex items-center justify-between w-full bg-white dark:bg-gray-800'
          )}
        >
          <TextField
            fullWidth
            placeholder='제목, 저자, 번역가, 출판사로 도서 검색'
            defaultValue={keyword}
            onChange={(e) => setBookKeyword(e.target.value?.trim())}
            onKeyDownCapture={(e) => {
              if (!e.target.value) return;
              if (e.which === 13) {
                _search(e.target.value?.trim());
              }
            }}
          />
          {!isMobile && (
            <LoadingButton
              loading={searching}
              onClick={(value) => {
                if (!value) return;
                _search(value.trim());
              }}
            >
              검색
            </LoadingButton>
          )}
        </div>

        <div className='flex-1'>
          <FlatList
            onEndReached={_searchMore}
            onEndReachedThreshold={0.3}
            ItemSeparatorComponent={<div className='h-3 bg-item-separator' />}
            renderItem={_renderItem}
            data={books}
            loadingMore={loadingMore}
          />
        </div>
      </>
    );
  };

  const _renderActions = () => {
    if (viewingSelectedBooks) {
      return (
        <>
          <Button
            onClick={() => setViewingSelectedBooks(false)}
            startIcon={<ArrowBack />}
            variant='outlined'
          >
            도서 선택으로
          </Button>

          <Button
            onClick={() => {
              onCompleteSelect(selectedBooksNotRemoved);
              onClose();
            }}
            disabled={selectedBooks.length === 0}
            variant={viewingSelectedBooks ? 'contained' : 'text'}
          >
            추가 완료 ({numeral(selectedBooksNotRemoved.length).format('0,0')})
          </Button>
        </>
      );
    }
    return (
      <>
        <Button onClick={onClose}>닫기</Button>

        <Button
          onClick={() => {
            setViewingSelectedBooks(true);
            bookFinderDivRef?.current.scrollIntoView(true);
          }}
          disabled={selectedBooks.length === 0}
          endIcon={<ArrowForward />}
          variant='outlined'
        >
          선택 도서 보기 ({numeral(selectedBooks.length).format('0,0')})
        </Button>
      </>
    );
  };

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth='md'
      open={open}
      onClose={onClose}
      onTransitionEnd={() => {
        if (!open) {
          setBooks([]);
          setBooksMeta({ total: 0 });
          setBookKeyword('');
          setViewingSelectedBooks(false);
          setSelectedBooks([]);
        }
      }}
      PaperProps={{
        sx: {
          position: 'relative',
          maxHeight: (theme) => (theme.breakpoints.up('lg') ? '90vh' : '100%'),
          minHeight: (theme) => (theme.breakpoints.up('lg') ? '50vh' : '100%'),
        },
      }}
    >
      <DialogContent
        ref={bookFinderDivRef}
        sx={{ pt: 0 }}
      >
        {_renderBody()}
      </DialogContent>
      <DialogActions
        sx={{ p: 3 }}
        className='sticky bottom-0 w-full flex items-center justify-between bg-white dark:bg-gray-800 border-t border-gray-100 dark:border-gray-700'
      >
        {_renderActions()}
      </DialogActions>
    </Dialog>
  );
};

export default BookFinder;
