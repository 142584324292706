export const notificationStudentActions = {
  FETCH_STUDENT_NOTIFICATION: "FETCH_STUDENT_NOTIFICATION",
  FETCH_STUDENT_NOTIFICATION_SUCCESS: "FETCH_STUDENT_NOTIFICATION_SUCCESS",

  FETCH_STUDENT_NOTIFICATION_MORE: "FETCH_STUDENT_NOTIFICATION_MORE",
  FETCH_STUDENT_NOTIFICATION_MORE_SUCCESS: "FETCH_STUDENT_NOTIFICATION_MORE_SUCCESS",
}

export const notificationStudentActionCreators = {
  fetchNotificationStudent: (cb) => ({type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION, cb}),
  fetchNotificationStudentSuccess: ({notifications, endReached, cb}) => ({type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION_SUCCESS, notifications, endReached, cb}),

  fetchNotificationStudentMore: (cb) => ({type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION_MORE, cb}),
  fetchNotificationStudentMoreSuccess: ({notifications, endReached, cb}) => ({type: notificationStudentActions.FETCH_STUDENT_NOTIFICATION_MORE_SUCCESS, notifications, endReached, cb}),
}

const initialState = {
  notifications: [],
  unreadCount: 0,
  isLoaded: false,
  loadingMore: false,
  endReached: false,
}

export const notificationStudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationStudentActions.FETCH_STUDENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.notifications || [],
        endReached: action.endReached,
        isLoaded: true,
      }
    case notificationStudentActions.FETCH_STUDENT_NOTIFICATION_MORE:
      return {
        ...state,
        loadingMore: true,
      }
    case notificationStudentActions.FETCH_STUDENT_NOTIFICATION_MORE_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
        endReached: action.endReached,
        loadingMore: false,
      }

    default:
      return state;
  }
}
