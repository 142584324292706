import React, {useLayoutEffect} from 'react';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {ListItemButton} from '@mui/material';
import {useRef, useState} from 'react';
import classNames from '../../assi-design-system-react/utils/classNames';


export default function BoxReadMore({text, rows, classText, classContainer}) {
  // height 보다 길어지면 작아지게 하거나, rows (Number) 이상 넘어가면  더보기 되게

  const descRef = useRef(null);

  const [isDescUnfolded, setDescUnfolded] = useState(false);
  const [hasOverflowText, setOverflowText] = useState(false); // 상태로 안해두면 펼치기만 가능함

  useLayoutEffect(() => {
    if (descRef.current) {
      setOverflowText(descRef.current?.scrollHeight > descRef.current?.clientHeight);
      console.dir(descRef.current);
    }
  }, [text]);

  return (
    <div className={classNames(classContainer, '')}>
      <p
        ref={descRef}
        className={classNames(!hasOverflowText && 'whitespace-pre-line', isDescUnfolded ? 'whitespace-pre-line' : 'line-clamp-3 text-ellipsis', classText)}
        style={{WebkitLineClamp: rows}}
      >
        {text}
      </p>

      {hasOverflowText && (
        <ListItemButton
          onClick={(e) => {
            e.stopPropagation();
            setDescUnfolded(!isDescUnfolded);
          }}
          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          {isDescUnfolded ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
      )}
    </div>
  );
}
