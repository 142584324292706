import PropTypes from "prop-types";
import {Button, Box as MuiBox, InputAdornment, InputBase, IconButton} from "@mui/material";
import * as Icon from "@mui/icons-material";
import {styled} from "@mui/material/styles";

const Box = styled(MuiBox)(({theme}) => ( {
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
} ));

// ----------------------------------------------------------------------

const SearchTextField = ({onSubmit, keyword, defaultValue, onClickClear, placeholder = '검색...', sx, ...others}) => {
  return (
    <Box
      component="form"
      sx={(theme) => ( {
        p: 0.5,
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        borderRadius: 1,
        outline: "solid",
        outlineWidth: 1,
        outlineColor: theme.palette.grey[500_32],
        height: 56,
        maxHeight: 56,
        "&:hover": {
          outlineColor: theme.palette.primary.light,
        },
        "&:focus-within": {
          outlineWidth: 2,
          outlineColor: theme.palette.primary.main,
        },
        ...sx,
      } )}
      onSubmit={onSubmit}
      {...others}
    >
      <InputBase
        fullWidth
        name="keyword"
        defaultValue={defaultValue}
        autoComplete="off"
        placeholder={placeholder}
        sx={{sm: {flex: 1}, minHeight: 42}}
        inputProps={{}}
        endAdornment={
          <InputAdornment position="end" sx={{ml: 1}}>
            {!!keyword && (
              <IconButton onClick={onClickClear}>
                <Icon.CloseRounded/>
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      <Button
        // size="small"
        type="submit"
        // variant="text"
        sx={{
          // fontSize: "1rem",
          py: 1.17,
          px: 0.5,
        }}
      >
        검색
      </Button>
    </Box>
  );
};

SearchTextField.propTypes = {
  sx: PropTypes.object,
  onSubmit: PropTypes.func,
  onClickClear: PropTypes.func,
  keyword: PropTypes.string || PropTypes.number,
  defaultValue: PropTypes.string || PropTypes.number,
};

export default SearchTextField;
