import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Link as LinkMui,
  Tabs,
  Tab,
  Chip,
  Pagination,
  PaginationItem,
  TableCell,
} from "@mui/material";
import NetworkStudent from "../../lib/NetworkStudent";
import LoadingComponent from "../../components/shared/LoadingComponent";
import dayjs from "dayjs";
import MessageBox from '../../components/shared/MessageBox';
import {PATH_STUDENT} from '../../routes/paths';
import Scrollbar from "../../components/NOT_Classified/Scrollbar";

const StudentTeacherDetailPage = (props) => {
  const {teacherUserId} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const tab = query.get("tab") || "BOOK_REPORT";
  const page = parseInt(query.get("page") || "1", 10);
  const LIMIT = 50;

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState({});
  const [books, setBooks] = useState([]);
  const [bookReportIsLoaded, setBookReportIsLoaded] = useState(false);
  const [bookReports, setBookReports] = useState([]);
  const [bookReportMeta, setBookReportMeta] = useState({total: 0});

  const _load = async () => {
    try {
      const res = await NetworkStudent.userGET({userId: teacherUserId});
      if (res.user?.userType !== "TEACHER") {
        alert("데이터가 존재하지 않습니다");
        navigate(`/`);
      }
      setIsLoaded(true);
      setUser(res.user);
    } catch (e) {
    }
  };
  useEffect(() => {
    _load();
  }, []);
  const _loadBooks = async () => {
    try {
      const res = await NetworkStudent;
    } catch (e) {
    }
  };
  const _loadBookReports = async () => {
    if (tab !== "BOOK_REPORT") return;
    try {
      const res = await NetworkStudent.bookReportsGET({
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
        teacherUserId: teacherUserId,
      });
      setBookReports(res.bookReports);
      setBookReportMeta(res.meta);
      setBookReportIsLoaded(true);
    } catch (e) {
    }
  };

  useEffect(() => {
    _loadBookReports();
  }, [tab, page]);

  const _handleTabChange = (event, newValue) => {
    setSearchParams({tab: newValue});
  };

  if (!isLoaded) return <LoadingComponent/>;

  const _renderBook = () => {
    if (tab !== "BOOK") return null;
    if (books.length < 1) {
      return (
        <Box
          sx={{
            bgcolor: "#efefef",
            p: 4,
            borderRadius: 1,
            mt: 3,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>준비중입니다.</Typography>
        </Box>
      );
    }
    return <Box sx={{mt: 3}}>book</Box>;
  };
  const _renderBookReports = () => {
    if (tab !== "BOOK_REPORT") return null;
    if (!bookReportIsLoaded) return <LoadingComponent/>;
    if (bookReports.length < 1) {
      return (
        <Box
          sx={{
            bgcolor: "#efefef",
            p: 4,
            borderRadius: 1,
            mt: 3,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography>
            <b>{user?.name} </b>
            선생님께 제출된 독후감이 없어요.
          </Typography>
        </Box>
      );
    }
    return (
      <Box sx={{mt: 2}}>
        <Pagination
          sx={{my: 3}}
          page={page}
          count={parseInt(bookReportMeta.total / LIMIT) + (bookReportMeta.total % LIMIT === 0 ? 0 : 1)}
          renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?tab=${tab}&page=${item.page}`} {...item} />}
        />
        <Scrollbar>
          <Table sx={{minWidth: 800, position: "relative"}}>
            <TableHead>
              <TableRow>
                <TableCell>제목</TableCell>
                <TableCell>수업</TableCell>
                <TableCell>과제명</TableCell>
                <TableCell>도서</TableCell>
                <TableCell>제출일</TableCell>
                <TableCell>선생님</TableCell>
                <TableCell>댓글 수</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports?.map((bookReport, index) => {
                return (
                  <TableRow key={bookReport.id}>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.bookReport.detail.root(bookReport?.id)}
                        className={"text-[#1370F5] line-clamp-1 w-[100px]"}
                      >
                        {bookReport.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.course.detail(bookReport.task?.course?.id)}
                        className={"text-[#1370F5] line-clamp-1 max-w-[100px]"}
                      >
                        {bookReport.task?.course?.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.task.detail(bookReport.task?.id)}
                        className={"text-[#1370F5] line-clamp-1 max-w-[100px]"}
                      >
                        {bookReport.task?.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      <LinkMui
                        as={Link}
                        to={PATH_STUDENT.searchBook.detail(bookReport.book?.id)}
                        className={"text-[#1370F5] line-clamp-1 max-w-[100px]"}
                      >
                        {bookReport.book?.title}
                      </LinkMui>
                    </TableCell>
                    <TableCell>
                      {!!bookReport.submittedAt ? (
                        <Typography>{dayjs(bookReport.submittedAt).format("YYYY.MM.DD(ddd) HH:mm")}</Typography>
                      ) : (
                        <Chip variant="outlined" color="warning" label={"미제출"}/>
                      )}
                    </TableCell>
                    <TableCell>{bookReport.teacherUser?.name}</TableCell>
                    <TableCell>{bookReport.stat?.bookReportCommentCount}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Scrollbar>
      </Box>
    );
  };

  return (
    <Container maxWidth={"lg"}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <img className={"w-16 h-16 object-cover rounded-xl"} src={user.profileImageUrl}/>
        <Typography sx={{ml: 2}}>
          {user.name} ({user.displayName})
        </Typography>
      </Box>
      <MessageBox
        sx={{mb: 3, mt: 4}}
        // title={'1:1 문의 안내'}
        content={'해당 선생님께 제출한 독후감을 모아서 볼 수 있어요.\n'}
      />
      <Box sx={{borderBottom: 1, borderColor: "divider"}}>
        <Tabs value={tab} onChange={_handleTabChange}>
          <Tab value={"BOOK_REPORT"} label={"나의 독후감"}/>
          {/*<Tab value={'BOOK'} label={'선생님의 책장'}/>*/}
        </Tabs>
      </Box>
      {_renderBook()}
      {_renderBookReports()}
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentTeacherDetailPage);
