import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from "react-router-dom";
import LoadingComponent from "../../components/shared/LoadingComponent";
import Network from "../../lib/Network";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

const TeacherStudentDetail = (props) => {

  const {} = props.authReducer;
  const {studentUserId} = useParams();
  const location = useLocation();

  const [isLoaded, setIsLoaded] = useState(false)
  const [studentUser, setStudentUser] = useState({})
  const [bookReports, setBookReports] = useState([])
  const [meta, setMeta] = useState({})
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const year = parseInt(query.get("year")) || '';

  const _loadStudent = async () => {
    try {
      const res = await Network.userGET({userId: studentUserId, year})
      setStudentUser(res.user);
    } catch (e) {

    }
  }

  const _load = async () => {
    try {
      const res = await Network.bookReportsGET({
        studentUserId,
        teacherUserId: props.authReducer?.user?.id,
        offset: (page - 1) * perPage,
        limit: perPage,
      })
      setBookReports(res.bookReports);
      setMeta(res.meta || {})
      setIsLoaded(true);
    } catch (e) {

    }
  }

  useEffect(() => {
    _loadStudent()
    _load();
  }, [])

  if (!isLoaded) return <LoadingComponent/>

  return (
    <div className={''}>
      {studentUserId}

      <Table>
        <TableBody>
          {bookReports.map((bookReport, i) => {
            return (
              <TableRow>
                <TableCell>
                  {bookReport.id}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TeacherStudentDetail);
