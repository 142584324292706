// layouts
import {PATH_STUDENT, PATH_TEACHER} from "./paths";

import AdminLayout from "../layout/AdminLayout";
import TeacherLayout from "../layout/TeacherLayout";
import StudentLayout from "../layout/StudentLayout";
// 공통
import LoginPage from "../pages/shared/LoginPage";
import NotFound from "../pages/shared/NotFound";

// ADMIN ---------------------------------------------------------------------------
import AdminStudentPage from "../pages/admin/AdminStudentPage";
import AdminClassPage from "../pages/admin/AdminClassPage";
import AdminClassDetailPage from "../pages/admin/AdminClassDetailPage";
import AdminClassCreatePage from "../pages/admin/AdminClassCreatePage";
import AdminCoursePage from "../pages/admin/AdminCoursePage";
import AdminCourseCreatePage from "../pages/admin/AdminCourseCreatePage";
import AdminCourseDetailPage from "../pages/admin/AdminCourseDetailPage";
import AdminSchoolManagePage from "../pages/admin/AdminSchoolManagePage";
import AdminStudentCreatePage from "../pages/admin/AdminStudentCreatePage";
import AdminStudentDetailPage from "../pages/admin/AdminStudentDetailPage";
import AdminSubjectPage from "../pages/admin/AdminSubjectPage";
import AdminTeacherPage from "../pages/admin/AdminTeacherPage";
import AdminTeacherCreatePage from "../pages/admin/AdminTeacherCreatePage";
import AdminTeacherDetailPage from "../pages/admin/AdminTeacherDetailPage";
import AdminBookReportDetailPage from "../pages/admin/AdminBookReportDetailPage";
import AdminTaskDetailPage from "../pages/admin/AdminTaskDetailPage";
import AdminHelpMainPage from "../pages/admin/AdminHelpMainPage";
import AdminSubjectCreatePage from "../pages/admin/AdminSubjectCreatePage";
import AdminBookDetailPage from "../pages/admin/AdminBookDetailPage";
import AdminHomePage from "../pages/admin/AdminHomePage";
import AdminTaskPage from '../pages/admin/AdminTaskPage';
import AdminBookReportPage from '../pages/admin/AdminBookReportPage';

// TEACHER ---------------------------------------------------------------------------


import CourseCreateOrModifyPage from "../pages/teacher/CourseCreateOrModifyPage";
import MyAccountPage from "../pages/teacher/MyAccountPage";

import TeacherHome from "../pages/teacher/TeacherHome";
import TeacherBookDetail from "../pages/teacher/TeacherBookDetail";
import TeacherBookMain from "../pages/teacher/TeacherBookMain";
import TeacherBookReportDetail from "../pages/teacher/TeacherBookReportDetail";
import TeacherBookReportMain from "../pages/teacher/TeacherBookReportMain";
import TeacherClassMain from "../pages/teacher/TeacherClassMain";
import TeacherClassDetail from "../pages/teacher/TeacherClassDetail";
import TeacherClassStudentDetail from "../pages/teacher/TeacherClassStudentDetail";
import TeacherCourseMain from "../pages/teacher/TeacherCourseMain";
import TeacherCourseDetail from "../pages/teacher/TeacherCourseDetail";
import TeacherCourseStudentDetail from "../pages/teacher/TeacherCourseStudentDetail";
import TeacherNotification from "../pages/teacher/TeacherNotification";
import TeacherStudentDetail from "../pages/teacher/TeacherStudentDetail";
import TeacherTaskDetail from "../pages/teacher/TeacherTaskDetail";
import TeacherTaskCreateOrEdit from "../pages/teacher/TeacherTaskCreateOrEdit";
import TeacherTeacherDetail from "../pages/teacher/TeacherTeacherDetail";


// STUDENT ---------------------------------------------------------------------------
import StudentHelpMainPage from "../pages/student/StudentHelpMainPage";
import StudentCourseDetailPage from "../pages/student/StudentCourseDetailPage";
import StudentCourseMainPage from "../pages/student/StudentCourseMainPage";
import StudentTaskDetailPage from "../pages/student/StudentTaskDetailPage";
import StudentBookDetailPage from "../pages/student/StudentBookDetailPage";
import StudentClassMyPage from "../pages/student/StudentClassMyPage";
import StudentInquiryMainPage from "../pages/student/StudentInquiryMainPage";
import StudentInquiryDetailPage from "../pages/student/StudentInquiryDetailPage";
import StudentInquiryWritePage from "../pages/student/StudentInquiryWritePage";
import StudentNoticeMainPage from "../pages/student/StudentNoticeMainPage";
import StudentNoticeDetailPage from "../pages/student/StudentNoticeDetailPage";
import StudentNotificationPage from "../pages/student/StudentNotificationPage";
import StudentSearchPage from "../pages/student/StudentSearchPage";
import StudentUserBookPage from "../pages/student/StudentUserBookPage";
import StudentBookReportMainPage from "../pages/student/StudentBookReportMainPage";
import StudentBookReportWritePage from "../pages/student/StudentBookReportWritePage";
import StudentBookReportDetailPage from "../pages/student/StudentBookReportDetailPage";
import StudentTeacherDetailPage from "../pages/student/StudentTeacherDetailPage";
import StudentSettingPage from "../pages/student/StudentSettingPage";
import StudentSearchCourseIrregularPage from "../pages/student/StudentSearchCourseIrregularPage";
import StudentHomePage from "../pages/student/StudentHomePage";
import StudentTaskMainPage from "../pages/student/StudentTaskMainPage";
import StudentBookReportWriteSelectTypePage from '../pages/student/StudentBookReportWriteSelectTypePage';
import StudentBookReportWriteCreateTaskPage from '../pages/student/StudentBookReportWriteCreateTaskPage';


// TEMP


const routesForTeachers = [
  {
    path: "admin",
    element: <AdminLayout/>,
    children: [
      {index: true, element: <AdminHomePage/>},
      {path: "help", element: <AdminHelpMainPage/>},

      {path: "book/:bookId", element: <AdminBookDetailPage/>},

      {path: "book-report", element: <AdminBookReportPage/>},
      {path: "book-report/:bookReportId", element: <AdminBookReportDetailPage/>},

      {path: "class", element: <AdminClassPage/>},
      {path: "class/create", element: <AdminClassCreatePage/>},
      {path: "class/:classId", element: <AdminClassDetailPage/>},

      {path: "course", element: <AdminCoursePage/>},
      {path: "course/create", element: <AdminCourseCreatePage/>},
      {path: "course/:courseId", element: <AdminCourseDetailPage/>},

      {path: "school-manage", element: <AdminSchoolManagePage/>},

      {path: "student", element: <AdminStudentPage/>},
      {path: "student/create", element: <AdminStudentCreatePage/>},
      {path: "student/:userId", element: <AdminStudentDetailPage/>},

      {path: "subject", element: <AdminSubjectPage/>},
      {path: "subject/create", element: <AdminSubjectCreatePage/>},
      {path: "subject/:subjectId/courses", element: <AdminCoursePage/>},

      {path: "task", element: <AdminTaskPage/>},
      {path: "task/:taskId", element: <AdminTaskDetailPage/>},

      {path: "teacher", element: <AdminTeacherPage/>},
      {path: "teacher/create", element: <AdminTeacherCreatePage/>},
      {path: "teacher/:userId", element: <AdminTeacherDetailPage/>},
    ],
  },
  {
    path: "/",
    element: <TeacherLayout/>,
    children: [
      {index: true, element: <TeacherHome/>},

      // BOOK
      {path: PATH_TEACHER.book.root, element: <TeacherBookMain/>},
      {path: PATH_TEACHER.book.detail(':bookId'), element: <TeacherBookDetail/>},

      // BOOK REPORT
      {path: PATH_TEACHER.bookReport.root, element: <TeacherBookReportMain/>},
      {path: PATH_TEACHER.bookReport.detail.root(':bookReportId'), element: <TeacherBookReportDetail/>},

      // CLASS
      {path: PATH_TEACHER.class.root, element: <TeacherClassMain/>},
      {path: PATH_TEACHER.class.detail.root(":classId"), element: <TeacherClassDetail/>},
      {path: PATH_TEACHER.class.detail.studentDetail(":classId", ":userId"), element: <TeacherClassStudentDetail/>},

      // COURSE
      {path: PATH_TEACHER.course.root, element: <TeacherCourseMain/>},
      {path: PATH_TEACHER.course.create, element: <CourseCreateOrModifyPage/>},
      {path: PATH_TEACHER.course.detail.root(":courseId"), element: <TeacherCourseDetail/>},
      {path: PATH_TEACHER.course.detail.student.detail.root(':courseId', ':userId'), element: <TeacherCourseStudentDetail/>},
      {path: PATH_TEACHER.course.detail.edit(":courseId"), element: <CourseCreateOrModifyPage/>},

      {path: PATH_TEACHER.course.detail.task.create(":courseId"), element: <TeacherTaskCreateOrEdit/>},
      {path: PATH_TEACHER.task.detail.edit(":taskId"), element: <TeacherTaskCreateOrEdit/>},


      // MY
      {path: "my-account", element: <MyAccountPage/>},
      {path: "notification", element: <TeacherNotification/>},

      // STUDENT
      {path: PATH_TEACHER.student.detail.root(":studentUserId"), element: <TeacherStudentDetail/>},

      // TASK
      {path: PATH_TEACHER.task.detail.root(":taskId"), element: <TeacherTaskDetail/>},


      // TEACHER
      {path: PATH_TEACHER.teacher.detail.root(':teacherUserId'), element: <TeacherTeacherDetail/>}
    ],
  },
  {path: "login", element: <LoginPage/>},
  {path: "*", element: <NotFound/>},
];

const routesForStudents = [
  {
    path: `/`,
    element: <StudentLayout/>,
    children: [
      {index: true, element: <StudentHomePage/>},
      {path: PATH_STUDENT.book.root, element: <StudentUserBookPage/>},
      {path: "book/:bookId", element: <StudentBookDetailPage/>},
      {path: "book-report", element: <StudentBookReportMainPage/>},
      {path: "book-report/write/select-type", element: <StudentBookReportWriteSelectTypePage/>},
      {path: "book-report/write/create-task", element: <StudentBookReportWriteCreateTaskPage/>},
      {path: "book-report/:bookReportId", element: <StudentBookReportDetailPage/>}, // TODO
      {path: "book-report/:bookReportId/edit", element: <StudentBookReportWritePage/>}, // TODO

      {path: PATH_STUDENT.class.root, element: <StudentClassMyPage/>},
      {path: PATH_STUDENT.class.detail.root(":classId"), element: <StudentClassMyPage/>},


      {path: "course", element: <StudentCourseMainPage/>},
      {path: "course/:courseId", element: <StudentCourseDetailPage/>}, // TODO
      {path: "course-irregular", element: <StudentSearchCourseIrregularPage/>}, // TODO
      {path: "course-irregular/:courseId", element: <StudentCourseDetailPage/>}, // TODO

      {path: "help", element: <StudentHelpMainPage/>},

      {path: "teacher/:teacherUserId", element: <StudentTeacherDetailPage/>}, // TODO

      {path: "task", element: <StudentTaskMainPage/>}, // TODO
      {path: "task/:taskId", element: <StudentTaskDetailPage/>}, // TODO
      {path: "task/:taskId/write-report", element: <StudentBookReportWritePage/>}, // TODO

      {path: "notification", element: <StudentNotificationPage/>}, // TODO
      {path: "setting", element: <StudentSettingPage/>}, // TODO

      {path: "search", element: <StudentSearchPage/>}, // TODO
      {path: "search/book/:bookId", element: <StudentBookDetailPage/>},
      {path: "search/teacher/:teacherUserId", element: <StudentTeacherDetailPage/>},

      {path: "inquiry", element: <StudentInquiryMainPage/>}, // TODO
      {path: "inquiry/create", element: <StudentInquiryWritePage/>}, // TODO
      {path: "inquiry/:inquiryId", element: <StudentInquiryDetailPage/>}, // TODO
      // notice
      {path: "notice", element: <StudentNoticeMainPage/>}, // TODO
      {path: "notice/:noticeId", element: <StudentNoticeDetailPage/>}, // TODO
    ],
  },
  {path: "login", element: <LoginPage/>},
  {path: "*", element: <NotFound/>},
];

export {routesForTeachers, routesForStudents};
