export const termTypes = [
  { type: "SPRING", name: "1학기" },
  { type: "SUMMER", name: "여름방학" },
  { type: "FALL", name: "2학기" },
  { type: "WINTER", name: "겨울방학" },
  { type: "ETC", name: "기타" },
];

export const termTypesObj = {
  SPRING: { type: "SPRING", name: "1학기" },
  SUMMER: { type: "SUMMER", name: "여름방학" },
  FALL: { type: "FALL", name: "2학기" },
  WINTER: { type: "WINTER", name: "겨울방학" },
  ETC: { type: "ETC", name: "기타" },
};
