import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Network from "../../lib/Network";
import Loader from "../../assi-design-system-react/components/Loader";
import { Container } from "@mui/material";

const TeacherTeacherDetail = (props) => {

  const {teacherUserId} = useParams();
  const location = useLocation();
  const navigate = useNavigate()

  const [isLoaded, setIsLoaded] = useState(false)
  const [user, setUser] = useState({})

  const _load = async () => {
    try {
      const {user} = await Network.userGET({userId: teacherUserId});
      if (user.userType !== 'TEACHER') {
        navigate(-1, {replace: true})
        throw {cause: {errorMessage: '선생님 정보가 존재하지 않습니다.'}}
      }
      setUser(user);
      setIsLoaded(true);
    } catch (e) {

    }

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  if (!isLoaded) return <Loader/>;

  return (
    <Container maxWidth={'lg'}>
      <div className={'flex flex-col items-center'}>
        <img src={user.profileImageUrl} className={'w-[50px] h-[50px] rounded-full object-cover'}/>
        <p className={'text-xl mt-2'}>
          {user.name}
        </p>
      </div>
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TeacherTeacherDetail);
