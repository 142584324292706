import PropTypes from "prop-types";
import { Button, Box as MuiBox, InputAdornment, Typography, InputBase, IconButton } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const Box = styled(MuiBox)(({theme}) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

// ----------------------------------------------------------------------

const MessageBox = ({title, content, sx, children, ...others}) => {
  return (
    <Box
      className={"border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800"}
      sx={{
        p: 2,
        // display: "flex",
        alignItems: "center",
        borderRadius: 0.5,
        ...sx,
      }}
      {...others}
    >
      {!!title && (
        <Typography variant={'h6'} className={'text-gray-700 font-bold'}>
          {title}
        </Typography>
      )}
      {!!content && (
        <Typography
          variant={'body'}
          className={'whitespace-pre-line text-gray-600'}>
          {content}
        </Typography>
      )}
      {!!children && (
        <div>
          {children}
        </div>
      )}
    </Box>
  );
};

MessageBox.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default MessageBox;
