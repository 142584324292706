import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Box, Chip, Typography, ImageList, ImageListItem, Button,
  Dialog, DialogTitle, DialogContent, DialogContentText, InputBase, DialogActions, Divider,
} from '@mui/material';
import { questionTypeText } from '../../constants/dataTypes';
import numeral from 'numeral';


const StudentAnswerComponent = ({answer, hideDescription = false, hideType=false}) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})
  const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    if (selectedImageIndex > -1) {
      setImageModalOpen(true);
    } else {

    }
  }, [selectedImageIndex]);

  if (!answer) return null;

  const _renderQuestionType = () => {
    if(hideType) return null;
    return (
      <Chip variant="contained"
        // color="success"
            sx={{mb: 2}}
            label={`답변 유형: ${questionTypeText[answer.question?.questionType]}`}
      />)
  }
  const _renderQuestionTitle = () => {
    return (
      <Box sx={{display: 'flex', mb: 2}}>
        <Box sx={{
          display: 'flex',
          width: 30, height: 30, bgcolor: 'primary.main',
          borderRadius: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold',
            }}>
            {answer?.question?.order + 1}
          </Typography>
        </Box>
        <Typography
          sx={{pl: 1, fontWeight: 'bold', mt: 0.5}}
        >
          {answer?.question?.title}
        </Typography>
      </Box>
    );
  };
  const _renderQuestionDescription = () => {
    if (!answer.question?.description || answer.question?.description?.trim() === "" || hideDescription) return null;
    return (
      <Typography
        // variant={'h6'}
        sx={{mb: 2, color: 'gray'}}
        className={'whitespace-pre-line line-clamp-3'}>
        {answer.question?.description}
      </Typography>
    );
  };
  const _renderBody = () => {
    switch (answer.question?.questionType) {
      case "TEXT":
        return (
          <Box>
            <Typography className={'whitespace-pre-line'}>
              {answer.content}
            </Typography>
            <p className={'mt-2 text-gray-500 text-sm'}>
              {numeral(answer.content?.length).format('0,0') || '0'}자{' '}
              (공백 제외 {numeral(answer.content?.replace?.(/ /gi, '')?.replace?.(/\n/gi, '').length).format('0,0') || '0'}자)
            </p>
          </Box>
        )
      case "VIDEO":
        if (answer.videoUrls?.length > 0) {
          return (
            <Box>
              <div className={'snap-center min-w-full aspect-h-3 aspect-w-4'}>
                <video controls className="w-full outline-none ">
                  <source src={answer.videoUrls[0]?.url} type="video/mov"/>
                  <source src={answer.videoUrls[0]?.url} type="video/mp4"/>
                  영상 재생을 지원하지 않는 브라우저 입니다. 최신 버전의 브라우저를 사용해 주세요. (크롬을 권장합니다.)
                </video>
              </div>
            </Box>
          )
        } else {
          return (
            <p className={'text-gray-500'}>
              첨부한동영상이 없습니다
            </p>
          )
        }
      case "IMAGE":
        return (
          <Box>
            <div className={'flex overflow-x-scroll pb-2'}>
              {
                answer?.imageUrls.map((item, index) => (

                  <img
                    onClick={() => setSelectedImageIndex(index)}
                    key={item.url}
                    className={'border border-gray-800 !w-24 !h-24 object-cover mr-2 rounded-xl flex-shrink-0 hover:cursor-pointer'}
                    src={item.url}
                  />

                ))
              }
            </div>
            <Dialog
              fullWidth
              open={imageModalOpen}>
              <DialogContent>
                <img
                  src={answer?.imageUrls[selectedImageIndex]?.url || ''}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  setImageModalOpen(false);
                  setSelectedImageIndex(-1);
                }}>
                  닫기
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )
      case "FILE":
        return (
          <Box>
            {
              answer?.fileUrls?.map((fileUrl, index) => {
                return (
                  <div key={index} className={'my-2 flex items-center w-full'}>
                    <Chip variant={'outlined'} label={'파일'} sx={{mr: 1}}/>
                    <div className={"pr-3 flex-1"}>
                      <p className={'line-clamp-1 break-all'}>
                        {fileUrl.fileName}
                      </p>
                    </div>
                    <a target={'_blank'}
                       href={fileUrl.url}
                       className={'ml-4'} rel="noreferrer"
                    >
                      <Button
                        variant={'outlined'}
                        size={'small'}
                      >
                        다운받기
                      </Button>
                    </a>
                  </div>
                )
              })
            }
          </Box>
        )
      default:
        return null;
    }
  }
  return (
    <Box sx={{mb: 8}}>
      {_renderQuestionTitle()}
      {_renderQuestionDescription()}
      {_renderQuestionType()}
      {_renderBody()}
    </Box>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(StudentAnswerComponent);
