import dayjs from "dayjs";
import numeral from "numeral";

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Container, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { courseTypesObj } from "../../constants";
import Network from "../../lib/Network";
import { PATH_TEACHER } from "../../routes/paths";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import NewCourseDialog from "../../components/teacher/NewCourseDialog";

const YEAR_OPTIONS = [2022, 2021, 2020];


const TeacherCourseMain = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const semesterId = parseInt(query.get("semesterId")) || "";
  const keyword = query.get("keyword") || "";
  const year = query.get('year') || 2022

  const [courseMakeModalVisible, setCourseMakeModalVisible] = useState(false)
  const [isLoaded, setLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);


  useEffect(() => {
    const _loadCourses = async () => {
      try {
        const res = await Network.coursesGET({
          year,
          semesterId,
          offset: (page - 1) * perPage,
          limit: perPage,
          teacherUserId: props.authReducer?.user?.id,
          keyword,
        });
        setMeta({...res.meta});
        setCourses(res.courses || []);
        setSemesters(res.semesters);
        setLoaded(true);
      } catch (error) {
        window.alert(error.cause?.errorMessage);
      }
    }
    _loadCourses();
  }, [year, semesterId, keyword, page, perPage]);

  const _onChangeYear = ({target}) => {
    if (target.value) {
      searchParams.set('year', target.value)
    } else {
      searchParams.delete('year');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }
  const _onChangeSemester = ({target}) => {
    if (target.value) {
      searchParams.set('semesterId', target.value)
    } else {
      searchParams.delete('semesterId');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }
  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  if (!isLoaded) return <LoadingComponent/>;

  return (
    <Container maxWidth={'xl'}>
      <div className={'flex items-start'}>
        <div className={'flex-1'}>
          <p className={'text-xl font-bold'}>
            수업/활동
          </p>
          <p className={'text-gray-400'}>
            {meta.total}개의 수업이 있습니다.
          </p>
          <p className={'text-gray-400'}>
            최근 수업/활동 시작일 - 수업 종료일 순서입니다.
          </p>
        </div>
        <Button variant={'contained'} onClick={(e) => setCourseMakeModalVisible(true)}>
          새로운 수업/활동 만들기
        </Button>
      </div>
      <Stack
        spacing={1}
        direction={{xs: "column", sm: "row"}} sx={{width: "full", my: 2, alignItems: "center"}}>
        <TextField
          fullWidth
          select
          label="연도"
          value={year}
          onChange={_onChangeYear}
          sx={{maxWidth: {xs: "full", sm: 100}}}
        >
          {YEAR_OPTIONS.map((option) => (
            <MenuItem
              key={option.toString()}
              value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          select
          label="학기 선택"
          value={semesterId}
          onChange={_onChangeSemester}
          sx={{maxWidth: {xs: "full", sm: 100}}}
        >
          <MenuItem value={''}>
            전체
          </MenuItem>
          {semesters.map((o, index) => (
            <MenuItem
              key={o.id}
              value={o.id}>
              {o.text}
            </MenuItem>
          ))}
        </TextField>
        <SearchTextField onSubmit={_handleSearch} keyword={keyword} onClickClear={() => navigate(location.pathname)}/>
      </Stack>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: 'max-content'}}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  [
                    {name: "No.", className: ""},
                    {name: "제목", className: ""},
                    {name: "타입", className: ""},
                    {name: "진행기간", className: ""},
                    {name: "상태", className: ""},
                    {name: "인원", className: ""},
                    {name: "과제", className: ""},
                  ].map((head) => (
                    <TableCell key={head.name} className={head.className}>
                      {head.name}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course, i) => {
                return (
                  <TableRow className={'group hover:bg-hover'} key={course.id}>
                    <TableCell>
                      {(page - 1) * perPage + 1 + i}
                    </TableCell>
                    <TableCell className={'flex items-center'}>
                      <img alt={course.title} src={course.coverImageUrl} className="object-cover object-center aspect-1 rounded-full overflow-hidden w-[30px]"/>
                      <p
                        onClick={() => navigate(PATH_TEACHER.course.detail.root(course.id))}
                        className="clickable-primary pl-2"
                      >
                        {course.title}
                      </p>
                    </TableCell>
                    <TableCell>
                      {courseTypesObj[course.courseType].name}
                    </TableCell>
                    <TableCell>
                      {dayjs(course.startDate).format(dayjs().isSame(course.startDate, "year") ? "M.D (dd)" : "YYYY.M.D (dd)")} ~
                      {dayjs(course.endDate).format(dayjs().isSame(course.startDate, "year") ? "M.D (dd)" : "YYYY.M.D (dd)")}
                    </TableCell>
                    <TableCell>
                      {
                        dayjs(course.startDate) > dayjs() ? (
                          `D-${dayjs(course.startDate).diff(dayjs(), "day")}`
                        ) : dayjs(course.startDate) <= dayjs() && (dayjs().isSame(course.endDate, "day") || dayjs(course.endDate) > dayjs()) ? (
                          <span className="text-success-500">진행중</span>
                        ) : (
                          "진행종료"
                        )
                      }
                    </TableCell>
                    <TableCell>
                      {numeral(course.stat.registerCount).format("0,0")}
                    </TableCell>
                    <TableCell>
                      {numeral(course.stat.taskCount).format("0,0")}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <NewCourseDialog open={courseMakeModalVisible} onCancel={() => setCourseMakeModalVisible(false)}/>
    </Container>
  )
    ;
};

const enhance = connect(
  (state) => ({
    ...state,
  }),

);
export default enhance(TeacherCourseMain);
