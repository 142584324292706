import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import MessageBox from '../../components/shared/MessageBox';
import {Box, Container, Tab} from '@mui/material';
import dayjs from "dayjs";
import {TabContext, TabList , TabPanel} from "@mui/lab";
import {useLocation, useNavigate} from "react-router-dom";
import AdminClassCreatePageTabOne from "../../components/admin/AdminClassCreatePageTabOne";
import AdminClassCreatePageTabBulk from "../../components/admin/AdminClassCreatePageTabBulk";

const AdminClassCreatePage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const tab = query.get("tab") || "ONE";

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})
  const [newClass, setNewClass] = useState({year: dayjs().format('YYYY'), grade: '', classNo: ''})

  const _load = async () => {

  }

  const _upload = async () => {

  }

  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <Container maxWidth={'lg'}>
      <MessageBox
        title={'안내'}
        content={'추후 제공될 예정입니다.'}
      />

      <TabContext value={tab}>
        <Box sx={{borderBottom: 1, borderColor: "divider", mb: 3, mt: 2}}>
          <TabList onChange={(e, newValue) => _handleChangeTab(newValue)}>
            <Tab label="한개씩 등록" value="ONE"/>
            {/*<Tab label="엑셀 등록" value="BULK"/>*/}
          </TabList>
        </Box>
        <TabPanel value={'ONE'}>
          <AdminClassCreatePageTabOne/>
        </TabPanel>
        {/*<TabPanel value={'BULK'}>
          <AdminClassCreatePageTabBulk/>
        </TabPanel>*/}
      </TabContext>
    </Container>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminClassCreatePage);
