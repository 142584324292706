import PropTypes from "prop-types";
import classNames from "../utils/classNames";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const FlatList = (
  {
    data = [],
    renderItem,
    className,
    ListHeaderComponent,
    ListFooterComponent,
    ListEmptyComponent,
    ItemSeparatorComponent,
    onEndReached,
    onEndReachedThreshold,
    loadingMore,
  }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const _renderItemSeparator = (index) => {
    if (!ItemSeparatorComponent) return null;
    if (index !== data?.length - 1) {
      return ItemSeparatorComponent;
    }
  };

  useEffect(() => {
    // console.log({ inView });
    if (inView && !loadingMore) {
      onEndReached?.();
    }
  }, [inView]);

  const _renderListHeaderComponent = () => {
    if (!ListHeaderComponent) return <></>;
    if (typeof ListHeaderComponent) {
      return ListHeaderComponent();
    } else {
      return (
        <div>
          {ListHeaderComponent}
        </div>
      );
    }
  };
  const _renderListFooterComponent = () => {
    if (!ListFooterComponent) return <></>;
    if (typeof ListFooterComponent) {
      return ListFooterComponent();
    } else {
      return (
        <div>
          {ListFooterComponent}
        </div>
      );
    }
  };

  const _renderLoadingMore = () => (
    <div className={classNames(loadingMore ? "" : "hidden", "fixed bottom-0 py-4 left-0 right-0 bg-black bg-opacity-30")}>
      <p>loadingMore</p>
    </div>
  );

  if (Array.isArray(data) && data.length < 1 && !!ListEmptyComponent) {
    if (typeof ListEmptyComponent === 'function') {
      return ListEmptyComponent();
    } else {
      return <div>{ListEmptyComponent}</div>;
    }
  }

  return (
    <div className={classNames("", className)}>
      {_renderListHeaderComponent()}
      {data.map((item, index) => (
        <div key={index}>
          {renderItem?.({ item, index })}
          {_renderItemSeparator?.(index)}
        </div>
      ))}
      <div className='h-1' ref={ref} />
      {_renderListFooterComponent()}
      {_renderLoadingMore()}
    </div>
  );
};

FlatList.propTypes = {
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
};
export default FlatList;
