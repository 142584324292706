import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Checkbox, FormControlLabel, MenuItem, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Add, Check, ContentCopy, Inbox, KeyboardArrowDown, KeyboardArrowUp, Remove } from '@mui/icons-material';
import numeral from 'numeral';
import { debounce } from 'lodash';
import { message, questionTypes, questionTypesObj } from '../../constants';
import classNames from '../../assi-design-system-react/utils/classNames'
import Network from '../../lib/Network';
import LoadingSpinner from '../shared/LoadingSpinner';
import FlatList from '../../assi-design-system-react/components/FlatList';

const QuestionFormComponent = forwardRef(function QuestionFormComponent(
  { question, order, useQuestionOnFocus, onChange, onForward, onBackward, onDuplicate, onDelete, onAddMultiple, total, disabledChange = true, teacherUserId },
  ref
) {
  const LIMIT = 30;
  // 문항 로드용
  const [tasks, setTasks] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [coursesMeta, setCoursesMeta] = useState({ total: 0 });
  const [tasksMeta, setTasksMeta] = useState({ total: 0 });
  const [questions, setQuestions] = useState([]);
  const [isLoadedObj, setIsLoadedObj] = useState({ courses: false, tasks: false, questions: false });
  const [loadingMoreObj, setLoadingMoreObj] = useState({ courses: false, tasks: false });
  const [endReachedObj, setEndReachedObj] = useState({ courses: false, tasks: false });
  const [selectedCourseId, setSelectedCourseId] = useState();

  const questionFormWrapRef = useRef();
  const [questionOnFocus, setQuestionOnFocus] = useQuestionOnFocus;

  const [booleanObj, setBooleanObj] = useState({
    delete: false,
    sidebar: false,
    questionFinder: false,
    selectedQuestionsShown: false,
  });

  const _handleChangeTitle = debounce((e) => onChange({ questionId: question.id, key: 'title', value: e.target.value }), 300);
  const _handleChangeDescription = debounce((e) => onChange({ questionId: question.id, key: 'description', value: e.target.value }), 300);

  const _loadCourses = async () => {
    setIsLoadedObj((prev) => ({ ...prev, courses: false }));
    try {
      const result = await Network.coursesGET({ offset: 0, limit: LIMIT, teacherUserId });
      setCourses(result.courses);
      setCoursesMeta(result.meta);
      setEndReachedObj((prev) => ({ ...prev, courses: result.courses.length < LIMIT }));
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setIsLoadedObj((prev) => ({ ...prev, courses: true }));
  };

  const _loadCoursesMore = async () => {
    if (!isLoadedObj.courses || endReachedObj.courses || loadingMoreObj.courses) return;
    setLoadingMoreObj((prev) => ({ ...prev, courses: true }));
    try {
      const result = await Network.coursesGET({ offset: courses.length, limit: LIMIT, teacherUserId });
      setCourses((prev) => [...prev, ...result.courses] || []);
      setEndReachedObj((prev) => ({ ...prev, courses: result.courses.length < LIMIT }));
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setLoadingMoreObj((prev) => ({ ...prev, courses: false }));
  };

  const _loadTasks = async (courseId) => {
    setIsLoadedObj((prev) => ({ ...prev, tasks: false }));
    setSelectedCourseId(courseId);
    try {
      const result = await Network.tasksGET({
        courseId: courseId,
        offset: 0,
        limit: LIMIT,
      });
      setTasks(result.tasks);
      setTasksMeta(result.meta);
      setEndReachedObj((prev) => ({ ...prev, tasks: result.tasks.length < LIMIT }));
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setIsLoadedObj((prev) => ({ ...prev, tasks: true }));
  };

  const _loadTasksMore = async () => {
    if (!isLoadedObj.tasks || endReachedObj.tasks || loadingMoreObj.tasks) return;
    setLoadingMoreObj((prev) => ({ ...prev, courses: true }));
    try {
      const result = await Network.tasksGET({
        courseId: selectedCourseId,
        offset: tasks.length,
        limit: LIMIT,
      });
      setTasks((prev) => [...prev, ...result.tasks]);
      setEndReachedObj((prev) => ({ ...prev, tasks: result.tasks.length < LIMIT }));
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setLoadingMoreObj((prev) => ({ ...prev, courses: false }));
  };

  const _loadQuestions = async (taskId) => {
    setIsLoadedObj((prev) => ({ ...prev, questions: false }));
    try {
      const result = await Network.taskGET({ taskId });
      setQuestions(
        result.task.questions?.map((question) => ({
          id: uuidv4(),
          questionType: question.questionType,
          title: question.title,
          description: question.description || '',
        })) || []
      );
    } catch (error) {
      window.alert(error.cause?.errorMessage || error);
    }
    setIsLoadedObj((prev) => ({ ...prev, questions: true }));
  };

  const sideMenuTimeout = () => {
    setTimeout(() => {
      setBooleanObj((prev) => ({ ...prev, sidebar: false }));
    }, 3000);
  };

  const _onClose = () => {
    setBooleanObj((prev) => ({ ...prev, questionFinder: false, selectedQuestionsShown: false }));
    setSelectedQuestions([]);
    setCourses([]);
    setTasks([]);
    setQuestions([]);
    setIsLoadedObj({ courses: false, tasks: false, questions: false });
    setEndReachedObj({ courses: false, tasks: false, questions: false });
  };

  useEffect(() => {
    if (booleanObj.questionFinder) {
      _loadCourses();
    } else {
      setCourses([]);
    }
  }, [booleanObj.questionFinder]);

  useEffect(
    () => () => {
      setBooleanObj({ delete: false, questionFinder: false, sidebar: false });
      setCourses([]);
      setTasks([]);
      setQuestions([]);
    },
    []
  );

  const _renderItemCourse = ({ item: course }) => (
    <div
      key={course.id}
      onClick={() => {
        if (course.stat.taskCount > 0) {
          _loadTasks(course.id);
        }
        return;
      }}
      className={classNames(
        course.stat.taskCount > 0 ? 'cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-750 dark:hover:bg-opacity-50 opacity-75 hover:opacity-100' : 'cursor-not-allowed opacity-50',
        'bind-text-gray-700 font-semibold group flex items-center justify-between min-h-[4rem] px-4 rounded'
      )}
    >
      <div>
        <p className='line-clamp-1 text-ellipsis'>{course.title}</p>
        {!!course.description && <p className='mt-1 bind-text-gray-400 text-sm line-clamp-1 w-full text-ellipsis'>{course.description}</p>}
      </div>
      <p className='text-right'>{numeral(course.stat.taskCount).format('0,0')}</p>
    </div>
  );

  const _renderItemTask = ({ item: task }) => (
    <div
      key={task.id}
      onClick={() => {
        if (task.stat.questionCount > 0) {
          _loadQuestions(task.id);
        }
        return;
      }}
      className='cursor-pointer bind-text-gray-700 font-semibold group flex flex-col justify-center min-h-[4rem] px-4 rounded hover:bg-gray-50 dark:hover:bg-gray-750 dark:hover:bg-opacity-50 opacity-75 hover:opacity-100'
    >
      <div className='flex space-x-2'>
        <p className='line-clamp-1 text-ellipsis max-w-[90%]'>{task.title}</p>
        {!task.isPublished && <span className='badge min-w-max'>임시저장</span>}
      </div>
      {!!task.description && <p className='mt-1 bind-text-gray-400 text-sm line-clamp-1 w-full text-ellipsis'>{task.description}</p>}
      <p className='text-right'>{numeral(task.stat.questionCount).format('0,0')}</p>
    </div>
  );

  const _renderQuestionFinder = () => {
    const _renderBody = () => {
      if (booleanObj.selectedQuestionsShown) {
        return (
          <div className='space-y-1 pt-4'>
            {selectedQuestions.map((question) => (
              <FormControlLabel
                key={`selected-questions-${question.id}`}
                labelPlacement='start'
                label={
                  <div className='flex items-start space-x-2'>
                    <span className='badge min-w-max'>{questionTypesObj[question.questionType].name}</span>
                    <div className='space-y-2'>
                      <p className='line-clamp-3 text-ellipsis'>{question.title}</p>
                      <p className='line-clamp-5 text-ellipsis bind-text-gray-500'>{question.title}</p>
                    </div>
                  </div>
                }
                control={
                  <Checkbox
                    checked={selectedQuestions.map((o) => o.id).includes(question.id)}
                    checkedIcon={<Check />}
                    icon={<Check sx={{ opacity: 0.2, color: 'common.black' }} />}
                    onChange={(e) => {
                      const isSelected = selectedQuestions.map((o) => o.id).includes(question.id);
                      if (isSelected) {
                        setSelectedQuestions((prev) => prev.filter((o) => o.id !== question.id));
                      } else {
                        setSelectedQuestions((prev) => [...prev, question]);
                      }
                    }}
                    sx={{ marginRight: 2 }}
                  />
                }
                sx={{ marginLeft: 0, width: '100%', justifyContent: 'space-between' }}
              />
            ))}
          </div>
        );
      }

      if (questions.length > 0) {
        return (
          <div className='space-y-1 pt-4'>
            {questions.map((question) => (
              <FormControlLabel
                key={`questions-${question.id}`}
                labelPlacement='start'
                label={
                  <div className='flex items-start space-x-2'>
                    <span className='badge min-w-max'>{questionTypesObj[question.questionType].name}</span>
                    <div className='space-y-2'>
                      <p className='line-clamp-3 text-ellipsis'>{question.title}</p>
                      <p className='line-clamp-5 text-ellipsis bind-text-gray-500'>{question.title}</p>
                    </div>
                  </div>
                }
                control={
                  <Checkbox
                    checked={selectedQuestions.map((o) => o.id).includes(question.id)}
                    checkedIcon={<Check />}
                    icon={<Check sx={{ opacity: 0.2, color: 'common.black' }} />}
                    onChange={(e) => {
                      const isSelected = selectedQuestions.map((o) => o.id).includes(question.id);
                      if (isSelected) {
                        setSelectedQuestions((prev) => prev.filter((o) => o.id !== question.id));
                      } else {
                        setSelectedQuestions((prev) => [...prev, question]);
                      }
                    }}
                    sx={{ marginRight: 2 }}
                  />
                }
                sx={{ marginLeft: 0, width: '100%', justifyContent: 'space-between' }}
              />
            ))}
          </div>
        );
      }

      if (tasks.length > 0) {
        return (
          <div className='space-y-1'>
            <div className='sticky top-0 z-10 flex justify-end py-4 px-2 bg-white dark:bg-gray-800 border-0 border-b border-solid border-gray-100 dark:border-gray-700'>문항수</div>
            <FlatList
              onEndReached={_loadTasksMore}
              onEndReachedThreshold={0.3}
              ItemSeparatorComponent={<div className='h-3 bg-item-separator' />}
              renderItem={_renderItemTask}
              data={tasks}
              loadingMore={loadingMoreObj.tasks}
            />
          </div>
        );
      }

      if (courses.length > 0) {
        return (
          <div className='relative space-y-1'>
            <div className='sticky top-0 z-10 flex justify-end py-4 px-2 bg-white dark:bg-gray-800 border-0 border-b border-solid border-gray-100 dark:border-gray-700'>과제수</div>
            <FlatList
              onEndReached={_loadCoursesMore}
              onEndReachedThreshold={0.3}
              ItemSeparatorComponent={<div className='h-3 bg-item-separator' />}
              renderItem={_renderItemCourse}
              data={courses}
              loadingMore={loadingMoreObj.courses}
            />
          </div>
        );
      }

      if (!isLoadedObj.courses || !isLoadedObj.tasks || !isLoadedObj.questions) return <LoadingSpinner />;

      return <div className='blank'>{message.blank.emptyTasks}</div>;
    };

    const _renderActions = () => (
      <>
        <div className='flex space-x-2 mr-auto'>
          <Button onClick={_onClose}>닫기</Button>
          {tasks.length > 0 && (
            <Button
              onClick={() => {
                setTasks([]);
                setQuestions([]);
                setBooleanObj((prev) => ({ ...prev, selectedQuestionsShown: false }));
              }}
              variant='outlined'
            >
              수업 목록
            </Button>
          )}
          {questions.length > 0 && (
            <Button
              onClick={() => {
                setQuestions([]);
                setBooleanObj((prev) => ({ ...prev, selectedQuestionsShown: false }));
              }}
              variant='outlined'
              sx={{ mr: 1, ml: 1 }}
            >
              과제
            </Button>
          )}
        </div>

        {booleanObj.selectedQuestionsShown ? (
          <Button
            variant='contained'
            onClick={() => {
              setBooleanObj((prev) => ({ ...prev, questionFinder: false, selectedQuestionsShown: false }));
              onAddMultiple(question.id, selectedQuestions);
              setSelectedQuestions([]);
            }}
            disabled={!selectedQuestions.length}
            sx={{ ml: 'auto' }}
          >
            문항 추가 ({numeral(selectedQuestions.length).format('0,0')})
          </Button>
        ) : (
          <Button
            variant='outlined'
            onClick={() => setBooleanObj((prev) => ({ ...prev, selectedQuestionsShown: true }))}
            disabled={!selectedQuestions.length}
            sx={{ ml: 'auto' }}
          >
            문항 보기 ({numeral(selectedQuestions.length).format('0,0')})
          </Button>
        )}
      </>
    );

    return (
      <Dialog
        fullWidth
        maxWidth='md'
        open={booleanObj.questionFinder}
        onClose={_onClose}
        PaperProps={{
          sx: {
            maxHeight: (theme) => (theme.breakpoints.up('lg') ? '70vh' : '100%'),
            minHeight: (theme) => (theme.breakpoints.up('lg') ? '40vh' : '100%'),
          },
        }}
      >
        <DialogContent className='relative overscroll-contain flex flex-col px-4 min-h-full -mb-1'>{_renderBody()}</DialogContent>
        <DialogActions className='sticky bottom-0 w-full flex items-center justify-between pt-3 pb-4 bg-white dark:bg-gray-800 border-t border-gray-100 dark:border-gray-700'>
          {_renderActions()}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div
      ref={questionFormWrapRef}
      onMouseLeave={() => booleanObj.sidebar && sideMenuTimeout()}
      onMouseOver={() => setBooleanObj((prev) => ({ ...prev, sidebar: true }))}
    >
      <div
        ref={ref}
        className={classNames(
          [question.questionType, question.title].every((e) => !!e)
            ? // bg-success-50 bg-opacity-20 dark:bg-success-500 dark:bg-opacity-10
              'outline outline-1 hover:outline-2 focus-within:outline-2 outline-primary-500/50 focus-within:outline-primary-500'
            : 'outline-1 outline-dashed focus-within:bg-white dark:focus-within:bg-gray-700 dark:focus-within:bg-opacity-25 dark:bg-gray-750 outline-gray-300 dark:outline-gray-600',
          'group relative p-4 focus-within:border-solid focus-within:shadow-lg rounded-xl'
        )}
      >
        <div className='rounded-md relative'>
          <div className='flex flex-col space-y-4'>
            <TextField
              fullWidth
              label='문항유형 *'
              placeholder='문항 유형을 선택해 주세요.'
              value={question.questionType}
              sx={{ maxWidth: { sm: 'full', md: 200 } }}
              name='questionType'
              onChange={(e) => {
                if (e.target.value !== question.questionType) onChange({ questionId: question.id, key: 'questionType', value: e.target.value });
              }}
              select
            >
              {questionTypes.map((item) => (
                <MenuItem
                  value={item.type}
                  key={item.type}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name='title'
              label='질문 *'
              fullWidth
              placeholder='질문을 작성해 주세요.'
              defaultValue={question.title}
              onChange={_handleChangeTitle}
              inputProps={{ maxLength: 500 }}
            />
            <TextField
              fullWidth
              name='description'
              label='추가 설명'
              aria-label='추가 설명'
              placeholder='문항에 대한 설명이 필요한 경우 작성해 주세요.'
              defaultValue={question.description}
              rows={3}
              multiline
              onChange={_handleChangeDescription}
              inputProps={{ maxLength: 10000 }}
              autoComplete='off'
            />
          </div>

          {!disabledChange && (
            <div className='mt-4 flex space-x-2 justify-end'>
              <IconButton
                onClick={() => onForward(question)}
                disabled={disabledChange || order === 0}
              >
                <KeyboardArrowUp />
              </IconButton>
              <IconButton
                disabled={disabledChange || order === total - 1}
                onClick={() => onBackward(question)}
              >
                <KeyboardArrowDown />
              </IconButton>
              <IconButton
                disabled={disabledChange}
                onClick={() => onDuplicate(question)}
              >
                <ContentCopy />
              </IconButton>
              <IconButton
                disabled={disabledChange || total <= 1}
                onClick={() => !disabledChange && setBooleanObj((prev) => ({ ...prev, delete: true }))}
              >
                <Remove />
              </IconButton>
            </div>
          )}
        </div>

        {!disabledChange && (
          <div
            className={classNames(
              booleanObj.sidebar ? 'flex' : 'hidden group-focus-within:flex group-hover:flex',
              'absolute flex-col px-2 py-3 space-y-2 -right-16 bottom-0 rounded-xl border border-gray-100 dark:border-gray-600 bg-gray-50 dark:bg-gray-750'
            )}
          >
            <IconButton
              disabled={disabledChange}
              onClick={() => onDuplicate({ id: question.id, questionType: undefined, title: '', description: '' })}
            >
              <Add />
            </IconButton>
            <IconButton
              disabled={disabledChange}
              onClick={() => {
                if (disabledChange) return;
                setBooleanObj((prev) => ({ ...prev, questionFinder: true }));
                setQuestionOnFocus(question.id);
              }}
            >
              <Inbox />
            </IconButton>
          </div>
        )}
      </div>

      {!disabledChange && (
        <Dialog
          open={booleanObj.delete}
          onClose={() => setBooleanObj((prev) => ({ ...prev, delete: false }))}
          fullWidth
          maxWidth='xs'
        >
          <DialogTitle sx={{ p: 3 }}>문항 삭제</DialogTitle>
          <DialogContent>문항을 삭제하시겠습니까?</DialogContent>
          <DialogActions
            sx={{ p: 3, pt: 0 }}
            className='grid grid-cols-3'
          >
            <Button
              fullWidth
              onClick={() => setBooleanObj((prev) => ({ ...prev, delete: false }))}
              className='col-start-1'
            >
              취소
            </Button>
            <Button
              variant='outlined'
              color='error'
              className='col-start-2 col-span-2'
              onClick={() => {
                if (disabledChange) return;
                onDelete(question.id);
                setBooleanObj((prev) => ({ ...prev, delete: false }));
              }}
            >
              삭제
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {_renderQuestionFinder()}
    </div>
  );
});

export default QuestionFormComponent;
