import React from "react";
import Layout from "./Layout";
// config
import { NAV_CONFIG_STUDENT } from "./navConfig";
import { MENU_OPTIONS_STUDENT } from "./header/accountPopoverConfig";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const StudentLayout = (props) => {
  const location = useLocation();
  const {isLoggedIn} = props.authReducer;
  if (!isLoggedIn) return <Navigate to="/login" state={{from: location}} replace/>;
  return <Layout accountPopoverConfig={MENU_OPTIONS_STUDENT} navConfig={NAV_CONFIG_STUDENT}/>
};

const enhance = connect((state) => ({...state}), {});
export default enhance(StudentLayout);
