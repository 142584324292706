import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import Network from "../../lib/Network";
import Loader from "../../assi-design-system-react/components/Loader";
import {Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography} from "@mui/material";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import StudentAnswerComponent from "../../components/student/StudentAnswerComponent";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import _ from "lodash";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import BookReportComment from "../../components/teacher/BookReportComment";
import NetworkStudent from "../../lib/NetworkStudent";

const TeacherBookReportDetail = (props) => {

  const {bookReportId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [bookReport, setBookReport] = useState({});

  // 선생님 변경을 위한 부분
  const [searching, setSearching] = useState(false);
  const [teacherSearchDialogOpen, setTeacherSearchDialogOpen] = useState(false);
  const [teacherUsers, setTeacherUsers] = useState([]);

  const [scoring, setScoring] = useState(false);
  const [scoreUploading, setScoreUploading] = useState(false);

  // 댓글 부분
  const [bookReportComments, setBookReportComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [uploadingComment, setUploadingComment] = useState(false);

  const isScored = !!bookReport.score || bookReport.score === 0;
  const isAssessor = bookReport?.teacherUserId === props.authReducer.user.id;
  const _startGrading = () => {
    setScoring(true);
  }


  const _loadBookReport = async () => {
    if (!bookReportId) return;
    try {
      const res = await Network.bookReportGET({bookReportId});

      setBookReport(res.bookReport);
      setIsLoaded(true);
      _loadBookReportComment();
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  };
  const _loadBookReportComment = async () => {
    try {
      const res = await Network.bookReportCommentsGET({
        bookReportId,
        offset: 0,
        limit: 100, // TODO 여기 더 불러오기 형태로 바꿔야 하는데...
      });
      setBookReportComments(res.bookReportComments);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    } finally {
    }
  };
  useEffect(() => {
    setIsLoaded(false)
    _loadBookReport();
  }, [bookReportId])

  const _handleSearchTeacher = async (e) => {
    e.preventDefault();
    if (searching) return;
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    setSearching(true);
    const res = await Network.usersGET({
      offset: 0,
      limit: 100,
      keyword: keyword,
      userType: "TEACHER",
    });
    if (!res.err) {
      setTeacherUsers(res.users);
    }
    setSearching(false);
  };
  const _handleChangeTeacherUser = async (teacherUser) => {
    if (!teacherUser?.id) return;
    const a = window.confirm(`${teacherUser.name} 선생님에게 독후감을 전달하시겠습니까?`);
    if (!a) return;
    try {
      await Network.bookReportPUT({bookReportId, teacherUserId: teacherUser.id});
      await _loadBookReport();
      _handleCloseTeacherSearchDialog();
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
  };

  const _saveScore = async () => {
    if (scoreUploading) return;
    setScoreUploading(true);
    try {
      const score = _.sumBy(bookReport.answers, o => {
        if (o.score > -1) return o.score;
        else return 0;
      })
      const res = await Network.bookReportScorePUT({
        bookReportId,
        score: score,
        answers: bookReport.answers,
      })
      await _loadBookReport();
      setScoring(false);
    } catch (e) {
      window.alert(e.cause?.errorMessage);
    }
    setScoreUploading(false);
  }
  const _cancelScore = () => {
    setScoring(false);
  }
  const _deleteScore = async () => {
    if (scoreUploading) return;
    setScoreUploading(true);
    try {
      const res = await Network.bookReportScoreDELETE({bookReportId})
      await _loadBookReport()
      setScoring(false);
    } catch (e) {
      window.alert(e.cause?.errorMessage)
    }
    setScoreUploading(false);
  }

  const _uploadBookReportComments = async () => {
    if (newComment?.trim() === "") return;
    setUploadingComment(true);
    try {
      const res = await NetworkStudent.bookReportCommentPOST({
        bookReportId,
        content: newComment,
      });
      setNewComment("");
      await _loadBookReportComment();
    } catch (e) {
    } finally {
      setUploadingComment(false);
    }
  };

  const _handleOpenTeacherSearchDialog = () => {
    setTeacherSearchDialogOpen(true);
  };
  const _handleCloseTeacherSearchDialog = () => {
    setTeacherSearchDialogOpen(false);
    setTeacherUsers([]);
  };

  const _renderTeacherUserSearchDialog = () => {
    return (
      <Dialog fullWidth open={teacherSearchDialogOpen}>
        <DialogTitle>선생님 선택</DialogTitle>
        <Divider sx={{mt: 2}}/>
        <DialogContent>
          <SearchTextField onSubmit={_handleSearchTeacher}/>
        </DialogContent>
        <DialogContent sx={{height: "50vh"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>이름</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacherUsers.map((teacherUser, index) => {
                return (
                  <TableRow key={teacherUser.id}>
                    <TableCell>
                      <Avatar src={teacherUser.profileImageUrl}/>
                    </TableCell>
                    <TableCell>
                      {teacherUser.name} ({teacherUser.displayName})
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => _handleChangeTeacherUser(teacherUser)}>선택</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <LoadingButton onClick={_handleCloseTeacherSearchDialog}>닫기</LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  if (!isLoaded) return <Loader/>

  const {user: studentUser, teacherUser, task} = bookReport || {};

  const _renderTopTable = () => {
    if (scoring) return;
    return (
      <Box sx={{width: '100%'}}>
        <div className={'my-2'}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            기본정보
          </Typography>
        </div>
        <Scrollbar sx={{width: '100%'}}>
          <TableContainer sx={{}}>
            <Table className={'w-[100%]'}>
              <TableBody>
                {
                  !!task?.course?.id && (
                    <TableRow>
                      <TableCell className={'font-bold w-[200px]'}>
                        수업/학습활동
                      </TableCell>
                      <TableCell>
                        {task?.course?.title} ({task.course?.teacherUser?.name || '-'} 선생님)
                      </TableCell>
                      <TableCell>
                      </TableCell>
                    </TableRow>
                  )
                }
                <TableRow>
                  <TableCell className={'font-bold w-[200px]'}>
                    과제
                  </TableCell>
                  <TableCell>
                    {task?.title}
                  </TableCell>
                  <TableCell>
                    {task.type === 'PRIVATE' ? '개별독후감' : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={'font-bold'}>
                    기한
                  </TableCell>
                  <TableCell>
                    {dayjs(task.startDate).format('YYYY.MM.DD(ddd)')} - {dayjs(task.endDate).format('MM.DD(ddd) HH:mm')}
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={'font-bold'}>
                    담당 선생님
                  </TableCell>
                  <TableCell className={''}>
                    {/*<Link to={PATH_TEACHER.teacher.detail.root(teacherUser?.id)}>*/}
                    <div className={'flex items-center'}>
                      <img src={teacherUser.profileImageUrl} className={'w-[30px] h-[30px] rounded-full'}/>
                      {/*<p className={'pl-2 group-hover:underline clickable-primary hover:underline'}>*/}
                      <p className={'pl-2 '}>
                        {teacherUser.name} 선생님
                      </p>
                    </div>
                    {/*</Link>*/}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={_handleOpenTeacherSearchDialog}
                      variant={'outlined'}
                    >
                      변경하기
                    </Button>
                    {_renderTeacherUserSearchDialog()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={'font-bold'}>
                    학생
                  </TableCell>
                  <TableCell className={''}>
                    {/*<Link to={PATH_TEACHER.student.detail.root(studentUser?.id)}>*/}
                    <div className={'flex items-center group'}>
                      <img src={studentUser.profileImageUrl} className={'w-[30px] h-[30px] rounded-full'}/>
                      <div className={'pl-2'}>
                        <p className={''}>
                          {studentUser.name} ({studentUser.classRegister?.class?.year}년 {studentUser?.classRegister?.class?.grade}학년 {studentUser?.classRegister?.class?.classNo}반 {studentUser?.classRegister?.studentNo}번)
                        </p>
                      </div>
                    </div>
                    {/*</Link>*/}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {
                  (isAssessor && bookReport.task?.scoreType === 'SCORE') && (
                    <TableRow>
                      <TableCell className={'font-bold'}>
                        평가
                      </TableCell>
                      <TableCell>
                        {isScored ? bookReport.score : '미평가'}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={_startGrading}
                          variant={'outlined'}
                          disabled={!isAssessor || scoring}>
                          {isScored ? '평가수정' : '평가하기'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                }
                {
                  !!bookReport?.book?.id && (
                    <TableRow>
                      <TableCell className={'font-bold'}>
                        도서
                      </TableCell>
                      <TableCell>
                        {bookReport.book?.title || '-'} <br/>
                        {bookReport.book?.authors} | {bookReport.book?.publisher} | {bookReport.book?.pubDate}
                      </TableCell>
                      <TableCell>
                        <img src={bookReport.book?.coverImageUrl} className={'w-[35px] max-h-[50px] object-contain object-center overflow-hidden'}/>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
    )
  }
  const _renderAnswerPart = () => {
    return (
      <Box sx={{mt: 5, mb: 10, boxShadow: 5, p: 4}}>
        <div className={'space-y-3'}>
          <div>
            <p className={'text-gray-500 text-sm'}>
              제출일 : {dayjs(bookReport?.submittedAt).format('YYYY.MM.DD(ddd) HH:mm')}
            </p>
          </div>
          <div>
            <p className={'text-gray-500 text-sm'}>
              제목
            </p>
            <Typography variant={"h5"} sx={{fontWeight: "800"}}>
              {bookReport?.title}
            </Typography>
          </div>
        </div>

        <Divider sx={{mb: 3, mt: 1}}/>
        {bookReport?.answers?.map((item, index) => {
          return (
            <div className={'flex'} key={index}>
              <div className={'flex-1'}>
                <StudentAnswerComponent answer={item} hideDescription hideType/>
              </div>
              {
                (isAssessor && bookReport.task?.scoreType === 'SCORE') && (
                  <div className={'pl-4'}>
                    <TextField
                      disabled={!scoring}
                      label={'점수'}
                      type={'number'}
                      sx={{width: 100}}
                      value={String(item.score) || 0}
                      onChange={({target}) => {
                        const newAnswers = [...bookReport.answers];
                        if ((/^\d+$/).test(target.value)) {
                          newAnswers[index].score = Number(target.value);
                        } else {
                          newAnswers[index].score = 0;
                        }
                        console.log(newAnswers[index].score);
                        setBookReport(prev => ({...prev, answers: newAnswers}))
                      }}
                    />
                  </div>
                )
              }
            </div>
          )
        })}
        {scoring && (
          <div>
            <Divider sx={{my: 2}}/>
            <div className={'flex flex-col items-end'}>
              <p className={'text-lg font-bold mb-4'}>
                총점: {
                _.sumBy(bookReport.answers, o => {
                  if (o.score > -1) return o.score;
                  else return 0;
                })} 점
              </p>
              <div className={'flex w-full justify-between'}>
                <Button
                  onClick={_cancelScore}
                  variant={'outlined'}
                  // size={'large'}
                  color={'error'}
                  disabled={bookReport.score === null || scoreUploading}
                >
                  취소
                </Button>
                <div className={'flex space-x-2'}>
                  <Button
                    onClick={_deleteScore}
                    variant={'outlined'}
                    // size={'large'}
                    color={'error'}
                    disabled={bookReport.score === null || scoreUploading}>
                    평가 삭제
                  </Button>
                  <Button
                    disabled={scoreUploading}
                    onClick={_saveScore}
                    // size={'large'}
                    variant={'outlined'}>
                    평가 저장
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
    );
  };
  const _renderComment = () => {
    if (scoring) return <></>;
    return (
      <Box sx={{mt: 5, mb: 10, boxShadow: 5, p: 4}}>
        <div className={'my-2'}>
          <Typography variant={"h5"} sx={{fontWeight: "800"}}>
            댓글
          </Typography>
        </div>
        {bookReportComments.map((item, index) => {
          return (
            <Box key={item.id}>
              <BookReportComment loadBookReportComment={_loadBookReportComment} bookReportComment={item}/>
              {item.bookReportComments?.map((comment, i) => {
                return <BookReportComment key={i} loadBookReportComment={_loadBookReportComment} bookReportComment={comment}/>;
              })}
            </Box>
          );
        })}
        <TextField
          fullWidth
          label="댓글을 달아보세요."
          multiline
          rows={4}
          value={newComment || ""}
          onChange={(e) => {
            if (e.target.value?.length > 1000) return;
            setNewComment(e.target.value);
          }}
          placeholder={"작성 학생, 과목 담당선생님, 독후감을 받아보시는 선생님은 댓글을 확인할 수 있습니다."}
        />
        <Typography sx={{mt: 1, mb: 2}}>{newComment?.length > 0 ? `${newComment.length}/` : ""}1000자 이내</Typography>
        <LoadingButton onClick={_uploadBookReportComments} loading={uploadingComment} disabled={newComment?.trim()?.length < 1 || uploadingComment} variant={"contained"}>
          의견 남기기
        </LoadingButton>
      </Box>
    )
  }
  return (
    <Container maxWidth={'lg'} className={'space-y-4'}>
      {_renderTopTable()}
      {_renderAnswerPart()}
      {_renderComment()}
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(TeacherBookReportDetail);
