import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Container, Pagination, PaginationItem, Table, Avatar, InputLabel, Typography, TableHead, TableBody, Box, TableRow, TableCell, FormControl, Select, MenuItem, FormHelperText, Paper, TableContainer} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import * as Icon from '@mui/icons-material';
import dayjs from "dayjs";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import MessageBox from '../../components/shared/MessageBox';

const AdminSchoolManagePage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <Container maxWidth={'lg'}>
      <MessageBox
        title={'안내'}
        content={'추후 결제 내역 등 학교 전체에 대한 정보가 제공될 예정입니다.'}
      />
    </Container>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminSchoolManagePage);
