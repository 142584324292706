// message.js message js constants
// blank >> https://sideproject-readers.slack.com/archives/C01R7JZSS3C/p1649123189461489

export const placeholder = {
  findStudentsByNameAndEmail: "이름 또는 이메일로 학생 찾기",
  findStudentsByName: "이름으로 학생 찾기",
  findBookReportsByName: "이름으로 독후감 찾기",
  findTasks: "제목 또는 설명으로 과제 찾기",
  findCourses: "제목으로 수업 찾기",
};

export const blank = {
  emptyStudents: "학생이 없어요.",
  emptySearchResults: "검색결과가 없어요.",
  emptyNotScoredBookReports: "채점하지 않은 독후감이 없어요.",
  emptyCourseOpened: "개설한 수업이 없어요.",
  emptyCourseDescription: "작성된 과제 설명이 없어요.",
  emptyFile: "첨부된 파일이 없어요.",
  emptySelectedBooks: "지정된 도서가 없어요.",
  emptyBookReportsReported: "제출된 독후감이 없어요.",
  emptyBookReportsWritten: "작성된 독후감이 없어요.",
  emptyParticipatingStudents: "참여한 학생이 없어요.",
  emptyNotifications: "알림이 없어요.",
  emptyTasks: "과제가 없어요.",
  chooseStudents: "참여시킬 학생을 지정해 주세요.",
};


const message = { placeholder, blank };

export default message;
