import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Button, Card, CardContent, Typography, Container, Grid, Chip } from "@mui/material";
import * as Icon from "@mui/icons-material";
import NetworkStudent from "../../lib/NetworkStudent";
import dayjs from "dayjs";
import StudentCourseComponent from "../../components/student/StudentCourseComponent";
import { Link, useNavigate } from "react-router-dom";
import { courseTypeText } from "../../constants/dataTypes";
import { PATH_STUDENT } from "../../routes/paths";

const StudentHomePage = (props) => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const [courses, setCourses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [books, setBooks] = useState([]);
  const [meta, setMeta] = useState({});

  // 데이터 불러오기
  const _loadBooksIng = async () => {
    try {
      const res = await NetworkStudent.userMeBooksGET({
        offset: 0,
        limit: 5,
        readStatus: "ING",
      });
      if (!res.err) {
        setBooks(res.books);
        setMeta((prev) => ({ ...prev, totalBooks: res.meta?.total }));
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };
  const _loadTasksIng = async () => {
    try {
      const res = await NetworkStudent.tasksGET({
        offset: 0,
        limit: 5,
        isOngoing: true,
      });
      if (!res.err) {
        setTasks(res.tasks);
        setMeta((prev) => ({ ...prev, totalTasks: res.meta?.total }));
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };
  const _loadCourses = async () => {
    try {
      const res = await NetworkStudent.coursesGET({
        isRegistered: true,
        offset: 0,
        limit: 5,
        year: dayjs().format("YYYY"),
      });

      if (!res.err) {
        setCourses(res.courses);
        setMeta((prev) => ({ ...prev, totalCourses: res.meta?.total }));
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };
  const _load = async () => {
    await _loadTasksIng();
    await _loadCourses();
    await _loadBooksIng();
  };

  useEffect(() => {
    _load();
  }, []);

  const _renderMyBookReportStatus = (task) => {
    if (!task.bookReportMy) {
      return <Chip size={"small"} label={"미작성"} color={"error"} variant={"outlined"} sx={{ mb: 0.3 }} />;
    } else if (!task.bookReportMy.submittedAt) {
      return <Chip size={"small"} label={"미제출-작성중"} color={"error"} variant={"outlined"} sx={{ mb: 0.3 }} />;
    } else {
      return <Chip size={"small"} label={"제출완료"} color={"success"} variant={"outlined"} sx={{ mb: 0.3 }} />;
    }
  };

  return (
    <Container maxWidth={"lg"}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3 }}>
            <Link to={`/task`}>
              <CardContent className={"flex items-center !pb-0 group"}>
                <p className={"flex-1 text-lg font-bold group-hover:text-primary-500"}>진행 중인 과제</p>
                <Icon.ChevronRight className={"group-hover:text-primary-500"} />
              </CardContent>
            </Link>
            <CardContent>
              {tasks.map((task, i) => {
                return (
                  <Link to={PATH_STUDENT.task.detail(task.id)} key={i}>
                    <div className={"group flex py-1 my-4 hover:cursor-pointer hover:text-primary-500"}>
                      <div className={"flex-1"}>
                        {_renderMyBookReportStatus(task)}
                        <p className={"line-clamp-1 font-semibold"}>{task?.title}</p>
                        <p className={"line-clamp-1 text-sm"}>{task?.course?.title}</p>
                        <p className={"text-gray-600 group-hover:text-primary-500 text-sm "}>
                          {dayjs(task?.startDate).format("YY.MM(ddd)")} - {dayjs(task?.endDate).format("YY.MM(ddd)")}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3 }}>
            <Link to={`/course`}>
              <CardContent className={"flex items-center !pb-0 group"}>
                <p className={"flex-1 text-lg font-bold group-hover:text-primary-500"}>나의 수업/활동</p>
                <Icon.ChevronRight className={"group-hover:text-primary-500"} />
              </CardContent>
            </Link>
            <CardContent>
              {courses.map((course, i) => {
                return (
                  <Link to={PATH_STUDENT.course.detail(course.id)} key={i}>
                    <div className={"group flex py-1 my-4 hover:cursor-pointer hover:text-primary-500"}>
                      <div className={"w-16 h-16 rounded-2xl bg-gray-200 overflow-hidden"}>
                        <img className={"w-16 h-16 object-cover"} alt={course.title} src={course.coverImageUrl} />
                      </div>
                      <div className={"flex-1 pl-2"}>
                        <p className={"line-clamp-1 font-semibold "}>{course.title}</p>
                        <p className={"text-gray-600 group-hover:text-primary-500 text-sm "}>
                          {courseTypeText[course.courseType]} {course?.subject?.name}
                        </p>
                        <p className={"text-gray-600 group-hover:text-primary-500 text-sm"}>{course?.teacherUser?.name}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{ boxShadow: 3 }}>
            <Link to={`/book`}>
              <CardContent className={"flex items-center !pb-0 group"}>
                <p className={"flex-1 text-lg font-bold group-hover:text-primary-500"}>읽고 있는 책</p>
                <Icon.ChevronRight className={"group-hover:text-primary-500"} />
              </CardContent>
            </Link>
            <CardContent>
              {books.map((book, i) => {
                return (
                  <Link to={PATH_STUDENT.searchBook.detail(book.id)} key={i}>
                    <div className={"group flex py-1 my-4 hover:cursor-pointer hover:text-primary-500 items-center"}>
                      <div className={"w-16 h-24  overflow-hidden"}>
                        <img className={"w-16 h-24 object-contain"} alt={book.title} src={book.coverImageUrl} />
                      </div>
                      <div className={"flex-1 pl-2"}>
                        <p className={"line-clamp-1 font-semibold "}>{book.title}</p>
                        <p className={"text-gray-600 group-hover:text-primary-500 text-sm "}>{book.authors}</p>
                        <p className={"text-gray-600 group-hover:text-primary-500 text-sm "}>
                          {book.publisher} ({dayjs(book.pubDate).format("YYYY.MM")})
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(StudentHomePage);
