import dayjs from "dayjs";

import firebaseApp from "../config/firebase.config";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { v4 as uuidv4 } from "uuid";

export const fileUploadToFirebase = async ({
  file,
  bucketName = "temp",
  fileName = uuidv4(),
  onProgress = (bytesTransferred, totalBytes) => {
    // console.log(`upload: ${((bytesTransferred / totalBytes) * 100).toFixed(1)}%`);
  },
}) => {
  if (!file) return false;
  try {
    const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
    const metadata = {
      contentType: file.type,
    };

    const storage = getStorage(firebaseApp);
    // const storage = getStorage(firebaseApp, "gs://my-custom-bucket");

    const bucketUrl = `${bucketName}/${fileName}.${ext}`;
    const storageRef = ref(storage, bucketUrl);

    return new Promise((resolve, reject) => {
      const task = uploadBytesResumable(storageRef, file, metadata);
      task.on(
        "state_changed",
        (taskSnapshot) => {
          onProgress(taskSnapshot.bytesTransferred, taskSnapshot.totalBytes);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(task.snapshot.ref).then((downloadURL) => {
            resolve({
              ext: ext,
              url: downloadURL,
              fileName: file.name,
              fileType: file.type,
            });
          });
        },
      );
    });
  } catch (e) {
    return false;
  } finally {
  }
};
