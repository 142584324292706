import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Box, Button, Card, CardContent, Container,
  Dialog, TextField, DialogActions, DialogContentText, DialogTitle, DialogContent,
  Grid, Stack, Divider, Chip,
} from '@mui/material';
import {LoadingButton} from "@mui/lab";

import NetworkStudent from '../../lib/NetworkStudent';
import {questionTypeText} from "../../constants/dataTypes";

import MessageBox from '../../components/shared/MessageBox';
import LoadingSpinner from '../../components/shared/LoadingSpinner';

const StudentBookReportWriteCreateTaskPage = (props) => {

  const navigate = useNavigate();
  const {taskId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [taskLoaded, setTaskLoaded] = useState(false)
  const [task, setTask] = useState({})
  const [questions, setQuestions] = useState([]);
  const [exampleTasks, setExampleTasks] = useState([])
  const [taskDialogOpen, setTaskDialogOpen] = useState(false);


  const _loadExampleTasks = async () => {
    try {
      const res = await NetworkStudent.tasksGET(
        {
          offset: 0,
          limit: 10,
          example: true,
        },
      )
      setExampleTasks(res.tasks);
      setIsLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  }

  const _loadTask = async (taskId) => {
    try {
      const res = await NetworkStudent.taskGET({taskId});
      setTask(res.task);
      setTaskLoaded(true);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
  }

  const _makeTaskAndReport = async () => {
    if (!Array.isArray(task.questions) || task.questions?.length < 1) {
      return window.alert('최소 1개 이상의 문항이 필요합니다.');
    }
    setUploading(true);
    try {
      const res = await NetworkStudent.taskPOST({questions: task.questions});
      navigate(`/book-report/${res.bookReport?.id}/edit`);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    _loadExampleTasks();
  }, [])

  const _closeTaskDialog = () => {
    setTaskDialogOpen(false);
    setTask({});
    setTaskLoaded(false);
  }


  const _renderDialog = () => {
    return (
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={taskDialogOpen}
      >
        <DialogTitle>
          독후감 양식 선택
          <p className={'text-sm'}>
            양식의 문항을 확인해 주세요.
          </p>
        </DialogTitle>

        <Divider sx={{mt: 2}}/>
        {
          !!taskLoaded ? (
            <DialogContent sx={{height: "50vh"}}>

              {
                task.questions?.map((item, index) => {
                  return (
                    <div key={index} className={'mb-6'}>
                      <p className={'text-lg font-bold'}>
                        {index + 1}. {item.title}
                      </p>
                      <p className={''}>
                        {item.description}
                      </p>
                      <Chip variant="contained"
                            label={`답변 유형: ${questionTypeText[item.questionType]}`}
                      />
                    </div>
                  )
                })
              }
            </DialogContent>
          ) : (
            <DialogContent sx={{height: "50vh"}}>
              <LoadingSpinner/>
            </DialogContent>
          )
        }

        <DialogActions>
          <LoadingButton
            variant={'outlined'}
            onClick={_closeTaskDialog}
            loading={uploading}
            disabled={uploading}
          >
            취소
          </LoadingButton>
          <LoadingButton
            variant={'outlined'}
            loading={uploading}
            disabled={uploading}
            onClick={_makeTaskAndReport}
          >
            독후감 작성 시작하기
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <>
      {_renderDialog()}
      <Container maxWidth={'lg'}>
        <h4 className={'font-bold'}>
          독후감 양식 선택
        </h4>
        <MessageBox
          sx={{mt: 2}}
          content={'독후감 양식을 선택해 주세요.\n독후감 작성을 시작한 이후에는 문항을 수정할 수 없습니다.'}
        />
      </Container>
      <Container
        sx={{mt: 3}}
        maxWidth={'lg'}>
        <Grid
          container
          spacing={3}
        >
          {
            exampleTasks.map((item, index) => {
              return (
                <Grid
                  xs={12} md={6}
                  key={index}
                  item
                >
                  <Card sx={{boxShadow: 2}}>
                    <CardContent>
                      <p className={'font-bold'}>
                        {item.title}
                      </p>
                      <p className={'whitespace-pre-line mt-4'}>
                        {item.description}
                      </p>
                    </CardContent>
                    <CardContent>
                      <Button
                        onClick={() => {
                          setTaskDialogOpen(true);
                          _loadTask(item.id);
                        }}
                      >
                        선택
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(StudentBookReportWriteCreateTaskPage);
