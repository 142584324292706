import dayjs from "dayjs";

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, useSearchParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import Network from "../../lib/Network";
import numeral from "numeral";
import { Container, Pagination, PaginationItem, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import Loader from "../../assi-design-system-react/components/Loader";
import ListEmpty from "../../components/shared/ListEmpty";
import { PATH_TEACHER } from "../../routes/paths";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";

const TeacherClassDetail = (props) => {


  const {classId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const keyword = query.get("keyword") || "";


  const [isLoaded, setLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [cls, setCls] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const _loadClassUsers = async () => {
      try {
        const res = await Network.classUsersGET({
          classId,
          offset: (page - 1) * perPage,
          limit: perPage,
          keyword,
        });
        setMeta({total: res.meta.total});
        setCls(res.class || {});
        setUsers(res.users || []);
        setLoaded(true);
      } catch (error) {
        window.alert(error.cause?.errorMessage)
      }
    }
    _loadClassUsers();
  }, [classId, page, perPage, keyword]);

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  if (!isLoaded) return <Loader/>

  return (
    <Container maxWidth={'xl'}>
      <div className="space-y-1">
        <p className={'text-xl font-bold'}>
          {cls?.grade}학년 {cls.classNo}반
        </p>
        <p className="bind-text-gray-500 text-sm">
          {meta.total}명의 학생이 있습니다
        </p>
      </div>
      <SearchTextField
        sx={{my:2}}
        onSubmit={_handleSearch}
      />
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>이름</TableCell>
            <TableCell>이메일</TableCell>
            <TableCell>학년</TableCell>
            <TableCell>반</TableCell>
            <TableCell>번호</TableCell>
            <TableCell>독후감</TableCell>
            <TableCell>최근작성일</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => {
            return (
              <TableRow key={user.id} className={'hover:bg-hover cursor-pointer'}>
                <TableCell>
                  {(page - 1) * perPage + 1 + index}
                </TableCell>
                <TableCell>
                  <Link to={PATH_TEACHER.class.detail.studentDetail(classId, user.id)}>
                    <div className={'flex items-center'}>
                      <img src={user.profileImageUrl} className={'w-[30px] h-[30px] rounded-full'}/>
                      <p className={'pl-2 clickable-primary'}>
                        {user.name}
                      </p>
                    </div>
                  </Link>
                </TableCell>
                <TableCell>
                  {user.email}
                </TableCell>
                <TableCell>
                  {user.classRegister?.class?.grade}
                </TableCell>
                <TableCell>
                  {user.classRegister?.class?.classNo}
                </TableCell>
                <TableCell>
                  {user.classRegister?.studentNo}
                </TableCell>
                <TableCell>
                  {numeral(user.bookReportStat?.count || 0).format("0,0")}
                </TableCell>
                <TableCell>
                  {!!user.bookReportStat?.recentCreatedAt ? dayjs(user.bookReportStat.recentCreatedAt).format("YYYY. M. D (ddd) HH:mm") : "-"}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      {users.length === 0 && <ListEmpty/>}
    </Container>
  );
};

const enhance = connect((state) => ({...state}), {});
export default enhance(TeacherClassDetail);
