import dayjs from "dayjs";

import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {connect} from "react-redux";

import * as XLSX from "xlsx-js-style"; // import XLSX from "xlsx"; 모듈 default import 에러
import Network from "../../lib/Network";
import numeral from "numeral";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {LoadingButton} from "@mui/lab";
import {Button, Container, MenuItem, Pagination, PaginationItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import {SaveOutlined} from "@mui/icons-material";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {PATH_TEACHER} from "../../routes/paths";
import SearchTextField from "../../assi-design-system-react/components/SearchTextField";
import Loader from "../../assi-design-system-react/components/Loader";

const TeacherBookReportMain = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page")) || 1;
  const perPage = parseInt(query.get("perPage")) || 10;
  const keyword = query.get("keyword") || "";
  const isScored = query.get("isScored") || "";
  const minSubmittedAt = query.get('minSubmittedAt') || dayjs('2022-01-01').toISOString();
  const maxSubmittedAt = query.get('maxSubmittedAt') || dayjs().endOf('year').toISOString()

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({total: 0});
  const [bookReports, setBookReports] = useState([]);
  const [isLoadingForAllTheBookReports, setLoadingForAllTheBookReports] = useState(false);

  const _downloadExcel = (courseAndTask, columns, maxNumberOfAnswers) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(columns, {origin: "A8", skipHeader: true});

    const headingStyle = {
      t: "s",
      s: {
        font: {bold: true},
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: "1",
        },
      },
    };

    const title = [
      [
        {v: "독후감 목록", t: "s", s: {font: {bold: true, sz: 16}}},
        {v: `${dayjs(minSubmittedAt).format("YYYY-MM-DD")}~${dayjs(maxSubmittedAt).format("YYYY-MM-DD")}`, t: "s", s: {font: {bold: true, sz: 16}}},
      ],
    ];
    XLSX.utils.sheet_add_aoa(worksheet, title, {origin: "A2"});

    const info = [[{v: "총 독후감 수", ...headingStyle}], [{v: columns.length}]];
    XLSX.utils.sheet_add_aoa(worksheet, info, {origin: "A4"});

    const subheading = [
      [
        {v: "이름", ...headingStyle},
        {v: "학년", ...headingStyle},
        {v: "반", ...headingStyle},
        {v: "번호", ...headingStyle},
        {v: "과제 타입", ...headingStyle},
        {v: "수업명", ...headingStyle},
        {v: "과제명", ...headingStyle},
        {v: "책", ...headingStyle},
        {v: "책 저자", ...headingStyle},
        {v: "독후감 제목", ...headingStyle},
        {v: "제출일", ...headingStyle},
        {v: "점수", ...headingStyle},
        ...[...Array(maxNumberOfAnswers * 2)].map((item, idx) => ({
          v: (idx + 1) % 2 === 0 ? `${parseInt(idx / 2) + 1}번 답변` : `${parseInt(idx / 2) + 1}번 문항`,
          t: "s",
          s: {
            font: {bold: true},
            alignment: {
              vertical: "center",
              horizontal: "left",
              wrapText: "1",
            },
          },
        })),
      ],
    ];
    console.log(maxNumberOfAnswers);
    XLSX.utils.sheet_add_aoa(worksheet, subheading, {origin: "A7"});

    // worksheet["!merges"] = [
    //   { s: { r: 6, c: 0 }, e: { r: 6, c: 3 } },
    //   { s: { r: 6, c: 4 }, e: { r: 7, c: 4 } },
    //   { s: { r: 6, c: 5 }, e: { r: 6, c: 6 } },
    //   { s: { r: 6, c: 7 }, e: { r: 6, c: 10 } },
    //   { s: { r: 6, c: 11 }, e: { r: 6, c: 11 + maxNumberOfAnswers * 2 - 1 } },
    //   // ...[...Array(maxNumberOfAnswers)].map((item, idx) => ({ s: { r: 7, c: 11 + idx * 2 }, e: { r: 7, c: 11 + idx * 2 + 1 } })),
    // ];

    worksheet["!cols"] = [
      {wch: 12}, // 이름
      {wch: 4},
      {wch: 4},
      {wch: 4},
      {wch: 12},
      {wch: 16}, // 수업명
      {wch: 16},
      {wch: 32}, // 책 제목
      {wch: 12},
      {wch: 32},
      {wch: 16},
      {wch: 4},
      ...[...Array(maxNumberOfAnswers * 2)].map((empty, idx) => {
        return {wch: idx % 2 === 0 ? 12 : 60};
      }),
    ];

    worksheet["!rows"] = [{}, {hpt: 40}, {}, {}, {}, {}, {hpt: 24}];

    XLSX.utils.book_append_sheet(workbook, worksheet, "학생 독후감 엑셀");
    XLSX.writeFile(workbook, `독후감_${dayjs().format("YYYY-MM-DD_a_hh:mm")}.xlsx`, {cellStyles: true});
  };
  const _loadForExcel = async () => {
    let allTheBookReports = [];

    const limit = 500;
    const loadBookReportsBy500 = async (offset = 0) => {
      const result = await Network.bookReportsGET({
        offset,
        keyword,
        limit,
        teacherUserId: props.authReducer.user?.id,
        includingAnswers: true,
        minSubmittedAt,
        maxSubmittedAt,
      });
      allTheBookReports = allTheBookReports.concat(result.bookReports);
      if (result.bookReports.length < limit) {
        return;
      } else if (result.bookReports.length === limit) {
        return await loadBookReportsBy500(allTheBookReports.length);
      }
    };

    try {
      await loadBookReportsBy500();
      return allTheBookReports;
    } catch (error) {
      alert(error.cause?.errorMessage)
      return [];
    }
  };
  const _downloadBookReportsInExcel = async () => {
    if (dayjs(maxSubmittedAt).diff(minSubmittedAt, 'day') > 180) return alert('기간을 180일 이내로 변경해 주세요')
    setLoadingForAllTheBookReports(true);

    try {
      const allTheBookReports = await _loadForExcel();

      const numberOfAnswers = allTheBookReports.map((bookReport) => bookReport.answers?.length || 0) || [];
      const maxNumberOfAnswers = Math.max(...numberOfAnswers);

      const questionAndAnswerSequenceArray = [];
      allTheBookReports.forEach((bookReport) => {
        const questionsAndAnswers = [];
        bookReport?.answers.forEach((answer, idx) => {
          questionsAndAnswers.push(answer.question.title);
          questionsAndAnswers.push(
            answer.content ||
            answer.imageUrls.map((image) => image.url).join("\n") ||
            answer.fileUrls.map((file) => file.url).join("\n") ||
            answer.videoUrls.map((video) => video.url).join("\n"),
          );
        });
        questionAndAnswerSequenceArray.push(questionsAndAnswers);
      });

      _downloadExcel(
        {},
        allTheBookReports.map((item, idx) => [
          item.user.name,
          item.user.classRegister.class.grade,
          item.user.classRegister.class.classNo,
          item.user.classRegister.studentNo,
          //
          item.task.taskType === "PRIVATE" ? "개별 제출" : "수업/활동 과제",
          item.task?.course?.title || "",
          item.task?.title || "",
          item.book?.title || "",
          item.book?.authors || "",
          item.title || "",
          `${dayjs(item.submittedAt).format("YYYY-MM-DD a hh:mm")}`,
          item.score || "",
          ...questionAndAnswerSequenceArray[idx],
        ]),
        maxNumberOfAnswers,
      );
    } catch (error) {
      console.log(error);
    }

    setLoadingForAllTheBookReports(false);
  };

  // API
  useEffect(() => {
    const _loadBookReports = async () => {
      try {
        const result = await Network.bookReportsGET({
          offset: (page - 1) * perPage,
          keyword,
          limit: perPage,
          teacherUserId: props.authReducer.user?.id,
          isScored,
          minSubmittedAt,
          maxSubmittedAt,
        })
        setBookReports(result.bookReports);
        setMeta(prev => ({...prev, ...result.meta}));
        setIsLoaded(true);
      } catch (error) {
        window.alert(error.cause?.errorMessage);
      }
    }
    _loadBookReports();
  }, [keyword, page, perPage, isScored, minSubmittedAt, maxSubmittedAt]);

  const _handleSearch = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const keyword = data.get("keyword");
    if (keyword) {
      searchParams.set('keyword', keyword)
    } else {
      searchParams.delete('keyword');
    }
    searchParams.delete('page')
    console.log(searchParams.toString())
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  const _onChangeIsScored = ({target}) => {
    if (target.value) {
      searchParams.set('isScored', target.value)
    } else {
      searchParams.delete('isScored');
    }
    searchParams.delete('page')
    navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
  }

  if (!isLoaded) return <Loader/>;

  return (
    <Container maxWidth={'xl'}>
      <div className="space-y-1 mb-3">
        <p className={'text-xl font-bold'}>
          독후감
        </p>
        <p className={'text-gray-400'}>
          {bookReports.length > 0 ? `${numeral(meta.total).format("0,0")}개의 제출된 독후감이 있어요.` : "제출된 독후감이 없어요."}
        </p>
      </div>
      <div className="mb-3">
        <div className={'flex flex-col sm:flex-row sm:items-start sm:space-x-2 my-4'}>
          <DesktopDatePicker
            label="기간 시작 날짜"
            inputFormat="YYYY-MM-DD"
            value={dayjs(minSubmittedAt).format('YYYY-MM-DD')}
            onChange={(newValue => {
              searchParams.set('minSubmittedAt', dayjs(newValue).startOf('day').toISOString())
              navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
            })}
            sx={{maxWidth: {xs: "full", sm: 150}}}
            renderInput={(params) => <TextField fullWidth {...params} sx={{maxWidth: {sm: "full", md: 200}}}/>}
          />
          <DesktopDatePicker
            label="기간 마지막 날짜"
            inputFormat="YYYY-MM-DD"
            value={dayjs(maxSubmittedAt).format('YYYY-MM-DD')}
            onChange={(newValue => {
              searchParams.set('maxSubmittedAt', dayjs(newValue).endOf('day').toISOString())
              navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true})
            })}
            sx={{maxWidth: {xs: "full", sm: 150}}}
            renderInput={(params) => <TextField fullWidth {...params} sx={{maxWidth: {sm: "full", md: 200}}}/>}
          />
          <div className={'flex-1'}/>
          <LoadingButton
            size={'large'}
            startIcon={<SaveOutlined/>}
            sx={{alignSelf: 'flex-end'}}
            variant={'outlined'}
            loading={isLoadingForAllTheBookReports}
            onClick={_downloadBookReportsInExcel}
            disabled={isLoadingForAllTheBookReports}
          >
            엑셀
          </LoadingButton>
        </div>
      </div>
      <div className={'flex flex-col sm:flex-row space-x-0 sm:space-x-2 space-y-2 sm:space-y-0'}>
        <TextField
          fullWidth
          select
          label="채점 여부"
          value={isScored}
          onChange={_onChangeIsScored}
          sx={{
            maxWidth: {xs: "full", sm: 150},
          }}
        >
          <MenuItem value={''}>
            전체
          </MenuItem>
          {[{value: 'true', text: '채점 완료'}, {value: 'false', text: '채점 전'}].map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>
        <SearchTextField onSubmit={_handleSearch} onClickClear={() => navigate(location.pathname)} keyword={keyword}/>
      </div>
      <Pagination
        sx={{my: 3}}
        page={page}
        count={parseInt(meta.total / perPage) + (meta.total % perPage === 0 ? 0 : 1)}
        renderItem={(item) => {
          searchParams.set('page', item.page);
          let path = `${location.pathname}?${searchParams.toString()}`;
          return (
            <PaginationItem
              component={Link}
              to={path}
              {...item}
            />)
        }}
      />
      <Scrollbar>
        <TableContainer sx={{minWidth: "max-content"}}>
          <Table>
            <TableHead>
              <TableRow>
                {
                  [
                    {name: "No.", className: ''},
                    {name: "이름", className: 'min-w-[120px]'},
                    {name: "학년", className: "min-w-[55px]"},
                    {name: "반", className: "min-w-[55px]"},
                    {name: "번호", className: "min-w-[55px]"},
                    {name: "제목", className: "min-w-[200px] md:w-fit"},
                    {name: "제출일"},
                    {name: "책 / 수업"},
                    {name: "담당선생님"},
                    {name: "점수"},
                  ].map((head) => (
                    <TableCell key={head.name} className={head.className}>
                      {head.name}
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {bookReports.map((bookReport,index) => {
                return (
                  <TableRow key={bookReport.id} className={'hover:bg-hover'}>
                    <TableCell>
                      {(page-1) *perPage + 1 + index}
                    </TableCell>
                    <TableCell>
                      <Link to={`${PATH_TEACHER.student.detail.root(bookReport.userId)}?year=${dayjs(bookReport.submittedAt).format('YYYY')}`}>
                        <div className={'flex items-center min-w-[40px] clickable-primary'}>
                          <img src={bookReport.user?.profileImageUrl} className={'w-[30px] aspect-1 object-cover rounded-full'}/>
                          <p className={'pl-2'}>
                            {bookReport.user?.name}
                          </p>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.class?.grade}
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.class?.classNo}
                    </TableCell>
                    <TableCell>
                      {bookReport.user?.classRegister?.studentNo}
                    </TableCell>
                    <TableCell>
                      <Link to={PATH_TEACHER.bookReport.detail.root(bookReport.id)}>
                        <p className="clickable-primary text-hidden">
                          {bookReport.title}
                        </p>
                      </Link>
                      {/*<Link to={PATH_TEACHER.bookReport.detail.old(bookReport.id)}>
                        <p className="clickable-primary text-hidden">
                          구
                        </p>
                      </Link>*/}
                    </TableCell>
                    <TableCell>
                      {dayjs(bookReport.submittedAt).format(dayjs().isSame(bookReport.submittedAt, "year") ? "M.D(dd) HH:mm" : "YYYY.M.D(dd) HH:mm")}
                    </TableCell>
                    <TableCell className={'w-[250px]'}>
                      <div className={'line-clamp-1'}>
                        {bookReport.book?.title || '-'}
                      </div>
                      <div className={'line-clamp-1'}>
                        {
                          bookReport.task?.taskType === 'PRIVATE' ? '개별독후감' : (bookReport.task?.course?.title || '-')
                        }

                      </div>
                    </TableCell>
                    <TableCell>
                      {bookReport?.teacherUser?.name}
                    </TableCell>
                    <TableCell>
                      {
                        bookReport.scoreType ==='SCORE' ? (bookReport.score === null ? <span className={'text-red-700'}>평가 전</span> : bookReport.score) : '평가 없음'
                      }
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

    </Container>
  );
};

const enhance = connect((state) => ({...state}), {});
export default enhance(TeacherBookReportMain);
