export const questionTypes = [
  { type: "TEXT", name: "글", description: "최대 1000자까지 가능합니다." },
  // { type: "TEXT", name: "글", description: "최대 ???자까지 가능합니다." },
  { type: "IMAGE", name: "사진" },
  { type: "VIDEO", name: "영상" },
  { type: "FILE", name: "파일" },
];

export const questionTypesObj = {
  TEXT: { type: "TEXT", name: "글" },
  IMAGE: { type: "IMAGE", name: "사진" },
  FILE: { type: "FILE", name: "파일" },
  VIDEO: { type: "VIDEO", name: "영상" },
};
