import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import MessageBox from '../../components/shared/MessageBox';
import {Container} from '@mui/material';

const AdminCourseCreatePage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  },[])

  return (
    <Container maxWidth={'lg'}>
      <MessageBox
        title={'안내'}
        content={'수업 생성은 각 과목 담당 선생님이 직접 개설합니다.\n 추후 관리자가 일괄 개설 기능이 추가될 예정입니다.'}
      />
    </Container>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(AdminCourseCreatePage);
