import { createLogicMiddleware } from "redux-logic";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";

import rootReducer from "./rootReducer";
import logic from "./logic";


const deps = {
  // Network: Network,
  A_SECRET_KEY: 'hello',
};

const logicMiddleware = createLogicMiddleware(logic, deps);
const middleware = applyMiddleware(logicMiddleware);
const enhancer = composeWithDevTools(middleware); // could compose in dev tools too

const store = createStore(
  rootReducer,
  enhancer,
);

export default store;
