// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const PAGE_TITLE = {
  "/": "메인",
  "/book-report": "독후감 목록",
  "/book-report/:bookReportId": "독후감 상세",
  "/class": "우리반",
  "/class/:classId": "우리반 상세",
  "/class/:classId/student/:studentId": "우리반 학생 상세",

  "/course": "수업",
  "/course/:courseId": "수업 상세",
  "/course/:courseId/task": "수업 - 과제",
  "/course/:courseId/task/:taskId": "수업 - 과제 상세 (독후감)",
  "/course/:courseId/task/:taskId/book-report": "수업 - 과제 상세 (독후감)",
  "/course/:courseId/task/:taskId/student": "수업 - 과제 상세 (참여학생)",
  "/course/:courseId/task/:taskId/information": "수업 - 과제 상세 (정보)",

  "/course/create": "수업만들기",
  "/course/:courseId/task/create": "수업 - 과제만들기",

  "/my-account": "내계정",
  "/notification": "알림",
  "/help": "도와줘요!",
};
