import dayjs from 'dayjs';

export function getFromNowOrDate (date, hours= 24 , format = 'YYYY.MM.DD(ddd) HH:mm'){
  if(dayjs().diff(date,'hours') < hours){
    return dayjs(date).fromNow()
  } else {
    return dayjs(date).format(format)
  }
}


export function convertSecondsToHHmmss(value) {
  const sec_num = parseInt(value, 10); // don't forget the second param
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = sec_num - (hours * 3600) - (minutes * 60);

  let hourStr = hours;
  let minStr = minutes;
  let secondStr = seconds;
  if (hourStr < 10) {hourStr = "0" + hourStr;}
  if (minStr < 10) {minStr = "0" + minStr;}
  if (secondStr < 10) {secondStr = "0" + secondStr;}
  const string = hourStr + ":" + minStr + ":" + secondStr;
  return {
    string,
    hours,
    minutes,
    seconds,
  }
}
