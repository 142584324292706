import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Table, TableContainer, TableHead, TableCell, TableBody, TableRow} from "@mui/material";
import {courseTypesObj} from "../../constants";
import dayjs from "dayjs";
import {PATH_TEACHER} from "../../routes/paths";
import Network from "../../lib/Network";

const CourseDetailInfoTab = (props) => {

  const {courseId} = useParams();
  const navigate = useNavigate();
  const {course} = props;
  const [uploading, setUploading] = useState(false);

  const _handleDelete = async () => {
    const a = window.confirm('수업을 삭제하시겠습니까?\n등록된 학생이나 과제가 있는 경우 삭제되지 않을 수 있습니다.');
    if (!a) return;
    setUploading(true);
    try {
      const res = await Network.courseDELETE({courseId});
      navigate(PATH_TEACHER.course.root, {replace: true})
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }
    setUploading(false);

  }

  if (!course || !course.id) return <div/>

  return (
    <div className="space-y-2 mt-4">
      <div className={'flex space-x-2'}>
        <Link to={PATH_TEACHER.course.detail.edit(courseId)}>
          <Button variant={'outlined'}>
            수정하기
          </Button>
        </Link>
        <Button
          onClick={_handleDelete}
          variant={'outlined'} color={'error'}>
          삭제하기
        </Button>
      </div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={'font-bold w-[130px]'}>
              타입
            </TableCell>
            <TableCell>
              {courseTypesObj[course.courseType]?.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'font-bold'}>
              교과목
            </TableCell>
            <TableCell>
              {course?.subject?.title || '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'font-bold'}>
              학기
            </TableCell>
            <TableCell>
              {course.semester?.year} - {course.semester?.text}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'font-bold'}>
              시작일
            </TableCell>
            <TableCell>
              {dayjs(course.startDate).format('YYYY.MM.DD(ddd)')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'font-bold'}>
              종료일
            </TableCell>
            <TableCell>
              {dayjs(course.endDate).format('YYYY.MM.DD(ddd)')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'font-bold'}>
              설명
            </TableCell>
            <TableCell className={'whitespace-pre-line'}>
              {course.description}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(CourseDetailInfoTab);
