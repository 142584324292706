import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';

const StudentNoticeDetailPage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <div>
      StudentNoticeDetailPage
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(StudentNoticeDetailPage);
