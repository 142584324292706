import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { authenticationActionCreators } from "../../../redux/auth/auth.store";
import { notificationActionCreators } from "../../../redux/notification/notification.store";
import { notificationStudentActionCreators } from "../../../redux/notification-student/notification-student.store";
// @mui
import { Box, List, Badge, Button, Divider, Typography, IconButton } from "@mui/material";
// components
import Scrollbar from "../../../components/NOT_Classified/Scrollbar";
import MenuPopover from "../../../components/shared/MenuPopover";
import NotificationItem from "./NotificationItem";
import { message } from "../../../constants";
import { NotificationsRounded } from '@mui/icons-material';

// ----------------------------------------------------------------------

const NotificationsPopover = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const navigateToNotificationPage = () => navigate(`/notification`);
  const notifications = props.authReducer.user?.userType === 'STUDENT' ? props.notificationStudentReducer?.notifications : props.notificationReducer?.notifications;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    if (props.auth.user.userType === "STUDENT") {
      props.fetchNotificationStudent();
    } else {
      props.fetchNotificationTeacher();
    }
  };
  const handleClose = () => setOpen(null);

  return (
    <>
      <IconButton
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge
          badgeContent={props.notificationReducer.unreadCount}
          color='error'
        >
          <NotificationsRounded />
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 3, pb: 2, px: 2.5 }}>
          <Typography variant='subtitle1'>알림</Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Scrollbar
          sx={{ height: { xs: 340, sm: 'fit-content' }, maxHeight: { xs: 340, sm: 500 } }}
          className='overflow-overlay'
        >
          <List disablePadding>
            {notifications.length > 0 ? (
              notifications.slice(0, 15).map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClose={handleClose}
                />
              ))
            ) : (
              <div className='blank m-5'>{message.blank.emptyNotifications}</div>
            )}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => {
              navigateToNotificationPage();
              handleClose();
            }}
          >
            전체 보기
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {
    ...authenticationActionCreators,
    ...notificationActionCreators,
    ...notificationStudentActionCreators,
  });
export default enhance(NotificationsPopover);
