import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Network from "../../lib/Network";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Avatar, Box, Button, Checkbox, Container, TableCell, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, InputBase, Link as LinkMui, Pagination, PaginationItem, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Typography,} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Icon from "@mui/icons-material";
import LoadingComponent from "../../components/TO_BE_deleted/LoadingComponent";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";
import {PATH_ADMIN} from "../../routes/paths";

const AdminClassDetailPage = (props) => {
  const LIMIT = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const tab = query.get("tab") || "STUDENT";
  const {classId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [cls, setCls] = useState({});
  const [meta, setMeta] = useState({total: 0});
  const [users, setUsers] = useState([]);


  const [teacherSearchDialogOpen, setTeacherSearchDialogOpen] = useState(false);
  const [teacherUsers, setTeacherUsers] = useState([]);

  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);

  const [studentSearchDialogOpen, setStudentSearchDialogOpen] = useState(false);
  const [studentUsers, setStudentUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);


  const _load = async () => {
    const res = await Network.classGET({classId});
    if (!res.err) {
      setCls(res.class);
      setIsLoaded(true);
    }
  };
  const _loadUsers = async () => {
    if (!classId) return;
    if (tab !== "STUDENT") return;
    try {
      const res = await Network.classUsersGET({
        classId,
        offset: (page - 1) * LIMIT,
        limit: LIMIT,
      });
      setUsers(res.users);
      setMeta(res.meta);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    }

  };
  const _handleDeleteClassRegister = async (studentUserId) => {
    const a = window.confirm("반에서 삭제할까요?");
    if (!a) return;
    setUploading(true);
    try {
      const res = await Network.classRegisterDELETE({classId, userId: studentUserId});

    } catch (e) {
      window.alert(e.cause.errorMessage);

    } finally {
      await _loadUsers();

    }

    setUploading(false);
  };

  const _handleSearchTeacher = async (e) => {
    e.preventDefault();
    if (searching) return;
    setSearching(true);
    const res = await Network.usersGET({
      offset: 0,
      limit: LIMIT,
      keyword,
      userType: "TEACHER",
    });
    if (!res.err) {
      setTeacherUsers(res.users);
      setIsLoaded(true);
    }
    setSearching(false);
  };

  const _handleOpenTeacherSearchDialog = () => {
    setTeacherSearchDialogOpen(true);
  }
  const _handleCloseTeacherSearchDialog = () => {
    setTeacherSearchDialogOpen(false);
    setKeyword('');
    setTeacherUsers([]);
  }
  const _handleChangeTeacherUser = async (teacherUser) => {
    if (!teacherUser) return;
    setUploading(true);
    try {
      await Network.classPUT({classId, teacherUserId: teacherUser.id})
    } catch (e) {

    }
    setCls(prev => ({...prev, teacherUserId: teacherUser?.id, teacherUser: teacherUser}))
    setUploading(false);
    _handleCloseTeacherSearchDialog()
  }
  const _handleDeleteTeacher = async () => {
    const a = window.confirm('선생님을 삭제하시겠습니까?');
    if (!a) return;
    await Network.classPUT({classId, teacherUserId: null})
    setCls(prev => ({...prev, teacherUserId: null, teacherUser: undefined}))
  }

  const _handleSearchStudentUser = async (e) => {
    e.preventDefault();
    if (searching) return;
    setSearching(true);
    const res = await Network.usersGET({
      offset: 0,
      limit: LIMIT,
      keyword,
      userType: "STUDENT",
    });
    if (!res.err) {
      setStudentUsers(res.users);
      setIsLoaded(true);
    }
    setSearching(false);
  };
  const _handleOpenStudentSearchDialog = () => {
    setStudentSearchDialogOpen(true);

  }
  const _handleCloseStudentSearchDialog = () => {
    setStudentSearchDialogOpen(false);
    setSelectedUsers([])
    setStudentUsers([]);
    setKeyword('');
  }
  const _handleAddClassRegister = async () => {
    const userIds = selectedUsers.map(o => o.id);
    if (userIds.length < 1) return window.alert('선택된 학생이 없습니다');
    try {
      setUploading(true);
      await Network.classRegisterPOST({classId, userIds: userIds})
      await _loadUsers();
      setSelectedUsers([])
      _handleCloseStudentSearchDialog()
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }
  }

  useEffect(() => {
    _load();
  }, []);
  useEffect(() => {
    _loadUsers();
  }, [tab, page]);
  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  };

  if (!isLoaded) return <LoadingComponent/>;

  const _renderTeacher = () => {
    if (!cls?.teacherUserId) {
      return (
        <div className={'border border-warning-500 rounded-[16px] p-4 bg-warning-50 bg-opacity-50 mt-4'}>
          <h5 className={'text-warning-500'}>
            담임선생님이 설정되어 있지 않습니다.
          </h5>
          <p>
            선생님을 등록해 주세요.
          </p>
          <Button
            sx={{mt: 2}}
            variant={'outlined'}
            onClick={_handleOpenTeacherSearchDialog}>
            선생님 검색
          </Button>
        </div>
      )
    } else {
      return null;
    }
  }
  const _renderTeacherUserSearchDialog = () => {
    return (
      <Dialog fullWidth open={teacherSearchDialogOpen}>
        <DialogTitle>
          선생님 선택
        </DialogTitle>
        <Divider sx={{mt: 2}}/>
        <DialogContent>
          <form className={"bg-gray-100 rounded-[16px] flex pr-4 py-[4px] items-center"}
                onSubmit={_handleSearchTeacher}>
            <InputBase
              value={keyword}
              name={"keyword"}
              sx={{ml: 1, flex: 1}}
              placeholder="이름으로 검색해 주세요."
              inputProps={{}}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            {!!keyword && (
              <IconButton
                onClick={() => {
                  setKeyword("");
                }}
                sx={{p: "2px"}}
                aria-label="search"
              >
                <Icon.CloseRounded/>
              </IconButton>
            )}
            <Button type="submit" variant={"outlined"} sx={{p: "2px"}}>
              검색
            </Button>
          </form>
        </DialogContent>
        <DialogContent sx={{height: "50vh"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>이름</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacherUsers.map((user, index) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Avatar src={user.profileImageUrl}/>
                    </TableCell>
                    <TableCell>
                      {user.name} ({user.displayName})
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => _handleChangeTeacherUser(user)}>선택</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <LoadingButton
            onClick={_handleCloseTeacherSearchDialog}
          >
            닫기
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
  const _renderStudentUserDialog = () => {
    return (
      <Dialog
        maxWidth={'lg'}
        fullWidth open={studentSearchDialogOpen}>
        <DialogTitle>
          학생검색
        </DialogTitle>
        <Divider sx={{mt: 2}}/>
        <DialogContent className={'pb-0'}>
          <form className={"bg-gray-100 rounded-[16px] flex pr-4 py-[4px] items-center"}
                onSubmit={_handleSearchStudentUser}>
            <InputBase
              value={keyword}
              name={"keyword"}
              sx={{ml: 1, flex: 1}}
              placeholder="이름으로 검색해 주세요."
              inputProps={{}}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            {!!keyword && (
              <IconButton
                onClick={() => {
                  setKeyword("");
                }}
                sx={{p: "2px"}}
                aria-label="search"
              >
                <Icon.CloseRounded/>
              </IconButton>
            )}
            <Button type="submit" variant={"outlined"} sx={{p: "2px"}}>
              검색
            </Button>
          </form>
        </DialogContent>
        <DialogContent className={'flex h-[50vh]'}>
          <div className={'flex-1 max-h-[50vh] overflow-y-scroll'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentUsers.map((user, index) => {
                  const selectedIndex = selectedUsers.findIndex(o => o.id === user.id);
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e, checked) => {
                                if (selectedIndex > -1) {
                                  selectedUsers.splice(selectedIndex, 1);
                                  setSelectedUsers(prev => ([...selectedUsers]))
                                } else {
                                  setSelectedUsers(prev => ([...prev, user]))
                                }
                              }}
                              checked={selectedIndex > -1}/>
                          }
                          label={''}
                        />
                      </TableCell>

                      <TableCell>
                        <Avatar src={user.profileImageUrl}/>
                      </TableCell>
                      <TableCell>
                        {user.name} ({user.displayName})
                      </TableCell>
                      <TableCell>
                        {user.email}
                      </TableCell>
                      <TableCell>
                        {user.phoneNumber}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <div className={'w-12'}></div>
          <div className={'flex-1 max-h-[50vh]'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>이름</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedUsers.map((user, index) => {
                  const selectedIndex = selectedUsers.findIndex(o => o.id === user.id);
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e, checked) => {
                                if (selectedIndex > -1) {
                                  selectedUsers.splice(selectedIndex, 1);
                                  setSelectedUsers(prev => ([...selectedUsers]))
                                } else {
                                  setSelectedUsers(prev => ([...prev, user]))
                                }
                              }}
                              checked={selectedIndex > -1}/>
                          }
                          label={''}
                        />
                      </TableCell>

                      <TableCell>
                        <Avatar src={user.profileImageUrl}/>
                      </TableCell>
                      <TableCell>
                        {user.name} ({user.displayName})
                      </TableCell>
                      <TableCell>
                        {user.email}
                      </TableCell>
                      <TableCell>
                        {user.phoneNumber}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>

        </DialogContent>
        <Divider/>
        <DialogActions>
          <LoadingButton onClick={_handleCloseStudentSearchDialog}>
            닫기
          </LoadingButton>
          <LoadingButton onClick={_handleAddClassRegister}>
            추가하기
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div>
      <Container maxWidth={"lg"}>
        <Typography gutterBottom variant={"h5"}>
          학급 관리
        </Typography>
        <ul className={'flex'}>
          <li className={'py-1'}>
            {cls.year}학년도
          </li>
          <li className={'p-1'}>
            {cls.grade}학년
          </li>
          <li className={'p-1'}>
            {cls.classNo}반
          </li>
          {
            cls.teacherUser?.id ? (
              <li className={'p-1'}>
                담임선생님:
                <Link
                  className={'font-bold text-primary-500 pl-2'}
                  to={PATH_ADMIN.teacher.detail(cls.teacherUserId)}>
                  {cls?.teacherUser?.name}
                </Link>
                <button
                  onClick={_handleDeleteTeacher}
                  className={'text-error-500 ml-3'}>
                  [선생님 삭제]
                </button>
              </li>

            ) : (
              <li className={'p-1'}>
                담임선생님: 등록된 담임 선생님이 없습니다.
              </li>
            )
          }
        </ul>
        {_renderTeacher()}
      </Container>
      <Container maxWidth={"lg"} sx={{mt: 5}}>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider", mb: 3, mt: 2}}>
            <TabList onChange={(e, newValue) => _handleChangeTab(newValue)}>
              <Tab label="학생" value="STUDENT"/>
              {/*<Tab label="담임선생님" value="TEACHER"/>*/}
              {/*<Tab label="반 정보" value="INFO"/>*/}
            </TabList>
          </Box>
          <TabPanel value={"STUDENT"}>
            <div className={'flex justify-between'}>
              <p className={'text-gray-600'}>
                <span className={'text-bold'}>
                {meta.total}명
                </span>
                의 학생이 있습니다.
              </p>
              <Button
                onClick={_handleOpenStudentSearchDialog}
                disabled={uploading}
                variant={"contained"}
                sx={{mb: 3}}>
                학생 추가
              </Button>
            </div>
            <Pagination
              sx={{mb: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + (meta.total % LIMIT === 0 ? 0 : 1)}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}${item.page === 1 ? "" : `?page=${item.page}`}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: 800, position: "relative"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>이름</TableCell>
                      <TableCell>닉네임</TableCell>
                      <TableCell>이메일</TableCell>
                      <TableCell>핸드폰</TableCell>
                      <TableCell>연도</TableCell>
                      <TableCell>학년</TableCell>
                      <TableCell>반</TableCell>
                      <TableCell>번호</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((user, index) => {
                      return (
                        <TableRow key={user.id}>
                          <TableCell>{index + 1 + (page - 1) * LIMIT}</TableCell>
                          <TableCell>
                            <LinkMui className={'text-[#1370F5]'} as={Link} to={PATH_ADMIN.student.detail(user.id)}>
                              <Box sx={{display: "flex", alignItems: "center"}}>
                                <Avatar sx={{mr: 1, width: "2rem", height: "2rem"}} src={user.profileImageUrl}/>
                                {user.name}
                              </Box>
                            </LinkMui>
                          </TableCell>
                          <TableCell>{user.displayName}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.phoneNumber}</TableCell>
                          <TableCell>{user.classRegister?.class?.year}</TableCell>
                          <TableCell>{user.classRegister?.class?.grade}</TableCell>
                          <TableCell>{user.classRegister?.class?.classNo}</TableCell>
                          <TableCell>{user.classRegister?.studentNo}</TableCell>
                          <TableCell>
                            <LinkMui
                              className={'text-red-500'}
                              component={'button'}
                              onClick={() => _handleDeleteClassRegister(user.id)}>
                              반에서 삭제
                            </LinkMui>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

          </TabPanel>
        </TabContext>
      </Container>
      {_renderTeacherUserSearchDialog()}
      {_renderStudentUserDialog()}
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminClassDetailPage);
