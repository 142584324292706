import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import NetworkStudent from "../../lib/NetworkStudent";
import { Container, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography } from "@mui/material";
import LoadingComponent from "../../components/shared/LoadingComponent";
import { notificationStudentActionCreators } from "../../redux/notification-student/notification-student.store";
import StudentNotificationComponent from "../../components/student/StudentNotificationComponent";
import LoadingButton from '@mui/lab/LoadingButton';

const StudentNotificationPage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);

  const _load = () => {
    props.fetchNotificationStudent(() => {
      setIsLoaded(true);
      NetworkStudent.notificationReadPUT({});
    });
  };
  const _loadMore = () => {
    props.fetchNotificationStudentMore();
  };

  useEffect(() => {
    _load();
  }, []);

  if (!isLoaded) return <LoadingComponent />;

  const { notifications, endReached, loadingMore } = props.notificationStudentReducer;

  return (
    <Container maxWidth={"xs"}>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {notifications?.map((item, index) => {
          return <StudentNotificationComponent key={index} notification={item} />;
        })}
        {!endReached && (
          <LoadingButton onClick={_loadMore} disabled={loadingMore} loading={loadingMore} variant={"contained"} color={"inherit"} size={"large"} sx={{ mt: 2, mb: 2 }}>
            더 불러오기
          </LoadingButton>
        )}
      </List>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {
    ...notificationStudentActionCreators,
  },
);

export default enhance(StudentNotificationPage);
