import PropTypes from "prop-types";
import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import Header from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import useCollapseDrawer from "../hooks/useCollapseDrawer";
import useResponsive from "../assi-design-system-react/mui/hooks/useResponsive";

// config
import { HEADER, NAVBAR } from "../config/mui.config";

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({

  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    /*transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),*/
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },


  // flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  // paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  // // marginLeft: "auto",
  // // marginRight: "auto",
  // // width: "100%",
  // // maxWidth: "90%",
  // [theme.breakpoints.up("lg")]: {
  //   // paddingLeft: 16,
  //   // paddingRight: 16,
  //   paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  //   paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  //   // maxWidth: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  //   transition: theme.transitions.create("margin-left", {
  //     duration: theme.transitions.duration.shorter,
  //   }),
  //   ...(collapseClick && {
  //     marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
  //     // maxWidth: `calc(95% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
  //   }),
  // },
}));

const Layout = (props) => {
  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const [open, setOpen] = React.useState(false);

  const isDesktop = useResponsive("up", "lg");

  const handleDrawer = () => {
    setOpen(!open);
    if (isDesktop) {
      onToggleCollapse();
    }
  };
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <Box sx={{ display: { lg: "flex" }, minHeight: { lg: 1 } }}>
      <Header isCollapse={isCollapse} isOpenSidebar={open} onToggleSidebar={handleDrawer} onOpenSidebar={handleDrawerOpen} accountPopoverConfig={props.accountPopoverConfig} />
      <NavbarVertical navConfig={props.navConfig} isOpenSidebar={open} onCloseSidebar={handleDrawerClose} />
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default Layout;

Layout.propTypes = {
  accountPopoverConfig: PropTypes.array,
  navConfig: PropTypes.array,
  notification: PropTypes.object,
};
