// navConfig.js
// components
import * as Icons from "@mui/icons-material";
import { PATH_TEACHER } from "../routes/paths";

// ----------------------------------------------------------------------

const getIcon = (name) => {
  const {...icons} = Icons;
  const TheIcon = icons[name];
  return <TheIcon sx={{width: 1, height: 1}}/>;
};

export const NAV_CONFIG_ADMIN = [
  {
    subheader: "관리",
    items: [
      {title: "선생님", path: "/admin/teacher", icon: getIcon("AccountBoxOutlined")},
      {title: "학생", path: "/admin/student", icon: getIcon("PersonOutlined")},
      {title: "학급", path: "/admin/class", icon: getIcon("LooksOneOutlined")},
      {title: "과목", path: "/admin/subject", icon: getIcon("FeedOutlined")},
      {title: "수업/활동", path: "/admin/course", icon: getIcon("SchoolOutlined")},
      {title: "과제", path: "/admin/task", icon: getIcon("FormatListBulleted")},
      {title: "독후감", path: "/admin/book-report", icon: getIcon("HistoryEduRounded")},
      {title: "학교 설정", path: "/admin/school-manage", icon: getIcon("SchoolOutlined")},
    ],
  },
  {
    subheader: "",
    items: [{title: "도움말", path: "https://my-assi.notion.site/a04c0bad9110469cb22c18aaac71d8b6", icon: getIcon("HelpOutlineOutlined")}],
  },
];

export const NAV_CONFIG_TEACHER = [
  {
    subheader: "관리",
    items: [
      {title: "수업/활동", path: "/course", icon: getIcon("SchoolOutlined")},
      {title: "우리반", path: "/class", icon: getIcon("PersonOutlined")},
      {title: "독후감", path: "/book-report", icon: getIcon("HistoryEduRounded")},
      {title: "알림", path: PATH_TEACHER.notification.root, icon: getIcon("NotificationsOutlined")},
    ],
  },
  {
    subheader: "계정",
    items: [{title: "내계정", path: "/my-account", icon: getIcon("SettingsOutlined")}],
  },
  {
    subheader: "",
    items: [{title: "도움말", path: "https://my-assi.notion.site/a04c0bad9110469cb22c18aaac71d8b6", icon: getIcon("HelpOutlineOutlined")}],
  },
];

export const NAV_CONFIG_STUDENT = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "내 활동",
    items: [
      {title: "나의 수업/활동", path: "/course", icon: getIcon("SchoolOutlined")},
      {title: "독후감", path: "/book-report", icon: getIcon("HistoryEduRounded")},
      {title: "과제", path: "/task", icon: getIcon("FormatListBulleted")},
      //
      {title: "책장", path: "/book", icon: getIcon("CollectionsBookmarkOutlined")},
    ],
  },
  {
    subheader: "탐색",
    items: [
      {title: "검색", path: "/search", icon: getIcon("SearchRounded")},
      {title: "비교과 활동 검색", path: "/course-irregular", icon: getIcon("GroupsOutlined")},
    ],
  },
  {
    subheader: "내 계정",
    items: [
      {title: "반", path: "/class", icon: getIcon("LooksOneOutlined")},
      {title: "알림", path: "/notification", icon: getIcon("NotificationsOutlined")},
      {title: "설정", path: "/setting", icon: getIcon("SettingsOutlined")},
      {title: "문의", path: "/inquiry", icon: getIcon("HelpCenterOutlined")},
      // {
      //   title: "기타",
      //   path: "/etc",
      //   icon: getIcon("WidgetsRounded"),
      //   children: [
      //     { title: "문의", path: "/etc/inquiry" },
      //     { title: "공지", path: "/etc/notice" },
      //   ],
      // },
    ],
  },
  {
    subheader: "",
    items: [{title: "도움말", path: "https://my-assi.notion.site/2e62545c5f0743348cbba1170a9e316c", icon: getIcon("HelpOutlineOutlined")}],
  },
];
