import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Container,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Divider,
} from "@mui/material";
import * as XLSX from "xlsx-js-style";
import dayjs from "dayjs";
import _ from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import Network from "../../lib/Network";
import { downloadTeacherCreateExcelTemplate } from "../../utils/downloadExcelTemplate";
import { generateRandomString } from "../../utils/string";

const AdminTeacherCreatePage = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newTeacherUserCandidatesFromExcel, setNewTeacherUserCandidatesFromExcel] = useState([]);
  const [newTeacherUser, setNewTeacherUser] = useState({ name: "", email: "", phoneNumber: "", displayName: "" });
  const [newTeacherUsers, setNewTeacherUsers] = useState([]);
  const [excelUploadDialogOpen, setExcelUploadDialogOpen] = useState(false);

  const [checking, setChecking] = useState(false);
  const [checkValid, setCheckValid] = useState(false);
  const [existingTeacherUsers, setExistingTeacherUsers] = useState([]);

  const fileInputRef = React.useRef();

  const _load = async () => {};
  const _checkExistingUsers = async () => {
    if (newTeacherUsers.length < 1) return;
    const emails = [...new Set(newTeacherUsers.map((o) => o.email).filter(Boolean))];
    const displayNames = [...new Set(newTeacherUsers.map((o) => o.displayName).filter(Boolean))];
    const phoneNumbers = [...new Set(newTeacherUsers.map((o) => o.phoneNumber).filter(Boolean))];
    setChecking(true);
    try {
      const res = await Network.usersCheckDuplicatedPOST({
        emails,
        displayNames,
        phoneNumbers,
      });
      setExistingTeacherUsers(res.users);
      if (res.meta?.total < 1) {
        setCheckValid(true);
        setUploading(false);
        window.alert("중복된 정보가 없습니다. 업로드할 수 있습니다");
      } else {
        setCheckValid(false);
        window.alert("이미 등록된 선생님과 중복된 정보가 있습니다");
      }
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setChecking(false);
    }
  };
  const _handleUpload = async () => {
    const a = window.confirm("생성하시겠습니까?");
    if (!a) return;
    setUploading(true);
    try {
      const users = newTeacherUsers.map((o) => ({ ...o, userType: "TEACHER" }));
      const res = await Network.usersPOST({ users });
      setNewTeacherUsers([]);
      setNewTeacherUser({ name: "", email: "", phoneNumber: "", displayName: "" });
      setNewTeacherUserCandidatesFromExcel([]);
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setUploading(false);
    }
  };

  const _addOneUser = () => {
    if (!newTeacherUser.name) return alert("이름을 정확하게 입력해 주세요");
    if (!newTeacherUser.email && !newTeacherUser.phoneNumber) return alert("이메일 또는 핸드폰이 입력되어야 합니다.");
    // if (!newTeacherUser.phoneNumber) return alert("핸드폰을 정확하게 입력해주세요.");
    if (!newTeacherUser.displayName) return alert("닉네임을 정확하게 입력해주세요.");

    const duplicatedIndex = newTeacherUsers.findIndex((o) => {
      const emailDuplicated = o.email?.trim() === newTeacherUser.email?.trim();
      const displayNameDuplicated = o.displayName?.trim() === newTeacherUser.displayName?.trim();
      const phoneDuplicated = o.phone?.replace?.(/-/gi, "").trim() === newTeacherUser.phone?.replace?.(/-/gi, "")?.trim();
      return emailDuplicated || displayNameDuplicated || phoneDuplicated;
    });
    if (duplicatedIndex > -1) return window.alert("중복된 데이터 입니다.");

    setNewTeacherUsers((prev) => [...prev, { ...newTeacherUser, phoneNumber: newTeacherUser.phoneNumber?.replace?.(/-/gi, "") }]);
    setNewTeacherUser({ name: "", email: "", phoneNumber: "", displayName: "" });
  };
  const _addManyUser = () => {
    setNewTeacherUsers((prev) => [...prev, ...newTeacherUserCandidatesFromExcel]);
    _handleCloseExcelUploadDialog();
  };
  useEffect(() => {
    setCheckValid(false);
  }, [newTeacherUsers]);

  const _readExcel = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = function (e) {
      /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
      // 문서 읽었을 때, 다른 브라우저에서 하면, 뭔가 에러날 수도 있을 것 같다는 느낌을 받음....
      const workbook = XLSX.read(e.target.result, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const dataArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
      dataArray.splice(0, 1);
      if (dataArray.length < 1) return window.alert("데이터가 없습니다");
      if (dataArray.length > 500) return window.alert("한번에 500명까지 등록가능합니다.");
      let dataObj = dataArray.map((o) => ({
        name: o[0]?.replace?.(/ /gi, ""),
        displayName: o[0]?.replace?.(/ /gi, "") + generateRandomString("STRING", 5),
        email: o[1],
        phoneNumber: o[2]?.replace?.(/-/gi, "").trim(),
        userType: "TEACHER",
      }));
      // dataObj = _.uniqBy(dataObj, (o) =>o.email);
      // dataObj = _.uniqBy(dataObj, (o) => o.phoneNumber);

      // if (dataArray.length !== dataObj.length) {
      //   return window.alert("전화번호 또는 이메일이 중복된 데이터가 있습니다.");
      // }

      const numberOfInvalidDisplayName = _.filter(dataObj, (o) => !/^[a-zA-Z가-힣0-9]*$/.test(o.displayName))?.length || 0;
      if (numberOfInvalidDisplayName > 0) {
        return window.alert("닉네임이 잘못되어 있습니다.");
      }

      setNewTeacherUserCandidatesFromExcel(dataObj);
    };
    reader.readAsArrayBuffer(file);
    e.target.value = "";
  };
  const _downloadTemplateExcel = () => {
    downloadTeacherCreateExcelTemplate();
  };

  const _handleOpenExcelUploadDialog = () => {
    setExcelUploadDialogOpen(true);
  };
  const _handleCloseExcelUploadDialog = () => {
    setExcelUploadDialogOpen(false);
    setNewTeacherUserCandidatesFromExcel([]);
  };

  useEffect(() => {
    _load();
  }, []);

  return (
    <div>
      <Container maxWidth={"lg"}>
        <Typography gutterBottom variant={"h5"}>
          선생님 등록
        </Typography>
        <Button variant={"outlined"} onClick={_handleOpenExcelUploadDialog}>
          엑셀로 등록하기
        </Button>
      </Container>
      <Container maxWidth={"lg"} sx={{ mt: 3, mb: 3 }}>
        <Typography gutterBottom variant={"h6"}>
          한명씩 추가
        </Typography>
        <Typography>수동으로 한명씩 입력합니다.</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>닉네임</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>핸드폰</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <InputBase
                  value={newTeacherUser.name}
                  onChange={(e) => setNewTeacherUser((prev) => ({ ...prev, name: e.target.value.trim() }))}
                  name={"name"}
                  placeholder="이름..."
                  sx={{ width: "100%" }}
                  inputProps={{}}
                />
              </TableCell>
              <TableCell>
                <InputBase
                  value={newTeacherUser.displayName}
                  onChange={(e) => setNewTeacherUser((prev) => ({ ...prev, displayName: e.target.value.trim() }))}
                  name={"displayName"}
                  placeholder="닉네임"
                  sx={{ width: "100%" }}
                  inputProps={{}}
                />
              </TableCell>
              <TableCell>
                <InputBase
                  value={newTeacherUser.email}
                  onChange={(e) => setNewTeacherUser((prev) => ({ ...prev, email: e.target.value.trim() }))}
                  name={"email"}
                  placeholder="email"
                  sx={{ width: "100%" }}
                  inputProps={{}}
                />
              </TableCell>
              <TableCell>
                <InputBase
                  value={newTeacherUser.phoneNumber}
                  onChange={(e) => setNewTeacherUser((prev) => ({ ...prev, phoneNumber: e.target.value.trim() }))}
                  name={"phoneNumber"}
                  placeholder="핸드폰"
                  sx={{ width: "100%" }}
                  inputProps={{}}
                />
              </TableCell>
              <TableCell>
                <Button variant={"contained"} onClick={_addOneUser}>
                  추가
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
      <Container maxWidth={"lg"} sx={{ mt: 3, mb: 3 }}>
        <Typography gutterBottom variant={"h6"}>
          리스트
        </Typography>
        <Typography>{newTeacherUsers.length}명의 선생님을 등록합니다.</Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <LoadingButton loading={checking} disabled={checking || newTeacherUsers.length < 1} variant={"outlined"} onClick={_checkExistingUsers}>
            기존 데이터 중복체크
          </LoadingButton>
          <Button disabled={uploading || checking || !checkValid} sx={{ ml: 2 }} variant={"outlined"} onClick={_handleUpload}>
            올리기
          </Button>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>닉네임</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>핸드폰</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {newTeacherUsers.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      // as={Link} to={`/admin/teacher/${user.id}`}
                    >
                      <Avatar sx={{ mr: 1, width: "2rem", height: "2rem" }} src={user?.profileImageUrl} />
                      {user.name}
                    </Box>
                  </TableCell>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        if (!window.confirm("삭제하시겠습니까?")) return;
                        const a = [...newTeacherUsers];
                        a.splice(index, 1);
                        setNewTeacherUsers(a);
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {newTeacherUsers.length < 1 && (
          <Box className={"bg-gray-100 p-2 mt-2 text rounded"}>
            <Typography className="text-center">등록된 리스트가 없습니다.</Typography>
          </Box>
        )}
      </Container>

      <Dialog open={excelUploadDialogOpen} onClose={_handleCloseExcelUploadDialog}>
        <DialogTitle>엑셀로 업로드</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "450px",
          }}
        >
          <DialogContentText mb={2}>엑셀파일로 업로드 합니다.</DialogContentText>
          <Button variant={"outlined"} onClick={_downloadTemplateExcel}>
            양식 다운로드
          </Button>
          <Button sx={{ ml: 2 }} variant={"outlined"} onClick={() => fileInputRef?.current?.click()}>
            <input hidden type={"file"} ref={fileInputRef} onChange={_readExcel} />
            엑셀파일 선택
          </Button>
          <Divider sx={{ mt: 2, mb: 3 }} />
          {newTeacherUserCandidatesFromExcel?.length > 0 ? (
            <Box>
              <Typography p sx={{ mt: 1 }}>
                {newTeacherUserCandidatesFromExcel?.length}건의 데이터가 있습니다.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography>파일을 선택해 주세요.</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={uploading} disabled={uploading} onClick={_handleCloseExcelUploadDialog}>
            취소
          </LoadingButton>
          <LoadingButton loading={uploading} disabled={uploading || newTeacherUserCandidatesFromExcel?.length < 1} onClick={_addManyUser}>
            등록
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const enhance = connect(
  (state) => ({
    ...state,
  }),
  {},
);

export default enhance(AdminTeacherCreatePage);
