export const questionTypeText = {
  VIDEO: "동영상",
  IMAGE: "사진",
  TEXT: "글",
  FILE: "파일",
};

export const courseTypeText = {
  REGULAR: "교과",
  IRREGULAR: "비교과",
};

export const semesterTypeText = {
  SPRING: "봄",
  SUMMER: "여름방학",
  FALL: "가을",
  WINTER: "겨울방학",
};

export const userBookReadStatuses = {
  BEFORE: "BEFORE",
  ING: "ING",
  DONE: "DONE",
  PAUSE: "PAUSE",
  STOP: "STOP",
};

export const readStatusText = {
  BEFORE: "읽고 싶은",
  ING: "읽는 중",
  DONE: "읽음",
  PAUSE: "잠시 멈춘",
  STOP: "멈춤",
};

export const userTypeText = {
  STUDENT: "학생",
  TEACHER: "선생님",
};

export const notificationType = {
  STUDENT_COURSE_INVITED: "STUDENT_COURSE_INVITED", // 학생이 초대 되었을 때,
  STUDENT_TASK_PUBLISHED: "STUDENT_TASK_PUBLISHED", // 새로운 과제가 출제 되었을 때,
  STUDENT_BOOK_REPORT_COMMENT: "STUDENT_BOOK_REPORT_COMMENT", // 독후감에 댓글 달렸을 때,

  TEACHER_NEW_BOOK_REPORT: "TEACHER_BOOK_REPORT_SUBMITTED", // 새로운 과제가 제출되었을 때,
  TEACHER_NEW_COURSE_REGISTER: "TEACHER_NEW_COURSE_REGISTER", // 학생이 새롭게 등록하였을 때,
  TEACHER_BOOK_REPORT_COMMENT: "TEACHER_BOOK_REPORT_COMMENT",
};

export const notificationTypeObj = {
  STUDENT_COURSE_INVITED: { type: "STUDENT_COURSE_INVITED", name: "수업에 초대", description: "수업에 초대되었어요." }, // 학생이 초대 되었을 때,
  STUDENT_TASK_PUBLISHED: { type: "STUDENT_TASK_PUBLISHED", name: "새로운 과제 출제", description: "새로운 과제가 출제되었어요." }, // 새로운 과제가 출제 되었을 때,
  STUDENT_BOOK_REPORT_COMMENT: { type: "STUDENT_BOOK_REPORT_COMMENT", name: "독후감 새 댓글", description: "독후감에 새로운 댓글이 달렸어요." }, // 독후감에 댓글 달렸을 때,
};
