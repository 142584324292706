import {Link} from "react-router-dom";
import {PATH_TEACHER} from "../../routes/paths";
import numeral from "numeral";
import dayjs from "dayjs";

const Course = ({course}) => {
  if (!course.id) return;
  return (
    <Link to={PATH_TEACHER.course.detail.root(course.id)}>
      <div className={'flex p-4 hover:bg-hover cursor-pointer'}>
        <img src={course.coverImageUrl} className={'w-[80px] h-[80px] rounded'}/>
        <div className={'pl-4'}>
          <p className={'line-clamp-1'}>
            {course.title}
          </p>
          <div>
            <p className={'text-sm text-gray-500'}>
              (인원) {numeral(course.stat.registerCount).format("0,0")}
            </p>
            <p className={'text-sm text-gray-500'}>
              ({dayjs(course.startDate) > dayjs() ? "시작전" : dayjs(course.startDate) <= dayjs() && dayjs(course.endDate) > dayjs() ? "진행중" : "종료"}) {dayjs(course.startDate).format('YYYY.MM.DD')} - {dayjs(course.endDate).format('MM.DD')}
            </p>
          </div>

        </div>
      </div>
    </Link>
  )
}

export default Course
