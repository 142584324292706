import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import dayjs from "dayjs";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
  Button,
  Container,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  Stack,
  Chip,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
  Link as LinkMui,
  Tab,
} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import NetworkStudent from "../../lib/NetworkStudent";
import LoadingComponent from "../../components/shared/LoadingComponent";
import {courseTypeText, semesterTypeText} from "../../constants/dataTypes";
import {PATH_STUDENT} from "../../routes/paths";
import Scrollbar from "../../components/NOT_Classified/Scrollbar";

const StudentCourseDetailPage = (props) => {
  const {courseId} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const tab = query.get("tab") || "TASK";

  const [isLoaded, setIsLoaded] = useState(false);
  const [applying, setApplying] = useState(false);

  const [course, setCourse] = useState({});
  const [meta, setMeta] = useState({total: 0});
  const [tasks, setTasks] = useState([]);
  const LIMIT = 10;

  const _loadCourse = async () => {
    if (!courseId) return window.alert("수업 아이디가 올바르지 않습니다.");

    try {
      const res = await NetworkStudent.courseGET({courseId});
      setCourse(res.course);
      setIsLoaded(true);

      if (!!res.course?.courseRegister) {
        _loadTasks();
      }
    } catch (e) {
      setIsLoaded(false);
      window.alert(e.cause?.errorMessage);
      // window.alert(e.cause?.errorMessage || e);
      navigate("/");
    }
  };
  useEffect(() => {
    _loadCourse();
  }, [courseId]);

  const _handleRegisterCourse = async () => {
    const a = window.confirm("등록하시겠습니까?");
    if (!a) return;
    try {
      setApplying(true);
      const res = await NetworkStudent.courseRegisterPOST({courseId});
      window.alert("신청이 완료되었습니다");
      await _loadCourse();
      await _loadTasks();
    } catch (e) {
      window.alert(e.cause.errorMessage);
    } finally {
      setApplying(false);
    }
  };

  const _loadTasks = async () => {
    if (!courseId) return;
    try {
      const res = await NetworkStudent.tasksGET({
        courseId,
        offset: ( page - 1 ) * LIMIT,
        limit: LIMIT,
      });
      if (!res.err) {
        setTasks(res.tasks);
        setMeta(res.meta);
        setIsLoaded(true);
      }
    } catch (e) {
      alert(e.cause?.errorMessage);
    }
  };

  useEffect(() => {
    if (!isLoaded) return;
    _loadTasks();
  }, [page]);

  useEffect(() => {
    if (!!course.courseRegister?.id) {
    }
  }, [course]);

  const _handleChangeTab = (selectedTab) => {
    navigate(`${location.pathname}?tab=${selectedTab}`, {replace: true});
  };

  if (!isLoaded) return <LoadingComponent/>;

  const _renderBookReportMy = (task) => {
    if (!task?.bookReportMy) {
      return <Chip variant="contained" color="error" label="미작성"/>;
    }
    const isSubmitted = !!task.bookReportMy.submittedAt;
    if (isSubmitted) {
      return `${dayjs(task.bookReportMy.submittedAt).format("MM.DD(ddd) HH:mm")}`;
    } else {
      return <Chip variant="contained" color="warning" label="미제출"/>;
    }
  };

  const _renderApplyPart = () => {
    if (course.courseType === "REGULAR") {
      return (
        <Box className="border border-warning-500 bg-warning-50 p-4 rounded-[18px] mt-8">
          <h4 className="text-warning-500 mb-2">교과 수업입니다.</h4>
          <p className="text-warning-500">교과 수업은 선생님이 초대한 학생만 확인할수 있습니다.</p>
        </Box>
      );
    } else if (dayjs().isBetween(course.registerStartDate, course.registerEndDate, 'day','[]')) {
      return (
        <Box className="border border-gray-500 bg-gray-50 p-4 rounded-[18px] mt-8">
          <h4 className="text-gray-500 mb-2">수업에 참가 신청할 수 있어요.</h4>
          <p className="text-gray-500">
            수업을 참가 신청하면, 담당 선생님께 알림이 가요 :){"\n"}
            수업 참가 신청 취소는 담당 선생님만 할 수 있으니, 신중하게 신청해 주세요.
          </p>
          <Button variant="contained" onClick={_handleRegisterCourse} sx={{mt: 2}}>
            참가신청하기
          </Button>
        </Box>
      );
    } else {
      return (
        <Box className="border border-warning-500 bg-warning-50 p-4 rounded-[18px] mt-8">
          <h4 className="text-warning-500 mb-2">모집 종료일이 지났습니다.</h4>
          <p className="text-warning-500">모집 종료일까지 신청할 수 있습니다. 참가가 필요한 경우, 담당 선생님께 직접 문의해 주세요.</p>
        </Box>
      );
    }
  };

  // 등록된 학생이 아닐 경우
  if (!course.courseRegister) {
    return (
      <Container maxWidth="lg">
        <Box sx={{p: 2, borderWidth: 1, borderRadius: 1}}>
          <Stack direction="row" spacing={1} sx={{mb: 2}}>
            <Chip label={courseTypeText[course.courseType]} color="success"/>
            {course.courseType === "REGULAR" && <Chip label={course.subject?.title} color="warning"/>}
          </Stack>
          <Typography variant="h5">{course.title}</Typography>
          <Typography sx={{mt: 2}}>
            <b>{course.semester?.year}</b>학년도 - <b>{semesterTypeText[course.semester?.term]}</b>학기
          </Typography>
          {
            course.courseType ==='IRREGULAR' && (
              <Typography>
                모집시작: {dayjs(course.registerStartDate).format("YYYY.MM.DD(ddd) HH:mm")} <br/>
                모집종료: {dayjs(course.registerEndDate).format("YYYY.MM.DD(ddd) HH:mm")}
              </Typography>
            )
          }
          <Typography>
            시작: {dayjs(course.startDate).format("YYYY.MM.DD(ddd)")} <br/>
            종료: {dayjs(course.endDate).format("YYYY.MM.DD(ddd)")}
          </Typography>

          <Box className="flex">
            선생님:{" "}
            <LinkMui as={Link} to={PATH_STUDENT.teacher.detail(course?.teacherUserId)} className="text-[#1370F5] line-clamp-1 w-[150px] ml-2">
              {course?.teacherUser?.name} ({course?.teacherUser?.displayName})
            </LinkMui>
          </Box>
        </Box>
        {_renderApplyPart()}
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg">
        <Box sx={{p: 2, borderWidth: 1, borderRadius: 1}}>
          <Stack direction="row" spacing={1} sx={{mb: 2}}>
            <Chip label={courseTypeText[course.courseType]} color="success"/>
            {course.courseType === "REGULAR" && <Chip label={course.subject?.title} color="warning"/>}
          </Stack>

          <Typography variant="h5">{course.title}</Typography>
          <Typography sx={{mt: 2}}>
            <b>{course.semester?.year}</b>학년도 - <b>{semesterTypeText[course.semester?.term]}</b>학기
          </Typography>

          <Box className="flex">
            선생님:{" "}
            <LinkMui as={Link} to={PATH_STUDENT.teacher.detail(course?.teacherUserId)} className="text-[#1370F5] line-clamp-1 w-[150px] ml-2">
              {course?.teacherUser?.name} ({course?.teacherUser?.displayName})
            </LinkMui>
          </Box>
        </Box>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider", mb: 3, mt: 2}}>
            <TabList onChange={(e, newValue) => _handleChangeTab(newValue)}>
              <Tab label={`과제 (${meta.total})`} value="TASK"/>
              <Tab label="수업 정보" value="INFO"/>
            </TabList>
          </Box>
          <TabPanel value="TASK">
            <Pagination
              sx={{my: 3}}
              page={page}
              count={parseInt(meta.total / LIMIT) + ( meta.total % LIMIT === 0 ? 0 : 1 )}
              renderItem={(item) => <PaginationItem component={Link} to={`${location.pathname}?page=${item.page}`} {...item} />}
            />
            <Scrollbar>
              <TableContainer sx={{minWidth: 800, position: "relative"}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>제목</TableCell>
                      <TableCell>시작일</TableCell>
                      <TableCell>종료일</TableCell>
                      <TableCell>나의 제출현황</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks?.map((task) => (
                        <TableRow key={task.id}>
                          <TableCell>
                            <LinkMui as={Link} to={PATH_STUDENT.task.detail(task?.id)} className="text-[#1370F5] line-clamp-1 w-[150px]">
                              {task?.title || "-"}
                            </LinkMui>
                          </TableCell>
                          <TableCell>{dayjs(task.startDate).format("MM.DD (ddd)")}</TableCell>
                          <TableCell>{dayjs(task.endDate).format("MM.DD (ddd)")}</TableCell>
                          <TableCell>{_renderBookReportMy(task)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TabPanel>
          <TabPanel value="INFO">
            <Typography variant="body" className="whitespace-pre-line">
              {course.description}
            </Typography>
          </TabPanel>
        </TabContext>
      </Container>
    );
  }
};

const enhance = connect(
  (state) => ( {
    ...state,
  } ),
  {},
);

export default enhance(StudentCourseDetailPage);
