import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Card, CardContent, Divider, CardActions, Container, Grid, Button, CardHeader, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {PATH_ADMIN} from '../../routes/paths';

const AdminHomePage = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])


  return (
    <Container maxWidth={'lg'}>
      <Grid container spacing={4}>
        {
          [
            {
              title: '선생님',
              description: '선생님을 검색하거나 새로운 선생님을 등록할 수 있어요. 선생님별 수업리스트를 확인할 수 있습니다.',
              path: PATH_ADMIN.teacher.root,
            },
            {
              title: '학생',
              description: '학생을 검색하거나, 등록할 수 있어요. 학생 정보 수정, 학생별 독후감 작성 내역 등을 관리할 수 있습니다.',
              path: PATH_ADMIN.student.root,
            },
            {
              title: '학급',
              description: '연도별로 학급을 조회할 수 있습니다. 학급별 학생을 조회/등록/제거하거나, 담임 선생님을 조회/변경할 수 있어요',
              path: PATH_ADMIN.class.root,
            },
            {
              title: '수업',
              description: '개설된 수업 리스트를 확인할 수 있습니다. 수업별 과제 현황과 학생현황을 볼 수 있습니다.',
              path: PATH_ADMIN.course.root,
            },
          ].map((item, index) => {
            return (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{borderRadius: 1}}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                  <Divider/>
                  <CardActions>
                    <Link to={item.path}
                          as={Link}>
                      <Button>
                        보기
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </Container>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(AdminHomePage);
